import React from "react";
import Cms from "./cms";

class Trust extends React.Component {
    render() {
        return (
            <div className="box box--end">
                <div className="container">
                    <div className="text-center mb-xs-3">
                        <h2 className="title title--primary">
                            <Cms
                                content={this.props.cms_content}
                                content_key="trust_1"
                                type="title"
                            />
                        </h2>
                        <p className="paragraph mw-670 center-block">
                            <Cms
                                content={this.props.cms_content}
                                content_key="trust_1"
                                type="text"
                            />
                        </p>
                    </div>

                    <div className="box row flex-row">
                        <div className="col-sm-6 col-md-4">
                            <div className="question-box text-center">
                                <figure className="question-box__image">
                                    <img
                                        src="/assets/images/anonymized-data.svg"
                                        alt="anonymized-data"
                                        loading="lazy"
                                    />
                                </figure>
                                <span className="question-box__title">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="trust_3"
                                        type="title"
                                    />
                                </span>
                                <p className="question-box__body">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="trust_3"
                                        type="text"
                                    />
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <div className="question-box text-center">
                                <figure className="question-box__image">
                                    <img
                                        src="/assets/images/hardcore-security.svg"
                                        alt="hardcore-security"
                                        loading="lazy"
                                    />
                                </figure>
                                <span className="question-box__title">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="trust_4"
                                        type="title"
                                    />
                                </span>
                                <p className="question-box__body">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="trust_4"
                                        type="text"
                                    />
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <div className="question-box text-center">
                                <figure className="question-box__image">
                                    <img
                                        src="/assets/images/standardized-indexing.svg"
                                        alt="standardized-indexing"
                                        loading="lazy"
                                    />
                                </figure>
                                <span className="question-box__title">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="trust_5"
                                        type="title"
                                    />
                                </span>
                                <p className="question-box__body">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="trust_5"
                                        type="text"
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Trust;
