import React from "react";
import RedBlock from "../containers/redBlock";
import Cms from "./cms";

class CaseStudy extends React.Component {
    render() {
        return (
            <RedBlock link="https://infogram.com/reelmetrics-case-study-parx-casino-1hnp27q7xdky4gq">
                <div className="col-lg-3">
                    <div className="text-center">
                        <img
                            src="/assets/images/parx_logo_white.png"
                            alt="parx-logo-img"
                            loading="lazy"
                            width="200"
                        />
                    </div>
                </div>
                <div className="text-center col-lg-6">
                    <h2 className="title hidden-md hidden-lg">Case Study</h2>
                    <h2 className="title hidden-sm hidden-xs">
                        Parx Case Study
                    </h2>
                    <p className="paragraph">
                        <Cms
                            content={this.props.cms_content}
                            content_key="logos"
                            type="text"
                        />
                        Click to read how the Philadelphia powerhouse leveraged
                        ReelMetrics to produce{" "}
                        <span className="text--emphasized">
                            record performance
                        </span>{" "}
                        and pull-off its{" "}
                        <span className="text--emphasized">
                            most successful slot buy ever
                        </span>
                        .
                    </p>
                </div>
                <div className="col-lg-3 hidden-md hidden-sm hidden-xs">
                    <div className="text-center">
                        <img
                            src="/assets/images/parxpdf.png"
                            alt="parx-pdf-img"
                            className="image-tilt-right"
                            loading="lazy"
                            width="200"
                        />
                    </div>
                </div>
            </RedBlock>
        );
    }
}
export default CaseStudy;
