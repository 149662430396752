import React from "react";

const ReelCastBanner = () => {
    return (
        <section className="box box--primary box-background--slot_symbols">
            <div className="container ">
                <div className="row">
                    <div className="col-sm-3 hideOnMobile">
                        <figure
                            className="team"
                            style={{
                                backgroundImage: `url("https://cdn.buttercms.com/qb6jz6ndT4KPBRvwjc9A")`
                            }}
                        >
                            <figcaption>
                                <span className="team__name">Nick</span>
                                <span className="team__function">
                                    Shameless Plugger
                                </span>
                            </figcaption>
                        </figure>
                    </div>

                    <div className="col-sm-6 text-center">
                        <h2 className="title mb-xs-2">
                            We&rsquo;d love to hear from you!
                        </h2>
                        <p className="paragraph--small paragraph--small--small-screen white-text">
                            At ReelCast, we&apos;re committed to delivering
                            data-driven insights and expert advice to help your
                            slot team thrive. Whether you have questions about
                            inventory overdiversification, premium slots, best
                            practices, or if you have specific topics you&rsquo;d like
                            us to cover, we&apos;re here to listen. Our team is
                            dedicated to providing the best possible experience
                            for our listeners, and we welcome any feedback you
                            may have.{" "}
                        </p>
                        <div
                            className="btn btn-lg btn-white"
                            data-toggle="modal"
                            data-target="#contact"
                        >
                            Contact
                        </div>
                    </div>
                    <div className="col-sm-3 hideOnMobile">
                        <figure
                            className="team"
                            style={{
                                backgroundImage: `url("https://cdn.buttercms.com/CdkaK82RRFe1ZsP6HH7o")`
                            }}
                        >
                            <figcaption>
                                <span className="team__name">Don</span>
                                <span className="team__function">
                                    Degenerate Gambler
                                </span>
                            </figcaption>
                        </figure>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ReelCastBanner;
