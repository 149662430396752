import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

function ModalComponent({ children, show, onHide }) {
    const [isOpen, setIsOpen] = useState(show);

    useEffect(() => {
        setIsOpen(show);
    }, [show]);

    const handleClick = () => {
        setIsOpen(false);

        if (onHide) {
            onHide();
        }
    };

    return (
        <Modal
            className={`react-modal ${show ? "in" : ""}`}
            show={isOpen}
            onHide={onHide}
        >
            <Modal.Body>
                <figure className="react-modal-close">
                    <img
                        src="/assets/images/close.svg"
                        alt="close"
                        role="presentation"
                        onClick={handleClick}
                    />
                </figure>
                {children}
            </Modal.Body>
        </Modal>
    );
}

ModalComponent.propTypes = {
    children: PropTypes.any,
    show: PropTypes.bool,
    onHide: PropTypes.func
};

ModalComponent.defaultProps = {
    children: <div />,
    show: false,
    onHide: null
};

export default ModalComponent;
