import React from "react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";

class S01E02 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname);
    }

    // calls API and attaches scriptt to DOM
    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);

        if (Helmet.canUseDOM) {
            Helmet.renderStatic();
        }
    }

    // checkes if component should update based on props in Helmet
    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }

    // ensures Helmet sets teh code when page loads
    componentDidUpdate() {
        if (Helmet.canUseDOM) {
            Helmet.renderStatic();
        }
    }
    
    render() {
        return (
            <>
                <Helmet>
                    <title>
                        {this.props.title} | {this.props.subTitle} | ReelCast by ReelMetrics the world&apos;s best slot analytics data platform
                    </title>
                    <meta
                        name="Description"
                        content={this.props.description}
                    />
                    <meta
                        name="Keywords"
                        content="High-value players, Low-value players, Denomination strategies, Casino floor optimization, Obsolete cabinets, Long-term strategic planning, ReelMetrics tools, Fair share analysis, Slot floor management, Gaming performance evaluation, Premium machines, Core machines, Evaluation cycles, Player preferences"
                    />
                    <link
                        rel="canonical"
                        href="https://www.reelmetrics.com/s01e02-inventory-overdiversification-part-ii"
                    />

                    {/* Open Graph / Facebook / LinkedIn */}
                    <meta property="og:type" content="website" />
                    <meta
                        property="og:url"
                        content="https://www.reelmetrics.com/s01e02-inventory-overdiversification-part-ii"
                    />
                    <meta
                        property="og:title"
                        content="Inventory Overdiversification - Part II"
                    />
                    <meta
                        property="og:description"
                        content="We continue our discussion on overdiversification and will explore the process of identifying and addressing underperforming areas on the casino floor. We will discuss how to analyze data to make data-driven decisions to improve casino performance and revenue."
                    />
                    <meta
                        property="og:image"
                        content="https://cdn.buttercms.com/OaWFV5QDqgOJxaKViwdg"
                    />
                    <meta property="og:locale" content="en_US" />
                </Helmet>
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}

S01E02.defaultProps = {
    id: "11607232-s01e02-inventory-overdiversification-part-ii",
    link: "s01e02-inventory-overdiversification-part-ii",
    title: "Inventory Overdiversification",
    subTitle: "Part II",
    buzzSproutLink:
        "https://reelcast.buzzsprout.com/2057836/11607232-s01e02-inventory-overdiversification-part-ii",
    spotifyLink:
        "https://open.spotify.com/episode/7gsOkp8fFhDr3mPkhQn5A2?si=bORXp9ayTdG2xPBJKiF_-Q",
    applePodcastsLink:
        "https://podcasts.apple.com/us/podcast/s01e02-inventory-overdiversification-part-ii/id1652485625?i=1000584638557",
    iHeartRadioLink:
        "https://www.iheart.com/podcast/269-reelcast-by-reelmetrics-104029260/episode/s01e02-inventory-overdiversification-part-ii-104029261/",
    cdcLink:
        "https://cdcgaming.com/reelmetrics/s01e02-inventory-overdiversification-part-ii/",
    description:
        "We continue our discussion on overdiversification and will explore the process of identifying and addressing underperforming areas on the casino floor. We will discuss how to analyze data to make data-driven decisions to improve casino performance and revenue.",
    keywords: [
        "High-value players",
        "Low-value players",
        "Denomination strategies",
        "Casino floor optimization",
        "Obsolete cabinets",
        "Long-term strategic planning",
        "ReelMetrics tools",
        "Fair share analysis",
        "Slot floor management",
        "Gaming performance evaluation",
        "Premium machines",
        "Core machines",
        "Evaluation cycles",
        "Player preferences"
    ],
    topics: [
        "Understanding the importance of managing casino floor inventory for high-value and low-value players",
        "Transitioning out obsolete cabinets and optimizing the casino floor for better results",
        "Creating a long-term strategic plan for a successful casino operation",
        "How ReelMetrics can provide data-driven insights and fair share analysis to optimize revenue",
        "Evaluating game performance and adjusting configurations to improve revenue"
    ],
    transcript: `Nick Hogan:
    At ReelMetrics. We collect and analyze a ton of slot data and we're hit up frequently for insights, tips, and tricks. So we decided to do a webcast in which we present, discuss, and otherwise nerd out on our work. Coming to you from our offices in lovely Leiden in the Netherlands, welcome to ReelCast.
    Good morning, Don. How are things today in the greater St. Louis area?
     Don Retzlaff:
    They're fine, Nick. Any jet lag from driving home or flying to Las Vegas?
    Nick Hogan:
    Oh yeah, I'm completely raggled, upside down, nothing new there. That took a couple weeks following the show to visit some clients and new folks around the US and so just hit Holland about 36 hours ago and arrived to what is the balmiest and sunniest late October in Dutch history. So it's trippy. It's about 70 degrees Fahrenheit, 21 Celsius here today, which is really weird for this time of here, so whatever that is.
     Don Retzlaff:
    That's a nice bonus.
    Nick Hogan:
    Yeah, yeah, yeah, it's okay. Not complaining, not complaining. So here we are, our second episode. And out of the gate, I have to say was pretty pleased with how the first was received. Remarkably, people actually listened and we received some nice comments from folks, so that was great. There were a few-
     Don Retzlaff:
    That was very nice.
    Nick Hogan:
    Yeah, for sure, for sure. There were a few requests that we get this into the various podcasting services and we're working on that. And of course, we all had great fun comparing your voice to mine. So I think we landed on you having the silky baritone of a Barry White, whereas I landed closer to Paul Lynde, as we like to say. And here's the kicker, while I was in the Midwest, to my horror, I think I discovered that there's some Ethel Merman in there also.
     Don Retzlaff:
    Well, that's a name I haven't heard in a while.
    Nick Hogan:
    Yeah, exactly. I need to update my references here, Two people who haven't been alive for 40 years or whatever. So no good, no good. But at least I can't be accused of loving the sound of my own voice, so I'm okay there. Also, we have another G2E in the tank. It was a great show, attendance was way up, the international vendors were, back parties returned. And we were so slammed with meetings and demos that I think I clocked a max of around 30 minutes on the floor. So considering the time, effort, and money that the vendors pour into these shows, certainly wouldn't be fair for me to comment about anything I saw. But I noticed that you managed to get a couple hours down there, so just wanted to ask you, did anything catch your eye that you saw there this year?
     Don Retzlaff:
    Yeah, a couple. I only got to spend about three, four hours down there. Like you said, we had a lot of meetings with clients and potential clients, but what I did see, I had a really nice tour of the IGT booth. That DiamondRS Reel cabinet is sharp and I'm not a big Reel fan. I went probably 10 years without buying a Reel product, everything was video, video, video. But they did a really nice job with that DiamondRS cabinet. The Neptune Single from Aristocrat looks nice. They've done a really nice job, their graphics are amazing. On the smaller side-
    Nick Hogan:
    Yeah, I did see that package. That was sharp, for sure.
     Don Retzlaff:
    Yeah, they've come such a long way. I remember going out and buying Mk fours and Mk fives back in the day, and you can see the dots on the screen from the graphics, and they've come such a long way. The other one that caught my eye from a smaller vendor was the Gaming Arts, the VertX Grand. That's a sharp looking cabinet. The graphics on it are really good, so we'll see if their games perform, but it sure is a sharp looking cabinet. But everything is so big nowadays. I feel sorry for the VPs of slots out there that have to try to worry about sight lines on their floor because everything is six foot, seven foot, eight foot tall.
    Nick Hogan:
    Yeah. And the riverboat operators with those low ceilings, I'm sure that stitches them up pretty solidly.
     Don Retzlaff:
    Yeah, there's a lot of places that can't even put the towers in because of the height. So yeah, everything's getting taller and wider.
    Nick Hogan:
    Okay. Yeah, I have to say, I did take a look at the Neptune Single, that one. I think you and I were together when I saw that one. Not entirely certain. Okay. And then we went over to, do you recall the name of the new L&W cabinet that we saw there?
     Don Retzlaff:
    I don't think they've named that one yet.
    Nick Hogan:
    Okay.
     Don Retzlaff:
    I think that was still a working title.
    Nick Hogan:
    All right. That was a cool looking new machine, too. It was looked pretty sharp, too. Okay, cool. Well, I think what we can do now is we can get cracking on the back half of our two-parter on Inventory Overdiversification. That is, Don, did you have any other observations that you want to share about G2E or were those the-
     Don Retzlaff:
    No, let's rock on this.
    Nick Hogan:
    Okay. All right, very good. Before we jump in though, let me state again that we'd love to tackle any questions that anyone listening may have. So if you have a question about what we're presenting or something that you'd like us to present, please drop us an email at reelcast@reelmetrics.com. Again, that's R-E-E-L-C-A-S-T@reelmetrics.com. Our policy here is to keep all questions anonymous, so please speak directly and don't worry about us revealing your identity, that's just not something we do.
    Okay. So without further ado, let's get at it. In the first part on overdiversification we focused on these points here. So we asked, what is over diversification? Why should I care about it? Industry-wide, how problematic is it? And how did we get there? So we covered all those points in the first, and now we're on to these two questions, which is, how do I know if my floor is over-diversified? And if it is, what can I do about it?
    So we'll jump in here, how we're teasing out imbalances in inventory. And what I'm going to do, is I'm going to start with a preamble here about the importance of operations and marketing collaborating when it comes to looking and attempting to achieve inventory balance. So getting your product balanced out on the floor. Without player data and not knowing who's playing what, trying to balance a floor is truly a fool's errand. And within our best practices stack, we spend a lot of time focusing on the idea of what we call demand-driven mix management. And that is we always start with the players that are really making or breaking our operations and really focusing on what their preferences are and then moving down from there in descending order from perspective of customer value. So it's incredibly important when we're doing this that we have an idea, a very, very clear notion actually, of who's playing what.
    So with that in mind, when we're first taking a look at diversification, let's start with the host-level play, and let's ask the question, what are they, not just what they're playing, but what are they pounding? What is this stuff that they love, that they come back trip after trip and just hammer? And then it's asked the question, do they struggle to get on these games? And good indicators of that, I mean, obviously if you're looking at, you're comparing high occupancy and low occupancy periods, you see it. But a good indicator of that is that the mean occupancy on a given game exceeds something like 70%.
    And the other indicator of it is with these host-level players, if you see them ratcheting up, let's say, a large number of fragmented sessions, that ricochet effect that we talked about in the previous episode, where we see short bursts on low-demand product and then never returning to it. So these are great indicators that you have a diversification problem when you just don't have enough stuff for your best players, then there's doggier stuff on the floor that's crowding it out. And so if you're under supplying host-level titles, I think you can just conclude that you're over-diversified, especially vis-a-vis your most important player segment. So Don, is there anything you you'd like to add in this point here?
     Don Retzlaff:
    Yeah, the one thing I always looked at was handle pulls per day. Most people run a report that'll give your handle pulls on a monthly basis, and you'll see those handle pulls, especially for your really good games, being north of 250,000 for the month. Look at the ones for the weekends. If your host-level players can't get on the games because these games are having seven, eight, 10,000 handle pulls on a day, it's probably a really good sign that they can't get on it.
    Plus, the other thing is, listen to your customers. I was getting hints back at the property level of not having enough of the good machines on the floor. Your host-level players will tell you, "Boy, I really like playing this game, but I just can't get on it." There's a lot of hints out there between besides handle pulls, what your hosts are telling you, what your players are telling you, you'll figure it out really soon. But the big thing, like what you said was, trying to figure out what these host-level players are playing. Get into the system, try to figure it out, because that's what you really want to expand upon.
    Nick Hogan:
    Yeah. And as we had mentioned in the previous episode, we've seen that when they're undersupplied on the stuff that they want, the productivity of those host-level players just plummets. So I think as what we had discussed and what we'd seen in validations in the field, is that that productivity will drop something 50, 60, 70%, huge numbers. So it's really hard to overstate the importance of getting the host-level players exactly the product that they want to play.
     Don Retzlaff:
    One of the things that I've talked about with one of our clients recently was the number of host-level players that are sitting at machines near the high demand waiting for those to open, not playing, just sitting at machines. Because I asked him, "Why was the performance at some of these banks so much lower than I expected?" And they went back and researched it and they've said that, "We've got our best players sitting at these machines, so they're not getting any coin in. So the performance on these machines is going down and they're not playing at all. They're just sitting there waiting." And you don't want your best players just sitting around waiting.
    Nick Hogan:
    No, most certainly not. No, no, no way. Okay. Moving on, other hints, other ways that you can look at it is to perform what we call these titles per terminal calculations. So that's pretty simple. If you just take the total number of titles that you're running on the floor and then divide it by the number of cabinets. And what we find generally is that a number lower than four indicates over diversification. Now, one thing to keep in mind here is you want to do this a little bit focus on by game type obviously. So you do number of titles, let's say there's a number of slot titles divided by the number of slot cabinets. That's really what we're specifying there.
    And then another way to get at this is to run diversification analysis, which we ran through in the previous episode. That is, you ask the question, "Okay, out of our entire library of titles that we're running on the floor, how many of them are installed on just one unit, two or fewer, three or fewer, et cetera?" And then you calculate the performance per category. And those are index values as you see there on the chart. So we'd see singletons at X versus floor average, we'd see twins then at Y versus floor average, triplets at Z versus floor average, et cetera, et cetera. So it's just a really simple way for you to tease this out and just look at how this stuff performs when it's so categorized. Don, I don't know, do you want to add anything on this topic?
     Don Retzlaff:
    One thing, Nick, what I've looked at nearly probably 100 casinos in the last year, trying to dive into this problem. And the one thing I keep coming back to is how many titles is 50% of your revenue? The one I looked at yesterday, I had a meeting with one of our clients yesterday, 66 of their themes make up half of their revenue on the floor. And this casino has over 1500 machines on their floor and just 66 themes, and that's probably the highest one I've seen. Most of them are 50, 48, 52, say 50 plus or minus five. So 50 of your themes earn half of your revenue. That's amazing. And that'll give you a pretty good indication of how many themes that you really do need.
    Nick Hogan:
    And Don, what do you feel is really the big reservation behind doubling up and tripling up and things of that nature? When you have a title that's just really performing, I'll see this, let's say a four pack or a six-pack, and it seems to me that it's led by one or two titles yet they're, let's say on a six-pack, you may see six titles out there.
     Don Retzlaff:
    The strategy when you buy a game is try to get as much play as possible out of that bank. So traditionally, you would buy two of each theme, let's say on a six-pack or an eight pack, knowing that you're going to get trial. And that trial was going to account for something for your first 90 days. And then most of the time, you'd either use your performance guarantees and try something different, try to get more trial on those games because you knew for 90 days they do really well. We found that really doesn't work well, you're better off going with a single theme for two themes on a bank and knowing that those really good games are going to hold up for a long time and you're not wasting the slot tech's time, you're not wasting conversion capital. Or you're not putting on a game that's... Well, you're lucky that a third of the titles come out that are good titles, so if you're putting on three titles out of a bank of six, one of them is going to work.
    Nick Hogan:
    Yeah. And I think that's another area where I really feel that the best practice has changed because now we have the ability to screen stuff before we're actually putting it out on the floor. So I think you'd largely guide... It sounds like to me that if you are putting it in a six-pack or whatever, and you could screen two titles and you see that they have excellent performance and very little intermarket dispersion, I think you'd guide, "Just go with them." Right? "Just go with those titles and don't experiment there."
     Don Retzlaff:
    For the smaller vendors with the smaller libraries, I would definitely go with one theme on a bank. For the larger, the Light & Wonders or Aristocrat, IGTs of the world, you can probably get away with two because their library's pretty deep. But for the smaller ones, I would go with a bank of four or a bank of six and put their best theme out there. Their best themes are doing 1.3, 1.5 times house. So I'd be really happy with that from a smaller vendor.
    Nick Hogan:
    Okay. Great. Well, okay, another point that we want to emphasize here, another way to tease out imbalances, great place to always look is in your premium portfolio. And what we find there is that as we look at, as we conduct these diversification analyses, casino to casino, what we see is that the supply of these high-octane lease products is almost always too low. And as we've discussed previously, this disproportionately affects your host-level players who tend to really carry these high-octane lease products. And if your premium floor share is in the single digits, generally you can assume that you need more of them on floor. Again, Don, you want to add anything here?
     Don Retzlaff:
    Yeah, surprisingly, your host-level players are the ones that carry your lease games. Your core, every day, $100 theoretical players tend to shy away from the lease games. They spend a very small percentage of their wallet on lease games, but your host-level players will pound the good lease games with a very high average bet. So if you have one bank or two banks of these games and they're showing a occupancy rate above 85, 90%, you need to really expand because you're leaving money on the table.
    Nick Hogan:
    Yeah. And as we've been working more and more with player data, this is effectively where we start in every single venue, is looking at the lease portfolio and seeing what we can do there. And I believe in every single case that we've evaluated thus far, we've guided toward a pretty significant expansion of lease portfolios. A few of our clients are up in the 20% plus floor share on some of this stuff, but I would say the overwhelming majority, mid single digits, five, 6%. We see some as low as two to 4%. So generally speaking, we feel that when that's a case, and you see it in the occupancy numbers, you just have a balance problem on the floor, no doubt about it.
     Don Retzlaff:
    And the big problem was always, are we moving money around? And we've definitely proven that you can actually grow your host-level player's wallet. The one I looked at yesterday was a simple floor change and they grew their host-level players' wallet by 12%, and that's about as low as number we've seen so far.
    Nick Hogan:
    Yeah, that's the lowest I've heard so far. I mean-
     Don Retzlaff:
    Yeah. And they were very happy. You've got a group of players, 1000, 2000 players, you grow their wallet by 12%, that'll send any marketing person skipping down the hallways.
    Nick Hogan:
    Oh yeah.
     Don Retzlaff:
    They'll be super excited for that.
    Nick Hogan:
    But I would say in many of the, at least when we were playing with it in the field, we saw jumps of 50, 60, 70% in those, in a lot of them.
     Don Retzlaff:
    Especially for the casinos that had a very limited number of lease games, if you have one bank of Dragon Link, if you have one bank of Lightning Link and you expand that, if you double that triple that, the number, the percentage is just astounding. It was north of 50% as far as growth rates.
    Nick Hogan:
    Yeah, it's crazy. Those were all eye-popping numbers, every single thing that I saw. I know you had a few thank you notes from general managers as well as I recall.
     Don Retzlaff:
    I just didn't expect that type of result, neither did they and neither their marketing departments. So it's been some really neat validation studies.
    Nick Hogan:
    Well, it's interesting that you say you didn't expect it because it's precisely what we predicted.
     Don Retzlaff:
    I know. It's just tough to get around that. You've been trying to stay within your budget and keep those lease costs and those recurring costs down, control what you can control, and then you pop in 20, 30 more lease games and all of a sudden you see your host-level players react that way. It's just what you've gone against for the last 30 years.
    Nick Hogan:
    Yeah. And it's always just lovely when hard data validate themselves. I always love that. So that was fantastic. Okay, so moving on here. So let's say, "Okay, now I've determined through various analyses that I'm over diversified." So the question is, now what? When we look at floor consolidation within the short term, a number of things to tackle here. So first of all, where do we start? Again, host-level inventory. So we know that these host-level players have strong preferences for high-octane premium. And if you are seeing mean occupancy levels, let's say north of 70%, that's probably indicating under supply. I think is 70 a safe number Don, or do you look a little north of there, generally?
     Don Retzlaff:
    I've been a little more conservative and I've been north of 80, but if you're looking at the weekend numbers and if your weekend numbers alone are north of 80, you've got an issue. As a blended average for a month, if you're north of 70, your weekends are probably pretty packed.
    Nick Hogan:
    Okay. And generally, I think you and I have spoken you, do you generally target around that 65% mean occupancy, just overall?
     Don Retzlaff:
    Absolutely, especially for places that are open 24/7.
    Nick Hogan:
    Okay. It's a great rule of thumb then too to put out there. The other thing is, again, returning to that topic of the best practices stack and the fact that we can screen titles now, it's very easy to put in a performance baseline of 2.5X floor. So that's a very important thing to keep in mind. Once you have the product installed and you're watching it over time, you can maintain that baseline of 2.5 because there are so many options out there of product that's doing that reliably with next to no intermarket dispersion. And importantly, if you want to keep your overall unit count static, then the best thing to do is to add this product at the direct expense of your doggy, depreciated Singleton Core. So Don, any additional points here?
     Don Retzlaff:
    One of the clients I was working with yesterday, a fairly new client, their lease game against house average ratio was above five. And I looked at them, I talked to them and I said, "Congratulations, your lease games are doing super. That's a problem."
    Nick Hogan:
    Yeah.
     Don Retzlaff:
    I said, "You're leaving so much money on the table. If you've got lease games that are doing north of four and five times house average, your best players aren't getting on them and you're costing yourself money." So thankfully, they listened and I think they're going to do expand. Good time for budget season.
    Nick Hogan:
    Yeah. Great to hear that. No doubt. And indeed, a lot of people are looking at these decisions right now, so hopefully it's timely, what we're doing here. Secondly, on your Convertible Core. So there, obviously what you want to do, when I say Convertible Core, what we're saying is these are new cabinets. So let's say they're not depreciated, they're fewer than five years old and they have deep libraries. Again, here we can screen before we put this stuff on floor. So we're looking at a performance baseline of 1.5X floor. That should be your baseline. And you want to look for product that has large samples, again, very low inner market performance dispersion.
    And when you see that that stuff on your floor is working and that the right players are going for it, fight any reservation you may have about replicating these well-performing high-occupancy titles. Just put more of them out there. And simply, in these cases, exercise the free conversions or spend the money, because when you have just a boat anchor core product out there, it's not doing anything for you. And when host-level players or your more important players get on that stuff, it actually hurts you, as we covered in the previous episode. So Don, any more points on the Convertible Core?
     Don Retzlaff:
    Yeah. Carousels are banks of single title, good games work extremely well. Some of the classic titles from Aristocrat, from IGT, some of the new stuff from Aristocrat, if you don't have at least a bank of Buffalo Gold collection on your floor, you're missing out, if you just have two or three or four of them. And don't be afraid to denom up, keep the same titles on the floor, but there's a lot of really good host-level players that will play at a higher denomination. It's a great way to combat some of the higher labor costs that the casinos are experiencing. Put some of these games, keep them at pennies for your core players, but for your host-level players, have a section of these games at two cents or five cents. Don't be afraid of having a bank of pennies and a bank of two cents in a different area. They will all get played.
    Nick Hogan:
    And Don, is your thought on de-nomming up, is it to keep them free for your stronger players, to keep the riffraff off those machines?
     Don Retzlaff:
    Absolutely. Okay. Now, these players have a spot that they can get on those games, most likely. They'll still be busy on the weekends, but now they've got a pretty good spot that they know when they come to this casino, "I can play my Buffalo Gold collection because I know that that two cent bank or that nickel bank isn't going to have someone sitting there playing the minimum bet.
    Nick Hogan:
    Okay. Excellent point. So basically, don't be shy about pricing out the lower level players, I guess.
     Don Retzlaff:
    Absolutely.
    Nick Hogan:
    Yeah. Okay.
     Don Retzlaff:
    Same thing you've do for dollar Reels, you know, have the same titles at quarters that you halve the halves, you have a dollars. You can price out some of those lower players and make sure that your players with the deeper wallets can get that time on the vice.
    Nick Hogan:
    Yeah. Okay. Excellent point. Okay. And then we move into the non-Convertible Core, for lack of a better phrase. So this is the old cabinets, again, typically older than five years old. And really, what you're looking at here is boat anchor performance. So the bottom quarter of your floor, very, very, very low occupancy. So this is the stuff that you want to replace, quite frankly. And again, we go back to the same point. You want to use your capital, you want to deploy your capital toward the best customers first. So start with the host-level, higher value players, isolate their favorite core products, replicate those with the highest mean occupancy rates and overall performance. And again, your performance baseline on this stuff is 1.5X floor with very low intermarket dispersion. So Don, more points on the non- Convertible Core.
     Don Retzlaff:
    There's so much of the casino floors out there right now that just don't get played. You've got older, fully depreciated cabinets that are obsolete, you can't get conversions for them anymore. You can probably reduce the size of your casino. The big thing is making sure which ones to pull off. There are host-level players that their favorite game is still Queen of Atlantis. So we go back to the original point is working with marketing, making sure that you know who your top 100 players fourth and fifth favorite games are, because you're going to have a lot of choices to pull off a floor. Most of the casinos out there, especially post-COVID, can reduce their floors by another 15 to 25%. They're just not getting played. The last study I looked at said 20% of the machines on casino floors get played on average of once every four hours.
    Nick Hogan:
    Yeah. And when we're doing the cupid segmentation and all of those breakouts, what's always interesting to me is just the poorest product on the floor oftentimes comprises 55, 60% of the floor, just really not getting played or doing much. And this is really, it's a great segue into the next point, which is the transition plan. And this is something that we emphasize to everybody. So in the research that we've been doing, what we've seen is that floors are far more bottom-heavy than we'd thought previously. And so there really is a need to start transitioning that old inventory out that's really doggy and just pre-screening and getting better replacements in. So this begs the question, "Well, okay, how do we want to do this?" Because it's not as though anybody can big bang this. That would just be ridiculous in terms of capital requirements.
    So what we're doing with a number of clients where we're facing this issue, is we're putting in place multi-year transition plans. And that's really the name of the game here. So it's again, top-down prioritization, you start with your host-level players and then you move down descending order in terms of value. And just generally, what you want to decide here is, where am I going to expand and where am I going to contract? And then you want to limit procurement moving forward to stuff that's proven. Again, premium at a baseline at 2.5 X floor with low inner market dispersion core at 1.5X floor with low inner market dispersion and don't be afraid to double up. So that's the strategy moving forward.
    And then people ask, "Well, what do we want to do? What about just brand new stuff coming out that's not proven?" Well, you can certainly take it on and you should, but the idea here is that you want to devote a fixed percentage of your floor to experimentation. And then within that area, what you want to do is really limit that to trials and or short-term premium placements. And then ensure that moving forward, all purchases and leases support your long-term strategic plan. So any additional thoughts on that one, Don?
     Don Retzlaff:
    If you're going to trial some product or put some stuff on the floor that there's too many first to market type stuff, make sure you're giving that a good location. A lot of stuff has new math models and the new math models tend to only work in prime locations. We've seen a lot of them over the last few years as people have tried to do stuff out of the box. And if you put that in the middle of the floor, it's not going to get used. So you've got to have those in prime spots if you're going to give it a good chance at all for a success. And that's another reason why you want to limit it, because you don't want to have trials in all your prime spots.
    Nick Hogan:
    Now, why is that, Don? Why is it that those math models... Is it just that they just simply need more exposure or what's your thought there?
     Don Retzlaff:
    Yeah, it's a combination of needing more exposure and the loyalty scores on these math models. Since it's so new and so out of the box, the loyalty scores are really, really low. So you see a lot of people not going back to these games because it's just beyond their comfort level. And there's a couple of games that I've played that were just totally unique and I enjoyed them, but if you bury them, you just don't think about them. They've got to be in those prime spots.
    Nick Hogan:
    So one thing I've noticed about trials and test banks and whatnot, is I do feel that oftentimes the approach is just to slap them on the floor with without much in the way of a systematic approach to it. So how important do you feel it is to, let's say, experiment with the configurations on those machines and whatnot once they're in?
     Don Retzlaff:
    It's a really good point. Most of the time your trial banks and test banks are right up front, closest to the doors, closest to your walkways. They're mostly carousels and they'll have multiple titles on it and the success rate on them is low. And the good thing for the operators who have them is you end up getting free conversions out of it. The bad thing is, the cabinets that they put these on, they're obsoleting after three and four years. So you'd have these for a limited time. And any cabinet you buy now, even though you're depreciating after five or seven years, you're going to have that cabinet on your floor for 10 plus years, unless you're one of the very, very few lucky ones that's getting enough capital to flip out 15, 20% of your floor in cabinets every year. And those are gone the way of the dodo bird.
    Nick Hogan:
    Sure. Do you feel, on these, let's say you have a test banker or something that's new and experimental, et cetera, would you shorten the evaluation cycle on these things before you would make a configuration change? So let's say you maybe go jump in there and start tweaking after 30 days as opposed to 90 days, or how would you generally go about that?
     Don Retzlaff:
    Yeah. If you're not getting information from the manufacturer, as far as what the best whole percentage is, what the best line count is, things like that, I experiment every 30 days trying to find that sweet spot, adjust the line counts, adjust the whole percentage and see what it does. Or if you really wanted to, set up the games differently. If you've got two themes, put one at 10%, one at 12%, one at 40 lines, one at 50 lines, and see if there's a difference. 'Cause you end up stumbling on that sweet spot and you can have a nice game.
    Nick Hogan:
    Yeah. And I think probably you'd look at it and say, okay, and if it's not, let's say 1.5X floor mean by the end of it, you probably just want to lose that product. Would that be your guidance on that one?
     Don Retzlaff:
    Yeah. After 90 days, maybe 120 days. If it's not performing at one and a half times house average, time to move on.
    Nick Hogan:
    Yeah. Gotcha. Okay. And then I think just this last point, which is a bit of a shameless plug here, we would say. On the diversification front, a good shortcut is just to give us a ring. We're deeply involved in this right now and doing a number of these transitions for a number of clients, and we built in utilities into ReelMetrics for basically every practice that we've been discussing here today. So we've got a lot of cool features in here that help you root these things out, and then also to screen things before they're coming in. And then of course, Don, with the work y'all doing in professional services, it's really helping people analyze this upfront and then get into place that multi-year transition plan. Because I do feel that there are just so many trade-offs and sweet spots, and a lot of things to take into consideration and analyze here. So having a helping hand with somebody who knows the art inside out is unquestionably invaluable. Don, anything to add there?
     Don Retzlaff:
    We've recently rolled out in a nice beta test on some fair share analysis for every property. So when you would log into the site, it would automatically, the first thing you would see would be a 30,000-foot view of your property and it would show you where you were over diversified, what you're short, where you're heavy, what your next steps should be for removals, things like that. And then we actually drilled down to things like, what games you don't have on your floor that you should. So there's a lot of really neat tools in there and we keep developing and keep adding to it. We've got some ideas come out of G2E this year that will be working on for 2023 to make it even more impactful for the casino operators, to make it easier for you to your jobs.
    Nick Hogan:
    Those fair share calcs, they went over really well with people because a lot of the slot directors and whatnot, they're doing those on a monthly basis, and they all mentioned, "Man, it's a lot of work." So it was really cool to see that reception that now that when they go into ReelMetrics right up front, right in the operational overview, you can get all those fair share calculations all pre-compiled for you right out of the gate. And yeah, we received a lot of great compliments and thank-yous on that one, so that was cool, for sure. Okay. Well, I think, Don, that pretty much wraps us up for this episode. So again, for anybody who's listening today, if you would like to drop us an email about this webcast or anything we're discussing here today or something you'd like to discuss, please just drop us an email at reelcast@reelmetrics.com. Again, R-E-E-L-C A-S-T@reelmetrics.com. And Don, I think that wraps us up here.
     Don Retzlaff:
    Cool. Thanks, Nick.
    Nick Hogan:
    Okay. Thanks. You have a great day.
     Don Retzlaff:
    You too.
    Nick Hogan:
    All right. Thank you. Bye-bye.
     Don Retzlaff:
    Bye.
    `
};

export default S01E02;
