export const selectStyles = {
    optionsStyle: {
        input: base => ({ ...base, color: "#9fa0a1" }),
        singleValue: base => ({ ...base, color: "#9fa0a1" }),
        control: (base, { isFocused }) => ({
            ...base,
            backgroundColor: "#ffffff",
            boxShadow: "#d3d3d3",
            borderColor: "#d3d3d3",
            "&:hover": {
                boxShadow: "#d3d3d3",
                borderColor: isFocused ? "#b91d1a" : "#d3d3d3"
            }
        }),
        option: (base, { isSelected }) => ({
            ...base,
            color: isSelected ? "#ffffff" : "#9fa0a1",
            backgroundColor: isSelected ? "#b91d1a" : "default",
            "&:hover": {
                backgroundColor: isSelected ? "#b91d1a" : "#ffe3e3",
                color: isSelected ? "#ffffff" : "#686d70"
            }
        })
    },
    noValueOptionsStyle: {
        control: base => ({
            ...base,
            backgroundColor: "#ffffff"
        })
    },
    primaryColors: {
        primary: "#b91d1a",
        primary75: "#b91d1a",
        primary50: "#ffe3e3",
        primary25: "#ffe3e3"
    }
};
