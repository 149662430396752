import React from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";

class S02E06 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname);
    }

    // calls API and attaches scriptt to DOM
    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);

        if (Helmet.canUseDOM) {
            Helmet.renderStatic();
        }
    }

    // checkes if component should update based on props in Helmet
    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }

    // ensures Helmet sets teh code when page loads
    componentDidUpdate() {
        if (Helmet.canUseDOM) {
            Helmet.renderStatic();
        }
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>
                        {this.props.title} | {this.props.subTitle} | ReelCast by
                        ReelMetrics the world&apos;s best slot analytics data
                        platform
                    </title>
                    <meta name="Description" content={this.props.description} />
                    <meta
                        name="Keywords"
                        content="Player segmentation, Over-diversification, Mix management, Slot performance, Inventory management"
                    />
                    <link
                        rel="canonical"
                        href="https://www.reelmetrics.com/s02e06-video-poker-with-action-gamings-mike-fields"
                    />
                    <meta
                        property="og:url"
                        content="https://www.reelmetrics.com/s02e06-video-poker-with-action-gamings-mike-fields"
                    />
                    <meta
                        property="og:title"
                        content="Video Poker with Action Gaming's Mike Fields | s02e06"
                    />
                    <meta
                        property="og:description"
                        content="Mike Fields from Action Gaming joins us to discuss the importance of video poker and its impact on your slot floor. Learn how to optimize your video poker inventory for maximum performance and profitability."
                    />
                    <meta
                        property="og:image"
                        content="https://cdn.buttercms.com/OaWFV5QDqgOJxaKViwdg"
                    />
                </Helmet>
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}

S02E06.defaultProps = {
    id: "13137763-s02e06-video-poker-with-action-gamings-mike-fields",
    link: "s02e06-video-poker-with-action-gamings-mike-fields",
    title: "Video Poker with Action Gaming's Mike Fields",
    subTitle: "Season 2, Episode 6",
    description:
        "In this episode, Nick & Don welcome Mike Fields, Executive Vice President at video poker powerhouse, Action Gaming. In this discussion, Fields covers the latest trends in video poker, as well as general operational tips for transforming aging, long-neglected poker portfolios into differentiating, high-yield sources of competitive advantage.",
    keywords: [
        "ReelCast S02E06",
        "Casino",
        "Jackpots",
        "Split Card Poker",
        "Keno Star",
        "Multipliers",
        "Account Executive",
        "Las Vegas",
        "Video Poker",
        "Action Gaming",
        "Mike Fields",
        "Video Poker Inventory",
        "Video Poker Mix",
        "Video Poker Performance",
        "Video Poker Optimization",
        "Video Poker Inventory Management",
        "Video Poker Mix Management",
        "Video Poker Mix Optimization"
    ],
    topics: [
        "Leveraging Action Gaming’s experts to fine tune poker portfolios",
        "Video poker best practices",
        "Upgrading / renewing aging video poker portfolios",
        "Latest trends in video poker product",
        "Upcoming Action Gaming releases"
    ],
    buzzSproutLink:
        "https://www.buzzsprout.com/2057836/13137763-s02e06-video-poker-with-action-gaming-s-mike-fields",
    spotifyLink:
        "https://open.spotify.com/episode/7IjOPXF7EIAI4nicgBRkuW?si=c11Mi77DQFShJ-EC8PfREw",
    applePodcastsLink:
        "https://podcasts.apple.com/us/podcast/s02e06-video-poker-with-action-gamings-mike-fields/id1652485625?i=1000618861423",
    iHeartRadioLink:
        "https://www.iheart.com/podcast/269-reelcast-by-reelmetrics-104029260/",
    cdcLink:
        "https://cdcgaming.com/reelmetrics/s02e06-video-poker-with-action-gamings-mike-fields/",
    transcript: `Introduction:
    At ReelMetrics we collect and analyze a ton of slot data and we're hit up frequently for insights, tips, and tricks. So we decided to do a webcast in which we present, discuss and otherwise nerd out on our work. Coming to you from our offices in lovely Leiden, The Netherlands, welcome to ReelCast.
    Nick Hogan:
    Good morning, Don. How are things with you today?
    Don Retzlaff:
    Good morning, Nick. I'm fine. How are you?
    Nick Hogan:
    Not too shabby, thanks. As you know, I just returned from a couple of weeks in the US. We attended and presented at AGS GameON Customer Summit, which was, once again, absolutely fantastic. So this year was a Reno Tahoe gathering complete with a lakeside golf tournament, a Stanley Cup party. There were lots of Las Vegas there of course, and then a yacht rock cover band known as Yächtley Crëw, which was a great band, by the way, although I did have Toto's Hold The Line stuck in my head for close to a week, which I'm pretty sure violates the Geneva Convention. So that was the only [inaudible 00:01:31].
    Don Retzlaff:
    I'm not sure Mötley Crüe would be considered a yacht rock band though.
    Nick Hogan:
    Yächtley Crëw.
    Don Retzlaff:
    Like a hair band from the '80s.
    Nick Hogan:
    Actually, it's Yächtley Crëw with umlaut dots above the A in Yächtley and the E in Crëw, so it's really solid. Anyway, some great talks there this year. There was a heavy emphasis on AI, some great stuff on game design and cybersecurity, and we covered our Cupid Initiative and presented some results from the validation testing that we conducted last year. So everything was very well received, the attendees super-engaged, and we had three solid days there of really elevated industrial debate, so it was really good.
    Don Retzlaff:
    [inaudible 00:02:14].
    Nick Hogan:
    Incidentally, Don, I had close to a dozen separate attendees, all heavy hitters, take me aside at one point during the summit to tell me that they're huge ReelCast fans and they listen to every single episode.
    Don Retzlaff:
    People need hobbies.
    Nick Hogan:
    I just assumed they were being nice, but then they started asking me detailed questions about things we discussed. Anyway, it was incredibly gratifying, and huge thanks to those who [inaudible 00:02:45].
    Don Retzlaff:
    That's wonderful. I still get notes every week from people that listen and ask questions. It's kind of neat. One thing about your trip to Reno, they had a really nice write-up article about your speech and your presentation and everything else, but there was one big typo in that article. I don't know if you saw it.
    Nick Hogan:
    Uh-uh.
    Don Retzlaff:
    When we were talking about the under-45 people playing, and it had instead of 80% slot play from women under the age of 45, it had 8%.
    Nick Hogan:
    Oops. Yeah, yeah. That is a big miss. Yeah, so-
    Don Retzlaff:
    A little bit. So I was reading that, I said, "Well, they're close, but they just missed a zero."
    Nick Hogan:
    Yeah. Just to clarify on that, so one of the things that we did find in that age bracket was an assumption that was the majority of play was going toward tables. What we found is that it was only 40% for males and just 20% going to tables for females. So indeed, yeah, that was a typo to be sure.
    Don Retzlaff:
    It's an interesting topic. I know it's one we've talked about in the past, but there's just so much that we're getting out of this Cupid data, so many insights from player behaviors that were counterintuitive for things that I was doing wrong for years in the casino, and people are still continuing to do wrong. So I know we'll dive back into that in future episodes.
    Nick Hogan:
    Absolutely. Yeah, all the counterintuitive insights, no doubt about it. Okay, so anyway, on that, thank you to all the people who've reached out and said nice things. We really appreciate it. And speaking of reaching out, we received a fair number of listener questions this month, Don. So as a reminder to listeners ... Whoops. I had a microphone malfunction there. Sorry about that, guys. As a reminder to our listeners, we'd love to tackle any questions that anybody listening may have. So if you have a question about what we're presenting or something you'd like us to present, please drop us an email at reelcast@reelmetrics.com. Again, that's R-E-E-L-C-A-S-T@reelmetrics.com. Our policy is to keep all questions anonymous, so please speak directly and don't worry about us revealing your identity. We do not do that.
    Now, interestingly, two unrelated listeners submitted roughly the same question relating to persistence games and advantage players or, as they're known colloquially within the industry, the fleas. So the question reads, "Over the past couple of years, vendors have been selling us machines without clearly indicating on the par sheets when they're true persistence games. As a result, we've bought several that have attracted advantage players/fleas to the casino. The fleas wait until the games have been put into an advantageous state by other players, then they swoop. When they find an available true persistence machine, they check through all the denoms and bet combinations. When they find a ripe machine, they clean out the bonus rounds on that game, then move on to the next true persistence machine."
    "After all the bonus rounds have been cleaned out, they leave the casino with their profits. To make things worse, some are also rated and receive comps from us as well. The money the fleas walk out of the casino would've otherwise been won by genuine gamblers. It's true that in the long run, the game will hold the same percentage regardless of who is playing, but the whole percentage is different from bottom line dollars, and this is frustrating some of our best players. I'm hoping to see an industry trend away from true persistence toward perceived persistence. What trends are you seeing?"
    So first, let's hit a couple of definitions here. So to use the listener's terminology, a true persistence game is one that contains some form of visual indicator that there's an impending jackpot or bonus event. So the most common thing here would likely be a must hit or a mystery feature, which informs players that they've entered an active bonus round that's guaranteed to pay out relatively shortly. As for the perceived persistence games, these, and I hope I have this right, Don, they're games that appear to be persistence games, but in actuality are not, right? So those visual indicators are a bit misleading.
    So as for the behavior the listener is describing, we've seen as it often works like this. So the advantage players, or teams of them, they find the persistence games, and if there are players occupying them, what they'll typically do is loiter, somewhat menacingly, hoping to make the players sufficiently uncomfortable to basically pack up, move, and then allow them to get on there and go after that impending event.
    So before we jump into the trends, on the supply side, we can discuss a few mitigation strategies for this. So first, this is absolutely, for everybody out there who's struggling with this, make sure that you discuss this issue directly with each of your account executives. So they'll steer you clear these products moving forward, and many are likely to work with you on converting any machines which are already currently problematic and on your floor.
    Second, keep in mind that a lot of these advantage players target older players, as they're typically a bit easier to intimidate. So you can often find the fleas by keeping an eye on the older players and then looking for anybody stalking them. And then lastly, depending upon the severity of the issue, you can also cluster problematic inventory physically so that it's a bit easier to corral the fleas in a single section, which will enable you to work with surveillance to identify and then ultimately ban the culprits. So that strategy also has the added effect of placing advantage players on notice that the casino has been alerted and is now watching. So now, Don, trends in true persistence and perceived persistence, what are you seeing there?
    Don Retzlaff:
    There's a lot more perceived persistence games being released now than true. There are still some true persistent games out there. One of the more popular titles from the last year is a true persistent game, but they are slowly but surely being phased out by perceived, but there are some still out there from almost all the manufacturers. So it's not completely gone, but it is less and less every year.
    The good thing about it is most of the players who do take advantage of it do not want to be caught, and they are not harassing your customers. If they are harassing your customers, 86 them, get rid of them. I had to do that at my casino. Most of them are pretty sly, but they're so easy to catch. Just make your slot attendants and your security people aware of it. If it's something that you do want to stop on your casino floor, you can stop because there is an easy tell. You can tell surveillance to watch these games, and these people have to click through the screens, they have to spend time on the games. It's really easy to catch them.
    Nick Hogan:
    Okay, okay, great. Okay, so thanks again to listeners who submitted those questions. Fascinating topic. Really, really appreciate the feedback.
    Okay, so the second question that we received covered the analytical nuances of picking, configuring and marketing video poker. And rather than rattling off a question and a quick answer, we realized that we haven't devoted much time to video poker and that it would be cool to make an episode out of it. So that's what we're doing today.
    And as video poker is a very unique animal, we decided to invite an expert, who is himself a very unique animal. So this gentleman and I have, we've known each other for nearly 30 years now. We started in the industry at roughly the same time and worked very closely together. He was actually my boss for a bit at Mikohn Gaming back in the '90s. He then went on to senior commercial roles at Silicon Gaming and IGT, and for the past coming on 18 years has served as Executive Vice President at Action Gaming, pretty much the name in video poker.
    Also, an interesting factoid, his son, who as a kindergartner used to march into my office and chat me up about dinosaurs, is the first American to win an Olympic gold medal in BMX, which he snagged at the 2016 Summer Olympics in Rio. He has his finger on the pulse of all things video poker, and he's here with us today. So without further ado, ladies and gents, Mr. Michael Fields. Mike, how are you, buddy?
    Mike Fields:
    I'm doing good. That's a tremendous intro. I forgot about the dinosaur lessons.
    Nick Hogan:
    I just remember he would come in there, and he could rattle off every single dinosaur that ever existed.
    Mike Fields:
    The crazy thing is they'd keep discovering dinosaurs that didn't seem to exist when we were kids.
    Nick Hogan:
    Oh, I didn't know that. Same thing with planets. They keep moving them around. So yeah, today, I think before we get started here, I will say that my video poker acumen is a joke compared to those of you two guys, so I'm going to be mostly listening today. So I think with that, I think, Don, we can turn it over to you, and you and Mike, to really jump in here on the video poker topic.
    Don Retzlaff:
    Awesome. Well, I have spent I don't know how many hundreds of hours playing video poker across the United States and then on my home computer, and of course, my favorite games are Action Gaming games, and it's always interesting to see the new product that's coming out, Mike. I guess let's first start off with the organization and what you're doing now with Action Gaming.
    Mike Fields:
    We thank you for your business. I want to make sure we say that. I suspect it's thousands of hours, not hundreds.
    Don Retzlaff:
    It is.
    Mike Fields:
    We're all being humble about our play levels. We're continuing to invent games and create patents where possible for IGT to manufacture, get regulatory approval, and sell to the marketplace. We're in a haven for multipliers, these days. It seems to be the biggest commonality of a lot of the new games. We're starting to try to get involved with influencers and see how that can improve play for our casinos. So there's a lot of stuff going on.
    Don Retzlaff:
    Awesome. One of the neat things I always found with Action Gaming is you have some of the best account executives of any of the gaming companies and many of the manufacturers. I had Michael Drummond as my account executive for, gosh, 20+ years, and he helped me out more than most of the account executives. His knowledge on setups and configurations and jackpot liabilities and what themes are working and which ones are working in the market was just unsurpassed. And then I got to work with Shannon Bloom the last year or so, and she's been topnotch as well. You've got a pretty nice team working with you at Action Gaming.
    Mike Fields:
    Well, that's wonderful to hear. When I joined IGT, I was asked to create a business model and a department specific to satisfy Ernie Moody's inventions and desires to sort of overtake the slot floors. Mike, Shannon, Sal and Nicole, probably together, Shannon's the newest. I think she's got 8 or 10 years under her belt. The other three have 15 years, 20 years for Nicole. I think one of the things that speaks to our success is the fact that we have continuity in those positions. It's really rare for people to stay in a sales-type role for 15 or 20 years as Nicole has.
    What we did when we created those roles, we didn't want fast-talking, deal-closing salespeople. I was looking more to create sort of field sales engineers, who would keep the promises that were made by the fast-talking sales guys. And there are tremendous assets, the four of them. It's unfortunate that some casinos ask them for help, but then don't actually use the help.
    Don Retzlaff:
    Yeah. It's really great, because you build up a rapport and a trust. I can tell you I'll use Mike as mine, as I knew when he came in, he was going to not only shoot straight with me, but he was going to help me out on areas that were weak or on my concerns. He was great at setting up slot machines and just what games to enable, what denominations. It was very thorough. When you walked out of that meeting with him, you knew that he had all the answers, and if he didn't, he got right back with you. He was just a treat to work with.
    Mike Fields:
    That's wonderful to hear. It's just so normal. For 20 years, someone would buy an Action game, put it on their floor, and then call 45 days later to say it's not working. And in many cases, Mike or Shannon or Sal or Nicole would go out there and find out that they've turned on nothing less than dollars, so there's no Quarter Games enabled, or maybe they've turned on all low denom. And some people in the early stages of Ultimate X would put Ultimate X out there in 2 cent, 5 cent, 10 cent and never achieve the throughput that they could and the profit that they could be making.
    Don Retzlaff:
    Yeah. The one nice thing about dealing with the account execs was when you're dealing with some manufacturers, you basically buy a theme, and then when you get to install the theme, you've got all these options for whole percentages and minimum bets, maximum bets, line counts. And you really didn't get a whole lot of guidance from a lot of the organizations, but that just didn't happen with Action Gaming. It was, "Here's the game, here's the optimal hold percentages, here's what games you should enable, and here's the denominations." So it was basically A to Z versus "Here's a theme, buy it, and then good luck." Your team actually did a really nice job of helping you set up the game for maximum profitability for the games. And it was always long-term strategy, which I always just preferred is I didn't want a game to sit on that floor for six months and then burn it out. Your games last, and I think a lot of it's because you configured them properly.
    Mike Fields:
    I love to hear the support. When casinos open, it's their opportunity to really establish themselves as what kind of player mix they're going to get. And it's interesting that several of the most recent casinos that opened in Vegas in the last five years did not put much Action product on the floor, but within 30 days, were ordering two and three times as many as they had. And you're right. The setup really does matter.
    Don Retzlaff:
    Yes, it does. I guess the final important question on your organization or comment should be is the casino should use the account executives that you have and use their skills, because they can make a difference, they will increase your profitability on the games. They'll spend time studying your configurations. They'll be out there on the floor with you looking at stuff. They can really make a difference. And I've told many of our clients at ReelMetrics that if you haven't talked to your account executive lately, you really need to, whether it was on new themes, new cabinets set up. It's a great resource, and they really need to utilize it.
    Mike Fields:
    I agree. If you think about it, if were offered a free tuneup on our car, we'll probably take advantage of it. If we're offered a free physical by our doctor, we take advantage of it. I wish all casinos would follow your guidance in that and have their video poker account specialist come in and assess the floor just from top to bottom, because there's nothing wrong with discovering you've set up properly, but you might find that there's opportunities for improvement.
    Don Retzlaff:
    Absolutely. Spending an hour with an account executive from Action Gaming, on a quarterly basis at least, is definitely a no-brainer. Take Mike to lunch, and we'd sit there and talk about the setups. We walked the floor, it was just it's time well spent.
    Mike Fields:
    Well, they also can go scout the competition easier than you can, and they can assess what the competition's doing quicker than you can, and they know what to look for.
    Don Retzlaff:
    Yep, absolutely. I do have a couple questions about some things that are worrying casinos, and obviously, the biggest one I hear a lot is exposure to large jackpots. How do you work with their concerns? I know each casino's a little bit different on what they're [inaudible 00:21:10] to be. I've got some clients that they don't bat an eye at a quarter-of-a-million, others don't want to go above $75,000. How do you manage that?
    Mike Fields:
    It's a really good question, and I'm not sure how to answer it. There's a tolerance for jackpot pain, and the best thing to do I think is probably, the simplest thing is if you've got a number in mind or a number that's going to get you fired on Monday morning, the odds number, then set it to that. Maybe not over a dollar or two dollars or something, but make sure you've got other denominations enabled as well.
    Don Retzlaff:
    Right. I always told my team, when we're setting up games, let's do the math, especially in the days of multipliers. Everything changed a lot when Ultimate X and those types of games came out. But even when we're looking at Hundred Play or Triple Play, Five Play, Ten Play, and all that early stuff, I guess Super Times Pay was the first one we really had to look at. So just do the math, what if, and are you going to be happy if that happens? And it's so rare that those large jackpots dealt royals with a max multiplier hit. Like you said, I've played these games for thousands of hours, and I've had dealt royals, but I've never had dealt royals with a big multiplier. One of these days, it'll happen probably. [inaudible 00:22:44]
    Mike Fields:
    The more you play, the more likely it'll happen.
    Don Retzlaff:
    Exactly. The unlimited number of monkeys, typing on a typewriter, one of them will type Hamlet one day. But yeah [inaudible 00:22:56]
    Mike Fields:
    I always find it kind of interesting when a big jackpot does hit, and the slot manager or the casino manager suggests that they're unaware of that exposure, and then they pull the game right away. They'll never get their money back.
    Don Retzlaff:
    No, right. It's one of the things that I talked to our VPs and directors and slots that are clients is, that's one of the best practices we talk about is you really need to know what your exposure is on every one of your games. It's something that we did at all the properties that I worked with. We went from everything from your high limit games to your penny games and your video poker games, and you look at your exposure, because you really didn't want to. And you have to have that conversation with your general managers and your CFOs is "What's our maximum exposure? What do you want it to be?" So no one is surprised on Monday morning.
    Mike Fields:
    That's exactly what you don't want is that sort of surprise. I'm amazed how many casinos won't enable triple-double in a $2 denomination or on Triple Play, and it's one of the most popular game families, and you make a fortune off the daggone game. It is very volatile. You'll give up an $8 or a $12 grand jackpot every now and then on a Triple Play, but you're making a lot of money leading up to that.
    Don Retzlaff:
    Well, that's a good intro into my next question is back in, I go back to the early '90s and learning how to play properly on video poker and taking advantage of some of the casino promotions and things like that and cash back to where you actually could have an advantage profitability on a game if it was set at above 99% or 98%. Do you see any skill play on Action Gaming that gets you above 100%?
    Mike Fields:
    There's a real unicorn out there on double bonus, excuse me on Bonus Poker or Jacks Are Better on a $100 denom Triple Play or Ten Play, someone can get closer, get over the edge, but there's very little of it out there to the best of my knowledge anymore. I'm not sure I see everybody being as pay table sensitive as they used to be 20 years ago. One of our Action games, you can't look at it and see a 9-6 or a 10-6, and "Oh, it's a 100% game," because the odds within the bonus feature are different. So I'm not sure I see as much shopping for that stuff. There's definitely creatures out there still, like Bob Dancer, that will take a punt at some of these things, but I'm not sure it's as big a problem as it used to be.
    Don Retzlaff:
    I agree. I used to be able to just pop in the pay tables in the internet and figure out ... But that was basically on the five-coin stuff. It's a lot different on the Action Gaming, and plus, you don't see Action Gaming generally set at 98.4%. Most of the properties are setting them in the 97% range, 97.5%. So it's a big jump to get from 97.5% to 100%. Plus, people generally don't play these games as well as they do a five-coin, just because they're-
    Mike Fields:
    That's absolutely true.
    Don Retzlaff:
    They're harder to play as far as decisions to make. So they only go-
    Mike Fields:
    Well, Ultimate X is the big, best example of that. To play the proper strategy of Ultimate X, you feel dirty. You have a king and a 10 of spades and a three of spades, and you keep the three of spades because you're really going for the multiplier, not necessarily the ending hand. So there's a lot of cases in Ultimate X that seem very contrary to the single-themed games. It's really tricky to figure out. I feel kind of dirty doing it.
    Nick Hogan:
    Mike, as Action, do you market the basic strategies for these games? Are you putting those out there, publishing them, or you just have the players figure it out?
    Mike Fields:
    No, we're the only people that have been able to solve Ultimate X, which is kind of interesting. We're the second-largest user of Amazon Web Services on the West Coast, our little shop here.
    Nick Hogan:
    No kidding.
    Mike Fields:
    Because all the complications that go into figuring out perfect strategy. But I think there's a degree, a swing in the whole percentage that is inherently due to the bonus feature that we've put on top of Single Hand or Triple Play. And your 97% game that you talked about, people typically under-hold 2% or 3%, and that can go as far as 9% or 10% on Ultimate X and other games.
    Don Retzlaff:
    I've generally figured that my Action Gaming over the long term held between 130% and 150% of normal. So I just looked at over, let's see, how many big games do I have on here? I just looked at well over a billion dollars worth of coin in, and the average hold percentage on Action Gaming is four-and-a-half percent. So it's holding about 140% of theoretical. It's something we actually had to do on our marketing programs, because our customers were losing it higher than theoretical.
    So we actually started doing some of our marketing programs based on actual, because we had some customers that were spending tens of thousands of dollars, but they weren't getting properly marketed to. They were some of our best customers. They'd come in and drop $10,000, $20,000, $30,000, and we were treating them like they were dropping a fraction of that.
    Mike Fields:
    I laugh. I get a $15 MGM offer every month. And I've emailed them back saying, "I can't afford to park at your casino at $35 to play my $15."
    Don Retzlaff:
    Yeah. I had a really smart GM at my property, who knew what was going on with video poker. He was a sharp guy. And we changed our marketing programs to reflect that. Now, I had to do reports every month to make sure that we weren't getting hit by advantage players and the five coin stuff. But instead of getting a $15 or $25 coupon, now, all of a sudden they're getting $75 and $100, and our video poker play exploded, because we were treating them, they should have been treated, and the competition wasn't. So it really did help.
    Mike Fields:
    I've been very impressed with some of the marketing from Southern California. They were extremely aggressive with really good offers.
    Don Retzlaff:
    Yeah. It's still a good part of the casino floor, and I'm seeing 10% of the floor on average being designated for video poker. Is that pretty much still what you're seeing, Mike?
    Mike Fields:
    So it varies market to market. Nevada being the oldest market, and video poker tends to grow as a percentage of a floor over time, as more players want to take advantage of being able to influence their outcome of their gambles. But 8%, 10%, 12%, somewhere in that range seems to be the norm in most areas. Nevada was 50%, 55% when I was at IGT in the early 2000s. It's probably down to 40% now, but it's still a big chunk.
    Don Retzlaff:
    Yep, absolutely.
    Mike Fields:
    Absolutely. What I see is that Single Hand Game King has been probably the share loser, and it's being replaced by either Action games, which are more volatile, more entertaining, and slots.
    Don Retzlaff:
    I've had the pleasure of helping set up a couple brand new casinos recently, and I used my video poker experience and knowledge and, of course, the knowledge of all the revenue that they generate to limit the number of traditional five-coin poker Game Kings and have a higher percentage of Action Gaming. If you're setting up a casino, what would your split be? What percentage of video poker would be Action? What percentage would be Game King?
    Mike Fields:
    Well, every bar position I had, a peak bar, IGT's fairly new platform with a Superstar. Absolutely the best value on a casino floor is Superstar, which is a blend of the single hand and lots of multi-hand stuff as well, as Keno and blackjack. The main floor, I probably cover with All Stars, Ultimate X, Ultimate X Bonus Streak, Super Times Pay, 25, 50, and 100 Play if you do a few of those. That handful between the Superstars, All Stars and the multiplier Ultimate X Games is probably the basics that you have to have.
    Don Retzlaff:
    I would say at the casinos I've been setting up recently, I've been at each denomination of Game King, but the vast majority of the machines have been Action Gaming. And I'm looking right now across our clients, and it's basically, I would say, it's 40% Action Gaming and 60% Game King, and it probably should be 75% or 80% Action Gaming and 20% Game Kings. Just looking at the revenue numbers, a Game King typically is going to do about 40% of house average, but an Action Gaming unit is going to do over house average.
    So you're looking at two-and-a-half to three times the revenue per machine, and some of the titles out there are doing well above that. Some of the titles you mentioned, like Superstar Three, All Star Three Ultimate, all the Ultimate Xs, stuff like that is just doing well above house average. I'm looking at 150%, 170% house average. So people really need to move off the Game Kings and move on to Action Gaming. The fees are reasonable, and the revenue potential is just that much better.
    Nick Hogan:
    Yeah, I think it's coming back to more of this hostility to, "Well, we love the revenues, but we hate the fees," type of stuff. Don, I'm assuming that's that's really what's going on there.
    Don Retzlaff:
    It is, and I don't know what the deal is with some of the densities on the video poker machines that people ... There are just so many ghost banks out there on Game Kings that you just don't need as many of them as people have. And the fees, you're looking at $15 a day on the average for a lot of this stuff, and if it's going to make you another $150 bucks a day, it's pretty much a no-brainer.
    Mike Fields:
    We've been frustrated over the last 20 years. We think it's quite shortsighted that for $15 bucks and you get, as you say, a lift of $100, $140 bucks per machine, to look at the operating expense line and not recognize the revenue increase on the revenue line is just really frustrating. I think some people feel that the people have come to their property, they're going to spend whatever their wallet is for the day, and in some of the games, if there's less least games or daily fee games, then the money will end up in a non-daily fee game. But I don't believe that's the case.
    Nick Hogan:
    No and we-
    Mike Fields:
    People know where they want to play Ultimate X.
    Nick Hogan:
    Mike, we just finished about a calendar year of validations on this, a very detailed double-blind scientific investigations of this, and we established conclusively that you are not simply moving money around the floor, that these things are creating incremental revenue. Because the simple fact is when people come in and they play what they have to play versus what they want to play, they're going to give you a fractional chunk of their budget. That's it.
    Don Retzlaff:
    Yeah. And I guess-
    Mike Fields:
    It's getting so much easier to get access to more money with all the cashless systems and printing tickets, game tickets directly from the ATM that people will go back to the well more times than ever before to keep playing due to the how they're [inaudible 00:36:22].
    Don Retzlaff:
    Absolutely.
    Nick Hogan:
    Yes, you're right.
    Don Retzlaff:
    One of the things I always talk about-
    Nick Hogan:
    What we've seen happens in the data, absolutely. Sorry, Don.
    Don Retzlaff:
    Yep. That's all right. And video poker is its own unique animal too, because there are so many video poker players that simply won't play anything else. They won't play penny slots, they won't play tables. It's the same reason why I had Keno on the floor. It wasn't because they did great numbers, they did okay, but the Keno players wouldn't play anything else, so if I took them out, I lost that revenue. And it's the same thing with video poker.
    I guess the big example for me is Las Vegas. When I go out there, my favorite game is Split Card Poker. Love that game, have had great success with it, and even when I have no winning sessions on it or losing sessions on it, I'll still play it. But it's very limited in its placement, and I know exactly where they are on the strip. So where do I spend my money at? It's at the places that have that, because I could walk from casino to casino. And I play it at two casinos mainly on the strip, because I know that's where they're at.
    And it's the same thing in a locals market. I'm here in St. Louis, so I have my choice of several casinos, and if they have my video poker games that I like to play, that's where I play. If I walk in a casino and they don't have it, well, I don't return to that place. And I think the video poker players ... I used to keep a list on my bulletin board in my office of themes that people want, and they would always tell me, "Don, if you put this theme on your floor, I will visit you." And they did. So I was lucky enough I had a really good selection of Action Gaming, I had a really nice room for it, and I put these themes on, and I would steal business from the competition.
    Mike Fields:
    It seems like an easy formula, but a lot of folks aren't executing on it. I'll tell you an interesting trend that I've spotted out here in Vegas. I tend to play at taverns more than I play at the casinos. I recognize that I'm a heathen, not going to the best pay table, a heretic, but I like when I can call ahead, have a place waiting for me that I can play. They keep my Prisoner wine, which I like to drink while I'm playing. What a lot of these taverns have done is they've gone to 10 coins at 50 cents, $1 and $2, and they've gone to 45-coin enabled triple play. And I can think of 10 or 15 bars, taverns now that have done this, and it's dramatically improved their revenues, because I guess people playing there are like mini-whales.
    But that has really got people moving up in denomination and gambling more. The 45-coin Triple Play is really fun, because the two, three, fours of the kicker is a $6 grand hit, which feels good, $12 grand for the royal aces with a kicker, and it is free. That's the best thing about it is it's free. If listeners to this podcast, they should take it upon themselves to go and try it on a bank of games and see if it doesn't work.
    Don Retzlaff:
    That's great. And stash some Prisoner behind the bar too. That's a great red blend.
    Mike Fields:
    So the other thing that I've seen them do for the last couple of years is moving the four-card Keno up to 20 coins from 16. And it's a small, it's one coin more per card, but it's a round number, and the winning jackpot numbers are round numbers, and it seems to feel a lot better, and they've had a lot of success driving Keno play doing that.
    Don Retzlaff:
    Nice. Yeah, like I said, I like to play. I'll play Split Card, and I liked Big Split. But in Split Card, it's a large bet, and it's a great game to multi-denom, and you don't have to get a ton of density on that game, a ton of hours played to generate a lot of coin in. So you don't need a bunch of those games, but you do need a significant number of Action Gamings on your floor. Like I said, I would go with that 75%, 80% if I was looking at a casino. The fees are well worth it. Mike, are there any recent releases or anything performing well recently that we should be looking at?
    Mike Fields:
    Keno Star is creeping up in play. It's sort of a slow burn, like Spin Poker was. Keno Star is a collection of multi-card, if you think about video poker with single hands, and Triple Play came along and made it three hands and tripled the volume and then created the opportunity for us to put five-coin bets on it, we did the same thing with Keno. So we've got an update to Cleo, an update to Caveman. We've got a Dream, if you've played Dream Ball Keno or Video Poker, we've got a Dream Ball Keno, which is really cool.
    When the Dream Ball feature occurs, a winning ball is assigned to each and every one of your cards. So if you're sitting at five out of seven, you'll guarantee six out of seven. If you're at six out of seven or seven out of eight, you'll get the winning ball and put you over the edge. So that's one to keep an eye on. We've got a couple of really good ones I'm excited about that we'll debut at the Gaming Show. One's called Power Stacks, another called Multipliers Rising. I think they're both going to be great.
    Don Retzlaff:
    It's always interesting to see what's out there at G2E, and I always dedicated some time. I always took an hour-and-a-half for the IGT booth, because I knew there was just so much to cover between looking at the reels and the video. Yeah, I'd walk the floor for an hour with James, and then I'd always have at least a half-an-hour with Mike and looking at the new product from the Action Gaming side, because it's the only place I got video poker from. And if you get it right, it can be a really nice revenue bump. Plus, I wanted to see how to play it. So get those games out there, and you can sit there and test them for a while and spend a little time. So yeah, if you're at a G2E, spend some time with your account rep. It's time well worth it.
    Nick Hogan:
    Guys, I had a ...
    Mike Fields:
    I-
    Nick Hogan:
    Go ahead, Mike.
    Mike Fields:
    Go ahead. I was just going to comment that that's what ... Sorry. I was interrupted. That's one of the biggest things we wanted to do with videopoker.com is take that uncomfortable learning segment off of the table. So for Gold members, which is $7 a month, you get previews of the games a month or two early before they hit the floor. So you could practice and play them at home to see if you like them without sticking a hundred dollars in and find out that you do or don't like it.
    Don Retzlaff:
    It's a great lead-in. And you stole my next question, because I've been a member of videopoker.com for, geez, for years, and it was always one of the tools I used when I was looking for conversions. Because I'd sit there and I'd play the game, and since I did know video poker a little bit, "Do I like the game, do I not like the game?" You could sit there and you can play it and figure out if the price point's going to be right for your customers. Is this a 30-coin bonus bet? Is this a 15-coin bonus bet? And so on. So you get a little bit of an insight. So if you're not using video poker.com and you're looking at conversions and all that, I would definitely log on.
    It's a great little site. It's got some neat tools to it. You can find out where your favorite games are. You can find out what casinos are carrying what Action games. You can look at your competition if you want to, I always did, and figure out what they have on their floor, because it's all right there on the website. So if you're flying out to Vegas and you like Split Card, you can actually type in Nevada, and it's like dropdown menus and then chop down Split Card, you can find out exactly what casinos have it. Don't do that, because that's my game, and you'll be on it, so I don't like that. But videopoker.com's a great resource.
    Mike Fields:
    Well, it's interesting. We launched it with the intent of it being a B2C site, but as we started maturing with our features on it, putting playables in the hands of casino buyers so they can see what the game's like before they pay for it, install it, and wonder if people will like it, we're happy to provide playable links to casinos, so the casinos could send a playable link to all their best players and let them know when the game's going to be installed and invite them in for that purpose specifically. And as you said earlier, video poker players come specifically for one or two games. So why not make them feel like they're part of the inside, let them see a game that's coming to your floor early, and then you'll be able to gauge interest as well.
    Don Retzlaff:
    Yep. I've only got a couple more questions for you, Mike. And then the next one was based off of the interaction I had with one of my guests. She was trying to learn how to play video poker at a higher level. So I recommended a program that teaches you how to play at a higher level and basically yells at you when you make a mistake. And about a month later, she bought it, and she comes in, and I see her, and she yelled at me for two minutes because I increased her knowledge of the game because it was hard, and she then also realized how many mistakes she was making in the past.
    Mike Fields:
    That's funny.
    Don Retzlaff:
    Oh, it was great. She was a treat, and she was a good friend. But there are tools out there that you can learn how to play video poker better, especially now with there's so many different videos out there and different products. It's a little bit different than the cheat cards and the rankings they used to have for playing Jacks Are Better or Deuces Wild or stuff like that. Are there still decent programs out there for Action Gaming?
    Mike Fields:
    We are the only people that actually can provide accurate strategies on some of the Ultimate X Bonus Streak. We have a sort of an intro to training program that comes with the gold membership, and then we have a pro trainer for $20 a month, which has every imaginable game that we've ever created, including the bonus type ones, which are the harder strategies to figure out and to employ.
    Don Retzlaff:
    Wow.
    Mike Fields:
    We don't have as many members as I would've thought we've gotten. It can be hard to play, like I said, Ultimate X properly and some of these other games, but people are kind of wasting their money if they're good-sized gamblers and not getting a training program. I jump on every couple of months just to make sure I'm getting my straight-plus draws right, and I made mistakes, so it's good to be refreshed.
    Don Retzlaff:
    Yeah, I remember doing those programs a few years back when I was ... It's just like when I learned how to count cards, it took a lot of practice to get this right, and it was the same thing with video poker. It does make a difference, especially if you're playing a lot, and you're playing thousands of hours like we do. When you increase that whole percentage by a percentage point, that's a lot of money, and it just gives you a lot more [inaudible 00:48:35] to play with.
    Mike Fields:
    Well, in its simplest form, playing really volatile games, you're just trying to get your money back on every wager until you get a really high value starting hand or you get a dealt winner. And if you could play a little bit better than what percent you mentioned, if you could get the straight flush draws, the right holds on a couple of areas, you could stay in the game long enough or longer, which gives you the shot at those big hands.
    Don Retzlaff:
    Exactly. And if you're analytical at all, those programs are really interesting, because not only will they tell you what you did wrong, but it'll show you the percentage what you just cost yourself. And it's really interesting, and you can actually think about it and think of the math, "Okay, well, now I get it." So those tools for the customers are great and for the casino operators are great too. It was something that I always enjoyed talking to the players about and teaching them, because I knew that long term, we're still going to win, but they're going to have a more enjoyable experience, and they're going to come more often. And so I was in it for the long term, and I wanted my customers to be happy for the long term.
    Mike Fields:
    Exactly. Well, look, if you think about it, when the Green Felt Jungle and some of those groundbreaking books came out, Comp City, they were introducing people to the concept of becoming an expert player or a smarter player. The same with all the blackjack movies, the MIT teams and that kind of stuff. So many people think they know how to count cards. They don't, but they feel better about doing it and gambling, because they think they're smarter than everyone else. The same is true with video poker. You can make yourself smarter, and you can see the difference in holds. At the end of playing on videopoker.com and some of the games, it'll actually tell you how much money you would've made had you been in a casino. We didn't think to show how much money you would've lost would be that good.
    Don Retzlaff:
    You do a neat job with that site, because they have contests and drawings, and they tell stories and everything else on there, and big hits and stuff like that. I've had so many good experiences in on video poker and at the casinos, and whether it's Dream Card, where you get that dream card for that royal, I actually had that happen to me. I was out in Vegas for New Year's Eve a few years back and sat down after the fireworks, and my third hand, I've got the dream card and four-to-the-royal. So bang, there it was. It was right when I sat down, right after the fireworks. That's the nice way to start a new year off.
    Mike Fields:
    The unicorn lives.
    Don Retzlaff:
    Yes, it does. That was really neat, and a nice way to spend a birthday. I've got so many pictures on my phone of four aces with kickers and with multipliers and stuff like that. So those are always neat to have.
    Mike Fields:
    It's funny. We players, I sometimes will mutter softly to my machine, "I'm not so proud. Deal it to me, just give it to me."
    Don Retzlaff:
    I've got your phone.
    Mike Fields:
    I like to hit a big jackpot.
    Don Retzlaff:
    I'll tell my machine, "Just give me four aces, and let me draw to the kickers."
    Mike Fields:
    That's so much fun. And then what you can do is you just clap your hands and say, "My work here is done."
    Nick Hogan:
    Yeah. We have all kinds of strategies we're laying out here today.
    Don Retzlaff:
    Oh, my gosh. You make me want to go play now. I got too much stuff to do, but I need to go play.
    Nick Hogan:
    So guys, I have an operational question about saturation and optimum occupancy levels. So you had mentioned earlier that it looks like the standard footprint, something like 8% to 10% of overall inventory. Do we have some benchmarks there for the optimum occupancy levels like we do on some of the core and premium slots?
    Don Retzlaff:
    Well, Nick, when I was setting up those casinos, I was treating it pretty much like I would an own penny video, that once I got above 25%, 35%, I was in really good shape. So that's why I cut back on a lot of the Game Kings, because a lot of the Game Kings are in single digits. I did the same thing with the Action Gaming, but on a lot of the video poker, just because of how quickly they can be played and the lack of bonus rounds and all that, I did a lot of financials. But basically that 30% range is probably a pretty good number to shoot for.
    Nick Hogan:
    Okay. Okay, great.
    Don Retzlaff:
    One last question for you, Mike. I've spent a lot of time, I'm probably the last person to discover YouTube in the last couple of years, but I've been looking at a lot of YouTube videos on, I started looking at YouTube videos, because I am a semi-professional golf better, and there's really interesting stuff out there on golf. But I started seeing the Brian Christophers of the world on the slot side and what the impact of those YouTube influencers are doing to the slot floor, especially increasing average bets, shorter durations, really strong high limit play, like on Tencent Video, and you're seeing people playing $20 and $30 average bets on some of this stuff. And I'm seeing it on the casino floor. Is there any of that going on the Keno or the video poker front for YouTube influencers, slot influencers on the video poker side?
    Mike Fields:
    There definitely are a handful of people doing it. No one's got really critical mass. There's no son of Brian Christopher yet, but there's a handful of them that we like that we work with, Slot Club 500, Big Cats, Slot Massacre, Vegas Matt, there's a handful of people. They don't have all the views that Brian Christopher does, but they're doing their best to grow their following. I find it just so amazing that people will sit and watch them play, kind of saying the same stuff that they said in their 30 previous videos, and then they'll actually send them money through YouTube to gamble for. It's fascinating. [inaudible 00:55:19]
    Nick Hogan:
    Yeah, there's a lot of these, a lot of this team play stuff going on with these guys. No doubt about it.
    Don Retzlaff:
    One of the things I used to use the YouTube videos for is I used to try to show all of the slot attendants and slot techs to bonus rounds and stuff when I put new video on the floor, video pennies and that, but with an average hit of 120 spins, I got away from that and started showing them the YouTube videos of people playing the bonus rounds, and it just worked out a lot easier. I'd say, "Okay, watch this YouTube video on Buffalo Link, and this is how the bonus rounds work."
    So the YouTube videos did have its purpose, but I have watched a lot of them when I was looking at average bets and trying to figure out what these people are actually playing at high limit and what they're playing for a Tencent dollar video. But I haven't seen anything yet on the video poker side. So are you seeing any impact on the casino side from these YouTube videos yet? Or is the following still too small?
    Mike Fields:
    I think the following's a bit small, and it's very difficult to identify the metrics necessary to justify perhaps helping an influencer get to a casino to play. The casinos typically put one or two of our new games in, and then they just expect people to find the game. It's kind of like the movie 300, when the kids are seven, they get sent out, if they survive in the wild or they die. So we get one or two games, and then if the games do well over a couple or three months, we might get another one or two. So I want the influencers to be very helpful and people finding the games. I just don't know yet how or if that converts to revenues. It clearly does in slots with Brian Christopher.
    Don Retzlaff:
    It does, and I can definitely see the impact. It's interesting, because Nick mentioned earlier we talked about this study that we did for what the sportsbook players are playing when they play in the casino. Very little of it is video poker or ERGs. Especially under the age of 35, you really start seeing an increase in video poker play after the age of 35, in particular after the age of 45. So maybe the YouTube people aren't reaching, the influencers aren't reaching the core audience for the video poker players, which seems to be over the age of 45, over the age of 50. Just not getting a whole lot of coin in. But the older customer gets, I think we see that in the casino floors, the more experienced the casino floor is, the more they like the video poker side.
    Mike Fields:
    Like you mentioned yourself, you're kind of late to the game on YouTube or new to YouTube. Younger people are not, and YouTube's a fascinating phenomenon. You can find anything on that. It blows my mind all the stuff you can find on YouTube. But getting the younger people to play video pokers has been a challenge.
    Don Retzlaff:
    Yeah, well there's definitely a skill level to it versus just sitting there and be entertained by the video pennies and waiting for the bonus round, there's some thinking to it. So it's definitely a thinking man's, a thinking girls game, and it's really enjoyable once you get into it.
    Mike Fields:
    There's a few taverns here in town that have 15-position limited gaming licenses, and there's several in town that have all Superstars. And I remember when we came up with Superstar back in the day at IGT, I did it, I came up with the idea because I was frustrated. Super Times Pay had just come on, and as you said earlier, Super Times Pay was one of the first bonus games on top of Triple Play. And a couple of Sierra Gold here in town put one machine in.
    And I got into this battle with this guy. I would get there at five o'clock, and he would've just sat down. I went in at a quarter to five the day after that, and then he didn't get on. He showed up at 4:30 the next day. With only having the one Super Times Pay machine, you could have five people who want to hammer the games, but there weren't enough options. So Superstar was created to solve that problem.
    Don Retzlaff:
    It's one of my-
    Mike Fields:
    But people want to fire, you want to give them targets.
    Don Retzlaff:
    Exactly. It's one of my classic stories on supply and demand is when I first started putting Action Gaming on the floor, I had a customer, one of my best customers, he pulled out a hundred dollars bills out of his pocket and said, "I want to play this, and you won't let me, because this person's playing on my machine, and this is the only one that I want to play." I said, "Oh, I can fix this." You've got to have a good variety, and the All Stars and Superstars and stuff like that are just great choices for that.
    Mike Fields:
    It's funny. In those days, I would go look at this guy sitting. When you've got a limited number of Superstars, and they're all playing like one-coin keno, I just want to give them $20 bucks and tell them to get off the machine.
    Don Retzlaff:
    Exactly. Exactly right.
    Mike Fields:
    The snobbery of it all.
    Don Retzlaff:
    Mike, is there anything else that you wanted to mention or talk about before we give you back the rest of your day?
    Nick Hogan:
    Yeah, Mike, I think you had mentioned-
    Mike Fields:
    No, what's that?
    Nick Hogan:
    You mentioned two debuts at G2E. I think it was Power Stacks and Multipliers Rising. Is that right?
    Mike Fields:
    Precisely. Power stacks, I think, is going to be a huge success. We have a game called Stack 'Em High that when you get dealt two aces or three aces or four-to-the-royal, it gives you free hands. Let's say you get six free hands, and for those six hands, we have what's called a sticky feature, something you've seen in slots, where if you've got three aces, then you get to the first bonus hand, and you get that fourth ace delivered, that fourth fourth ace will be duplicated all the way up the six hands. So then you're just looking for kickers. It's really fun.
    We've taken that feature off of just aces and deuces, and we've put it on all the cards. So that game will also be available on the Curve platform, which we're starting to make games that work better on these much larger, take advantage of these much larger screens and provide more entertainment. So that one, I think, is going to do really, really well.
    And Multipliers Rising is kind of a fun one that randomly, each individual hand is assigned a set of multipliers, 2, 4, 6 and 12 Xs. And so when you're playing Triple Player Five Play, even Ten Play, you've got two or three different hands that have multipliers going all the time. It sort of builds in excitement, because you got to go through the lower ones to get to the 12X. So I think that one's going to kill it as well.
    Don Retzlaff:
    Yeah. That sounds cool, and I should have mentioned this earlier is back in the day when I had my G20s on the floor. Once they ran their course, I converted my G20s to Action Gaming. We're starting to see that now with the Crystal Cabinets, Crystal Slants, Crystal Curves, and seeing some really impressive numbers as far as win per unit for some of the Game King X, some of the newer releases, the Super Stacks. I've seen some really, really impressive numbers. So for casinos that have run the gamut on all the conversion options for their Crystal Cabinets, really think about converting those to video poker. I've made that suggestion probably to a dozen of our clients in the last year, and we're seeing some really nice numbers.
    Mike Fields:
    That's very consistent with what's happening out there. Crystal Slant is becoming a very good solid poker box. IGT is having some stuff perform better on the Curve Cabinet. We always called it the Hermit Crab Strategy. At some point, people would buy boxes or they'd have a problem with content on them, slot content, and then we would creep in with our Action theme, and we'd live in a hermit crab shell forever.
    Don Retzlaff:
    That's perfect. Well, we did it with the G20s, and now we're starting to do it with the Crystals. And I'm seeing some of this stuff that's well over a thousand dollars a day as far as theoretical, and actuals are even higher on All Star Threes and Game King X and Super Stacks and stuff like that. And I'm sure you're going to released more and more titles for the Crystal Cabinets, but these initial numbers are really strong.
    Mike Fields:
    That's excellent.
    Don Retzlaff:
    Yeah.
    Mike Fields:
    It's funny. I argued with Ernie when [inaudible 01:05:08] "I don't think we can get 15 more coins out of players, Ernie." One of my less prescient moments.
    Nick Hogan:
    We all have them. I have more than most.
    Mike Fields:
    It's funny. When I joined IGT, everybody thought Triple Play was going to be a flash in the pan. I remember sitting down with TJ Matthews, the CEO, and he said, "Oh, we all were trying to invent a game to get one more coin out of people. Ernie figured a way out to get 10."
    Nick Hogan:
    Yeah.
    Don Retzlaff:
    Yeah, absolutely. I remember when they first came out and how many I had on my floor, and you couldn't get on them. I had them set up at 25 cent, $1, $2, $5, and they're still getting played, and it's been 15, 20 years ago.
    Nick Hogan:
    Good indicator that you got the formula right, I'd say.
    Don Retzlaff:
    Yeah.
    Nick Hogan:
    Okay, guys-
    Mike Fields:
    We like to think of them as loyalty-inducing games.
    Don Retzlaff:
    Yep.
    Nick Hogan:
    That's a good way to put it, absolutely. Okay. All right, guys. Well, I think we've pretty much covered. Don, did that roll through all of your questions that you had today?
    Don Retzlaff:
    It did.
    Nick Hogan:
    Mike? Okay. Let's see. I guess before we go, I wanted to insert my standard shameless plug on this. So we're going to be doing Raving Consultings, Gaming Technology, and Marketing Conference at the Grand Sierra in Reno on the 18th and 19th of July. So in addition to exhibiting there, I'll be participating on a panel on the intersection between player data and slot analytics. So it should be a hoot. If you have a chance to get up to JSR for that, I'd love to see you there.
    So I think that about wraps it. Mike, thanks for all your comments and wisdom today. It was great talking to you, and you guys keep up the good work there. We're always really impressed by what we see coming out of Action, and we definitely steer a lot of clients towards you. It's great stuff, and we really admire what you guys do there.
    Mike Fields:
    Well, thanks so much. It was great catching up with both of you guys. I really appreciate the chance to chit-chat, and I hope you guys have a good afternoon.
    Nick Hogan:
    Yeah, likewise.
    Don Retzlaff:
    Thanks, Mike.
    Nick Hogan:
    Okay.
    Don Retzlaff:
    Really appreciate your time. We'll see you out in Vegas at G2E.
    Mike Fields:
    Can't wait.
    Nick Hogan:
    Okay, buddy. You take care.
    Mike Fields:
    See you soon. Bye.
    Nick Hogan:
    Bye-Bye.`
};

export default S02E06;
