import React from "react";

const PreviewTeaser = () => (
    <div className="row">
        <div className="col-xs-12 col-sm-8">
            <p className="paragraph paragraph--small">
                Like what you&apos;ve seen so far? Then you&apos;re going to{" "}
                <span className="text--emphasized text--red">LOVE</span>{" "}
                what&apos;s waiting for you inside.
            </p>
            <p className="paragraph paragraph--small">
                ReelMetrics subscriptions provide you with unfettered access to
                the world&apos;s largest slot repository and our full suite of
                groundbreaking advisory apps. Affordably priced and packed with
                value, ReelMetrics subscriptions deliver robust returns on
                annual subscription fees, a claim that you can verify with
                existing ReelMetrics Subscribers.{" "}
            </p>
            <p className="paragraph paragraph--small">
                To learn more about ReelMetrics subscriptions, give us a yodel
                and learn how our Big Data solutions help you take the guesswork
                out of game work.
            </p>
            <p className="paragraph paragraph--small">&nbsp;</p>
            <div
                className="btn btn-block-mobile btn-lg btn-primary mw-270 hidden-xs"
                data-toggle="modal"
                data-target="#contact"
            >
                Contact us
            </div>
        </div>
        <div className="col-xs-12 col-sm-4">
            <div className="teaser-container">
                <div className="teaser-content">
                    <img
                        style={{ width: "80px", paddingBottom: "15px" }}
                        src="/assets/images/reelmetrics-logo-white.svg"
                        alt=""
                    />
                    <p
                        style={{ maxWidth: "200px", margin: "0 auto" }}
                        className="paragraph paragraph-smallest paragraph--white white--emphasized"
                    >
                        You&apos;ve discovered a premium feature.
                    </p>
                    <p className="paragraph paragraph--smallest">&nbsp;</p>
                    <p className="paragraph paragraph--smallest paragraph--white">
                        Contact us to learn how ReelMetrics can help you take
                        the guesswork out of game work.
                    </p>
                    <div
                        className="btn btn-block-mobile btn-lg  btn-white btn-transparent mw-270"
                        data-toggle="modal"
                        data-target="#contact"
                    >
                        Contact us
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default PreviewTeaser;
