import React, { useState } from "react";
import PropTypes from "prop-types";

import Cms from "../../cms";
import Modal from "../../shared/Modal";
import SocialMedia from "../../shared/SocialMedia";
import Details from "./Details";

function Description({
    cmsContent,
    contentKey,
    imageDetails,
    reverseDescription
}) {
    const [showModal, setShowModal] = useState(false);

    const handleImageClick = () => setShowModal(true);

    const handleModalHide = () => setShowModal(false);

    const text = (
        <>
            <h2 className="title--banner text--red">
                <Cms
                    content={cmsContent}
                    content_key={contentKey}
                    type="title"
                />
            </h2>
            <p className="paragraph paragraph--small">
                <Cms
                    content={cmsContent}
                    content_key={contentKey}
                    type="text"
                />
            </p>

            <div className="paragraph reelhot-availability">
                <Cms
                    content={cmsContent}
                    content_key="reelhot_availability"
                    type="text"
                />
                <SocialMedia />
            </div>
        </>
    );

    const image = (
        <img
            src={imageDetails.image}
            alt={imageDetails.title}
            loading="lazy"
            role="presentation"
            onClick={handleImageClick}
        />
    );

    return (
        <>
            <div className="outside-wrapper container-fluid spotlight-hidden-gems-description">
                <div className="flex-row">
                    <div
                        className={`col-xs-12 col-sm-6 flex-column ${
                            reverseDescription ? "description-text" : ""
                        }`}
                    >
                        {text}
                    </div>
                    <figure
                        className={`col-xs-12 col-sm-5 flex-column ${
                            reverseDescription ? "description-image" : ""
                        }`}
                    >
                        {image}
                    </figure>
                </div>
            </div>

            <Modal show={showModal} onHide={handleModalHide}>
                <Details imageDetails={imageDetails} />
            </Modal>
        </>
    );
}

Description.propTypes = {
    cmsContent: PropTypes.object.isRequired,
    contentKey: PropTypes.string.isRequired,
    imageDetails: PropTypes.shape({
        title: PropTypes.string,
        title_slug: PropTypes.string,
        image: PropTypes.string,
        uuid: PropTypes.string
    }),
    reverseDescription: PropTypes.bool
};

Description.defaultProps = {
    imageDetails: {
        title: "",
        title_slug: "",
        image: "",
        uuid: ""
    },
    reverseDescription: false
};

export default Description;
