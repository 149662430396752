import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import Scrolltotop from "../components/scrollToTop";
import Header from "../components/header";
import CookieBannerComponent from "../components/cookie_banner_component";
import Cta from "../components/calculate-cta";
import Footer from "../components/footer";
import Login from "../components/login";
import ContactPopup from "../components/contactPopup";

function Layout({ cms_content, children, showCta }) {
    return (
        <Scrolltotop>
            <Helmet>
                <title>ReelMetrics</title>
                <meta
                    name="Description"
                    content="Leverage Big Data technologies and the world’s largest slot repository to drive revenues, cut costs and build loyalty."
                />

                <script id="mcjs">
                    {`
                            !function(c,h,i,m,p)
                            {
                                ((m = c.createElement(h)),
                                (p = c.getElementsByTagName(h)[0]),
                                (m.async = 1),
                                (m.src = i),
                                p.parentNode.insertBefore(m, p))
                            }
                            (document,"script","https://chimpstatic.com/mcjs-connected/js/users/b960b5797ce521627019711f4/07f4922d672543d75347e5039.js");
                        `}
                </script>
            </Helmet>

            <CookieBannerComponent cms_content={cms_content} />

            <Header />

            {children}

            {showCta && <Cta cms_content={cms_content} />}

            <Footer />

            <Login cms_content={cms_content} />

            <ContactPopup cms_content={cms_content} />
        </Scrolltotop>
    );
}

Layout.propTypes = {
    cms_content: PropTypes.object.isRequired,
    showCta: PropTypes.bool
};

Layout.defaultProps = {
    showCta: true
};

export default Layout;
