import React from "react";
import { Link } from "react-router-dom";

const Well = props => {
    const theme = props.theme ? props.theme : "well--default";

    let body;
    if (props.children) {
        body = props.children;
    } else {
        body = (
            <div className="well-container">
                <h2 className="title title--white well-title">{props.title}</h2>
                <Link className="well-link" to={props.link.target}>
                    <span className="btn btn-lg btn--color-red btn-white">
                        {props.link.title}
                    </span>
                </Link>
            </div>
        );
    }

    return (
        <div className={`well ${theme}`}>
            <div className="well-body clearfix">{body}</div>
        </div>
    );
};

export default Well;
