/* eslint-disable */
import React from "react";
import $ from "jquery";
import {} from "../jquery/slots";

export default class Statistics extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            configurations: [0, 0, 0],
            machines: [0, 0],
            games: [0, 0],
            cabinets: [0],
            venues: 0,
            markets: 0
        };

        this.counterFormatter = this.counterFormatter.bind(this);
    }

    counterFormatter(props) {
        return {
            configurations:
                props.counter_configurations_indexed.text.split(","),
            machines: props.counter_contributing_machines.text.split(","),
            games: props.counter_profiled_games.text.split(","),
            cabinets: props.counter_profiled_cabinets.text,
            venues: props.counter_contributing_venues.text,
            markets: props.counter_markets_covered.text
        };
    }

    componentDidMount() {
        // will trigger componentDidUpdate if the state is set here
        if (Object.keys(this.props.cms_content).length > 0) {
            // when switching pages the props are already available and the update lifecycle is not triggered
            this.setState(this.counterFormatter(this.props.cms_content));
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // the props are coming async so the state should be set here (in the update lifecycle)
        this.setState(this.counterFormatter(nextProps.cms_content));
    }

    shouldComponentUpdate(nextProps, nextState) {
        // if the state was already updated, don't trigger another update+render
        const update = nextState.markets !== this.state.markets;
        return update;
    }

    componentDidUpdate() {
        $(".js-slots").each(function (key, item) {
            new Slots($(item));
        });
    }

    render() {
        return (
            <section className="init-slots box box--dark box--dark--gradient-mobile">
                <div className="container-fluid scrollable-horizontal">
                    <ul className="questions inline-list inline-list--bordered">
                        <li>
                            <span className="comma-separated-slots">
                                <span
                                    data-number={this.state.configurations[0]}
                                    className="js-slots slot"
                                />
                                ,
                                <span
                                    data-number={this.state.configurations[1]}
                                    className="js-slots slot"
                                />
                                ,
                                <span
                                    data-number={this.state.configurations[2]}
                                    className="js-slots slot"
                                />
                            </span>
                            <span>Configurations indexed</span>
                        </li>
                        <li>
                            <span className="comma-separated-slots">
                                <span
                                    data-number={this.state.machines[0]}
                                    className="js-slots slot"
                                />
                                ,
                                <span
                                    data-number={this.state.machines[1]}
                                    className="js-slots slot"
                                />
                            </span>
                            <span>
                                Contributing
                                <br />
                                machines
                            </span>
                        </li>

                        <li>
                            <span className="comma-separated-slots">
                                <span
                                    data-number={this.state.games[0]}
                                    className="js-slots slot"
                                />
                                ,
                                <span
                                    data-number={this.state.games[1]}
                                    className="js-slots slot"
                                />
                            </span>
                            <span>
                                Profiled
                                <br />
                                game titles
                            </span>
                        </li>

                        <li>
                            <span className="comma-separated-slots">
                                <span
                                    data-number={this.state.cabinets}
                                    className="js-slots slot"
                                />
                            </span>
                            <span>
                                Profiled
                                <br />
                                cabinets
                            </span>
                        </li>

                        <li>
                            <span
                                data-number={this.state.venues}
                                className="js-slots slot"
                            />
                            <span>
                                Contributing
                                <br />
                                venues
                            </span>
                        </li>

                        <li>
                            <span
                                data-number={this.state.markets}
                                className="js-slots slot"
                            />
                            <span>
                                Markets
                                <br />
                                covered
                            </span>
                        </li>
                    </ul>
                </div>
            </section>
        );
    }
}

class Slots {
    constructor(item) {
        this.hasPlayed = false;
        this.item = item;
        this.numbers = Array.from(item.data("number").toString());
        this.slots = [];
        this.buildFaces();
        this.window = $(window);
        this.windowHeight = this.window.outerHeight();
        this.slots = $(".init-slots");

        if (!this.hasPlayed) {
            this.window.on("scroll", this.handleScroll.bind(this));
            this.handleScroll();
        }
    }

    handleScroll() {
        this.window.scrollTop();

        if (!this.hasPlayed && this.isScrolledIntoView(400)) {
            this.initializeSlots();
            this.hasPlayed = true;
        }
    }

    initializeSlots() {
        let key;
        let currSlot;
        for (const entry in this.numbers) {
            key = parseInt(entry, 10);
            currSlot = this.item.find(".slot-container").eq(key);
            const machine = currSlot.slotMachine({
                active: 0,
                auto: false,
                direction: "down",
                delay: (key + 1) * 75,
                randomize: () => {
                    return parseInt(this.numbers[entry], 10);
                }
            });

            machine.shuffle(4, function () {});
        }
    }

    buildFaces() {
        let slots;
        let slot;
        for (const entry in this.numbers) {
            slots = $(`<div data-index="${entry}" class="slot-container" />`);

            for (let i = 0; i <= 9; i++) {
                slot = $(`<span>${i}</span>`);
                slots.append(slot);
            }

            this.item.append(slots);
        }

        this.initializeSlots();
    }

    isScrolledIntoView(offset) {
        const docViewTop = this.window.scrollTop();
        const docViewBottom = docViewTop + this.window.height();

        const elemTop = this.slots.offset().top;
        const elemBottom = elemTop + this.slots.height();

        return elemBottom <= docViewBottom + offset && elemTop >= docViewTop;
    }
}
