export function butterImage(cmsContent, imagesKey) {
    if (cmsContent && imagesKey && cmsContent[`image.${imagesKey}`]) {
        return {
            backgroundImage: `url('${cmsContent[`image.${imagesKey}`].url}')`
        };
    }
    return {
        backgroundImage: `url(data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==)`
    };
}
export function butterImageURL(cmsContent, imagesKey) {
    if (cmsContent && imagesKey && cmsContent[`image.${imagesKey}`]) {
        return cmsContent[`image.${imagesKey}`].url;
    }
    return "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";
}
export function butterText(cmsContent, key) {
    if (cmsContent && key) {
        return cmsContent[key].text;
    }
    return "";
}
