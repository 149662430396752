import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import butter from "../utils/butterClient";

import Services from "../components/Services/Services";
import PickPlan from "../components/pick-plan";
import PlanDescription from "../components/plan-description";
import ProductInfo from "../components/product-info";
import AnalyticsInfo from "../components/analytics-info";
import PlanDescriptionLegend from "../components/plan-description-legend";
import ProductCompatibility from "../components/ProductCompatibility/ProductCompatibility";

function Product({ cms_content }) {
    ReactGA.pageview(window.location.pathname);

    const [cmsData, setCmsData] = useState({
        services: []
    });

    useEffect(() => {
        butter.content
            .retrieve(["services", "platform_testimonials"])
            .then(response => {
                const { services } = response.data.data;
                setCmsData({
                    services
                });
            });
    }, []);

    return (
        <>
            <Helmet>
                <meta name="keywords" content="slot machine data analytics platform" />
                <meta name="title" content="Slot Machine Data Analytics Platform" />
                <meta
                    name="Description"
                    content="Fact-based, actionable insights, Improve purchases and conversions, 
            Optimize conf, Drive revenues, Detect hits early, Gain clearer insights, Stay in the loop"
                />
                <link rel="canonical" href="https://www.reelmetrics.com/slot-machine-data-analytics-platform" />
                <title>Slot Machine Data Analytics Platform</title>
                <meta property="og:url" content="https://www.reelmetrics.com/slot-machine-data-analytics-platform" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Slot Machine Data Analytics Platform" />
                <meta property="og:description" content="Fact-based, actionable insights, Improve purchases and conversions,
            Optimize conf, Drive revenues, Detect hits early, Gain clearer insights, Stay in the loop" />
                <meta property="og:image" content="https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ" />
            </Helmet>
            <PickPlan navTo="plans-description" cms_content={cms_content} />
            <ProductCompatibility />
            <AnalyticsInfo cms_content={cms_content} />
            <Services services={cmsData.services} />
            <div id="plans-description" className="box col-xs-12">
                <div
                    className="container container--970 flex-row"
                    style={{
                        justifyContent: "space-between"
                    }}
                >
                    <PlanDescription isBasic />
                    <PlanDescription />
                </div>
                <PlanDescriptionLegend />
            </div>
            <ProductInfo className="hidden-xs" cms_content={cms_content} />
        </>
    );
}

Product.propTypes = {
    cms_content: PropTypes.object.isRequired
};

export default Product;
