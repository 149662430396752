import React from "react";
import PropTypes from "prop-types";

class Service extends React.Component {
    render() {
        let divider = false;
        if (!this.props.last) {
            divider = (
                <div className="box">
                    <div className="divider mw-970" />
                </div>
            );
        }
        if (this.props.index % 2 === 0) {
            return (
                <div>
                    <div className="flex-row">
                        <div className="col-sm-6 col-md-5">
                            <div className="cycle-item">
                                <h2 className="title title--primary title--md">
                                    {this.props.title}
                                </h2>
                                <p
                                    className="paragraph paragraph--small"
                                    dangerouslySetInnerHTML={{
                                        __html: this.props.text
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-7 col-media">
                            <img
                                src={this.props.image}
                                alt={this.props.title}
                                style={{
                                    height: this.props.imageHeight || "auto"
                                }}
                                className="img-responsive"
                                loading="lazy"
                            />
                        </div>
                    </div>
                    {divider}
                </div>
            );
        }
        return (
            <div>
                <div className="flex-row row--flex-column-reverse">
                    <div className="col-sm-6 col-md-7 col-media col-media-left">
                        <img
                            src={this.props.image}
                            alt={this.props.title}
                            style={{
                                height: this.props.imageHeight || "auto"
                            }}
                            className="img-responsive"
                            loading="lazy"
                        />
                    </div>
                    <div className="col-sm-6 col-md-5">
                        <div className="cycle-item body-right">
                            <h2 className="title title--primary title--md">
                                {this.props.title}
                            </h2>
                            <p
                                className="paragraph paragraph--small"
                                dangerouslySetInnerHTML={{
                                    __html: this.props.text
                                }}
                            />
                        </div>
                    </div>
                </div>
                {divider}
            </div>
        );
    }
}

Service.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageHeight: PropTypes.string,
    last: PropTypes.bool
};

Service.defaultProps = {
    imageHeight: "",
    last: false
};

export default Service;
