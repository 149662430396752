import React from "react";
import {
    XYChart,
    AreaSeries,
    LineSeries,
    CrossHair,
    XAxis,
    YAxis,
    LinearGradient,
    PatternLines,
    HorizontalReferenceLine,
    withParentSize
} from "@data-ui/xy-chart";
import { LegendOrdinal } from "@vx/legend";
import { scaleOrdinal } from "@vx/scale";
import { measureColors } from "./colors";

const legendScale = scaleOrdinal({
    range: [
        { stroke: measureColors.gptwColor },
        { stroke: measureColors.twColor },
        { stroke: measureColors.gpColor }
    ],
    domain: ["GPTW RPM", "Theo Win", "Games Played"]
});

const TimeSeriesChart = props => (
    <>
        {/* https://github.com/williaster/data-ui/blob/2620c9a7c37b180f3aeebc363423103053968700/packages/demo/examples/01-xy-chart/LineSeriesExample.jsx */}
        <XYChart
            margin={{ top: 10, left: 10, right: 40, bottom: 40 }}
            ariaLabel="Bar chart showing ..."
            width={props.parentWidth}
            height={props.height}
            showYGrid
            showXGrid
            xScale={{ type: "time" }}
            yScale={{ type: "linear" }}
            renderTooltip={({ datum, color }) => {
                const monthNames = [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December"
                ];
                const month = monthNames[datum.x.getMonth()];
                return (
                    <div>
                        <strong style={{ color }}>{datum.label}</strong>
                        <div>
                            <strong>Month: </strong>
                            {month} {datum.x.getYear() + 1900}
                        </div>
                        <div>
                            <strong>Value: </strong>
                            {datum.y}
                        </div>
                    </div>
                );
            }}
            snapTooltipToDataX
        >
            <XAxis label="Time" />
            <YAxis label="GPTW RPM" numTicks={8} />

            {props.series.map((serie, index) => (
                <PatternLines
                    key={JSON.stringify(serie)}
                    id={`area_pattern_${index}`}
                    height={12}
                    width={12}
                    stroke={serie.color}
                    strokeWidth={1}
                    orientation={["diagonal"]}
                />
            ))}
            {props.series.map((serie, index) => (
                <LinearGradient
                    key={JSON.stringify(serie)}
                    id={`gradient_${index}`}
                    from={serie.color}
                    to="#ffffff"
                />
            ))}
            {props.series.map((serie, index) => (
                <AreaSeries
                    key={JSON.stringify(serie)}
                    data={serie.gptwData}
                    stroke={serie.color}
                    fill={`url('#area_pattern_${index}')`}
                />
            ))}
            {props.series.map((serie, index) => (
                <AreaSeries
                    key={JSON.stringify(serie)}
                    data={serie.gptwData}
                    stroke={serie.color}
                    fill={`url('#gradient_${index}')`}
                />
            ))}
            {props.series.map(serie => (
                <LineSeries
                    key={JSON.stringify(serie)}
                    data={serie.gpData}
                    stroke={measureColors.gpColor}
                    strokeWidth={2}
                    seriesKey="games_played"
                    label="Games Played"
                />
            ))}
            {props.series.map(serie => (
                <LineSeries
                    key={JSON.stringify(serie)}
                    seriesKey="theo_win"
                    data={serie.twData}
                    stroke={measureColors.twColor}
                    strokeLinecap="butt"
                    strokeWidth={2}
                    label="Theo Win"
                />
            ))}
            <HorizontalReferenceLine
                reference={1}
                stroke="#000000"
                strokeWidth={1}
                strokeDasharray="3 3"
                dashType="dotted"
                strokeLinecap="butt"
                label="Floor Average"
                labelProps={{
                    width: 100,
                    verticalAnchor: "middle",
                    dx: 5,
                    dy: -10
                }}
            />

            <CrossHair showHorizontalLine={false} fullHeight stroke="black" />
        </XYChart>
        {props.legend ? (
            <div className="legend_wrapper">
                <LegendOrdinal
                    key="legend"
                    direction="row"
                    scale={legendScale}
                    shapeMargin="0"
                    labelMargin="0 0 0 4px"
                    itemMargin="0 5px"
                    shape={({ fill: style, width, height }) => (
                        <svg width={width + 12} height={height}>
                            <line
                                x1={6}
                                x2={width + 12}
                                y1={height / 2 + 1}
                                y2={height / 2 + 1}
                                strokeWidth={3}
                                stroke={style.stroke}
                            />
                        </svg>
                    )}
                    fill={({ datum }) => legendScale(datum)}
                    labelFormat={label => label}
                />
            </div>
        ) : null}
    </>
);
export default withParentSize(TimeSeriesChart);
