import React from "react";
import Cms from "./cms";

class Cta extends React.Component {
    render() {
        return (
            <section className="box box--primary box--up-your-game col-xs-12">
                <div className="container text-center">
                    <h2 className="title mb-xs-2">
                        <Cms
                            content={this.props.cms_content}
                            content_key="cta"
                            type="title"
                        />
                    </h2>
                    <div
                        className="btn btn-lg btn-white"
                        data-toggle="modal"
                        data-target="#contact"
                    >
                        <Cms
                            content={this.props.cms_content}
                            content_key="cta"
                            type="text"
                        />
                    </div>
                </div>
            </section>
        );
    }
}

export default Cta;
