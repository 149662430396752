import React from "react";
import { Table } from "react-bootstrap";

const PerformanceProfileTable = props => (
    <Table striped bordered condensed hover className="performance_profile">
        <thead>
            <tr>
                <th>Measure</th>
                <th style={{ textAlign: "right" }}>Value</th>
            </tr>
        </thead>
        <tbody>
            {Object.keys(props.data || {}).map(key => {
                const displayValue =
                    key.indexOf("Average Bet") > -1 ? (
                        <span
                            className="glyphicon glyphicon-lock"
                            style={{ float: "right", top: "3px" }}
                        />
                    ) : (
                        props.data[key]
                    );

                return (
                    <tr key={key}>
                        <td>{key}</td>
                        <td style={{ textAlign: "right" }}>{displayValue}</td>
                    </tr>
                );
            })}
        </tbody>
    </Table>
);
export default PerformanceProfileTable;
