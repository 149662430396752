import React from "react";

class ReelCastEpisodes extends React.Component {
  componentDidMount() {
    // Initialize Buzzsprout player script for each episode
    this.props.episodes.forEach((episode) => {
      const script = document.createElement("script");
      script.src = `https://www.buzzsprout.com/2057836/${episode.id}.js?container_id=buzzsprout-player-${episode.id}&player=small`;
      script.type = "text/javascript";
      script.charset = "utf-8";
      document.body.appendChild(script);
    });
  }

  render() {
    return (
      <>
        {/* {this.props.episodes.map((episode) => (
          <div key={episode.id}>
            <h2>{episode.title}</h2>
            <div id={`buzzsprout-player-${episode.id}`}></div>
          </div>
        ))} */}
      </>
    );
  }
}

export default ReelCastEpisodes;
