import React from "react";
import { Button, Row, Col } from 'react-bootstrap';
import ReelCastBanner from "../../shared/ReelCastBanner";

class ReelCastEpisodeTemplate extends React.Component {
    speakerNamePattern = /^[\w\s]+:/;

    formatTranscript(text) {
      if (typeof text !== "string" || text.trim() === "") {
        return <p>Error: Invalid input</p>;
      }
    
      const lines = text.split("\n");
      const formattedLines = lines.map((line, index) => {
        const isSpeakerName = this.speakerNamePattern.test(line);
    
        if (isSpeakerName) {
            return (
            // eslint-disable-next-line react/no-array-index-key
              <div key={index} style={{ marginBottom: 0 }}>
                <strong>{line}</strong>
              </div>
            );
          }
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>
              {line}
              <br />
            </div>
          );
      });
    
      return formattedLines;
    }
    
    render() {
        const { title, subTitle, description, transcript, topics, id, buzzSproutLink, spotifyLink, applePodcastsLink, iHeartRadioLink, cdcLink  } = this.props;
        const formattedTranscript = this.formatTranscript(transcript);
        
        return (
            <>
                <div className="text-page">
                    <div className="box" style={{ paddingBottom: 20 }}>
                        <div className="container">
                            <Row className="justify-content-md-start justify-content-center">
                                <Col className="col-sm-6">
                                    <h1 className="title title--primary title--md noPaddingBottom">
                                        {title}
                                    </h1>
                                    <h3>
                                        {subTitle}
                                    </h3>

                                    <p className="paragraph--small paragraph--small--small-screen">
                                        {description}
                                    </p>
                                </Col>
                                <Col className="col-sm-6">
                                    <div id={`buzzsprout-player-${id}`} style={{ padding: '10px 0' }}/>
                                    <Row className="justify-content-center">
                                        <Col className="col-sm-12 text-center">
                                            <Button variant="default" style={{ border: "none" }} onClick={() => window.open(`${cdcLink}`, '_blank')}>
                                                <img src="/assets/images/cdcLogo.svg" alt="CDC Gaming" width="40" height="40" style={{ verticalAlign: "middle" }} />
                                            </Button>
                                            <Button variant="default" style={{ marginLeft: 8, border: "none" }} onClick={() => window.open(`${spotifyLink}`, '_blank')}>
                                                <img src="https://upload.wikimedia.org/wikipedia/commons/1/19/Spotify_logo_without_text.svg" alt="Spotify Logo" width="40" height="40" style={{ verticalAlign: "middle" }} />
                                            </Button>
                                            <Button variant="default" style={{ marginLeft: 8, border: "none" }} onClick={() => window.open(`${applePodcastsLink}`, '_blank')}>
                                                <img src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg" alt="Apple Music" width="40" height="40" style={{ verticalAlign: "middle" }} />
                                            </Button>
                                            <Button variant="default" style={{ marginLeft: 8, border: "none" }} onClick={() => window.open(`${iHeartRadioLink}`, '_blank')}>
                                                <img src="https://images.squarespace-cdn.com/content/v1/54becebee4b05d09416fe7e4/1475275223141-LJT001HD9RFZQUCM9H0F/favicon.ico" alt="iHeartRadio" width="40" height="40" style={{ verticalAlign: "middle" }} />
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col className="col-sm-12 text-center">
                                            <Button variant="default" style={{padding: 8, marginTop: 10}}onClick={() => window.open(buzzSproutLink, '_blank')}>
                                                <img src="https://cdn.pixabay.com/photo/2013/07/12/17/11/add-151736_1280.png" alt="Buzzsprout" width="20" height="20" style={{ verticalAlign: "middle" }} />
                                                {" "} All Platforms
                                            </Button>
                                        </Col>
                                    </Row>


                                </Col>
                                {/* BUTT - TONNNNNN */}
                                <Col className="col-sm-12">
                                    {/* <hr /> */}
       
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div className="box box--light">
                        <div className="container">
                            <div className="row flex-row">
                                <div className="col-sm-12">
                                    <h3>Topics:</h3>
                                    <ul style={{ paddingBottom: 0, marginBottom: 0 }}>
                                        {topics.map(topic => (
                                            <li key={topic}>{topic}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Reelcast CTA */}
                    <ReelCastBanner />

                    <div className="box">
                        <div className="container">
                            <div className="row flex-row">
                                <div className="col-sm-12">
                                    <h3>Transcript</h3>
                                    <div className="paragraph--small paragraph--small--small-screen">
                                        {formattedTranscript}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid box--light">
                        <div className="row flex-row">
                            <div className="col-sm-1">
                                <h4>Keywords:</h4>
                            </div>
                            <div className="col-sm-11">
                                {/* eslint-disable-next-line jsx-a11y/no-distracting-elements */}
                                <marquee>
                                    {this.props.keywords.map(keyword => (
                                        <span
                                            key={keyword}
                                            style={{
                                                paddingRight: 10,
                                                fontWeight: "bold"
                                            }}
                                        >
                                            {keyword}
                                        </span>
                                    ))}
                                </marquee>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ReelCastEpisodeTemplate;
