import React from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";

import Cms from "../components/cms";
import { butterImageURL } from "../utils/butterHandler";

class ReelScan extends React.Component {
    constructor(props) {
        super(props);

        ReactGA.pageview(window.location.pathname);
    }

    render() {
        return (
            <>
            <Helmet>
                <title>ReelScan</title>
                <meta name="description" content="ReelScan is a mobile app that allows you to scan your slot machine's reels and get real-time data on your slot machine's performance." />
                <link rel="canonical" href="https://www.reelmetrics.com/reelscan" />
                <meta property="og:url" content="https://www.reelmetrics.com/reelscan" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="ReelScan" />
                <meta property="og:description" content="ReelScan is a mobile app that allows you to scan your slot machine's reels and get real-time data on your slot machine's performance." />
                <meta property="og:image" content="https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ" />
            </Helmet>
                <section className="box box--light">
                    <div className="container">
                        <div className="row flex-row">
                            <div className="col-sm-6">
                                <h1 className="title title--primary title--md">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="reelscan_title"
                                        type="title"
                                    />
                                </h1>
                                <p className="paragraph--small paragraph--small--small-screen">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="reelscan_paragraph"
                                        type="text"
                                    />
                                </p>
                            </div>
                            <div className="col-sm-6 white-frame">
                                <img
                                    src={butterImageURL(
                                        this.props.cms_content,
                                        "reelscan_upper"
                                    )}
                                    alt="Reelscan logo"
                                    className="img-responsive gray-border"
                                    loading="eager"
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <div className="box">
                    <div className="container">
                        <div
                            className="row flex-row"
                            style={{ alignItems: "flex-start" }}
                        >
                            <div className="col-sm-6 center-text qr-codes-section install-section">
                                <div>
                                    <h4 className="title title--primary">
                                        <Cms
                                            content={this.props.cms_content}
                                            content_key="reelscan_scan_text"
                                            type="title"
                                        />
                                    </h4>
                                    <p className="paragraph">
                                        <Cms
                                            content={this.props.cms_content}
                                            content_key="reelscan_click_paragraph"
                                            type="text"
                                        />
                                    </p>
                                </div>
                                <div className="flex-row">
                                    <img
                                        src={butterImageURL(
                                            this.props.cms_content,
                                            "reelscan_qrcode_apple"
                                        )}
                                        className="img-responsive qr-code col-sm-4"
                                        alt="QR code apple store"
                                    />
                                    <img
                                        src={butterImageURL(
                                            this.props.cms_content,
                                            "reelscan_scan_image"
                                        )}
                                        className="img-responsive col-sm-2"
                                        alt="cellphone scanning qr code"
                                    />
                                    <img
                                        src={butterImageURL(
                                            this.props.cms_content,
                                            "reelscan_qrcode_android"
                                        )}
                                        className="img-responsive qr-code col-sm-4"
                                        alt="QR code google play store"
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6 center-text install-section">
                                <div>
                                    <h4 className="title title--primary">
                                        <Cms
                                            content={this.props.cms_content}
                                            content_key="reelscan_click_text"
                                            type="title"
                                        />
                                    </h4>
                                    <p className="paragraph">
                                        <Cms
                                            content={this.props.cms_content}
                                            content_key="reelscan_scan_paragraph"
                                            type="text"
                                        />
                                    </p>
                                </div>
                                <div className="flex-row">
                                    <a
                                        href="https://apps.apple.com/app/id1476685256"
                                        className="flex-center col-xs-4 col-sm-4"
                                    >
                                        <img
                                            src={butterImageURL(
                                                this.props.cms_content,
                                                "reelscan_button_apple"
                                            )}
                                            className="img-responsive"
                                            alt="App store logo"
                                        />
                                    </a>
                                    <img
                                        src={butterImageURL(
                                            this.props.cms_content,
                                            "reelscan_click_image"
                                        )}
                                        className="img-responsive hidden-xs col-sm-2"
                                        alt="hand clicking"
                                    />
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.reelmetrics.reelscan"
                                        className="flex-center col-xs-4 col-sm-4"
                                    >
                                        <img
                                            src={butterImageURL(
                                                this.props.cms_content,
                                                "reelscan_button_android"
                                            )}
                                            className="img-responsive"
                                            alt="Google play store logo"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row flex-center row-separated">
                            <p className="paragraph paragraph--extra-smallest">
                                Keeping it reel at
                            </p>
                        </div>
                        <div className="row flex-center-space-around">
                            <img
                                src={butterImageURL(
                                    this.props.cms_content,
                                    "reelscan_g2e_logo"
                                )}
                                className="img-responsive fade-image col-xs-3 col-sm-2"
                                alt="G2E logo"
                            />
                            <img
                                src={butterImageURL(
                                    this.props.cms_content,
                                    "reelscan_ice_logo"
                                )}
                                className="img-responsive fade-image col-xs-3 col-sm-2"
                                alt="ICE logo"
                            />
                            <img
                                src={butterImageURL(
                                    this.props.cms_content,
                                    "reelscan_niga_logo"
                                )}
                                className="img-responsive fade-image col-xs-3 col-sm-2"
                                alt="NIGA logo"
                            />
                            <img
                                src={butterImageURL(
                                    this.props.cms_content,
                                    "reelscan_g2easian_logo"
                                )}
                                className="img-responsive fade-image col-xs-3 col-sm-2"
                                alt="G2E Asia logo"
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ReelScan;
