import React from "react";
import PropTypes from "prop-types";
import ServicesBlockItem from "./ServicesBlockItem";

function ServicesBlock({ services }) {
    return (
        <section className="box box--primary box-background--slot_symbols services_block">
            <div className="container">
                <div className="flex-row">
                    {services.map(service => {
                        const { title, text, image } = service;
                        return (
                            <ServicesBlockItem
                                key={title}
                                title={title}
                                text={text}
                                image={image}
                            />
                        );
                    })}
                </div>
            </div>
        </section>
    );
}

ServicesBlock.propTypes = {
    services: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            image: PropTypes.string.isRequired
        })
    ).isRequired
};

export default ServicesBlock;
