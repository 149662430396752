import React from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { butterImage } from "../utils/butterHandler";
import butter from "../utils/butterClient";

import Cms from "../components/cms";



class Vacancies extends React.Component {
    constructor(props) {
        super(props);
        this.state = { jobs: [] };
        ReactGA.pageview(window.location.pathname);
    }

    componentDidMount() {
        caches.keys().then((names) => {
            names.forEach((name) => {
              caches.delete(name);
            });
          });

        butter.content.retrieve(["jobs"]).then(response => {
            this.setState({
                jobs: response.data.data.jobs
            });
        });
    }

    render() {
        return (
            <>
            <Helmet>
                <title>Careers</title>
                <meta name="description" content="Interested in joining ReelMetrics? We post jobs regularly on this webpage but we are always open to meeting new people." />
                <link rel="canonical" href="https://www.reelmetrics.com/careers" />
                <meta property="og:url" content="https://www.reelmetrics.com/careers" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Careers" />
                <meta property="og:description" content="Interested in joining ReelMetrics? We post jobs regularly on this webpage but we are always open to meeting new people." />
                <meta property="og:image" content="https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ" />
            </Helmet>
                <div className="box box--light">
                    <div className="container">
                        <div className="mb-xs-3">
                            <div className="col-md-offset-1 col-md-8">
                                <h1 className="title title--lg title--primary">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="vacancies_splash_1"
                                        type="title"
                                    />
                                </h1>
                                <p className="paragraph">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="vacancies_splash_1"
                                        type="text"
                                    />
                                </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-offset-1 col-sm-10">
                                <span className="mb-xs-2 title title--explanation">
                                    We are currently looking for:
                                </span>
                                <table className="list-table">
                                    <tbody>


                                        {this.state.jobs.map(vacancy => (
                                            <tr key={vacancy.title}>
                                                <td>
                                                    <span className="title title--primary list-table__title">
                                                        {vacancy.title}
                                                    </span>
                                                    <span className="list-table__subtitle">
                                                        {
                                                            vacancy.summary
                                                        }
                                                    </span>
                                                </td>
                                                <td>
                                                    <Link
                                                        to={`/job/${vacancy.title}`}
                                                    >
                                                        <span className="btn btn-default btn-lg">
                                                            Learn more
                                                        </span>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="box">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-offset-1 col-sm-5">
                                <h2 className="title title--primary title--md">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="vacancies_band_1_message_1"
                                        type="title"
                                    />
                                </h2>
                                <p className="paragraph paragraph--small">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="vacancies_band_1_message_1"
                                        type="text"
                                    />
                                </p>
                            </div>
                            <div className="col-sm-offset-1 col-sm-5">
                                <ul className="list-bullet list-bullet--diamonds paragraph paragraph--small">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="vacancies_band_1_message_2"
                                        type="text"
                                    />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="box box--light">
                    <div className="container">
                        <div className="text-center mb-xs-5">
                            <h1 className="title title--primary title--md">
                                We&apos;re gaming vets, coders <br /> and
                                quants.
                            </h1>
                        </div>

                        <div className="container">
                            <ul className="list-unstyled grid row">
                                <li className=" col-sm-6">
                                    <div
                                        className="grid__item grid__item--rectangle squared"
                                        style={butterImage(
                                            this.props.cms_content,
                                            "about_us_splash"
                                        )}
                                    />
                                </li>

                                <li className="col-xs-6 col-sm-3">
                                    <div
                                        className="grid__item grid__item--squared squared"
                                        style={butterImage(
                                            this.props.cms_content,
                                            "about_us_2"
                                        )}
                                    />
                                </li>
                                <li className="col-xs-6 col-sm-3">
                                    <div
                                        className="grid__item grid__item--squared squared"
                                        style={butterImage(
                                            this.props.cms_content,
                                            "about_us_3"
                                        )}
                                    />
                                </li>

                                <li className="col-sm-6">
                                    <div
                                        className="grid__item grid__item--rectangle squared hidden-xs"
                                        style={butterImage(
                                            this.props.cms_content,
                                            "about_us_6"
                                        )}
                                    />
                                </li>

                                <li className="col-sm-6">
                                    <div
                                        className="grid__item grid__item--rectangle squared"
                                        style={butterImage(
                                            this.props.cms_content,
                                            "about_us_5"
                                        )}
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Vacancies;
