import React from "react";

function SocialMedia() {
    return (
        <ul className="socialmedia list-unstyled nav-inline nav-inline--small">
            <li>
                <a
                    href="https://www.facebook.com/reelmetrics/"
                    className="btn facebook"
                >
                    <span className="accessibility">Facebook</span>
                </a>
            </li>
            <li>
                <a
                    href="https://nl.linkedin.com/company/reelmetrics"
                    className="btn linkedin"
                >
                    <span className="accessibility">LinkedIn</span>
                </a>
            </li>
            <li>
                <a
                    href="https://www.instagram.com/reelmetrics/"
                    className="btn instagram"
                >
                    <span className="accessibility">Instagram</span>
                </a>
            </li>
        </ul>
    );
}

export default SocialMedia;
