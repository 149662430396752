import React from "react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";

class S02E02 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname);
    }

    // calls API and attaches scriptt to DOM
    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);

        if (Helmet.canUseDOM) {
            Helmet.renderStatic();
        }
    }

    // checkes if component should update based on props in Helmet
    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }

    // ensures Helmet sets teh code when page loads
    componentDidUpdate() {
        if (Helmet.canUseDOM) {
            Helmet.renderStatic();
        }
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>
                        {this.props.title} | {this.props.subTitle} | ReelCast by ReelMetrics the world&apos;s best slot analytics data platform
                    </title>
                    <meta
                        name="Description"
                        content={this.props.description}
                    />
                    <meta
                        name="Keywords"
                        content="best practices, slot machines, data-driven strategies, inventory optimization, player satisfaction"
                    />
                    <link
                        rel="canonical"
                        href="https://www.reelmetrics.com/s02e02-updating-best-practices-part-ii"
                    />
                    {/* Open Graph / Facebook / LinkedIn */}
                    <meta property="og:type" content="website" />
                    <meta
                        property="og:url"
                        content="https://www.reelmetrics.com/s02e02-updating-best-practices-part-ii"
                    />
                    <meta
                        property="og:title"
                        content="Updating Best Practices - Part II"
                    />
                    <meta
                        property="og:description"
                        content="Part two discussing best practices for managing your slot machines, we dive deeper into data-driven strategies for optimizing your inventory and increasing player satisfaction."
                    />
                    <meta
                        property="og:image"
                        content="https://cdn.buttercms.com/OaWFV5QDqgOJxaKViwdg"
                    />
                    <meta property="og:locale" content="en_US" />
                </Helmet>
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}

S02E02.defaultProps = {
    id: "12338678-s02e02-updating-best-practices-part-ii",
    title: "Updating Best Practices",
    subTitle: "Part II",
    buzzSproutLink:
        "https://reelcast.buzzsprout.com/2057836/12338678-s02e02-updating-best-practices-part-ii",
    spotifyLink:
        "https://open.spotify.com/episode/2ySDroifXThNFoH9QP4Zym?si=0Cpw87LXQJmd9E50AVxisA",
    applePodcastsLink:
        "https://podcasts.apple.com/us/podcast/s02e02-updating-best-practices-part-ii/id1652485625?i=1000601934019",
    iHeartRadioLink:
        "https://www.iheart.com/podcast/269-reelcast-by-reelmetrics-104029260/episode/s02e02-updating-best-practices-part-109635048/",
    cdcLink:
        "https://cdcgaming.com/reelmetrics/s02e02-updating-best-practices-part-ii/",
    description:
        "In part two of our discussion on best practices for managing your slot machines, we dive deeper into data-driven strategies for optimizing your inventory and increasing player satisfaction. ",
    link: "s02e02-updating-best-practices-part-ii",
    keywords: [
        "best practices",
        "slot machines",
        "data-driven strategies",
        "inventory optimization",
        "player satisfaction"
    ],
    topics: [
        "What are the data-driven strategies for optimizing inventory in a casino setting?",
        "How can you balance inventory diversity with player preferences?",
        "What is the impact of inventory optimization on player satisfaction?",
        "How can best practices for managing slot machines lead to increased revenue?"
    ],
    transcript: `Nick Hogan:
    At ReelMetrics, we collect and analyze a ton of slot data. And, we're hit up frequently for insights, tips, and tricks. So, we decided to do a webcast in which we present, discuss an otherwise nerd out on our work. Coming to you from our offices in lovely Leiden, The Netherlands, welcome to ReelCast.
    Good morning, Don. How are things near (and this is a St. Louis nickname I picked up over the weekend), the Chess Capitol of the World.
    Don Retzlaff:
    <Laugh>. It's very true. It's, it's a beautiful late winter day, little breezy today. We had winds like 50 miles an hour today, but we are definitely the chess city of the world. The Chess Hall of Fame is located in St. Louis, and there's a lot of major tournaments that are held in St. Louis, and the collegiate champions have been from St. Louis for years, so it is definitely a big chess town.    
    Nick Hogan:
    Yeah. What I was, I was reading that there's a Missouri billionaire in his seventies. He goes by the name of Tyrannosaurus Rex. Have you ever heard of this dude?    
    Don Retzlaff:
    <Laugh> Oh, yeah.    
    Nick Hogan:
    Ok. So he's apparently obsessed with the game, and has poured tens of millions of dollars into the sport locally. So there, there are three local universities now that offer full ride chess scholarships. Webster even has an institute that's headed by a grandmaster, and they're now afterschool chess programs at more than 100 local schools. So quite a <laugh>, quite a factoid to to pick up. And, and here's the kicker, Don: I checked this morning, and you can bet on chess. So,    
    Don Retzlaff:
    Really?    
    Nick Hogan:
    Yeah. Yeah. So if you're looking for a new hobby, you won't have to drive far to handicap the next generation of chess Champions.    
    Don Retzlaff:
    I guess. <Laugh>. Ok. I've bet on a lot of things in my life, but never chess.    
    Nick Hogan:
    Okay. Yeah. My, my kids played it for a while, then they got bored. So, I guess I kind of went the same, same direction as a kid. Oh, well.    
    Don Retzlaff:
    My dad taught me how to play chess when I was six years old.    
    Nick Hogan:
    Oh, wow. Okay. So you've been at it for a long time. But, the bug never really, truly bit you?    
    Don Retzlaff:
    Yeah, I, I go for years without playing a game, but thankfully in the age of computers, you can now play games online versus, back in the day I was playing games by mail.    
    Nick Hogan:
    Oh, wow. Okay.    
    Don Retzlaff:
    <Laugh>. Yeah, you have a postcard and you change, you'd make your moves via postcard.    
    Nick Hogan:
    Oh, wow. Okay. So like, you'd make a move every five days or something like that?    
    Don Retzlaff:
    Pretty much.    
    Nick Hogan:
    Okay. <laugh>. Alright. Well, I thought today, Don and let's see, this is our second episode of the, the second season. So I thought we could just kind of dive right in during the intro this morning and address a couple of listener questions. We had quite a few interesting queries float in during the last month. So I thought it'd be nice to touch upon two of them. So the first comes from a listener in the American Northeast who says, "Hi, guys. Loved the best practices episode. During the setup, Nick stated that Marketing should have a seat at the table as, as it pertains to, to inventory management. But, I don't believe you guys expanded upon this. So, can you provide a bit more detail? So first many thanks for the, the question.s
   It's a, it's a great one. Now, in addressing it what I'd say is it's, it's first important to explore a little bit Marketing's traditional role within gaming organizations. And I'm not saying that this is, is universal, but it's, it's certainly quite widespread. And it's this notion that Marketing's job is to bring people into the venue and its Operations' job to give them reason to come back. And so as such, Marketing traditionally within gaming has, has focused more on the Marketing Communications side of the equation. So these are ad buys email marketing, snail mail, promotions et cetera. While operations has been the organization really focused on the Product Marketing, so the selection, pricing, placement promotion of whatever we place on our “shelves”. Now we call our best practices stacked Demand Driven Inventory Management or DDIM for a reason.s
   And that is that everything, so all critical slot decisions are based on evidentiary demand, and you get to that evidentiary demand by analyzing player data in exhaustive detail. And the department that typically manages these data sets and is most experienced in with mining them is Marketing. Yet, when we look at soft polls, when we, we go out and we're, we're talking to gaming organizations, what we see is that Marketing almost never has a role in the inventory management within gaming organizations. And in fact, in a disturbingly large number of organizations, there are actually policies in place which prohibit Marketing from sharing player data with other departments. And as we've discussed you know, we're not aware of any other retail-oriented industry where Marketing is, you know, so isolated from the inventory side of the equation. And when we look at the waste levels in, in slot product, trust me : It shows . Now, a question is, you know, do, do we get pushback from Operations on, on Marketing's involvement?s
   Initially, yeah, we can. However, when the nuance of what Marketing contributions bring to the table start entering the fray, this is when we see that, that resistance, if there's any, there, really start to, to dissipate. And, and I'll give you a great example of this nuance. So one of the places where it absolutely shines is in the concentration of player productivity. So, when you discuss demand with slot people, they often cite this Pereto Principle of, let's say 20% of the customers generating 80% of the revenue. Fine. I think everybody, everybody understands that principle. But in this realm, the devil is very much in the detail, and it's generally Marketing that can provide that detail. So first, the, the top level split is generally a bit closer to you know, 15% of the players generating you know, around 85% of the revenue.s
   But more critically, you often see fewer than, let's say 5% of players generating close to half of all revenue. So who are they? What do they play? When do they play? How do they play? Are we creating any obstacles which impede that play? And most critically, how do our inventory management decisions impact that play? This is the, this is where Marketing can really provide a lot of incremental detail. That's, that's really help helpful here. So why involve Marketing? Well, first it's generally Marketing and the data over which they preside that's, you know, best positioned to help you find reliable high resolution answers to such questions. And secondly, modeling to find these answers is typically a multi-step process that requires a lot of collaboration and, and iterative enhancement. So player data is just a very different beast than performance data. And you'll need an open dialogue with experienced staff to, to, to truly harness and, and exploit it.s
   And, and typically you can find these you can find these folks in Marketing departments and, you know, a little, little shameless plug here. And that is, you know, if you combine this collaboration between Operations and Marketing with our best practices stack and our Cupid Segmentation modeling, you're going to streamline and accelerate this process exponentially. And, truly that's your goal. So by the time your competitors have any idea what you're doing, you want to have a vibrant, well-structured program up operational, snatching wallet from your competition, and spinning off cash. So, if you, if you're interested in that <laugh>, just give us a jingle and we'll walk you through it. But, in all this, really, Marketing is a Core component of making sure that you can really create these vibrant, well-structured programs. So, Don, any anything you wanna add to that with respect to Marketing having a seat at the table?    
    Don Retzlaff:
    Well, we'll dive into some of the best practices using Marketing and Host-Level players here in a little bit. The big thing I guess would be, it's not always easy to pull player data out of the slot information systems. You can find machine numbers and length of play and stuff like that, but it's really tough to coordinate what your best players are playing. And that's where Marketing has it all together. They can help you run reports that will identify what your best players are playing. So, lean on Marketing to help find some of these answers that we'll be talking about here shortly.    
    Nick Hogan:
    Okay, great. Okay. And so the second question that I wanted to hit it was from a listener in the Northern Midwest, and they had asked, "In season two, episode one, you mentioned a machine doing 6-times house average and that, due to demand, this isn't ideal. So my question is, where's the sweet spot with 1.5X being too low and 6X too high? What's a good mean performance level to target? So, again, many thanks for the question. Now, my guidance to this listener was, was to focus more on target occupancy levels which we've covered previously. So we generally guide around 65% mean occupancy for, for your high octane lease games. And then Don, I think it's a little closer to 35% in the Core. Is that generally where you guide ?    
    Don Retzlaff:
    It is.    
    Nick Hogan:
    Okay. So, I kind of cautioned him away from targeting specific occupancy levels, but then Don chimed in and said, actually, you can kind of eyeball this <laugh>. So Don, maybe you can, you can add a, a bit additional additional color there.    
    Don Retzlaff:
    Yeah, it has to be a combination of occupancy, plus you're average against house average. If you're above 4X, 4.5X, you really should consider adding more units unless your occupancy levels are really, really low. But, this only happens in casinos that have a very low win per unit, per day average saysouth of $150, where your lease games are doing so well, they're doing $1,000, but your house average is only $150. You'll see it sometimes there, you'll see a 6X multiple, but the density levels are really, really low. A lot of weekend driven business. But generally if you're above 4X, 4.5X, you really should start considering expanding.    
    Nick Hogan:
    Yeah. Okay. And then in terms of, do you, do you have like general thoughts on kind of where they want to shoot in terms of a performance level? Like is it more 2X to 2.5X or where, where do you, or do, do you guide in that way? Really?    
    Don Retzlaff:
    I, I like to stay in the threes. Yeah. somewhere between 3X and 4X is usually a really nice place. That means you've got a, a nice variety, you've got enough depth and enough units. Once you get below 3X, that gets tough. Then, like you said, once you get above 5X, it's really you're not satisfying your customers. There's a lot of wait, there's a lot of downtime. Yeah.    
    Nick Hogan:
    Okay. Gotcha. Okay, well, many thanks for that, Don. And again thanks to the listeners who submitted those questions. We, we really appreciated it. And for everybody else we, we'd love to tackle any questions that anybody listening may have. So if you have a question about what we're presenting or something you'd like us to present, please drop us an email at reelcast@reelmetrics.com. Again, that's R E E L C A S T @reelmetrics.com. And as you've heard, our policy is to keep all questions anonymous, so please speak directly and don't worry about us revealing your identity. That is decidedly not our jam. So Don, here we go, the second part of a multi-part series on updating best practices. So, I will turn it over to you and let's get at it.    
    Don Retzlaff:
    All right, let's let's start with floor size, especially post-Covid. We've been seeing a lot of floors that are larger than they should be. I guess the first rule of thumb would be if 25% of your floor is getting less than 25,000 handle pulls per month, you probably could pull games without any revenue implications. There's just a lot of obsolete games on the floor, especially once you get down to the low denomination reels. Anything below a dollar per se, that's, look at those first we're seeing a lot of post-Covid play get away from reels. There was a lot of our older customers that did not come back to the casinos, and it's really impacted the win per unit of the low limit reels, especially quarters and, and pennies. There are just a lot of games on the floor that probably shouldn't be there anymore.s
   So starting with those obsolete cabinet removals pull 'em out bank-by-bank, you know, based upon your revenue and your fair share analysis, that's the best place to start to open up your floor to get to the second phase, which is getting rid of the large banks. Banks of eight should be split into two banks of four, banks of six should be reduced to packs of four banks along the walls need to be broken up. Try to create as many end games as you can and get rid of as many middle machines as possible. You can util utilize carousels whenever you can. They're a space eater, but if you get rid of those machines that are not producing in your floor, you'll just make the floor that much more comfortable for all your customers.    
    Nick Hogan:
    So Don, with these, we're    
    Don Retzlaff:
    Seeing a lot    
    Nick Hogan:
    Of, with these big linear banks, that was kind of always the standard, you know, way back when. What would you say is the--just for anybody who's not really familiar with this--why is it that these things are going out of mode?    
    Don Retzlaff:
    The revenue that the middle machines produce is so much lower than the end machines, especially if after you get past a six pack, when you start looking at banks of eight banks of 10 banks of 12, those the revenue that the middle machine's producing is so much lower than the end machines. Sure. The player comfort factor, especially Covid has added into it. But even before covid, their revenue for those machines was super low. And now post-Covid, it's really fallen off the floor. It's something that needs to be looked at at most casinos. Now, obviously, if you've got a capacity issue, if your capacity's strained during that Thursday through Sunday period, it's a different problem. I had times where I was jamming machines into every nook and cranny in my floor as I could just because I needed a space, but most casinos aren't in that world anymore and they really need to reduce these bank sizes.s
   We'll go on to the next step then, where you start getting to the artform of trying to build these neighborhoods, trying to examine your top themes and by theoretical tier. What are your themes that your key players are playing? Use that top 50 list and try to start putting banks in the general neighborhood of what your Host-Level players like to play. First you have to determine is there significant crossover between game types? If not, there's no need to place your top 50 units near high limit or near your Video Poker. If you're a Host-Level players shy away from Video Poker or there's no crossover play, there's no need to keep 'em next to each other. But if there is significant crossover play, you need to consider maybe placing your top 50 themes near game types that do crossover, like your dollar reels or your high limit area, your Electronic Table Games, your Video Poker, things like that.s
   But generally, if there's no crossover play between your Host-Level players and the other game types, you can separate 'em. Have your own penny area that has your best lease games, your best Core games, and keep those players in that neighborhood. And that's where one of the things that I've enjoyed doing the last couple years with the [ReelMetrics] site is using the location grading to determine the best location for building your neighborhoods. Will these themes work in a B location, or do they have to be in an A location to work? And that ends up being really key when you're building these neighborhoods because you can put something near a Dragon Link or a Lightning Link or a Buffalo Link bank and pick up the pace on those games. But, what happens when you separate 'em? Are you gonna lose revenue per day?s
   Are they gonna be fined someplace else? So you really have to use that art form of trying to figure out what banks need to go next to which banks. And the only way you can do that is look into, figure out where the crossover play is and then you start tiering it. What themes are that $150 to $300 ADT players playing? Is it different than your Host-Level players? Can you build a neighborhood for them? What themes are your low theoretical players playing? Like I said earlier, it's mostly gonna be your obsolete cabinets, your older themes, and you can definitely group those together. And most casinos do, and most of the casinos will put their obsolete games next to obsolete games. There are some cases where you can put some of those older cabinets in a preferred location because your Host-Level players will pound some of those older cabinets, but it's usually not their first choice.s
   And then the next thing is you start looking at things like what player segment is playing in your Electronic Table Games? Is it table games players? Is it predominantly slot players? And based on those findings, your Electronic Table Games either should or shouldn't be near your table games. If it's a lot of table games crossover played, but a close, if it's mostly slot players that are playing it, you don't have to put your Electronic Table Games near slots. You'll see a lot of Electronic Table Games near sports books now, and that's been a pretty successful crossover. And that same thing goes with your Video Poker players. If there's no crossover, play with your best players. You can afford to put your Video Poker in a C, D or F location on your floor, but if there is solid crossover play with your best players, you probably shouldn't put them any worse than a C location. There are some revenue impacts by putting your Video Poker in a better location. I've done it everywhere from an A location to an F location and there usually ends up being about a 10 to a 20% difference in revenue just because of the convenience factor of having Video Poker in a better location.    
    Nick Hogan:
    And, Don, with that with the ETG and, and poker crossover and slots, what are, are you finding generally that this is like massively variable by like changes in demographics or things of this nature? What, what are you, what are you really seeing in terms of crossover with those game types?    
    Don Retzlaff:
    It's kind of been surprising the, it's basically, let's try this again. It is a definitely a younger crowd that's playing them. Mm-Hmm. <affirmative>, you're seeing a lot of play under the age of 45 playing Electronic Table Games. You're not seeing a whole lot of crossover play between Electronic Table Games and slots. Mm-Hmm. <affirmative>. There are players who play ETG only. But the, the biggest I guess finding I've seen so far is the number of players, younger players who are playing slot machines more than I thought they would. Yeah. Yeah. I figured it would be the younger players would be gravitating towards the, the social games, the blackjack, craps, roulette, and they're spending the vast majority of their theoretical on slot machines. Yeah. Both men and women. And it kind of goes against what we've kind of figured for the last decade or so, is that No, certainly the younger players are looking for those social aspects of the games, but they're not, they are playing slots and they're playing specific slot machines.s
   So, I guess the next thing we want to talk about on the best practices is when you need to expand your current selection versus when you should buy new. One of the biggest things is just making sure that your themes that resonate with your Host-Level players, that you have enough of them. What's at high occupancy that you can't get enough of with conversions? Do you have certain cabinet shortages? Are all your themes on your banks at 1.2X house or better on your Core games? If so, you might want to consider expanding some of these cabinets, especially if they're fairly new games that have been out in the last two years or so. If all themes are over one and a half times house average on your bank, you definitely need to start looking at expansion for these games, especially if they are Host-Level player specific games.s
   You need to make sure you have enough of the new themes that are out there that are doing really well. And there's some Core games out there that have been introduced in the last two years that are still doing 2X to 2.5X now, 3X house average on some of these Core games, and there's just not enough of 'em out there for your Host-Level players. So look at all your game types and denominations. A lot of your Action Gaming themes can be expanded. A lot of your high worth Core games can be expanded, especially in cabinets that were introduced in the last two years. And the other big thing is you really need to start looking at video units above pennies for expansion. The Host-Level players will pound these games. We're seeing insane average bets on some of these units, north of $10 per spin on nickels and dimes. And it actually creates occupancy plusses for you because a lot of your players will not sit on those games because of the average bet. So, it opens up play opportunities for your Host-Level players who will play up. And there's a lot of games out there, both classics and new that are doing really well at 5 cents and 10 cents. Beyond the lease games.    
    Nick Hogan:
    Now, Don, on that point about, you know, adding more of, of stuff that's already working, we've discussed this in the past that there just kind of seems to be a natural reluctance to do that, that is just replicating themes that are already working, especially on the Core side of things. So would your advice to people on this one just be, you know, trust the math on this <laugh> and that if you're seeing the fair share values and the performance values really support it in addition to, you know your, your player data, just, just move on it. Because, I do encounter a lot of reluctance on that, on that notion of just, you know, replicating what's working.    
    Don Retzlaff:
    Yeah. You have that balancing act between refreshing your floor and making sure that your customers have what they want to play on your floor. Everyone wants the new shiny games, but we know we've talked about in the past that you only have about a 25, 30% success rate (at best) on new themes and new cabinets. Some of the new cabinets that are out there are slam dunks. Don't wait. But there's a lot of stuff out there that you look at and you say, well, there's no depth to these cabinets. And you might wanna consider waiting if you can for a few months to see if there's additional themes that come out. But in the meantime, you've got games on your floor that are doing over two times average as a Core game.s
   You probably have games out there that are doing better than your lease games and they need to be expanded. There's been a lot of really neat titles the last couple of years and they need to be expanded. They're, they're packed all the time, so why not consider expanding your successes? Cuz these games have legs and they're gonna be on your floor for a long time. You've got classic games that have legs that have been out the floor for seven, eight years that you wouldn't even think about getting rid of. And it's gonna be the same with these games here. Okay. So I guess that leads into, you know, when do you buy things that you don't have on your floor? So you know, if your floor is ready for new cabinets and themes, the first step is a look at that library depth. You know, how many themes are over one and a half times house average in their library.s
   The greater that number, the more machines that you can purchase. We've talked about it in the past where some manufacturers have come out with one or two themes and that was all they ended up with with that cabinet. Yeah. So once those themes ran their course, you were stuck. Yeah. And if it's a, if they couldn't come up with anything additional, you're stuck with that game for seven years of low performance. So you really need to look at those libraries and definitely those trust the ones that you trust, I guess is the easiest way to say it. Some of the smaller vendors out there have some really neat games, but they are limited in the number of units that they're producing. If none of the games are over 1.2 times house average, you might wanna consider passing because, you know, within a year or two that those games are all gonna be below house average.s
   So it really is about that depth of library now when you're looking at reels and Video Poker. Look for house average or better, that's about what you should expect on a good reel or a good Video Poker game. I'd look at conversions first before you have new spend. Revenue strength is just not comparable between new video and new reels or new Video Poker with very few exceptions. So, spend most of your money on that video, that video percentage of revenue keeps climbing and it's gonna be 80% of the revenue before too much longer. So the reel play continues to, to decline even with the addition of the new cabinets this year. They were really nice cabinets and looked really sharp and play really well, but that percentage of players who plays reels is going down every year and I just don't see it going up.    
    Nick Hogan:
    And, Don, has that has that been ran aging demographic on physical for some time now? I mean, has that been the, the overall trend you've seen?    
    Don Retzlaff:
    It really is. And between Covid and the introduction of the high performance lease games that your Host-Level players could play a higher average bet, it really started cutting into the reel play. I remember back, you go back about eight years ago or so, and I started seeing that drift from those dollar reel players and the $5 reel players to your high performing lease games because they were playing $5 a spin on these lease games, when before they couldn't. And that really just accelerated the issue. And then when Covid hit a lot of those customers just did not come back. It is definitely an older demographic that plays reels, and as a demographic ages and has less disposable income, you're starting to see that shift.    
    Nick Hogan:
    Yeah. And I know there was a theory out there for a long time that it was, you know, physical reels were far less intimidating to you know, unsophisticated players. People who are just coming in. But, you know, from, from some of the data that I looked at and some of these studies that we did recently, you know, you didn't really see that certainly with the younger people, <laugh> it wasn't, it didn't look like they were stepping up to a lot of physical reels. <Laugh>.    
    Don Retzlaff:
    Yeah. It used to be that the, the video was always intimidating cuz you had all these choices to make how many lines, how many, how many coins per line were you gonna play? Well the manufacturers have done a really good job of cleaning that up and now it's a lot of force betting. To where they're basically just hit the button just like you do, pull a handle on the old reels or hit the button and it's a lot less intimidating. There might be 243 ways or a thousand plus lines, but you don't have to know where all the lines are. You just hit the button and know what the results are.    
    Nick Hogan:
    One big fat button <laugh>.    
    Don Retzlaff:
    Yeah. Just pick the, the amount of that you're comfortable playing, whether it's, you know, 50 cents a dollar, $2, $3, and that's all you have to worry about. Just like before on the reels it was, you know, a quarter machine, you know, you're playing 75 cents or a dollar machine, you're playing two or $3. And so it, they've really simplified it and it, it's made it a lot easier to play video. You don't have to worry about it. I don't know where that 15th line is anymore. You just don't care. So I, the the next part on trying to figure out what you don't have on the floor that you might want, look at smaller banks, especially for new cabinet purchases. Anything released in the last 12 months, you probably wanna do four packs and six packs. Some cabinets just never do find a foothold on your floor.s
   And you're gonna have these machines for 10 years with declining performance every year. So I would definitely limit the number of themes per bank and make the bank smaller, especially for some of the smaller vendors out there that you want to have on your floor that you do wanna buy versus trial. A bank of four should only have two themes. A bank of six probably should only have two themes as well. It makes the themes last longer, it makes the libraries deeper for you. It, it really does help end the performance in these banks goes up. We did a study last year with all new bank installations and the banks that had one or two themes on 'em outperformed the banks that had three or more and it wasn't even close. Yeah. It was like double.    
    Nick Hogan:
    Yeah, I remember this. It was, those, those numbers were amazing <laugh>.    
    Don Retzlaff:
    Yeah. And, and we've kind of told that to the smaller manufacturers too is when you're going out there doing these sales, you don't have to pitch six themes on a bank of six, pitch your best one or two themes and watch the performance. Just lock it up for these themes. And then if they do well, then you can expand your sales. You can expand the number of cabinets you have on your floor. There, there's just no need to have six themes on six games or four themes on a bank of eight. You know, stick with your high counts of your allstars and then expand the cabinets if they're doing well.    
    Nick Hogan:
    Yeah. And harkening back to some of our first episodes, we, we discussed over-diversification and the problems that we see there. And you know, this is another thing where we say for theme count, typically we find, you know, the, the overriding principle is "less is more" <laugh> ,completely contrary to how we were raised in the industry, which is, you know, "variety is a spice of life" and "you need to hedge your risk". You know, that was always what it was about. And we just found, you know, that that's not something to live by anymore.    
    Don Retzlaff:
    I just looked at two new clients floors over the weekend and they're doing the same thing as everybody else. It is your top 35 to 50 machines on your floors doing 50% of your revenue. Just, there's just a limited number of themes that produce so much that you really don't have to have 500 different themes on your floor. You know, if you've got a thousand units on your casino floor, you can really limit the number of themes because that's what the customers want. They wanna play the games that they enjoy. And most of the time you've got a lot of the themes out there that people just don't wanna play.s
   So that was about it for the best practices for this episode. Nick. I know we wanted talk about tracking performance and utilizing performance guarantees and stuff like that in the next episode to wrap up the best practices. But the big thing, the overall, our overarching principles here is work with Marketing. Figure out what your Host-Level players are playing and make sure you have enough of those games, whether it's lease, whether it's Core, and really look at those weekends. If your Host-Level players play on the weekend, you need to make sure that they can have a good experience.    
    Nick Hogan:
    Yep. I think it's always this dichotomy of, you know, peak occupancy versus mean occupancy and just making sure you always have those numbers straight.    
    Don Retzlaff:
    Yeah. Most of your revenue is gonna come from that Thursday evening through Sunday evening period. And make sure that you have enough of the good games during that time. You know, and it goes to walking the floor, talking to your Host-Level players to see what they're playing through, you know, with Marketing's help or polling reports from the information systems. But you've gotta have enough of those good games on the weekends.    
    Nick Hogan:
    Yep. Okay. Cool. Well okay, so thanks for that, Don. And so before we sign off, I wanted to put in another shameless plug <laugh>. So we have the IGA tradeshow in San Diego coming up at the end of March. I'm going to be speaking on a panel there about data democratization. It's gonna be moderated by the great buddy Frank, and it will be myself and Grant Stousland, the CEO of Slot Check. Now it's gonna be in the Tribal Leaders Lounge at 11:00 AM on Wednesday the 27th of March. So if you're at IGA, please stop by. We'd love to see you.s
   So Don with that, I, I think it's a, it's a wrap. So, I think it's time for you to jump in the car and get out there and start handicapping some local chess nerds.    
    Don Retzlaff:
    <Laugh> <laugh>. I think I'll stick with golf. <Laugh>.    
    Nick Hogan:
    Oh, there you go. So how many people around the industry have your model now? Is it, is it in the tens or twenties or is it just really a limited limited circulation    
    Don Retzlaff:
    For the golf ?    
    Nick Hogan:
    Yeah, for your betting model, the golf.    
    Don Retzlaff:
    There's more of my fantasy football models out there for some of our clients that have asked for 'em. And especially some of the the previews that I do in like August and all that, some of the rankings, but there's a couple out there that, that are texting me every week on who to bet on for golf. So <laugh>, it's been a good run. So it's a nice secondary income. So it's it's something fun.    
    Nick Hogan:
    Well, at least you can make it work. If I bet on anything. It's just a disaster <laugh>.    
    Don Retzlaff:
    No, it's been fun. It's it's, it's makes the weekends enjoyable when you've got a guy in contention <laugh>.    
    Nick Hogan:
    Okay. Okay, cool. Well thanks again Don. And yeah, well until next time.    
    Don Retzlaff:
    All right. Take care Nick.    
    Nick Hogan:
    Thank you. Bye bye.`
};

export default S02E02;
