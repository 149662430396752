import React from "react";

const Card = props => {
    const cardClasses = ["card", "card-min-height", "card-clickable"];
    if (props.active) {
        cardClasses.push("card-active");
    }
    const cardBodyClasses = ["card-body"];
    let learnMore = null;
    if (props.locked) {
        cardBodyClasses.push("card-locked");
        cardClasses.push("card-locked");
        learnMore = (
            <div className="overlay hidden-xs hidden-sm">
                <span className="btn btn-block-mobile btn-lg btn-transparent mw-270">
                    Click to learn more
                </span>
            </div>
        );
    }

    return (
        <>
            <div className={cardClasses.join(" ")}>
                {props.locked ? (
                    <div className="card-locked-icon">
                        <span className="glyphicon glyphicon-lock" />
                    </div>
                ) : null}
                <div className="card-header">
                    <h2 className="title title--primary">{props.title} &gt;</h2>
                </div>
                <div className={cardBodyClasses.join(" ")}>
                    {props.children}
                </div>
            </div>
            {learnMore}
        </>
    );
};

export default Card;
