import React from "react";

function SlideItem({ images, slideKey }) {
    return (
        <div className="compatibility_slide_item">
            {images.map(image => {
                const { src, alt, height } = image;

                return (
                    <img
                        key={alt}
                        id={alt}
                        className={slideKey}
                        src={src}
                        alt={alt}
                        height={`${height}px`}
                        loading="eager"
                    />
                );
            })}
        </div>
    );
}

export default SlideItem;
