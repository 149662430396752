import React from "react";

export default class Cms extends React.Component {
    createMarkup() {
        if (
            this.props &&
            this.props.content &&
            this.props.content[this.props.content_key] &&
            this.props.content[this.props.content_key][this.props.type]
        ) {
            return {
                __html: this.props.content[this.props.content_key][
                    this.props.type
                ]
            };
        }

        // Only for debugging
        // return {__html : this.props.content_key + "." + this.props.type};
        return { __html: "" };
    }

    render() {
        if (this.props && this.props.type && this.props.type) {
            return <span dangerouslySetInnerHTML={this.createMarkup()} />;
        }

        return null;
    }
}
