import React from "react";
import Cms from "./cms";

class Questions extends React.Component {
    render() {
        return (
            <section className="box">
                <div className="container">
                    <div className="row flex-row">
                        <div className="col-xs-12 col-sm-6 col-md-4 text-center">
                            <div className="question-box">
                                <figure className="question-box__media">
                                    <img
                                        src="assets/images/which-games-work.svg"
                                        alt="which-games-work"
                                        className="img-responsive center-block"
                                        loading="lazy"
                                    />
                                </figure>
                                <span className="question-box__title">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="hard_questions_2"
                                        type="title"
                                    />
                                </span>
                                <p className="question-box__body">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="hard_questions_2"
                                        type="text"
                                    />
                                </p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6 col-md-4 text-center">
                            <div className="question-box">
                                <figure className="question-box__media">
                                    <img
                                        src="assets/images/which-config-yields.svg"
                                        alt="which-config-yields"
                                        className="img-responsive center-block"
                                        loading="lazy"
                                    />
                                </figure>
                                <span className="question-box__title">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="hard_questions_3"
                                        type="title"
                                    />
                                </span>
                                <p className="question-box__body">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="hard_questions_3"
                                        type="text"
                                    />
                                </p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6 col-md-4 text-center">
                            <div className="question-box">
                                <figure className="question-box__media">
                                    <img
                                        src="assets/images/which-player-profiles.svg"
                                        alt="which-player-profiles"
                                        className="img-responsive center-block"
                                        loading="lazy"
                                    />
                                </figure>
                                <span className="question-box__title">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="hard_questions_4"
                                        type="title"
                                    />
                                </span>
                                <p className="question-box__body">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="hard_questions_4"
                                        type="text"
                                    />
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="mt-sm-3 text-center">
                        <a href="/slot-machine-data-analytics-platform" className="link link--primary">
                            Take a product tour
                        </a>
                    </div>
                </div>
            </section>
        );
    }
}

export default Questions;
