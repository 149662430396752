import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import butter from "../utils/butterClient";
import Hero from "../components/shared/Hero";
import ServicesBlock from "../components/Services/ServicesBlock";
import Services from "../components/Services/Services";

function ServicesPage({ cmsContent }) {
    ReactGA.pageview(window.location.pathname);

    const [services, setServices] = useState({
        services: [],
        servicesBlock: []
    });

    useEffect(() => {
        butter.content
            .retrieve(["services_page_items", "services_page_items_red_block"])
            .then(response => {
                const { services_page_items, services_page_items_red_block } =
                    response.data.data;
                setServices({
                    services: services_page_items,
                    servicesBlock: services_page_items_red_block
                });
            });
    }, []);

    return (
        <>
            <Helmet>
                <title>Services</title>
                <meta
                    name="Description"
                    content="Fact-based, actionable insights, Improve purchases and conversions, 
                Optimize conf, Drive revenues, Detect hits early, Gain clearer insights, Stay in the loop"
                />
                <meta name="keywords" content="slot machine data analytics platform" />
                <meta name="title" content="Casnio Slot Data Analytics Services" />
                <link rel="canonical" href="https://www.reelmetrics.com/services" />
                <meta property="og:url" content="https://www.reelmetrics.com/services" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Casnio Slot Data Analytics Services" />
                <meta property="og:description" content="Fact-based, actionable insights, Improve purchases and conversions, Detect hits early, Gain clearer insights, Stay in the loop" />
                <meta property="og:image" content="https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ" />
            </Helmet>
            <div className="services">
                <Hero
                    cmsContent={cmsContent}
                    contentKey="services_hero"
                    imageSrc="/assets/images/rm_logo_csm.svg"
                    imageAlt="ReelMetrics CSM Logo"
                />
                <ServicesBlock services={services.servicesBlock} />
                <Services services={services.services} imageHeight="265px" />
            </div>
        </>
    );
}

ServicesPage.propTypes = {
    cmsContent: PropTypes.object.isRequired
};

export default ServicesPage;
