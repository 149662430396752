import React from "react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";

class S03E06 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname);
    }
    
    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);

        if (Helmet.canUseDOM) {
            Helmet.renderStatic();
        }
    }

    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }

    componentDidUpdate() {
        if (Helmet.canUseDOM) {
            Helmet.renderStatic();
        }
    }
    
    render() {
        return (
            <>
                <Helmet>
                    <title>{`${this.props.title} | ${this.props.subTitle} | ReelCast by ReelMetrics`}</title>

                    <meta
                        name="Description"
                        content={this.props.description}
                    />
                    <link
                        rel="canonical"
                        href="https://www.reelmetrics.com/s03e06-ags-with-jackson-floyd-and-steve-walther"
                    />

                    {/* Open Graph / Facebook / LinkedIn */}
                    <meta property="og:type" content="website" />
                    <meta
                        property="og:url"
                        content="https://www.reelmetrics.com/s03e06-ags-with-jackson-floyd-and-steve-walther"
                    />
                    <meta property="og:title" content="ReelCast - S03E06" />
                    <meta
                        property="og:description"
                        content={this.props.description}
                    />
                    <meta
                        property="og:image"
                        content="https://cdn.buttercms.com/PC2Hh3aFRRin56dd1HJf"
                    />
                    <meta property="og:locale" content="en_US" />
                
                </Helmet>
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}

    S03E06.defaultProps = {
        id: "15300571-s03e06-ags-with-jackson-floyd-and-steve-walther",
        link: "https://www.reelmetrics.com/15300571-s03e06-ags-with-jackson-floyd-and-steve-walther",
        title: "AGS with Jackson Floyd and Steve Walther",
        subTitle: "Season 3, Episode 6",
        description: "In this episode, Nick and Don catch-up with AGS's VP of Slots, Jackson Floyd, and VP of Game Development, Steve Walther. Learn about AGS's history, cultural leadership, developmental philosophy (Steve's tango analogy is awesome), GameON customer summit, upcoming product releases, and more. Also in this episode, keeping Actual and Theo variances in-check.",
        topics: [
            "AGS GameON",
            "Tory Party betting scandal",
            "Keeping Actual and Theo variances in-check",
            "AGS history",
            "AGS's cultural investments and leadership",
            "Game development, emotional resonance, and the Tango",
            "Recent AGS successes",
            "Upcoming AGS product releases"
        ],
        keywords: [
            "AGS GameON",
            "Jackson Floyd",
            "Steve Walther",
            "Slots",
            "Game Development",
            "AGS history",
            "Cultural leadership",
            "Developmental philosophy",
            "Tango analogy",
            "Product releases",
            "Actual vs Theo variances"
        ],
    buzzSproutLink:
        "https://www.buzzsprout.com/2057836/15300571-s03e06-ags-with-jackson-floyd-and-steve-walther",
    spotifyLink:
        "https://open.spotify.com/show/314iBnXRGGAuttAsIcZeC5",
    applePodcastsLink:
        "https://podcasts.apple.com/us/podcast/reelcast-by-reelmetrics/id1652485625",
    iHeartRadioLink:
        "https://iheart.com/podcast/104029260/",
    cdcLink:
        "https://cdcgaming.com/reelmetrics/s03e06-ags-with-jackson-floyd-and-steve-walther",
    
    transcript: `Nick Hogan:
Good morning Don. What's shaking in the land of Lincoln today?
Don Retzlaff:
Morning, Nick. Hot and humid. We're in the midst of summer. It didn't take long to get to the upper 90s here.
Nick Hogan:
Yeah, well we were just over there last week dodging lots of storms. We did a little whistle-stop tour of the American Midwest, and then we had AGS's Game on Customer Summit at the always delightful Encore Boston Harbor there in Boston. I think as you know Don, I'm a big fan of that event, and for the uninitiated, this is how it works. Basically each year, AGS selects a casino resort in a given city. This year was Boston, Encore Boston Harbor. Last year, GSR in Reno. The year before that Seminole Hard Rock down in Hollywood, Florida. So it's a three-day event where you have two full days of presentations and then all sorts of cool mixers and little field trips I guess you'd call them. So parties with bands, golf outings. This year we did a Red Sox game at Fenway, so really cool stuff, but two things make it just super interesting.
One, the talks cover everything under the sun, so it's not just gaming-specific stuff. We have things from politics to neuroscience and pretty much everything in between. And then secondly, the ground rule for the AGS staff is no selling. So it's a super low pressure event that's really conducive to learning and what I'd call deep networking. You can actually get to know people, so it's an invite only deal. So you'll need to gnaw on your AGS rep for a ticket, but trust me when I say that, it's absolutely worth it. You'll learn something, you'll have a blast and pick up some new connections that are truly meaningful. So huge thanks to David Lopez and his crew at AGS for making it happen and of course to Jenny Holaday and her team at Encore Boston Harbor for hosting us. It was an incredible event and an absolutely gorgeous property. So great stuff there.
And also Don, very quickly, there's a story that's bubbled up on this side of the pond that I thought would interest you. So on the 22nd of May, the UK's Prime Minister Rishi Sunak. He called for new parliamentary elections to occur in early July, and although the announcement itself was pretty surprising, the real shock came afterward, the revelation that four people close to him, so this is two Tory party members, a campaign director, and a member of his protection detail. They're being investigated now for placing bets on the election date.
So there wasn't much detail on the stakes involved, but I did catch a blurb that Ladbrokes had flagged a 100 pound bet with five to one odds, so not a very good payout when your career is going to go up in smoke. I thought you'd appreciate that one. So we have some insider betting now.
Don Retzlaff:
I haven't heard that story. It's always mystified me of the countries that can call elections when needed every four years. It's just an interesting way to do it growing up in the states and we have our elections every four years or every two years. Wow.
Nick Hogan:
Yeah, the parliamentary system's a bit different. Same deal here in Holland. So anyway, thought you'd appreciate that little tidbit. Okay, so with that I think we're good to move on to our listener questions. So before we do, let me say that we'd love to tackle any questions anybody listening may have. If you have a question about what we're presenting or something you'd like us to present, please drop us an email at reelcast@reelmetrics.com. Again, that's R-E-E-L-C-A-S-T @reelmetrics.com. Our policy is to keep all questions anonymous, so please speak directly and don't worry about us revealing your identity. That's not something we do.
So this month's question comes from a marketing director in the American West who asks, "Hello guys, I have a question regarding variances between actual and theo in slot play. Specifically, I'm interested in how game types and free play impact these variances. When do we become concerned and besides free play, where else can we look for remedy when theo far outpaces actual? Appreciate any insight. Thank you." Now I really like this question, Don, because it comes from a marketing team that's new to slots and just starting to cut its teeth in operational analysis. So I think we can clear up maybe a misconception or two here and also discuss a few tips. So Don, you want to take that one?
Don Retzlaff:
Sure. It's a great question and variances are part of slots, and it really does vary by game type. First off, free play really doesn't matter. It's based on all your coin in, so you don't have to worry about free play, but by game type it does change. Things like video poker will have the biggest variance because it's a skill-based game. Usually you're holding about 140% of theoretical, but I've seen casinos that are 200% of theoretical, so that's something that you're going to have to figure into your holds. Reels are more volatile due to jackpots, and they should be looked at on a longer term basis. Instead of 30 days, you're looking at 90, 180 days for reels, but your video pennies should be the closest to par, especially if you're looking at a longer term basis. In a given 30-day period, you could have 40% of your games being outside of 3%. That's just the way they work on the handle pulls.
But if you're looking at 180 days or a year, you should be down to the 1 to 2% range as far as how many machines are out of variance. Usually we see video pennies hold about 101% of theoretical, because people won't play max bet to trigger progressives or they won't play something like all four cards on a one to four game, things like that. So they always hold a little bit more. If you're holding less, you've got a couple issues. Usually what happens is on ETGs, there can be an issue about the bonus bets, but on the video games it's usually progressives. And I've had it happen at my casino where we had the progressive incrementation rate set up wrong when we're setting up the whole percentage. So a game with a 12% hold and you're putting 3% to the meter is only going to hold 9%.
So you've got to make sure you're including those incrementation rates in your par settings. And it's a little tricky because some games have it already baked in, and some don't. So it's definitely something you need to watch, give it to your slot techs to look. I had one, I had a classic Hee Haw game that was buried up on my third deck that was doing like 50 bucks a day, and all of a sudden the game starts doing like $200 a day and I couldn't figure it out. And then I saw it was severely under holding, it was holding about half of what it should have. So did an analysis and sure enough, when we moved the games, we set up the incrementation rates on the meters wrong and the meters were just purring. We were just throwing so much money into the meters, it was causing the games to under hold. But the win per unit skyrocketed.
Nick Hogan:
Of course. It's amazing how quickly people catch on.
Don Retzlaff:
Yeah, the meters that were supposed to be going off once a day were going off six times a day, and people caught onto it. So unfortunately I had to fix it. Now of course it killed the win per unit again on them, so I think I'd rather have them holding 4%, winning 200 than holding 10% and winning 50. But look at your progressives. That's probably the biggest thing. The progressives on video and your ETGs are the ones that you really should be paying attention to the most, and look at in a longer term basis, not just 30 days. You've got to look at it six months, 12 months, but it's something that should be looked at every month.
Nick Hogan:
Now, Don, we had had an interesting conversation. I think it was kind of off-mic we were speaking with Mike Dreitzer at Gaming Arts, and we were discussing one of those videos, I think it was, is it the Deal or No Deal that they have out? We were talking about some over-hold that was in the math model. Is that common in video slots?
Don Retzlaff:
It is. Most of the games will hold really close. I looked at, what did I look at? The top 50 games, and the range is 90% to 123% on a 30-day basis. So you will get some games that will over-hold, especially if there's a little bit of skill and some decision-making, take this offer or try again type thing. Some of the manufacturers have put a little blurb at the bottom sometimes saying the optimal choice is this, do it again or take the offer. But there are some players out there who either are very conservative or are going to continue to double up until they hit the jackpot. So there are games out there that you have to make some decisions and if the players make the wrong decisions, this game's going to over-hold.
Nick Hogan:
Yep. Okay. Well thank you again to the listener who submitted that. We really appreciate it. Also, you have our direct contact info now because we reached out, so please don't hesitate to contact us with anything else. Don and I are both huge proponents of tight collaboration between operations and marketing, so anything we can do to help, just ask. Okay. So with that Don, I think we're ready to introduce today's guests. These gentlemen have a combined 30 years of industry experience on the supply side, including long tenures with, and this is quite a list here, Don. Aristocrat, Light and Wonder, Aruze, Konami, and AGS. So quite a few of the heavy hitters there. Functionally, they've had roles in operational analysis, sales, marketing, product management, game development, all relative to diversified portfolios, spanning slots, tables, systems and peripherals. So it's safe to say that these two guys have supply pretty much down pat. For the past five years they've been leading the slot charge at AGS, which earlier this year hit a very impressive milestone.
They became the fourth-largest supplier in North America in terms of annual unit sales, just behind Aristocrat, Light and Wonder, and IGT. So I'm referring of course to Vice President of Slots at AGS, Mr. Jackson Floyd, and Vice President of Game Development at AGS, Mr. Steve Walther. Howdy gents. How are you today?
Steve Walther:
Hi guys.
Jackson Floyd:
Nick, doing great.
Nick Hogan:
Alrighty. So guys, I thought we could just kind of kick off with a little bit of company history. So can you maybe just run us back to 2013 or so? I guess that's when Apollo first came into the picture and kind of walk us through the more critical milestones to date and then specifically, maybe tell us a little bit about the companies that AGS purchased and integrated the portfolio to put together and markets on which you've focused.
Jackson Floyd:
Sure, absolutely Nick. And so the AGS history and story personally is one of my favorite things or one the most interesting things about working here as well, particularly getting that from people who lived it and were there the entire time. I came aboard in 2019. Half of the story is probably mine, so I hope I'm a great steward with some of this history. If you guys run into David Lopez, Drew Pollick, Sigmund Lee, others, I would urge you, ask for their version of the story. Every single time I hear it, I get more tidbits that are just more interesting than the previous time. It's pretty fascinating.
So to go back to 2013, you're absolutely right Nick, Apollo acquired AGS, almost immediately installed David Lopez as our CEO. At the time AGS had some class two and class three product, very slot-focused. One of David's first initiatives was to make aGS a multifaceted, multidimensional supplier. Very quickly, John Hemberger came over and started to build out our table games department to compete with Shuffle Master, the 800 pound gorilla that had a huge foothold in that market. Since then, that business vertical has been very, very fruitful and successful and still rapidly growing. We just launched our second shuffler, a multi deck shuffler called Pax that's been very successful in my opinion, one of the best progressive concepts in the industry, it's been extreme. That can go across all table games regardless of type with a very, very cool ingenious method. Our proprietary table game portfolio as well is robust and needle moving for our customers. So that venture has been very, very successful.
Outside of tables, we acquired a company called Rock and Play pretty quickly as well, which was our first foray into the digital space. And then in 2015, probably the most consequential acquisition that we made was Cadillac Jack, and really starting what I consider to be at least the beginnings of the modern era of AGS. So it's my opinion and the opinion of some others that if you look back throughout history in the gaming world that the acquisition of Cadillac Jack might be one of the most fruitful in the history of gaming because AGS had a ton of distribution. Cadillac Jack had a very compelling product. They were a Class II focused company with some really awesome product and strong footholds with our tribal customers in Wisconsin and Alabama. They had a huge business in Mexico. So when distribution meets product, a really fruitful marriage happened and gave us a great foundation to really build the future, which at the time was the Orion family of cabinets, namely the Orion Portrait cabinet, which represented really catching lightning in a bottle for a period of time back in the 2016 era.
So with the Orion Portrait, we created our best flagship brand Rakin' Bacon. It's our pig, his name is Cornsquealius. We're kind of calling him our Mickey Mouse, so to speak. Recently he's everything to everybody, very endearing character. I believe we have four theme families on the roadmap this year for brand extensions for Cornsquealius, so he's getting a lot of work recently. We went public in 2018, and then of course this itty bitty pandemic happened in 2020. It kind of represented, not just a great pause for us, but obviously a great pause for the entire globe and kind of a good reset.
I think from there, something I'm very proud in is we didn't stop investing in R and D, we didn't stop investing in our company. We entered the HHR market in 2020, which was a huge step for us. Have a very fruitful relationship and partnership with XACT and now Churchill Downs, realizing great performance and great ship shares in the historical horse racing world. We also did a lot of scaling with our R and D studios and development teams, scaled up not just to basically fit and service our current ship shares at the time, but really try to set aspirations of who we wanted to become and then build a roadmap that could really help us achieve our aspirations in the future going forward. So adding a lot of horsepower to the engine of AGS.
Nick Hogan:
Gotcha. Now on that, one question I had for you about Cadillac, because you have a pretty large or pretty sizable install base in Oklahoma I believe, and did that come out of Cadillac Jack as well?
Jackson Floyd:
Somewhat. I think our relationships in Oklahoma stem back to the AGS days certainly. We also distributed some third party content, namely from Bluberi, so your Royal Reels, Diamond Lotto style products. We still have a ton of those out earning at scale in Oklahoma. So it was a little bit of a mix of both.
Nick Hogan:
Okay, okay. Gotcha, gotcha. Okay. And so I guess maybe this were for you Steve. So for anybody who's not really been exposed to AGS internally, the people and whatnot, it's an extremely culture-focused organization. You guys really put a lot of time and effort into that. So can you give us a sense of that AGS culture and how you guys feel it advantages you out in the marketplace?
Steve Walther:
Sure Nick, and thanks again for having us on. It's a great opportunity to speak to you and to your audience and appreciate seeing you at Game On and yes, you're right. It was a fantastic event and Encore did a phenomenal job. Jenny Holaday's property is awesome. So why I speak first to Jenny is she's got an amazing passion. It shows in what she does and it shows in what she does for her team at Encore. And the same thing can be said for AGS. I mean we've got an amazing, absolutely amazing leadership team with a passion for people and a passion for the industry. I specifically mentioned people first because it's about people, and it's about real people, and it's about not putting up these stereotypes of what it means to be in an office environment. It's about having fun, it's about engaging with each other, and everyone that we work with wants everyone else to succeed.
And that I think is a secret sauce, because you can look at organizations that have brilliant people, but often they're in tug of war with each other. There's a lot of wasted energy there. Our wasted energy goes into fun, creating a great environment. I mean we have core values and our core values names have such fun names of Make Your Mama proud or Falling on a Budget. So look at all that sort of stuff that goes on there. And you look at the types of events that we throw and the way we interact with our customers who are very important to us, our business partners who are very important to us, it's a very supportive organization. The culture is about people, it's about support. It's about loving doing things with each other on a way to make the industry that we work in that we are passionate about that much better. And you see that at all levels of the organization.
Nick Hogan:
A hundred percent. It's one of those things you can just tell it's authentic that you guys are really walking the walk every day and it's a cool thing. And indeed that's what I see is not only does it seem like the employees are always really super supportive of one another, but I think you see it out in the marketplace as well. People really want you guys to succeed. You can feel it and you hear it when you talk to them. Everybody's really super, super jazzed when you guys have big announcements or when you have big coos. It's great. And then kind of following on that, so on quite a few occasions I've had great, great conversations, Steve, both with you and also Sigmund Lee who's the CTO at AGS, regarding this topic of emotional resonance in game design, and how it's never just one design attribute that really creates that resonance and whatnot, and there's this kind of delicate balance and really elusive combination of all these different design attributes. So can you maybe unpack that philosophy a bit and explain how you weigh these things when you're developing games there?
Steve Walther:
Sure, Nick. I think I'll look a little bit of an analogy of the arts and of dance. When you look at a tango, you have a very stunning lady in a beautiful dress. You think all the attention is on her from the look and the visual, but the man in the dance has a very important part. He plays the role of the strength of the dance. So during the dance, she's beautiful, he's pulling her, he's carrying her. The tango isn't a tango if you've just got one or the other, you've got a different kind of dance if you've got a very strong man doing moves out there and if you've got another thing with a fine beautiful woman doing her dancing alone, but together the strength and the beauty makes for something that's completely, absolutely special and why people love to see tango music. So let's bring this back to games.
In the games you've got the strength that is the math. It gives you the ride, it gives you the thrill, it gives the excitement, but then you also have the beauty of the presentation and the arts and the elements that give you that joy and that emotional reaction. So when we bring it all back to what is emotional about the game, just like with music, people want to lose themselves in their entertainment. They lose themselves in watching a dance, they lose themselves in watching a movie that has thrill and beautiful art. They lose themselves playing a video slot machine because they want to have that emotional ride and the connection. So as Jackson mentioned in his preamble with our pig Cornsquealius, there is an affinity that people have towards certain aspects of games. They could be the character, they could be the theme, they could be the features, they could be the symbols.
All of these things have to work together in concert, so that the people can have an emotional connection to their experience. Because Nick, fundamentally in our business, and Don knows this with his operations, we have the amazing opportunity to build product that allows people to separate themselves from their hard-earned money, and enjoy it so that they'll want to come back and do it again. And it's something that we pride ourselves on being very honest about. There's no trickery about this. You're going to get an emotional experience and emotional ride, and we hope in what we create it's enough of a value during the time it takes for you to have that emotional experience and ride that you're going to want to come back and do it again. Sometimes you leave with more. Most of the times you're going to leave with less. But the value proposition of bringing all these disparate elements together, the art, the sound, the math, the beauty of the ride, it's all there as that value proposition for our players.
Nick Hogan:
Now when you're developing and testing this, I mean are you focus grouping a lot and stuff on these points or how are you really evaluating these, whatever you choose from, let's say artwork to music, to whatever, how do you really evaluate that stuff?
Steve Walther:
I think it comes down to Nick, a lot of experience and a lot of market evaluation and listening. A big thing about building product, regardless of what industry you're in, is listening to what's going on in the world and in the market and observing. So observation and empathy is a key component. And so when we're workshopping all of these various ideas, we work together and we test what would this work better? How would this work better? Should we do A or should we do B? And then we start to put all the pieces together and make sure that when all that pieces come together, it's that symphony.
Again it's kind of like a musician writes a symphony on its own. They do the violins in one, they do the percussion in another, they do the winds in a third and then they adjust each and then they make sure when it all pulls together it looks great or it sounds great. We do the same things with regards to that. Observance, listening, our own personal experiences, and looking at data to figure out whether or not we've made good decisions in one area or good decisions in another.
Nick Hogan:
Yeah, and I really like that you use this analogy because I often use this myself and just speaking with our own folks et cetera, when we're trying to describe why it is that we have such large failure rates in the industry when we're putting out new games and things, and I always come back to this that it is like music and that it's so difficult to, how do you really measure and know when you just have nailed something emotionally, when you've made that emotional connection. So I really appreciate the analogy and I like yours actually a little bit better because there's a visual component of dance to it. Now I have to change my metaphors, but yeah, I got it. Thanks Steve.
And then so you all, I know you've been really just hearkening back to Jackson's answer earlier on the markets you've addressed. So I know you've really focused on the North American market, heavily Latin American market as well. What are you looking at in terms of international expansion? I know that I speak with a lot of European operators and I've heard a few ask about AGS, you guys might be coming across the pond at some point. So any international expansion plans in your immediate future?
Jackson Floyd:
Absolutely, Nick. That's the next logical step I think for our company. We've made a lot of headway in Latin American markets and we're continuing to evolve our operations south of the border as well. But of course we're looking at Europe, South Africa, Asia, Australia. I think the interesting part is balancing those opportunities versus some adjacent markets that we have domestically, including some other product segments that we have domestically. We just got into the Stepper market with our Rebel cabinet. Those are in performance testing now and we'll have a broader launch during G2E.
We're coming to market with a V75 or Spectra Slant 75 jumbo as well, which is a brand new segment for us. There are other markets domestically, there are potential growth drivers for us, like VLT. International absolutely is in the mix. All of these are fantastic potential growth drivers for us going forward. I think an important emphasis for us is not just doing it, not just entering these markets, but doing it well and with quality. And what that means is really taking the time to understand the markets, take the time to do the localization needed to be able to enter these markets, not just to meet the technical requirements, but also to suit player preference as well and make sure that we can not just be there, but we can be dominant with whatever we decide to do.
Nick Hogan:
Gotcha. And you just mentioned the, I'm sorry.
Jackson Floyd:
One of the most fun parts about my job is the evaluation to balance on all of these things together and we do so on a weekly, monthly, quarterly basis just to make sure our priorities are aligned.
Nick Hogan:
Gotcha. Okay. Okay. Well you had mentioned two things there. So you guys had talked about Rakin' Bacon, which actually I just have to tell you guys, it's actually my favorite slot game. I'm not a super huge slot player, but that one I really dig and I think it's mainly just I just get so fixated on that pig, waiting for him to detonate. So I think that's really the fun thing for me. So there were two things we saw at Game On. One was Spectra 75, the Jumbo that you just mentioned, and then the other one was a Rakin' Bacon link. So could you guys tell us a little bit more about those two things?
Jackson Floyd:
Absolutely, Nick. So like I said, we're spanning into some new hardware silos for us and so at G2E this year we'll do a full launch of Rebel. We also have another extension of our Spectra line of cabinets, the Spectra 49 Slant Plus. The plus is for an integrated topper that we have with the cabinet. Looks absolutely stunning with the core version. There's merchandise that goes along with it with these light bars. It's really incredible. I call it Core Plus Plus because I think it's almost a new category in itself.
And then we have the premium version as well that'll host the Rakin' Bacon link as a linked style hold and spend product in the whole merch package that Harvard did. I think it's the best one that we've done to date hands down, so couldn't be more incredibly excited about that. Alongside of all those offerings, if that wasn't enough, like I said, we're coming to market with the jumbo cabinet that'll host Rakin' Bacon Cash combo, which is basically a marriage between Pinata Pays, which has been a top performer in our Curve Premium segment and Cornsquealius, that pig that again is kind of our Mickey Mouse and we're going to keep on leaning on him and extending his brand going forward. So very, very excited for what's to come, more brand extensions for Cornsquealius and more iterations on our really successful hardware roadmap with our Spectra family.
Nick Hogan:
Okay, cool. Now Don, I'm sitting here hogging the mic here, so I want to let you get some questions in here too.
Don Retzlaff:
Jackson, you mentioned the Rebel cabinet and the Orion cabinet earlier. The Orion cabinet was one of my favorites. It had great performance, turned it on, left it on, and didn't have to worry about maintenance on it, plus it wasn't eight foot tall, which was always a plus. When you're worried about sight lines. So at what point do you consider introducing new cabinets? Is it when you can't do what you want to do with the math that you're developing or are there other factors?
Jackson Floyd:
I think the answer is yes to both Don and I kind of think of our hardware strategy as twofold. The first part is really the family of cabinets. So we have our Orion family and our Spectra family. Our Spectra family is our next generation family and I think we made the conscious choice to turn the page on Orion really to take a leap forward in terms of technological advancement, to be able to meet player preference, keep things fresh, to meet operator preference features and functionality that people have been asking us for, but maybe mostly to kind of take the shackles off Steve's team and enable our game developers to give them a platform that they can really create games at the highest possible level. We've invested so much in talent, have the best in the industry talent on our team. They deserve a platform that they're able to show their talents and build games to be able to perform and be difference makers on their floors.
So when that time comes, it's time for a new family of cabinets within the family of cabinets, it's our prerogative to provide diversity to operators. I think a lot of times people kind of oversimplify casino floors, there's so many different diverse nooks and crannies of casino floors or different needs depending on where games are positioned that you can really solve for with differentiated hardware. So within our Spectra portfolio for instance, we have a box that has a 43-inch screen, we have 49 curved screens, we have 49 curved with integrated toppers, we have steppers, we have jumbos. Our goal is to be everywhere across all floors, not just filling one niche. And so that combined with our strategy regarding to families of cabinets, we think gives us a great hardware strategy and one that could be considered best in industry.
Don Retzlaff:
Well, you've definitely been on a role, especially the last say 18 months or so. You're only one of three manufacturers who have a revenue share greater than your market share, which is obviously extremely impressive. And your new game performance actually ranked second in the industry based upon our studies. So congratulations there. Do you have a market share goal? I can see a day when you're at 10% of the floor, especially if you keep up the high performance of your current releases. Is this too optimistic or is this something achievable for you?
Jackson Floyd:
Thanks, Don. It's funny you asked that. I think a year ago we kind of set our near to midterm goal as being the number four undisputed provider in the industry. That's number four, obviously behind [inaudible 00:33:21]. I think we've achieved that. I believe we'll continue to achieve that quarter over quarter. I don't really think any goal is too lofty for us. I think our long-term goal is to solidify us as a top tier provider and if we continue to invest in R and D, if Steve and his team continues to deliver the way that they have, we continue to execute from a hardware perspective. I think the sky's the limit for us and I'm just really excited for what's to come and to see what the annualized returns are of all this investment and all these new cabinets that we're bringing to market should be pretty great.
Don Retzlaff:
Earlier this year I met with Jim Esposito on a project we were working on and he walked me through your math models, especially the releases from the last couple of years to what to expect in the next year or so, and I was really impressed. It was the first time I've had a manufacturer going in depth into their math models and what they've been producing, what's worked, what's upcoming. So how do you balance the new math models versus what the guests are playing now that are super popular and pervasive in the industry.
Steve Walther:
Well Don, thanks for that. Really good question and Jim's an awesome guy to be able to walk you and walk you through that. The key for us is to have a strategy in place, an intention for everything that we do specifically around math and theme and mechanics. So you want to be able to leverage known math models and good math models and take advantage of those math models that have worked for you. But if you continue to run the same horse over and over and over again, that math model may find itself to become stale. So you've got to do some things different in other areas as well. Strategically, we balance how much of the existing math models we use, how much we slightly adjust those math models as well as coming up with some new math models of our own. And then you can find new hits and new nuggets out of those math models.
So we plan multiple years in advance. We look at the market trends, again, it's all about listening like we talked about in the early part of the segment and identifying where you want to go with that you're building. And then we partner that with the amazing roadmap that Jackson was speaking about from our incredible hardware team. So what math models do you want to use to take advantage of launching a new cabinet? What math models do you want to use in maintaining your existing cabinet? And we put this all together on a gigantic roadmap and we have a great organization that I think is something special about AGS, which goes back to what we were saying about the culture. Our relationship between the commercial side and the development side of the business is incredible. Often you might find industries where commercial and development are at odds with each other.
Here we've got an amazing partnership and that partnership helps get more ears into the market. People like Jim and his team give that information back to people like Jackson and his team and we all sit and discuss how much of each various different math model or type of game we need and then we put it on a plan, we build it, we roll it out, we test it and we validate our strategy. And if the strategy is good, we do more of it. If the strategy is a little less validated, we kind of turn the crank the other way.
But it really is a team effort, but there's a strategic focus that begins in the development organization based upon what we hear are the needs of the market and we tune it that way. We have a great team of people in the development organization that can really tune their math the way it needs to be for the market. And that's why I believe you see the performance going where it is today is because we've done a really good job over the last few years of listening and strategically planning and we're happy to hear what you said.
Nick Hogan:
I had one question, Steve, and it's also back to Jackson's earlier part. This is about the hardware side of things. So one of the things in this cabinet war type situation with all the suppliers just introducing new cabinets over and over, do you always feel like you're just under tremendous pressure to get another upgraded platform out there with the latest bells and whistles? I mean it just seems like the pace of introduction is so high that I would think that it put a lot of pressure on the development teams.
Steve Walther:
Historically, companies used to go five or six years without running a new core cabinet and it was a good thing and now the life cycle is a little bit shorter on those and I think you can, I'll throw that back to Don from an operator perspective, the operators want more of the latest and greatest now versus the tried and true, and it's almost as if that's the way they're convincing their finance departments because it's new, we have to have it and I think it's put an extra pressure on the hardware side of the business. However, what it also has done is create an incremental drive for the operator because now something's new players might come in and give it an extra trance so it drives an incremental revenue. So in essence, this treadmill has sped up a little bit on the hardware side, but it's also helped to contribute to greater revenues across the industry.
So someday the treadmill is going to be a little bit too fast, but we also have to find that balance to where we keep people new in this short attention span society that we have today as our entertainment dollars are being split in all different areas that are evolving faster. So I think everybody's treadmill not just, I mean I remember when we could go to the movies and see movies in the big screen for six weeks on their run. Now movies stay in the movies probably about three weeks and then six weeks later they're on Netflix or Apple TV+. Exactly. So I think we're all dealing with the hyper consumerism and the speed to market that people are looking at and we're just along for the great rye producing great content that hopefully can run on multiple different types of boxes and still delight our players.
Don Retzlaff:
Very good. And you've had some great success. I look at the Specter cabinets over the last couple of years and look at things like Triple Coin Treasures and your series of games there on the Spectra 43 and Rakin' Bacon of course on the Curve. You've mentioned a couple things with Rakin' Bacon Link and your new cabinets. What are you excited about on the upcoming? What are we going to see at G2E if you can spill a couple, give us a couple of secrets or are there any titles or series that you're looking at that you're really excited about Jackson that are going to be released here in the next six months?
Jackson Floyd:
I think there's plenty. I think some of the launch titles for the Spectra 49 Slant Plus on the core side really excited me. We have dada gin bow, which is a 15 individual reel, two bag game, some really unique art I'm extremely excited about. We have Ascendant Jackpots, which has this really cool hold and spin feature where you can unlock these ascending reels as you land, upgrade symbols in your reel array. And then the end goal of that is to unlock a special jackpot associated with unlocking all of the reels. I think that's very cool. The game in game aspect of it really gets me going.
And then outside of that, I think some of the three real mechanical themes that we have on deck as well are really, really compelling. I didn't even know how much I really enjoyed three real mechanicals until we started our venture into that space, but playing some of these high denomination titles has almost converted me to a three row mechanical player, almost even more so than a video player, which is funny.
Don Retzlaff:
Interesting, and obviously the vast majority of the spend now is on video, but there is still a market for really good reels out there, especially games that can produce a high average bet. You look at some of the other vendors out there that have got great success, and it's still 15 to 20% of the revenue at a lot of casinos, so it's still a significant part, but there's still a need for the games. I'm not sure 25 years from now we'll be singing that, but for the next generation or so I think there's still going to be a need for reels. So I'm glad to hear you say that.
So going back to one of the comments that Steve made about new product releases and how fast it is, one of the big things for the operators is the library for your current releases. So can you walk us through the Specter Cabinets Library, how many releases you're going to be looking at in the next year, because obviously that's where your growth is going to be as you keep producing these key themes that you keep producing, you're going to keep selling more and more cabinets. So what type of release calendar do you have for the next 12 months?
Jackson Floyd:
Yeah, Don. So we decided that a threshold of 40 themes within Spectra was just about the right threshold for us. That gives us enough shots on that to score a few. I think outside of just the numbers game, I think we're getting smarter with the shots that we're taking and that's really what I work with Steve with on a daily basis, making sure that we're setting up our brand extensions and derivatives in a very intelligent way that's accretive to the brand or whatever derivative it is that we're creating, that the timing is right. So while we're producing 40 per year, we're trying to get much better with what we're producing and consistent and we think that's going to make all the difference as we close out 2024 and head in to 2025.
Nick Hogan:
In terms of the commercial models, are you tending to fall more in favor of premium models these days or more core or how's that shaken out?
Jackson Floyd:
I think it's both Nick. I think it's kind a left brain, right brain. There are two different product segments for our customers. We have people on the team who are really talented in both of those spaces. So I think in order to be successful, we really have to have extremely compelling content on both sides of the ledger, and that's something that we've been able to do successfully as we're continuing to increase our premium footprint and also increase our ship share on the core side as well.
Nick Hogan:
Yeah, gotcha.
Don Retzlaff:
Yeah, obviously you've had some really neat success and one of the more eyeopening things that I learned back as an operator and I highly suggest that to any manufacturer is pulling back the curtain a little bit on game development and math development, and get the operators to really have an appreciation as what goes into building a good game, whether it's the graphics, it's the sound, it's the math, and getting to meet some of those people on those teams that develop the games. It's quite a process, and some really interesting people that develop these games and you can see they pour their entire lives almost into creating these games and when they do have these hits, it's neat to see. I'm sure it's got to be a huge party when you have stuff like the Triple Coin Treasures that do so well and the people who develop it, I mean it's got to be a great sense of pride.
Steve Walther:
It's awesome, Don, and a lot of that validation comes from people who can generate those industry reports, like Real Metrics, being able to show us how our products are performing across the enterprise. We get internal reports that we get from customers that tell us how we're doing on their floor, but when we see something validated on an industry level, it's great. We have a team of people that just absolutely love to play games, mostly slots, a lot of board, a lot of video games. Another one of our core values is let your inner geek shine, and we have the value of the video game controller on it, and a lot of people love the video game. We just love the game, and it's the important thing to be able to be passionate about loving the game, and we're passionate about our slots and it's awesome when something that you're so passionate about comes back as being successful.
And you look at something like a Triple Coin Treasures or a Rakin' Bacon, Odyssey and Sahara or a Rakin' Bacon Deluxe that all come back and are leading in their categories, Pinata Pays most recently that Jackson mentioned. All that validation is very motivational for us and it just says, "Okay, we've done it once, let's go do it again, and let's do it again, and let's keep doing it." And as long as we've got this amazing hardware that our hardware team builds and the commercial team is psyched and excited to keep placing all of these things, it just works. And it's a great cycle to be in.
And talking about the analogy of the dancing and the visual and the art and the strength and the beauty, these people, the artists, the mathematicians, very different types of individuals sometimes.
Don Retzlaff:
Oh yeah.
Steve Walther:
But to get them to work together to produce such a beautiful output is incredible because it's a wonderful partnership. Everybody has their place in the symphony. And when you get them to work together, and that's what our studio leaders do and that's what our visionaries do in the group. They produce products like Pinata Pays, Triple Coin Treasurers, Rakin' Bacon.
Nick Hogan:
Here's a logistical question, I've just never asked this one because I know that in development organizations and there's a lot of going out and playing games, how does that work from a policy perspective? Are you given an allowance to go out and play or is it entirely at your own risk and your own cash?
Steve Walther:
Well, let me ask you a question, Nick. When you play Monopoly, and you're using the Monopoly money, would you really be buying those properties if it was your own money or would you save all of your money the other way? So I'm a firm believer that money is money, and you should be spending your money because your player is your player that it is. But at the same time, everybody has to have an opportunity to play a game. So there's a fine balance there, but ultimately it's you don't have the same experience unless you're sitting in somebody's shoes.
Nick Hogan:
This sounds like somebody who's had to explain the policy.
Steve Walther:
Mostly to people not in the industry, just so you know. Especially when you have to come home and say, it was research, so let's just leave it at that.
Nick Hogan:
Don does a lot of research.
Don Retzlaff:
We do, and I'll still play the dumb tourist that I don't know how to play the game or where's the bill validator, and I'll sit next to someone who's playing the same theme and ask them about the theme, how's the bonus round work and all that type of stuff. I did that when I was buying slots. I do that now when I'm recommending slots. I've probably played every one of your titles. I still go back and play games like River Dragons when I see them on the floor. But that's the research you really need to understand how these games work and what the differences are. And then I'll look at things differently than a player will on speed of the game and enjoyment and all that type of good stuff. But I really want to play the dumb tourist sometimes and see what the other people are thinking just like you would do in your focus groups. What are they saying about the sound, the speed, the game, the bonus rounds and all that type of stuff. So it has a place, definitely Steve, it definitely has a place.
Nick Hogan:
And I would think that you guys are probably these days, you're probably watching a lot of the social media influencers, their game play, are you not?
Steve Walther:
Sure. It helps you get to see it there and it's one form of consumption, but again, it's not the other form of consumption. Our players are also watching the social media. So if you're watching it and you're getting your perspective and the player is, so these guys are betting, some of these people are betting $500 a spin or $250 a spin on group pulls and stuff like that. And that's great. That's really exciting and that's compelling content. Look, we're not walking up to the machine and putting 500 bucks in and betting 50 bucks a pull regularly. So you want to approach a game as if you're regularly doing it. What makes you want to go back into your wallet? I mean, that's the key. Anybody could go up and try something. That means we did well on the art. Got you to the game, got you to sit down. It was compelling to sit down, but if it was compelling enough for you to stay and go back into your wallet and put more in, then we've done something right.
Nick Hogan:
Yeah, and I hear you on the incentive because I remember there was this, I don't know if you guys have read this book, but it was called Addiction by Design. It was by Natasha Schull. She's a MIT professor who was really just taking the slot industry to task and she had all these weird conclusions in it and it was about addiction rates being well and above 25% and stuff. I just couldn't figure this out. Well, then I look in the back of the book and it was all based on simulated stuff where people were playing without using money. I just kind of thought it doesn't work at all, which is kind of the end of the book for me. So I get that policy.
Don Retzlaff:
One of the neat things that we get to look at on a daily basis is what percentage of customers who play a GS titles come back and play ag GS titles on their next trip? And it's a very high number. You have some really strong loyalty to some of your brands. Rakin' Bacon, Triple Coin Treasures loyalty score is really, really high. And it's always neat to see, and it's a really nice predictor of future results is if the loyalty scores in some of these games is 20, 30%, you know you're going to see that 90 day cliff and they're going to fall off just like a lot of the titles do.
But we're seeing a lot of your scores very highly ranked where you're seeing 70, 80% of the customers come back and play that same title their next trip, which is a extremely high score. There's not a whole lot of titles that have a greater than 80% score. So congratulations there. There's a lot of people rooting for you, a lot of people happy for your success, and we'll be really curious to see what happens at G2E this year. Always a pleasure walking your booth and seeing what you got coming out.
Nick Hogan:
You guys have about the right size of booth I find for that show. It's about right. And by the way, after post-COVID, everybody scaled down. Did anybody scale back up?
Jackson Floyd:
I think a few scaled back up. I think some of the organization would like a slightly larger booth. It was pretty crowded last year, but at the same time, we definitely like to lean into the culture. Definitely like to make it a compact, punchy experience versus just a warehouse full of slots, for sure.
Nick Hogan:
Yeah, indeed.
Steve Walther:
Our marketing team does an incredible job of setting up the appropriate mood. I mean, as you noted from Game On, they put it on an amazing event, but at G2E, it takes it to another level. These guys are working 12 months out of the year on overlapping events like that, and it's pretty incredible what they can put together and the vibe that they set at G2E and the way the booth is laid out and the spec, it's just a different spot and it really speaks to our culture about what we value about each other and our people. And it communicates that very well just in the booth. That product is amazing too. I have to put a plug in for the product, but the booth environment is a great way of showing off the great product. I love to be able to have more product on there, but at the same time, you don't want to sacrifice the vibe.
Nick Hogan:
Yeah, and those with Julie and Nikki, that whole team, they're just so solid. They really do know what they're doing and create some terrific events, no doubt about it. So what do you guys have in terms of events coming up? What's on the agenda for the next couple of months here in advance of G2E? You're doing an OIGA and this type of stuff?
Jackson Floyd:
Absolutely. So OIGA is firmly in the cross hairs, just planning travel in that show. At present, of course we have pre G2E as well. Those happened at the very end of August, early September. I was just looking at the calendar today. I can't believe it's mid-June. The G2E is almost right around the corner. We're doing a ton of planning for that. So this year is going by very quickly.
Nick Hogan:
Yeah, it's always the same every year. It just all of a sudden sneaks up on you and you think, oh man, I've got a lot to do all of a sudden. So yeah, indeed. Okay, cool. Anything else from your side, Don, for these two?
Don Retzlaff:
No, thank you for coming on. It was a pleasure seeing you both talking to you both Jackson. Good to see you. And congratulations on your success, Jackson and Steve. It's been fun to see and always enjoy rooting for you.
Steve Walther:
Thanks Don. Appreciate the comments.
Nick Hogan:
Echo that as well. It's really great to see you guys succeeding and we wish you nothing but more of it.
Steve Walther:
Thanks gentlemen.
Jackson Floyd:
Perfect. Thank you guys so much. Really appreciate it.
Nick Hogan:
Okay, thank you gents. You take care.
Steve Walther:
Okay.
Don Retzlaff:
Thanks Nick.
Nick Hogan:
Okay guys, it looks...
`,


};

export default S03E06;
