import React from "react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";

class S02E03 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname);
    }

    // calls API and attaches scriptt to DOM
    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);

        if (Helmet.canUseDOM) {
            Helmet.renderStatic();
        }
    }

    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }

    componentDidUpdate() {
        if (Helmet.canUseDOM) {
            Helmet.renderStatic();
        }
    }  

    render() {
        return (
            <>
                <Helmet>
                    <title>
                        {this.props.title} | {this.props.subTitle} | ReelCast by ReelMetrics the world&apos;s best slot analytics data platform
                    </title>
                    <meta
                        name="Description"
                        content={this.props.description}
                    />
                    <meta
                        name="Keywords"
                        content="best practices, slot machines, data-driven strategies, inventory optimization, player satisfaction, casino operators, new releases, slot floors, sportsbook players, casino demographics, slot spend, G2E, manufacturers, data analysis, casino management"
                    />
                    <link
                        rel="canonical"
                        href="https://www.reelmetrics.com/s02e03-updating-best-practices-part-iii"
                    />

                    {/* Open Graph / Facebook / LinkedIn */}
                    <meta property="og:type" content="website" />
                    <meta
                        property="og:url"
                        content="https://www.reelmetrics.com/s02e03-updating-best-practices-part-iii"
                    />
                    <meta
                        property="og:title"
                        content="Updating Best Practices - Part III"
                    />
                    <meta
                        property="og:description"
                        content="In the final part of our discussion on best practices for managing your slot machines, we focus on the importance of staying up-to-date with industry trends and adjusting your strategies accordingly based on analyzing data from player behavior to evaluating new game releases."
                    />
                    <meta
                        property="og:image"
                        content="https://cdn.buttercms.com/OaWFV5QDqgOJxaKViwdg"
                    />
                    <meta property="og:locale" content="en_US" />
                </Helmet>
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}

S02E03.defaultProps = {
    id: "12338678-s02e02-updating-best-practices-part-iii",
    title: "Updating Best Practices",
    subTitle: "Part III",
    buzzSproutLink:
        "https://reelcast.buzzsprout.com/2057836/12517011-s02e03-updating-best-practices-part-iii",
    spotifyLink:
        "https://open.spotify.com/episode/0XBA7qkB1qjFAOOXFreI5p?si=Rj97xQBQT3iQ8rhqZHGMAA",
    applePodcastsLink:
        "https://podcasts.apple.com/us/podcast/s02e03-updating-best-practices-part-iii/id1652485625?i=1000605958262",
    iHeartRadioLink:
        "https://www.iheart.com/podcast/269-reelcast-by-reelmetrics-104029260/episode/s02e03-updating-best-practices-part-111543029/",
    cdcLink:
        "https://cdcgaming.com/reelmetrics/s02e03-updating-best-practices-part-iii/",
    description:
        "In the final part of our discussion on best practices for managing your slot machines, we focus on the importance of staying up-to-date with industry trends and adjusting your strategies accordingly based on analyzing data from player behavior to evaluating new game releases.",
    link: "s02e03-updating-best-practices-part-iii",
    keywords: [
        "best practices",
        "slot machines",
        "data-driven strategies",
        "inventory optimization",
        "player satisfaction",
        "casino operators",
        "new releases",
        "slot floors",
        "sportsbook players",
        "casino demographics",
        "slot spend",
        "G2E",
        "manufacturers",
        "data analysis",
        "casino management"
    ],
    topics: [
        "The importance of data analysis in inventory management for slot machines",
        "How player behavior affects inventory optimization in the casino industry",
        "Best practices for managing and optimizing slot machines",
        "The impact of inventory management on player satisfaction and revenue"
    ],
    transcript: `Nick:
    At ReelMetrics, we collect and analyze a ton of slot data, and we're hit up frequently for insights, tips, and tricks. So we decided to do a webcast in which we present, discuss, and otherwise nerd out on our work. Coming to you from our offices in Lovely Leiden, the Netherlands. Welcome to ReelCast.
    Good morning, Don. How are things today
    Don:
    Morning.
    Nick:
    ... in Mound City?
    Don:
    It's a nice spring day. It's going to be near 70, so winter's finally passed.
Nick:
    Wow, you guys have sun?
Don:
    We do. It's good. It was beautiful yesterday. It's supposed to be beautiful today as well.
Nick:
    Okay. Well, sun here is a distant memory or fantasy, whatever you want to say. We haven't had much of it lately. But okay, so back to Mound City. So that is one that I'd not heard previously, and I looked it up this morning and saw that goes all the way back to St. Louis' earliest days, and that it was used by French fur traders in reference to the many Native American burial mounds located in the area.
Don:
    Yeah. Cahokia Mounds is right across the river from the Arch, and it was the largest Native American settlement in North America for a long time.
Nick:
    Okay, are they actually still, they're still there and you can see them?
Don:
    Oh, yeah. Yeah, they're a big tourist site and they're a big educational site for the kids. It's fascinating of what they did and how they used to live. It was a massive city. I want to say it was somewhere like a hundred thousand Native Americans lived there at one point.
Nick:
    Oh, not kidding. Wow.
Don:
    Yeah, it was massive.
Nick:
    Okay.
Don:
    There's some mystery as to what happened to them him and why they dispersed. But we're not talking mounds, we're talking hills that they built to live upon. They were, it's fascinating.
Nick:
    Okay, so they weren't just, they're not burial mounds then, per se. They were actually, they lived on these hills?
Don:
    Exactly.
Nick:
    Huh.
Don:
    Exactly.
Nick:
    Okay. Well, fascinating. Next time I'm in the area, I'm definitely going to go check that out. I had no idea. So, cool, cool, cool. Okay, so I thought we could dive in early to listener questions, but before we do that, let me say that we'd love to tackle any questions that anyone listening may have. So if you have a question about what we're presenting or something you'd like us to present, please drop us an email at reelcast@reelmetrics.com. Again, that's R E E L CAST at reelmetrics.com.
    Our policy is to keep all questions anonymous, so please speak directly and don't worry about us revealing your identity. We do not do that. Okay, so we received a number of questions following the last episode, and two jumped out at me. So the first was from an exec on the supply side, who asked if we could devote some time to addressing operator anxieties regarding jackpot liabilities. So, we won't have time to hit that today, but we'll get at it in next month's episode, and we'll wrap some math around it so that we're dealing in digits as opposed to just platitudes.
    But for the listener who submitted that, thank you. Please trust that we didn't blow it off. To the contrary, we absolutely love that question and we're going to tackle it next month. So stay tuned on that one. The second question then comes from a slot director in the American Southwest. "Hi, Nick and Don. Loving the podcast. I've been listening to the episodes about best practices and I have a couple of questions. So first, in general, it seems like you're suggesting that if around 15% of our players generate about 85% of our revenues, we should be devoting 85% of our opex and CapEx budgets to inventory for this 15%. Is that correct?"
    And then second, "Does ReelMetrics help clients implement these new best practices?" So thank you very much for both questions. Don, I'd like you to discuss those percentages. But before we do, I'll tackle just very quickly that second question. So the answer to that second question is a categorical yes. We do indeed help our subscribers implement best practices. So these best practices episodes you should really view as thumbnail summaries of what Don's group does with our subscribers.
    So they dig into the local operations in meticulous detail, and then they help you start applying these methods in a way that provides you with long-term competitive advantage. So again, yes, that's a big part of what we do, helping people implement superior inventory management practices. Okay, so Don, the first part of that question was, again, "If 15% of the players are generating 85% of the revenues, should 85% of the slot budgets go toward that 15% of players?"
Don:
    In a word, yes. I've never thought of it that way, per se. So it's worded perfectly. But yeah, why not? If you wanted to make sure that, you've said in the past that you stock the shelves with things that your players want to buy, and if 85% of your sales are from these 15% of your players, well, make sure you have the games they want to play. And it's really interesting, because I'll get into this in a little bit on the best practices is, those customers play a certain style of games and the rest of your customers play a different style of games. So you have to have both obviously.
    You've got your daily customers, but your daily customers are not what you're spending your money on. It's your high-limit customers, your host customers, your customers who will bet three and $4 a spin. Those are the ones that are gravitating towards your lease games and your new games that you're buying. The daily customers that come in with less than $150 play a totally different type of game than what you expect.
Nick:
    Yep. Yeah, and it was an interesting way of phrasing it, an interesting way of looking at it. But the other thing that kicked off in my head was this topic of analytical capital. And I thought about that for a second, and I just looked at, okay, if you just looked at the two of us, right? So you go in, you're a slot player, right? You're a serious slot player, and you're somebody that they're going to want to pay attention to.
    Whereas myself, I am just C3 riff-raff. I do nothing worth anything inside of a slot floor. So when I look at that and I was thinking about it was like, well, certainly, if you look at those percentages in that way, certainly, 85% of your analytical capital should go into that 15%, right?
Don:
    Right.
Nick:
    So you definitely need to know what exactly what these people are all about and what they like. You talk, really, about slot capital, but I thought about analytical capital as well. You really need to focus a ton of attention on these people.
Don:
    And it works the same way with, just think about your host departments, who are your host departments spending 85% of their time on? Right? They will talk to your daily players and your high-cumulative theoretical customers who spend a hundred dollars a day, 300 days a year. But when you talk to a new host player, you're talking to them about, "What type of music do you like? What type of sports do you like?" So when those opportunities come by for tickets or events, you know who to target.
    So you really need to do the same thing with the slot machines. It's, "What type of slot machines do you like? What themes do you like? What's your average bets?" Those types of things. "What's your comfort zones?" And that's where you should be spending your time not only learning what your host-level players kids' names are and what their birthdays and anniversaries are, but well, what do they like to play? Why are they coming back?
Nick:
    Yep. Yep, absolutely. Okay, well thanks again to the listeners for that. That was a great question. Really interesting take on what we've been discussing. Very cool. So with that, Don, I think we can kick off our third and final episode covering best practices. So you ready to get at it?
Don:
    Sure.
Nick:
    Alrighty, let's go.
Don:
    All right, today, we'll hit on performance tracking, looking at the performance numbers, the analytical numbers, post-install, and then we'll do a recap of the first two episodes. I don't expect everybody to listen to all these episodes. It's still amazing that we get all the questions and responses that we do.
Nick:
    Oh, I wouldn't underestimate the nerdiness of the listeners. I think they're right there with us, man.
Don:
    Right. It always shocks me after we post these, the number of emails that I get. It's from friends and colleagues. It's just amazing.
Nick:
    It is, it is.
Don:
    So, we'll start off with, what do you do after installing the games? And the first thing that everyone has to keep in mind is utilizing the performance guarantees. They are so valuable, and it's such a big difference than what it was 20 years ago. So you really need to look at your performance of these machines every 30 days. Look at theoretical win, actual win and handle pull data. Anything that's less than 120% of house average should be converted at 90 days, if there are other options available.
    Anything that shows a great reduction in earning between 90 and 150 days should be reviewed, especially if the play is a fall-off from your host-level players. If they're declining in those games, they're probably not going to last and you really need to look at them. And most of your performance guarantees are usually less than 180 days or 150 days. So you need to make sure that you're timing this so you can get that value.
Nick:
    Mm-hmm. And Don, do you see, just out of curiosity on that point, because it's something that we see on a lot of new games that come to market. And we see this spike, let's say, within the first 30, 60 days where people are really sampling it. Have you looked at that? I mean, is that dominated by host level, your more valuable players that are really doing that experimentation?
Don:
    It is. You will get some trial from your daily customers, because it's the bright, shiny new penny, but they normally will go back to their favorite games quite quickly. So it's your host-level players that will make or break those games.
Nick:
    Okay, okay.
Don:
    So the next thing you do is, when you're looking at these banks, don't be afraid to convert all the themes. Dropping from multiple themes on the same cabinet to a single theme is usually a good idea, especially for the smaller manufacturers that don't have those large libraries. It's the strategy of going to a single theme bank has really, really worked. Yeah, the next thing we wanted to talk about was, the old practice of trying to put as many different types of themes on a single bank really doesn't work as well as going with one theme or two themes.
    If post-conversion, your games are performing at 80% of house average, don't try to save them by reducing the number of themes. If you've got three of them and they're doing 80%, don't try to go down to one. You're better off getting rid of all of them. Yeah, we've proven this on banks of four, banks of six, banks of eight. It just doesn't work. The other thing is, even if there are no performance guarantees, the manufacturers will usually work with you to correct below-average performance, because they want to keep you as a happy and satisfied customer. There's just no harm in asking.
    There's a lot of great AEs out there. They know what they're doing. They've been doing this for a long time, and they know that they need to fix the games on their floor before they sell you new stuff. So it's very important to work with your AEs. Now, the flip side to that is, you need to use the data to tell them what you want versus having them make suggestions. If you've got a database that you look at, like ReelMetrics, you need to push for what you want, for what's working out there.
    Some of the manufacturers do a really nice job of looking at their data, others do not. So you really need to look at this. We had a client that was working with their AEs before ReelMetrics, and they were successful on about 40% of their conversions. After ReelMetrics, they were a hundred percent successful in their conversions where performance actually went up. And that's pretty close to the industry average we've seen, Nick, right? It's about a 33% chance of a successful conversion without looking at any industry-wide data. So it makes a lot of sense to look at that data to find out what's working so you don't have to do multiple conversions. Just get it right the first time.
    So, if you're a single property with no other data to look at, it's really important to look at these industry-wide databases. If you're lucky enough to have other properties within your organization, reach out to those other properties and see what's working for them. I did both. I started off as a single property, then we went to six casinos, then we went to 40 casinos, and then we went to hundreds of casinos when we signed up with ReelMetrics, and you just leverage that data to make better decisions.
    Now, post-performance guarantees, the performance should be tracked monthly with the trends for the last 13-month viewed, especially on lease games, but also on your core games. You need to look at variances every month to determine the difference between actual and theoretical holds. A large percentage of your games will show up if you look at a 30-day review. So you really need to look at longer ranges as well, 180 days, 365 days. Sometimes, you'll find some issues that'll pop up on there. Sometimes it's things like, it could be a progressive jackpot that hits on a single theme.
    But there are times where you'll find things that are set incorrectly in your system, especially whole percentages. You'll see a return to player in your fields versus a whole percentage, and that will really mess with your theoreticals. So that's why it's really important to look at your new installs in your first month. If you can look at your installs after your first week, it's preferred. I've seen it happen.
    It happened to me once where someone put in 88% instead of 12%, and the players were getting seven times theoretical for their games. We had a new client where we looked at their slot file and we found on their electronic table games, they did that and it was, they didn't know it. And it was like that for months and months and months where their players were getting a absolutely ridiculous return on their theoretical. So you need to look at that every month.
Nick:
    And Don, there was another high-level metric that I recall that we looked at a long time ago, which related to conversion specifically. And we were really focused on what is the mean intervention time when new stuff comes on the floor and it's, let's say, not performing, et cetera, et cetera. So when we soft-pulled organizations and we asked them, "Okay, how long does it take for you to spot and intervene with product that's underperforming?" The answer we typically receive is either 30 days, 60 days, or 90 days.
    But then when we looked at it and we started looking at config changes and what happened, what we saw was that the mean intervention time was actually 300 days. So as things going out there and just being allowed to rot and there was really no intervention whatsoever for damn near a year. Now, is this consistent with things you've seen in casinos?
Don:
    It is. It's different for each casino, of course, and that's why we talk so much about tracking performance. If your games are only, if your new installs are only doing a house average or a little bit above house average, people have a tendency to wait a little bit longer than they should, hoping that things will get better, that maybe they're in a bad spot or they're going to change locations for them or change configuration options to try to get the performance better. But if a new game's only doing house average out the gate, it's not going to go up. They just go down from there. So yeah, that's why it's really important to track this every, you track it every 30, 60, 90, but you really need to act on it every 30, 60, 90 as well.
Nick:
    Yeah, good point.
Don:
    So the next thing you need to look at is definitely your host level player data. You need to track host-level play in all new themes to determine proper quantities. Every year, there's new themes that come out that need to be expanded quickly to capitalize on your host-level players. You need to identify it quickly, and then you need to expand quickly. Going back to the last comment, if you know it after 30, 60, 90 days, you can't wait a year to put it on the floor.
    We saw that happen with a certain lease game. I guess that's probably 10 years ago now, where this game came out of the gate, it was super strong and everyone sat there with four or six units, and they really needed to be at 18 units right out of the gate. So don't wait. And then you need to reduce your count of themes that are not being played by your host-level players. A non-host-level player generally gravitates towards your older cabinets, your classic themes, post-trial. And leverage the themes that the host-level players play by creating banks for them. Those are the people who going to carry these games. So that was about that.
    On tracking performance, it's really important. You really need to look at your lease games every month. Look at theoretical, look at actual, look at occupancy level. I use eight and a half spins per minute, but it's different for each game type. Obviously, poker plays a little bit faster, but eight and a half spins per minute gives you a pretty good gauge. Anything less than two times house average on your lease games needs to go, unless your floor is above 20 or 25% lease games. If you're less than 10% lease games, I'd really shoot for two and a half times house average.
    Once you get to about 20, 25%, you can lower that threshold. And your host-level players are carrying your lease games. If you have games that are over four times house average, it's probably host-level play that's doing it, and you really need to think about expanding. And there are some exceptions to that, especially for weekend-heavy casinos that we'll get into a little bit. But I always see casinos carry lease games at house average when others are maxed out. Sometimes, you need a second, third, and fourth banks of these titles. Just make sure that you're utilizing your lease expenses wisely.
Nick:
    Yep. And Don, on that one where you said the weekend-heavy venues, so just to break that out a little bit, you're talking about... or excuse me, operations where, let's say, they get 70-plus percent of their revenue on the weekends. Is that where we're landing or do you have a ratio for that?
Don:
    No, it's exactly right.
Nick:
    Okay.
Don:
    When you start looking at lease gain performance and you're starting to see stuff that's four, five, six times house average, you really also need to be looking at your spins per day, especially if you're a weekend-dominant casino. I was looking at a casino, actually, about two weeks ago, and the numbers were just eye-popping. They were doing six times house average on their lease games. So my initial thought was they needed to expand. And then I started diving into the handle pull numbers and I saw that their games were still only about 40 or 50% occupied on the weekends. So I was trying to put my head around it.
    How could you have a game that's doing six times house average and still only 50% occupied? Well, it was because their core games were doing so poorly and their attendance was a little low, post-COVID. So, their floor was probably way too big for the revenues they were producing. So all their revenue was getting spread out on their own games and it was concentrated on their lease games. But their lease games still were only 40% busy, so they had room to grow on them. So, at first glance, six times house average, they needed to expand. But then when I looked at their occupancy levels, said, "Wow, they really don't need to spend the money to expand. They just need to right-size their floor first."
Nick:
    And I remember we discussed on, I don't know if it was that one or another one, but we thought, one of the questions I recall asking is, "Is there a different commercial model for this?" So, for instance, should they start looking at participatory setups as opposed to daily fee? And I remember you saying, "Eh, probably not."
Don:
    Yeah. Yeah, if you can get away with just doing a daily fee, I definitely do this, especially for some of the games that are doing north of $1,500 a day. It gets really expensive.
Nick:
    Yeah, yeah, sure.
Don:
    So one of the other big things that we talked about was making sure you're thinking long-term on your purchases. Just try to think about how many games that you've pulled off your floor recently that are less than seven years old. And it's likely not many. Most of the machines that you're buying now, you're going to have on your floor for at least a decade, probably longer. So you really need to think about that before you buy. "What cabinets am I buying?" Obviously, they're going to be obsoleted before a decade's up, but what games and what cabinets are going to have long-term appeal? Figure out what your customers want, especially your host-level players, because they'll carry all of your new cabinets.
    Your daily theoretical players play the classics and they do shy away from lease games. There are some that they'll play, but usually it's the lower-average bet lease games, the ones where they can play less than a buck a spin. Obviously, look at your occupancy analysis. If it's a lease game, over 80% really needs to be looked at. On the core games, anything over 30% needs to be looked at. Those core games, over 30% are probably doing over two times house average. So you definitely need to look at those.
    Make sure your floor size is right. Just like I talked about with that last casino, they had way too many games that was pushing their average win per unit way down. The one thing that COVID taught us was that quality matters. It's not variety and quantity. You just need to make sure you have the right games on your floor, and that'll take care of a lot of your problems. The other thing that we did a lot with COVID is the social distancing. Bank sizes should be no larger than six. Four is preferred. If you've got long banks along the walls, try to bust them up. Have spaces in between your long banks.
    And you need to really limit the number of themes per bank that you have. Two themes per bank is probably the maximum and one is preferred, if you're using four-packs. Making your floor an all-star floor is really the best way to go. It gives you more in the library to pick from. Just pick the all-stars. Unless you've got a casino that has five, 6,000 machines on it, you could probably get away with the top three or four themes on most of your new cabinet purchases.
    And then just a couple more things. Think about building those neighborhoods. Start with your host-level players, what do they like to play? Put their lease games next to their favorite core games. Figure out what they like to play and put them together. Do the same with your high-limit players, what your younger players, what your table games players. Put the slot machines or your ATGs near games that are alike. You don't have to put your ATGs next to table games if your table games don't play them. If you've got a sportsbook and your younger customers are going to the sportsbook, figure out what your younger customers are playing and put those games next to your sportsbook. We're seeing a lot of that going on right now.
Nick:
    Some of the analysis that you did on that stuff with the younger players was fascinating. There's so many assumptions that go into that. But when we started looking at, well, when you started looking at this and breaking down what those folks were playing, there were some big surprises in there.
Don:
    There really was. And it really shocked me, because I was expecting sportsbook players under the age of 45 to spend 80% of their theoretical on tables and 20% on slots, and it really wasn't the case. There's a lot more slot spend from sportsbook players than I expected. And then to find out what themes they're actually playing and what their average bet is and what they're trying to accomplish on a slot machine, it was really eye-opening.
Nick:
    Yeah, for sure.
Don:
    That's probably a episode we can do sometime in the future, too, is-
Nick:
    I think so, too. There were a lot of takeaways from that study.
Don:
    Yeah, there really was.
Nick:
    I have to say there was not, out of what you broke down there, I don't think that there was an intuitive conclusion that I saw there. Every single one of them shocked me. And I realized, man, I went into this with so many assumptions that were so far off. So yeah, I think that's probably not a bad one to tackle later on.
Don:
    Sure.
Nick:
    [inaudible 00:27:21].
Don:
    And I did, too. I thought the same thing. But then when I started looking at the data and I started recalling walking around the slot floors in Las Vegas at G2E and looking at what they were playing, it started making sense. It started clicking, the games that I wanted to play, I couldn't get on, because there was a young man on the machine. So I started thinking about it and it really started making sense, but the data was the same. But the weird thing is, not every casino is set up for the under-45 crowd, and it's the rare casino that is, because most of your high-worth customers are over the age of 45.
Nick:
    Yeah, yeah, yeah.
Don:
    Right? Most of your high theoretical customers are in that 45 to 64 bracket, so you really don't set up your floor for under 45. And it's definitely a growing demographic-
Nick:
    For sure.
Don:
    ... And especially what sportsbooks now being relevant for the last three years, pretty much, or basically post-COVID, it's something you really need to think about. And building those neighborhoods for those customers really makes sense, and we're starting to see some success with that. And then lastly, expand the ones that work, especially your new machines, jump on those early, make sure you have that all-star floor. Get rid of the ones that aren't working, but expand the ones that are.
    If you have owned games doing 170% of house average, expand them, especially via conversions first, if you can. Before you add new titles to your floor, definitely do that if you do not have access to any industry-wide data, because only 20 to 30% of new releases are going to work. There is just a lot of new themes that just do not work, and that's always been the case.
    We were really happy with some of the major manufacturers if they came out with six new titles and two of them worked. And that's pretty much what's been happening still in the last five years. There are some vendors that do better than others on new releases. We had one manufacturer last year that went 0% on new releases doing above house average. None of them did it. And we've got others right now that are doing two and three times house average right out of the gate. So you really have to look at that industry-wide data. Because if you're flying blind, you're going to miss 70% of the time.
Nick:
    Now, Don, do you feel like when you were operating, did you feel like that was a point that is expanding the stuff that's working, right? Was that a point that, as a slot operator, that you felt required a lot of discipline on your part, to avoid the shiny, shiny, the new stuff and really focus on replicating success stories?
Don:
    It really became a balancing act, because you get new capital every year and you have to spend it. You've got a time limit to spend it, and you want to make sure that you're buying newer cabinets that'll be obsoleted farther into the future. So, it was mostly about what's new and picking the best of the new product versus expanding what's on the floor and working, and that really shouldn't be the case. It should be let's expand what's working and then look at the new stuff versus let's look at the new stuff and then expand what's working. Really had it backward.
Nick:
     And did you ever find yourself in one of those positions where... I had this with my son, interestingly enough, over the weekend, where he broke his old iPhone and I said, "Okay, so what you need to do," he needs to get a replacement phone. And what I said is, "What you need to do here is just break down what is cost to get various stuff? Start with some bottom of the market or bottom of the barrel, really simple Android stuff, all the way up to latest whizzbang iPhone. Figure out how much money you're making for your job," he is delivering food out to houses and stuff for a sushi restaurant, "... and figure out what your income is and what you can afford."
    In the end, he just skipped that whole thing and went for the latest, coolest iPhone. I was just curious if there's ever this type of dynamic in operations where you see something new that looks extremely cool, but there's absolutely no data on it, there's no real indication as to what... How strong is that temptation for slot managers to jump on the newest, shiniest, coolest, latest stuff.
Don:
    It happens every year.
Nick:
    Okay.
Don:
    It's just, you go to G2E, and usually October-ish, and most casinos get their capital in December or January. There's some different financial calendars, but the vast majority of people get their capital right after G2E. So you go and you look at the new cabinets and the manufacturers are doing such a great job on the video cabinets. And then the new reels have done really well in the last year. But you're out there looking at G2E and you're looking at all this new stuff and you say, "Okay, the cabinet looks great and I trust this manufacturer. These themes are going to perform." And every once in a while they just don't.
    And we know that even for the big manufacturers, they're lucky to get the 33 to 40% new themes working right out of the gate. Sometimes, especially for a new cabinet, it's not the first generation of software that works for them. It can be the second generation of software and that might not come out for six or eight months. Everyone was really hopeful at the stuff that we saw at G2E this year. And so far, there are probably three stars out of that, as far as cabinets, and the rest of them were going, "Oh, okay."
    So if you ended up buying in five different cabinets and you didn't hit one of those stars, you may have hit out of two of five. And that gets really scary. The worst thing in the world is going to your general manager and saying, "Oh, I messed up on this order, I got to spend more money on [inaudible 00:33:49]-
Nick:
    Nobody wants to do that.
Don:
    No, exactly. You want to show in your monthly reports or your quarterly reports that yeah, these new games I've bought, they're all doing two times house average, all my lease games are doing four times house average, and it just gets rid of some of that anxiety.
Nick:
    Sure.
Don:
    So yeah, it's just really tough. You build up relationships with these manufacturers, with the people who sell these games, and one of the hardest things to do is tell a manufacturer that you really like, "No, I'm not buying your machines this year." You know their family, you know their kids. You've had dinners with them, but sometimes their performance just isn't there to spend your company's money with them. And it just makes it, it can make it really, really tough, because there's some really good people in this industry that you know that's how they put bread on their table.
Nick:
    Sure.
Don:
    So that was all I had from the recap, from the last two months worth of episodes. And there's more, there's so much more. Like you said, Nick, we just basically did a thumbnail sketch on all this stuff. I think my last presentation was eight pages long as a PowerPoint. Working with some of the COOs from some of our clients and trying to get them to implement best practices. And it's really different since COVID. There's been so much turnover at the slot director, slot vice president level, that there's a lot of new people on this. And if there's no one out there teaching them the right ways to do things, people are just getting handed the keys to the car and saying, "Here, go drive."
Nick:
    Yeah. We encounter quite a bit of this, and increasingly. And as you said, it's all post-COVID. There's so many changes in staff, no doubt about it. And I know you've been encountering that more and more in your work in the professional services side of things. People really new to it.
Don:
    Mm-hmm. Yeah, they are, and I'm really pleased and proud of the people we've been working with. They ask good questions. They don't assume that they know everything. And there's a lot of shifts in play that people just don't realize have happened. If you're going by data that you thought you knew five years ago, it's totally different now. The customers are playing different machines, have different goals, they have different qualities to the players. It's really changed a lot post-COVID. And I can't stress it enough, that if you're going off information from, say, 2020 for your slot purchases and what you think your customers are doing, you really need to re-look at it. It's definitely changed a lot. Yep.
Nick:
    Okay, Don, well, cool. I think that largely concludes our third and final episode covering these best practices. I think what I wanted to say before we sign off here, I'm jumping on a plane here early next week, heading home, back over to the states. I'll be at the IGA show next week in San Diego, and we'll be speaking with Buddy Frank and CEO of Slot Check, Grant Stousland, on the topic of data democratization. So that'll be on Wednesday 27th of March at 11:00 a.m. in the Tribal Leaders Lounge. So if you have some time and you're at IGA, please stop by. We'd love to see you and catch up with you afterward or however it shakes out. So, I think with that, Don, it's pretty much a wrap for today.
Don:
    Cool. Yeah. And if anyone has any topics they want us to talk about in the future episodes, like the jackpot liability, please let us know. We're always interested to hear what you want to find out about.
Nick:
    Absolutely. Yeah. And we have another thing, just as a little bit of a teaser. I have been reaching out to some guests, so I think we're going to start bringing in a few other people, so it's not just you and I nerding out, we'll get some other nerds in the mix.
Don:
    Thank God. Been a long time since I had to interview anybody. Outside for a job, but for media that's going back to the '80s for me. [inaudible 00:38:29].
Nick:
    Yeah. We have to figure out some new skills for doing that.
Don:
    Yeah. Geez. I think the last time I interviewed somebody was for sports reporting, back in about 1988.
Nick:
    Oh, okay.
Don:
    Long time ago.
Nick:
    Yeah, for sure.
Don:
    Yeah.
Nick:
    Okay, very good.
Don:
    Well, have a safe trip.
Nick:
    Yeah. Thank you very much, Don. And yeah, enjoy the rest of your weekend.
    Don:
    You, too.
    Nick:
    Okay, thanks Don.
    Don:
    Take care.
    Nick:
    See you later, guys.    
    `
};

export default S02E03;
