import React, { Component } from "react";
import axios from "axios";
import Response from "./response";
import Cms from "./cms";

export default class LoginForm extends Component {
    static closeModal() {
        const btns = Array.from(
            document.querySelectorAll('[aria-label="Close"] span')
        );
        setTimeout(() => {
            btns.forEach(btn => btn.click());
        }, 3050);
    }

    constructor(props) {
        super(props);

        this.state = {
            form_params: {
                contact_fname: "",
                contact_lname: "",
                contact_email: "",
                contact_phone: "",
                contact_company: "",
                contact_title: "",
                contact_comments: "",
                contact_newsletter: "",
                contact_current_page: window.location.href
            },
            response_message: {
                colorClass: "",
                text: ""
            }
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.setResponseState = this.setResponseState.bind(this);
        this.sendRequest = this.sendRequest.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        this.sendRequest();
    }

    onInputChange(e) {
        const state = this.state.form_params;
        state[e.target.id] = e.target.value;
        this.setState(state);
    }
    
    
    setResponseState(response) {
        if (response.toString().includes("Error")) {
            this.changeResponseState(
                "text-danger",
                "Oops...looks like something went wrong. Please try again!"
            );
        } else if (response.status === 200) {
            this.changeResponseState(
                "alert alert-success",
                "Successfully sent!"
            );
        } else {
            this.changeResponseState(
                "alert alert-warning",
                response.statusText
            );
        }

        setTimeout(() => {
            this.changeResponseState("", "");
        }, 3000);
    }

    changeResponseState(colorClass, text) {
        const state = this.state.response_message;
        state.colorClass = colorClass;
        state.text = text;
        this.setState(state);
    }

    sendRequest() {
        const params = this.state.form_params;
        return axios
            .post("/api/contacts", params)
            .then(response => {
                this.setResponseState(response);
                if (response.status === 200) {
                    LoginForm.closeModal();
                }
            })
            .catch(error => {
                this.setResponseState(error);
            });
    }

    render() {
        return (
            <div
                className="modal fade login-box"
                id="contact"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="Contact"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div className="text-center">
                                <h1 className="title title--primary">
                                    Contact
                                </h1>
                            </div>
                            <form
                                className="login"
                                onSubmit={this.handleSubmit}
                            >
                                <div className="form-group row">
                                    <div className="col-sm-6">
                                        <label htmlFor="contact_fname">
                                            First Name
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="contact_fname"
                                                onChange={this.onInputChange}
                                            />
                                        </label>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="contact_lname">
                                                Last name
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="contact_lname"
                                                    onChange={
                                                        this.onInputChange
                                                    }
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-sm-6">
                                        <label htmlFor="contact_email">
                                            E-mail address
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="contact_email"
                                                onChange={this.onInputChange}
                                            />
                                        </label>
                                    </div>
                                    <div className="col-sm-6">
                                        <label htmlFor="contact_phone">
                                            Phone number
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="contact_phone"
                                                onChange={this.onInputChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group row">
                                <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="contact_company">
                                        Company
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="contact_company"
                                            onChange={this.onInputChange}
                                        />
                                    </label>
                                </div>
                                </div>
                                <div className="col-sm-6">
                                <label htmlFor="contact_title">
                                            Title
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="contact_title"
                                                onChange={this.onInputChange}
                                            />
                                        </label>
                                </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="contact_comments">
                                        Comments
                                        <textarea
                                            id="contact_comments"
                                            onChange={this.onInputChange}
                                        />
                                    </label>
                                </div>

                                <div className="contact_newsletter_section">

                                <div className="contact_newsletter">
                                    <input
                                        type="checkbox"
                                        id="contact_newsletter"
                                        onChange={this.onInputChange}
                                    />
                                    <label htmlFor="contact_newsletter" className="contact_newsletter_text">
                                       {" "} Sign up for our newsletter
                                    </label>
                                </div>
                                </div>

                                <div className="login__actions">
                                    <button
                                        type="submit"
                                        className="btn-block-mobile btn btn-lg btn-primary"
                                        value="Submit"
                                    >
                                        Send
                                    </button>
                                </div>
                            </form>

                            <Response
                                colorClass={
                                    this.state.response_message.colorClass
                                }
                                text={this.state.response_message.text}
                            />
                        </div>

                        <div className="modal-footer">
                            <div className="mw-470 center-block">
                                <Cms
                                    content={this.props.cms_content}
                                    content_key="contact_us_popup_footer"
                                    type="text"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
