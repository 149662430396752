import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

function Details({ imageDetails }) {
    const { title, title_slug, uuid, image } = imageDetails;

    return (
        <div className="reelhot-details">
            <figure>
                <img src={image} alt={title} />
            </figure>
            <div className="button-wrapper">
                <Link to={`/games/${uuid}/${title_slug}`}>
                    <Button variant="primary">More information</Button>
                </Link>
            </div>
        </div>
    );
}

Details.propTypes = {
    imageDetails: PropTypes.shape({
        title: PropTypes.string,
        title_slug: PropTypes.string,
        uuid: PropTypes.string,
        image: PropTypes.string
    })
};

Details.defaultProps = {
    imageDetails: {
        title: "",
        title_slug: "",
        uuid: "",
        image: ""
    }
};

export default Details;
