import React from "react";
import { Link, NavLink, withRouter } from "react-router-dom";

import $ from "jquery";

class Header extends React.Component {
    /* eslint-disable */
    componentDidMount() {
        const _window = $(window);
        const body = $("body");
        $(".js-menu-trigger").on("click", function () {
            body.toggleClass("is-menu-open");
        });

        $(".main-nav li a").on("click", function () {
            if ($(window).width() < 768) {
                $(".js-menu-trigger").click();
            }
        });

        _window.on("scroll", function () {
            if (_window.scrollTop() > 100) {
                body.addClass("is-scrolling");
            } else {
                body.removeClass("is-scrolling");
            }
        });
    }
    /* eslint-enable */

    render() {
        if (this.props.compact) {
            return (
                <div>
                    <Header className="visible-xs visible-sm" />
                    <header className="header hidden-xs hidden-sm">
                        <Link to="/" className="back-to">
                            Back to homepage
                        </Link>

                        {this.props.buttons && (
                            <div className="header-compact-actions">
                                <nav className="main-nav text-right@sm">
                                    <ul className="nav-inline@sm list-unstyled">
                                        <li className="nav-inline__item--btn">
                                            <div
                                                className="btn btn-default"
                                                data-toggle="modal"
                                                data-target="#login"
                                            >
                                                Login
                                            </div>
                                            {/* <Link to="/calculate" className="btn btn-primary">Calculate benefit</Link> */}
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        )}

                        <Link className="logo center-block" to="/">
                            <img
                                className="img-responsive"
                                alt="ReelMetrics logo"
                                src="/assets/images/reelmetrics-logo.png"
                            />
                        </Link>
                    </header>
                </div>
            );
        }

        return (
            <header className="header">
                <button className="js-menu-trigger menu-trigger">
                    <img src="/assets/images/menu.svg" alt="Menu trigger" />
                </button>
                <div className="outside-wrapper container-fluid">
                    <div className="col-sm-3">
                        <Link className="logo" to="/">
                            <img
                                src="/assets/images/reelmetrics-logo.png"
                                alt="ReelMetrics logo"
                                className="img-responsive"
                                loading="eager"
                            />
                        </Link>
                    </div>
                    <div className="col-sm-9">
                        <nav className="main-nav text-right@sm">
                            <ul className="nav-inline@sm list-unstyled">
                                <li>
                                    <NavLink
                                        activeClassName="is-active"
                                        to="/slot-machine-data-analytics-platform"
                                    >
                                        Platform
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        activeClassName="is-active"
                                        to="/services"
                                    >
                                        Services
                                    </NavLink>
                                </li>
                                {/* <li>
                                    <NavLink
                                        activeClassName="is-active"
                                        to="/cupid"
                                    >
                                        Cupid
                                    </NavLink>
                                </li> */}
                                <li>
                                    <NavLink
                                        activeClassName="is-active"
                                        to="/reelhot"
                                    >
                                        ReelHot
                                    </NavLink>
                                </li>
                                <li>
                                    <a href="/reelcast">ReelCast</a>
                                </li>

                                <li>
                                    <NavLink
                                        activeClassName="is-active"
                                        to="/contact"
                                    >
                                        Contact
                                    </NavLink>
                                </li>
                                <li className="nav-inline__item--btn">
                                    <div
                                        className="btn btn-default"
                                        data-toggle="modal"
                                        data-target="#login"
                                    >
                                        Login
                                    </div>
                                    {/* <Link to="/calculate" className="btn btn-primary">Calculate benefit</Link> */}
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>
        );
    }
}

export default withRouter(Header);
