import React from "react";
import { Row, Col } from "react-bootstrap";
import Card from "./card";
import Panel from "./panel";

export default class CardsRow extends React.Component {
    /* eslint-disable */
    mapChilds = (children, func) => {
        let index = 0;
        return React.Children.map(children, child =>
            React.isValidElement(child) ? func(child, index++) : child
        );
    };
    /* eslint-enable */

    filter = (children, filterFn) => {
        return React.Children.toArray(children).filter(filterFn);
    };

    render() {
        const { children } = this.props;
        const mdSize =
            12 / this.filter(children, child => child.type === Card).length;
        const smSize =
            12 / this.filter(children, child => child.type === Card).length;
        return (
            <>
                <Row className="CardsTabStrip">
                    {this.filter(children, child => child.type === Card).map(
                        child => {
                            const { cardId } = child.props;
                            const active =
                                cardId === this.props.activeCard
                                    ? "card-active"
                                    : "";

                            return (
                                <Col
                                    key={JSON.stringify(cardId)}
                                    onClick={() =>
                                        this.props.clickHandler(cardId)
                                    }
                                    className={[active, `${cardId}_card`].join(
                                        " "
                                    )}
                                    md={mdSize}
                                    sm={smSize}
                                >
                                    {child}
                                </Col>
                            );
                        }
                    )}
                </Row>
                <Row>
                    {this.filter(
                        children,
                        child =>
                            child.type === Panel &&
                            child.props.cardId === this.props.activeCard
                    ).map(child => (
                        <Col md={12} key={JSON.stringify(child.props.cardId)}>
                            {child}
                        </Col>
                    ))}
                </Row>
            </>
        );
    }
}
