import React from "react";
import { Link } from "react-router-dom";
import butter from "../utils/butterClient";

import SocialMedia from "./shared/SocialMedia";

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { jobs: 0 };
    }

    componentDidMount() {
        butter.content.retrieve(["jobs"]).then(response => {
            this.setState({
                jobs: response.data.data.jobs.filter(
                    job => job.title.length > 0
                ).length
        });
    });
    }

    render() {
        return (
            <footer>
                <div className="footer-wrapper">
                    <ul className="nav-inline list-unstyled">
                        <li>
                        
                                    <Link to="/reelscan">ReelScan</Link>
                                
                        </li>

                        <li>
                                    <Link

                                        to="/about-us"
                                    >
                                        About
                                    </Link>
                                </li>
                        <li>
                            <Link to="/legal">Legal</Link>
                        </li>
                        <li>
                            <Link to="/careers">
                                Careers
                                <span className="badge badge--primary">
                                    {this.state.jobs}
                                </span>
                            </Link>
                        </li>
                        <li>
                            <SocialMedia />
                        </li>
                    </ul>
                </div>
            </footer>
        );
    }
}

export default Footer;
