import React from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";

import Hero from "../components/shared/Hero";
import ReelHotIndexes from "../components/ReelHot/Indexes/ReelHotIndxes";
import SpotlightAndHiddenGems from "../components/ReelHot/SpotlightAndHiddenGems/SpotlightAndHiddenGems";

function ReelHot({ cmsContent }) {
    ReactGA.pageview(window.location.pathname);

    return (
        <div className="reelhot">
            <Helmet>
                <title>ReelHot</title>
                <meta
                    name="Description"
                    content="Fact-based, actionable insights, Improve purchases and conversions, 
        Optimize conf, Drive revenues, Detect hits early, Gain clearer insights, Stay in the loop"
                />
                <link rel="canonical" href="https://www.reelmetrics.com/reelhot" />
                <link
                    rel="preload"
                    as="image"
                    href="assets/images/reelhot-index-thumbnail.png"
                />
                <link
                    rel="preload"
                    as="image"
                    href="assets/images/reelhot-logo.svg"
                />
            </Helmet>

            <Hero
                cmsContent={cmsContent}
                contentKey="reelhot_hero"
                imageSrc="https://cdn.buttercms.com/rZ3jrYsxQzirDHaf5nqs"
                imageAlt="reelhot_image"
            />

            <ReelHotIndexes contentField="reelhot_indexes" />

            <SpotlightAndHiddenGems
                key="reelhot_spotlight"
                cmsContent={cmsContent}
                contentField="reelhot_spotlight"
                carouselTitle="Previous Spotlights"
            />

            <SpotlightAndHiddenGems
                key="reelhot_hidden_gems"
                cmsContent={cmsContent}
                contentField="reelhot_hidden_gems"
                carouselTitle="Previous Hidden Gems"
                reverseDescription
            />
        </div>
    );
}

ReelHot.propTypes = {
    cmsContent: PropTypes.object.isRequired
};

export default ReelHot;
