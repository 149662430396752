import React from "react";
import PropTypes from "prop-types";

import Cms from "../cms";

function Hero({ cmsContent, contentKey, imageSrc, imageAlt }) {
    return (
        <section className="hero">
            <div className="outside-wrapper container-fluid flex-row hero_container">
                <div className="col-xs-12 col-sm-6 flex-column">
                    <h2 className="title--banner text--red hero_title">
                        <Cms
                            content={cmsContent}
                            content_key={contentKey}
                            type="title"
                        />
                    </h2>
                    <p className="paragraph paragraph--small">
                        <Cms
                            content={cmsContent}
                            content_key={contentKey}
                            type="text"
                        />
                    </p>
                </div>
                <figure className="col-xs-12 col-sm-5 flex-column">
                    <img src={imageSrc} alt={imageAlt} loading="eager" />
                </figure>
            </div>
        </section>
    );
}

Hero.propTypes = {
    cmsContent: PropTypes.object.isRequired,
    contentKey: PropTypes.string.isRequired,
    imageSrc: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired
};

export default Hero;
