import React from "react";
import { Card, Button } from "react-bootstrap";

class ReelCastEpisodeDescription extends React.Component {  
    render() {
        return (
            <>
                <Card style={{ borderRadius: "10px" }}>
                    <Card.Header as="h4" className="linkHeader" style={{ paddingBottom: "0px" }}>
                        <Card.Link
                            href={this.props.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ cursor: 'pointer' }}
                        >
                            {this.props.heading}
                        </Card.Link>
                    </Card.Header>
                    
                    <Card.Body>
                    <Card.Text as="h3">{this.props.subHeading}</Card.Text>
                        <Card.Text>{this.props.description}</Card.Text>
                        <Card.Link
                            href={this.props.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Button variant="default">
                                Listen{" "}
                                <img
                                    src="https://cdn.pixabay.com/photo/2012/04/24/16/24/microphone-40327_960_720.png"
                                    alt="List to the Reelcast"
                                    width="10"
                                    height="18"
                                    style={{ verticalAlign: "middle" }}
                                />
                            </Button>
                        </Card.Link>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

export default ReelCastEpisodeDescription;
