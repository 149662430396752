import React from "react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";

class S03E04 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname);
    }
    
    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);

        if (Helmet.canUseDOM) {
            Helmet.renderStatic();
        }
    }

    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }

    componentDidUpdate() {
        if (Helmet.canUseDOM) {
            Helmet.renderStatic();
        }
    }
    
    render() {
        return (
            <>
                <Helmet>
                    <title>{`${this.props.title} | ${this.props.subTitle} | ReelCast by ReelMetrics`}</title>

                    <meta
                        name="Description"
                        content={this.props.description}
                    />
                    <link
                        rel="canonical"
                        href="https://www.reelmetrics.com/s03e04-bally-s-corporation-with-dan-cherry"
                    />

                    {/* Open Graph / Facebook / LinkedIn */}
                    <meta property="og:type" content="website" />
                    <meta
                        property="og:url"
                        content="https://www.reelmetrics.com/s03e04-bally-s-corporation-with-dan-cherry"
                    />
                    <meta property="og:title" content="ReelCast - S03E04" />
                    <meta
                        property="og:description"
                        content="In our second episode of Season  3, Nick and Don speak with Michael Carruthers, Vice President of Gaming Operations at Delaware North. Michael shares his insights on the company’s gaming operations, the impact of the pandemic, and the future of the industry. Join us for a deep dive into the world of gaming operations."
                    />
                    <meta
                        property="og:image"
                        content="https://cdn.buttercms.com/9nzbnxR9SnGfKpJAMnrm"
                    />
                    <meta property="og:locale" content="en_US" />
                
                </Helmet>
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}


    S03E04.defaultProps = {
        id: "14981282-s03e04-bally-s-corporation-with-dan-cherry",
        link: "https://www.reelmetrics.com/s03e04-bally-s-corporation-with-dan-cherry",
        title: "Bally's Corporation with Dan Cherry",
        subTitle: "Season 3, Episode 4",
        description: "In this episode, Nick & Don chat with Dan Cherry, Vice President of Gaming Operations at Bally's Corporation. Dan discusses the major projects underway in Vegas and Chicago, as well as the challenges and payoffs of implementing operational and analytical standards within hyper-growth gaming organizations. Also in this episode, should I replace ETGs with slots?",
            topics: [
              "Bally’s projects in Chicago and Las Vegas",
              "The challenges of integrating acquired casinos into a global gaming enterprise",
              "Centralized vs Decentralized organizational models for gaming enterprises",
              "Purchasing analytics and corporate purchasing strategies",
              "Changing perspectives vis-à-vis premium cost models",
              "Big Data analytics & its importance to gaming operations",
            ],

        keywords: [
            "Bally's Corporation",
            "Dan Cherry",
            "Vice President of Gaming Operations",
            "Vegas",
            "Chicago",
            "Operational and analytical standards",
            "Hyper-growth gaming organizations",
            "ETGs",
            "Slots",
            "Purchasing analytics",
            "Slot Data",
        ],
    buzzSproutLink:
        "https://www.buzzsprout.com/2057836/14981282-s03e04-bally-s-corporation-with-dan-cherry",
    spotifyLink:
        "https://open.spotify.com/show/314iBnXRGGAuttAsIcZeC5",
    applePodcastsLink:
        "https://podcasts.apple.com/us/podcast/reelcast-by-reelmetrics/id1652485625",
    iHeartRadioLink:
        "https://iheart.com/podcast/104029260/",
    cdcLink:
        "https://cdcgaming.com/reelmetrics/s03e04-bally-s-corporation-with-dan-cherrys",
    
    transcript: `Nick Hogan:
    Things in Illinois today.
    Don Retzlaff:
    Nice. After all the storms. Good morning, Nick.
    Nick Hogan:	
    Yeah, I was happy to see that your house wasn't obliterated by the tornadoes you had in your neighborhood yesterday.
    Don Retzlaff:
    Yeah, we've had some wild weather here in the Midwest the last couple of weeks. And tornado sirens going off, but nothing like what we had in Nebraska and Iowa over the weekend. That was crazy.
    Nick Hogan:	
    Yeah, truly devastating stuff. Well, spring appears to have tentatively sprung here in Holland and it can't come soon enough. We've had an absolutely terrible winter here and it's very nice to see some sun and get a reprieve from the driving rain and howling winds for once. And let's see, I'm fresh out of the Casino Operations Summit, which took place in Amsterdam last week for anybody who's not familiar with COS, it's an annual gathering of gaming operations folks here in Europe by Lushen Weisman, who Don you remember from the real cast we did with him last season. And also his business partner, Ariane Corsians, who has a casino marketing consultancy here in Europe. So it's a three-day gathering focused on European gaming Ops with the operators, vendors and advisors, congregating for talks, product demos, networking, and all kinds of evening events. They changed the location each year, 2022 was in Riga, last year was Bucharest and this year was here in Amsterdam. And it's this format, Don, that I absolutely love. You have about 300 people, you spend the days exposed to all sorts of interesting topics and you're given the time to discuss them with the various attendees and in many cases the speakers themselves. And it's just a layer of depth that you can't get at, let's say a G2E or IGA. It's actually quite similar in the format to what AGS does with their game on customer summits. And man, I'm a fan of this format. It's just such a great way to do stuff. So huge thanks to Lushen Ariane and the COS team for the lovely event. Once again, this guy's just knocked the cover right off the ball. Questions, Don, before I jump in there, let me say, we'd love to tackle any questions that anybody listening may have. So if you have a question about what we're presenting or something you'd like us to present, please drop us an email at reelcast@realmetrics.com. Again, that's R-E-E-L-C-A-S-T@realmetrics.com.
    Our policy is to keep all questions anonymous, so please speak directly and don't worry about us revealing your identity. We do not do that. Okay, so this one comes from an operator in the American West who asks, "Hi guys, I'm curious about your thoughts regarding ETG. Analyze as a game type competitor to tables or poker. There's a place for them on my floor. Analyze against slots however, it's rare for them to exceed house average performance. Although a few big players will jump on them from time to time, they don't really stick with them or show any deeper level of interest. They take up a lot of space in my floor and I'm thinking I'd do better with slots. Do you see ETGs as being necessary to the overall mix or more of an expendable nice to have? Thanks in advance."
    So Don, you and I have covered this topic I think several times in the past. I think I've asked a similar question, why are we giving so much real estate to these things? And at least in a couple of them when we discussed it, was in jurisdictions where table games weren't allowed. And then obviously in those cases it makes all sense in the world. But I've had this question too. So what's AU Mr. Retzlaff?
    Don Retzlaff:	
    This one is so tough to answer. It all depends on the properties. You're right, you're not going to get much more than house average. If you're lucky a lot of ETGs are well below house average, obviously cost. Are you better off having more high-end slot games? Probably. But there's always that line, the thing I'm always afraid of is, if you pull them out, do you lose revenue? It's kind of like Kino. You had to have Kino on your floor because that's all they played. And there are customers out there that's all they play is the ETGs. So yeah, you can't devote 20% of your floor space to it, but I think as long as you're not running up into some density and occupancy problems on the weekends, I think there's a space for them on your floor.
    Nick Hogan:	And generally speaking, do you find certain flavors of ETG just better than others? So let's say the automated roulette versus the other various options that are out there?
    Don Retzlaff:	
    It really has been mixed. I've probably looked at this at a hundred different places this last year, and there's a lot of places where the craps games do really, really well. There's others that the roulette do really well. Others at the blackjacks do well. But generally it's the roulette and the craps that seems to do the best in most places. But usually it's in limited numbers. There's some stuff the stadiums work really well in other places, but it really is a mixed bag.
    Nick Hogan:	
    And do you have any type of rough occupancy, guidance on these things like versus let's say core premium slots?
    Don Retzlaff:	
    No, I used it more towards actual win than anything else, just because the gameplay is so slow. Matter of fact, I just looked at this last week and it was 1.02 spins per minute on a lot of the games, so it's not exactly super fast. You're looking at 12 spins per minute for a dragon [inaudible 00:05:52]. They're really tough pin down the occupancy on games. So it's more, I look more for the actual win. I don't even look at theoretical win for these games. Because the actual win is usually so much higher than theoretical because you get a lot of people who are learning the games and they make a lot of mistakes or you see a lot of side utilization on ETG's too.
    Nick Hogan:	Okay. So a lot of overhauled and sounds like a little bit tougher to nail down than the slots, but. Okay, cool. Well, thank you. Sorry, Don, did you have something else on there?
    Don Retzlaff:	
    No, it really is an individual casino. Some places I've seen need to expand it and some places I've seen need to pull them all off the floor when you're doing 30, 40, 50 bucks a day on these things, it's just not worth it. But it really depends. I've seen some places and I've recommended expansions at some of our clients, but most of them, if you've got a couple banks of these, you're in pretty good shape.
    Nick Hogan:	
    Yeah. Okay, cool. Well, thank you so much to that listener for that question, really appreciate it. And so now Don, I think we can introduce today's guest. So as a person with whom Don and I have actually worked pretty closely over the years and it's time as a Louisiana native and a graduate of Washington University in St. Louis, and began his gaming career around 20 years ago as Ameristar, climbed the ranks up to VP of operational Excellence with Pinnacle following their Ameristar acquisition. He then moved to Penn Entertainment where after serving as VP of Casino Ops and eventually AGM of Hollywood Columbus, he spent roughly five years as VP of Casino Ops for Penn Entertainment there based in [inaudible 00:07:48] missing. From there, he did a very brief pre-opening stint as SVP of Casino Ops at Fontainebleau Las Vegas. And these days serves as vice president of gaming operates for Bally's Corporation.
        In addition, he loves professional soccer as a devoted Liverpool Reds fan and has given us explicit instructions to avoid all questions regarding their performance this season. So I'm talking of course about Mr. Dan Cherry and Dan, how about those Reds man?
    Dan Cherry:
    	What can you say, Nick? It was a promise, but it's good to see both of you guys this morning. Thanks for having me on.
    Nick Hogan:	
    Yeah, thanks. It's great to see you again. So Dan, I thought we could just kind of pick up, just start talking about, get a quick summary of your gaming career. Where'd you start? What was the path that took you to heading up gaming Ops for Bally's.
    Dan Cherry:	
    Yeah, definitely. And thanks for the intro. As I hear you say it sounds strange to hear that it's 20 plus years now, but it's true. As you said, I went to school in St. Louis and I stuck around that city for a couple of years. I know that's a place that's near and dear to Don's heart. And I kind of had my first role in gaming on a whim. I found a financial planning and analysis role at a company called Ameristar, which was a small five property casino company at the time with a property in St. Charles Missouri, which is just outside St. Louis. Ameristar had taken over operations from stations a couple of years before. And looking back, I think I really lucked out in a couple ways. I don't think I realized the time, but Ameristar just had a great culture and was a great place to cultivate and develop talent.
        They really cared about people and focused on long-term growth and developing people's careers. And frankly, if you're not going to start on the front lines, paying slot jackpots or dealing, P&A is a perfect way to get a crash course in all areas of the operation. Going through the budgeting process, the strategic planning process, there's no better way to at least get kind of a cursory understanding of all the different areas of the operations. So that was great, and frankly, I think that gave me a platform for my career. And not for the last time in my career, I was a little fortunate to have after a couple of years, somebody tap me on the shoulder and say, "Hey, there's potentially an opportunity here. And they brought me out to their corporate office in Vegas to do analysis of table games operations.
        It was a brand new role. I knew virtually nothing about tables, and I jumped right in. And fortunately, there's a lot of great folks and resources in Vegas, Bill Zender, Michael Shackleford, the Wizard of Odds and other folks. And I read and surrounded myself with experts to try to understand what are the drivers that make table games tick? And what are the ways that we can impact the operations? So that role was focused on really putting a structure in place on how do we optimize table games from the scheduling side to the yielding side, to looking at product minimums, maximums, reinvestment strategy, all these things. And I just tried to figure out where can I add value, both to drive the top line, but also improve the bottom line. That ultimately grew into a corporate director role of Tables for Ameristar. And I was out here in Vegas until, I guess it was 2011 when realizing I still after several years in gaming really had no on-the-ground operational experience.
        They asked me to move out. We had a property on the Nevada-Idaho border up on Highway US 93 called Cactus Peets Casino. Went out there as the director of operations and learned how casinos work. Put myself through dealer school up there. It's a really unique property at the time, no shoe blackjack, so everything was pitch double-deck. So that's how I learned how to [inaudible 00:12:14].
    Nick Hogan:	
    Okay. And that was in 2011, is that what you said?
    Dan Cherry:	
    2011, yeah. All pitch double-deck. And I don't know if either of you've ever dealt, but just holding two decks of cards in your hands and trying to pitch those is an endeavor on its own. So no better way to learn because you really learn correct process, correct policies, correct games protection. Was up there for a couple of years, great property, great mentor out there. Actually, the general manager, a woman named Sherry Summers. Actually just retired two weeks ago after a 37 year career with Ameristar. And what I loved about Ameristar was they focused on quality in all aspects of the business, whether it's product, whether it's food and beverage, whether it's service, they focused on quality, they focused on guest service. They really focused on being consistent and attentive to team member coaching and team member development.
        And not just in gaming, but I kind of feel like everywhere we all see those focuses have kind of gone by the wayside. But that focus, I think, really set me and many of the other folks that came out of our organization up to really have an attention to detail and try to be mindful of focusing on those things as we go throughout our career. And I think we are starting to see a bit of a U-turn where it's not just cut, cut, cut margins, margins, margins anymore.
        Lots of operators are starting to realize we do have to focus on growing the top line. We do have to focus on giving guests a reason to come back in on team member development, on service. So I think that attitude is starting to return. But I spent a couple more years at a couple other roles in Ameristar, and then in 2014 I moved over to Penn at our Hollywood, Columbus, Ohio property as vice president of gaming. That's where I met both you and Don. And then Penn at the time did not have a corporate gaming function, as Don knows, it was really a development role where they rotated around and gave people one year, two year gig to take the lead on corporate slots. And so really for the second time in my career, someone came to me and said, "Hey, why don't you do this thing that you know nothing about." I have no real slot knowledge. But I said yes, and I jumped in.
        And the good thing, and the great thing is there are people like Don who are incredible resources, other folks in the company who really had that on the ground knowledge to give me the time to kind of learn and get myself up to speed and figure out how does this thing work? And ultimately, that morphed into a corporate role with Penn a couple of years later. And now I'm doing the same thing with Bally's Corporation.
    Nick Hogan:
    	And I think each of the, well, the companies obviously had kind of a similar trajectory in a way, in that they really grew through acquisition. And I know that for these companies that can be, there's some real growing pains in this and that you're trying to consolidate and standardize practices and this kind of stuff. So I would assume this is a large part of what you're charged with presently at Bally's yes.
    Dan Cherry:
    	Yeah, absolutely. I mean, I think to your point, you can grow, but then what do you do once you grow? To really be able to create value, you have to figure out how do we put these things together in a way that there's a brand and there's an operation where everyone's able to leverage our size and scope, learn from each other, pull from collective resources, and ultimately generate guests loyalty because we're able to cross market because we're... And so goal one is ultimately how do we get to the point where we have a one-card program? How do we have the technology infrastructure up and running to support that so we can drive folks to destinations and build loyalty to the Bally's brand. But then from an operation side, it's how do we keep autonomy at the property level, keep those unique cultures at the individual properties, but figure out where, are there certain places that there should be a Bally's way to benefit everyone either on the product side or the operation side of the equation.
    Nick Hogan:
    	And as you step into that, because I know certainly when you were at Penn, you had a lot of these integration challenges that were just trying to bring this stuff together, trying to standardize it and all these trade-offs. How are they different? Between what you experienced at Penn versus what you're seeing now at Bally's.
    Dan Cherry:
    	I think Penn at the time was much further along in the curve. So by the time I joined Penn, I want to say Penn already had 18 properties ultimately growing up to Don, I want to say it was probably 43 at peak. So big and getting much larger. So Penn had kind of already gone through that evolution and already needed to put some of the corporate functions, some of the other things in place, some of the operational best practices in place to manage that. The really exciting thing about Bally's is Bally's today is much earlier in that process, so still much smaller our most aggressive phase of growth is probably still ahead of us. So we're still in that phase of, everyone wears a lot of hats at Bally's, right? Everyone is part of development essentially gets pulled into the various, whether it's design and construction or other development projects.
        And so the challenge right now is really balancing development focuses, design and construction focuses with operation focuses. And everyone finding the time to make sure that we're attentive to the business and trying to figure out how to create that culture. But I would say the other thing is team member development period. So one of the great things that I remember about Penn that Don knows as well is Penn had a very detailed specific process on building bench strength and developing talent depth and giving people specific career paths. And it was a very detailed program every year. And if you were a leader at the property, going through your team and identifying who, not just that next person up, but the person behind that person as well.
        And that's where Bally's is right now, is realizing we have to focus on those same kinds of things over the next few years so that when a Chicago permanent casino opens up, or when a Bally's Las Vegas or some other property opens up, not just we have the person to fill those key roles, but as we're moving people from property to property, it's not hindering that property because there's someone ready to step up. So I know that's going to be a huge focus for Bally's over the next several years, is really making sure there's formal development processes in all areas of the operation to make sure we're able to grow and not hindered, because we simply don't have the depth talent to do it.
    Nick Hogan:
    	Right. And it's always an interesting, we have this conversation at RealMetrics a lot, which is about these management models for the multi-jurisdictional operators. So to what extent do you want to centralize versus localize and all these trade-offs and culture and whatnot? And I have to say, we've worked with hyper centralized organizations that are quite large and some that are almost entirely distributed. And I don't think any of us really has a strong opinion on this, really, which one works. So I'm sure you're navigating all this now, no doubt. So do you lean in one direction on this stuff or is it really just more functionally dependent for you?
    Dan Cherry:
    	Well, I mean, like many of us, I worked at properties for many years. So the last thing I would want is if I was at a property all of a sudden would be A, for my autonomy to taken away, but B, for the things that I enjoy working on to just be taken away and not be part of the process. I know Don was in similar roles and has a similar viewpoint on that. At the same time, there's no point having a large company if you're not going to take advantage of that size and scale and figure out how to use it to your advantage.
        So I think you're probably referring to product more than anything else. When it comes to product, I think it's irresponsible not to try to be more coordinated, to try to be more standardized. I think the challenge and the goal is, how do you do that in a way that folks at the properties still feel the job is fun, still feel like they're on the ground, bringing the knowledge of what my specific players want, what I'm seeing on the ground, what I would like to do, what's unique about my property?
        And so I don't have the answer. I mean, I don't think anyone has the answer, but I think you have to do it slowly and you have to do it in a way that it's correctly balanced. And ultimately at the end of the day, what I would love to do is figure out a way to arm the properties with the structure, with the technology, with the information so that we're kind of all reading from the same hymn sheet. If we can agree on what are the metrics, what are the KPIs, what are the things that we're using to make decisions, then whether it's me coming up with the idea or a property coming up with the idea, nine times out of 10 we should be coming up with the same idea. And then it's just a conversation on, okay, how do we implement it? So that's the goal. That's where we're trying to get to. And the challenge is how do we put the structure in place to ultimately get there? And I think most large organizations, multi-properties probably think about it in a similar way and are successful to different degrees.
    Nick Hogan:
    	For sure. For sure. And yeah, it's kind of interesting in your background. I hadn't thought about this before, but it was one of those where Ameristar always seemed to be a very centralized company in that their execution was just so consistent from market to market. And you go into an Ameristar casino, I remember, they were kind of first really building up and you knew you were in Ameristar Casino, it was just very consistent standards where we're very sharp, et cetera, et cetera. And then Penn was far more decentralized organization, I think it's a lot in the philosophy, so it's kind of an endlessly fascinating topic for us, is this debate always which one really is the best. And I have to say, there are serious merits in each model. At least that's been our experience.
    Dan Cherry:
    	Yeah, absolutely.
    Nick Hogan:	
    So Bally's has obviously been in the news quite a bit lately and specifically in two cities that caught a lot of headlines. The first is, what you all are doing in Chicago and then what's going on in Las Vegas? I was wondering if you could maybe bring us up to speed as to both of these projects in these metro markets?
    Dan Cherry:
    	Absolutely, Nick. So in Chicago, we were awarded the first and the only casino license for the city of Chicago proper. And we opened our temporary casino, which is in the North Loop right downtown at a site called the Medina Temple, which is a historic three-story building their beautiful building that we retrofitted and opened-
    Nick Hogan:
    	Beautiful place.
    Dan Cherry:
    	Yeah. In September of 2023. And it's doing great. It's growing every month, month over month in terms of visitation numbers, which are incredible. And in terms of converting that to gaming. And we continue to set month over month records in terms of our performance. A couple unique things, so we're very happy and unique from, you would think that because there's been gaming in Chicagoland for many years that Chicagoland the Chicagoland, but or kind of realizing is the city core itself was really so insulated from gaming that it's almost like a new market in a way.
        And so what we're seeing is it's much more table games focused than the rest of Chicagoland. We're also seeing it's a newer gamer and a much younger gamer in general. And so table games is great slot's growing, but we're realizing it's slightly different mix of slot product than elsewhere in the market. And so we're tweaking that, we're optimizing it, and as we do that, we're confidence going to continue to grow. But as I said, visitation rates are amazing, so for us it's really about a continuing to educate the local population that, A, we're here. There's now gaming within the city proper. And B, all these people who are coming into trialing it, figuring out how to activate, how to bring them into our database that we can really use this as the platform for our permanent casino.
        And our permanent is also going to be downtown in the West Loop on the Chicago River, the site of the Tribune Publishing plant that's being relocated. And we're due to break ground on that in later this year as well. That property is going to have 4,000 gaming positions. It's going to start with a boutique hotel growing to up to 500 rooms, lots of unique food and beverage, entertainment, cultural content. It's going to a beautiful river walk and parkland outside. So we're very excited. That's due to open late 2026. And then-
    Nick Hogan:
    	Okay. All right.
    Dan Cherry:
    	And then I think you asked about Las Vegas as well. So we permanently shuttered the Tropicana at the beginning of April. So April 2nd, that property closed and we're preparing for demolition of the site later this year. We've announced we're targeting September, October for that in preparation for what comes, which will be a major league ballpark targeting 2028 opening day. And we're also finalizing plans on what resort looks like on that site as well. So still working through design and construction details will be announced soon, but very excited about getting started on that product as well, which obviously will be transformative for the company.
    Nick Hogan:
    	Oh yeah, it'll be great to see some development going on in that particular intersection. It's been a while since something's gone on down there, so it'll be really nice to do that up. I'm sure you have some folks in Oakland that aren't so pleased with you, but after taking the Raiders, now the A's.
    Dan Cherry:
    	So what can you say?
    Nick Hogan:
    	I've been hogging the mic here, Don-
    Dan Cherry:
    	[inaudible 00:27:55] but yeah.
    Nick Hogan:
    	Indeed. So Don, sorry, I've been hogging the mic here, so I wanted to give you an option or opportunity to get some questions in here to Dan.
    Don Retzlaff:
    	Sure. Good morning, Dan. How are you?
    Dan Cherry:	
    Great, Don. Always good to see you.
    Don Retzlaff:
    	Good to see you too. One of the things I always think about when we worked together was how well you negotiated with the manufacturers and the great deals that you got for us. All thought reduce prices for our lease games, for our CapEx purchases, conversions, things like that. What was one of the biggest challenges when you're negotiating with these manufacturers? Was it that they all wanted a bigger share or what was the biggest obstacle that getting these contracts done?
    Dan Cherry:	Well, thanks Don, and first of all, don't want to advertise in case there's any suppliers listening that our pricing is good. Our pricing is terrible in case they're listening. Can always be better. But I think it's a great question and I think there's a couple of things. The first big one I would point to is really probably what I think of as silos, Don, both on the operator side for anyone who's worked at a large casino company, especially if you're in many different states or if you have a brick and mortar, an online business, you're figuring out how to operate as one. But that same effect happens on the supplier side as well. As you all know, when you have large multinational companies that sell slot machines, leased slot machines, or in the table games business or in the table games, hardware business, have online digital or sports betting business, a system business, you look at the big three suppliers, you now have IGT who it looks like is going to add a robust FinTech business to that.
        So the challenge is, on our side figuring out strategically what do we want in all of these areas, but also figuring out how do we have that conversation with the suppliers because they'll be the first to say they're very siloed, as I said. And being able to have a conversation about all those things at once, which is ultimately where you want to get to, can become very difficult and very challenging and pushing to do that, ask people to come out of their comfort zone quite a bit or to disrupt the organizational structure and put deals together in a way that they're not used to. I don't think it's from a lack of desire from anybody. That's the goal. Ultimately, suppliers want to leverage all these tentacles and all these different aspects of their business, but still trying to figure out how to do it. So I think that's the one big thing.
        And then the second thing I would say is just lack of clarity and lack of visibility to what the long-term trends are going to be. So I always when I put a deal together, want it to be strategic, want it to be long-term and don't want it to be something that we spend a little time and effort and leverage in putting together this year and exert it, expended a lot of capital and then we have to come right back to the table next year. So trying to do things in a way that they're measured that we can figure out, hey, is this something we can repeat next year? Is your product going to be at the same par that you're going to be able to deliver, whether it's on the core side or the premium side, the same amount of content. Because nobody wants to go back and renegotiate and renegotiate and renegotiate.
        We want to put these deals in place that work for everybody. That makes sense that I value for everybody so we can focus on more strategic things. But that's the challenge just with how quickly trends are changing to do that and feel comfortable that it's going to work.
    Don Retzlaff:	
Well. You mentioned the game performance. How much weight do you place on game performance when you're doing these negotiations for both basically cabinets? Because obviously it's tough to predict theme performance in the future, but how much weight do you put on, how well they're doing on the casino floors right now?
    Dan Cherry:	
    I think it's the most important thing, Don, at least in my mind. If we're going to buy a game, that game has to work for 10 years. We know it's going to tail off, but we have to generate revenue out of that game for a long time. And when it comes to the premium side of the equation, if you're going to put a game out there, it's disruptive to everyone if that game's not going to work. It's disruptive to the operations team to convert it. It's very expensive for the supplier to put a game out and then take it back. So I would say it's the most important thing and really it's the primary driver. You can impact, and I've seen the work that you all have done around this, you can impact the top line and the bottom line so much more on the revenue side than you can on the cost side.
        So the impact you're going to have by picking the right cabinets, picking the right themes, tweaking them, doing the right conversion is going to dwarf what you can do by saving a dollar. I would never buy a game simply because it's cheap. So I think the goal is, what's the right product? And then how do we get the right deal for that right product?
    Nick Hogan:	
    And that philosophy is just like if we rewind, let's say 15 years ago and think about how we thought about it then this notion that we have revenue levers suddenly that are actually pretty relatively precise. This is kind of a new thing that people are realizing that it's really refreshing to hear an operator say this, that you're so much more impactful on their website than it's on the cost side. I think it's a very healthy perspective to have.
    Dan Cherry:	
    It's a great time to be in slots and in gaming, Nick. I mean I think we've seen this really, it was really the Lightning link revolution that was Daniel fact check me, but I want to say that was probably eight years or so ago, eight or nine years now that that's premium content. But on the lease side, we used to think of lease as an expense. It was a necessary evil. It was product we put out there because we had to. It was more an attract feature, it was IP focused, it was brand focused. It wasn't necessarily, with some exceptions, it wasn't necessarily appealing to the core gamer. Now it's the complete opposite. Now our best players and our most loyal players are hammering away on premium product. And becoming a real source of not just they need to have, it's a growth driver of revenue.
        And same thing on the core side, we're just seeing performance of the best core product over the last few years just really accelerate in terms of the premium that it's generating and some of it even performing it at premium level. So I mean, it becomes pretty clear that good product is important. It can't be an afterthought. It's something that actually is key to our revenue future. And I think we're seeing it in ship share numbers and reinvestment numbers. When you look at a lot of data, you see this upward trajectory year over year in premium footprints, but also in core replenishment rate. So right now it's a win-win as long as these trends continue.
    Don Retzlaff:	
    Yeah, yeah. We're seeing a lot more of our host-level players playing at a higher average bet on these new games. And every year the average bet keeps going up and up and up as the manufacturers have realized that's how they sell more games is to get the host-level players to increase their average bet. And we just finished a study last week. We're seeing our host-level players, our A-level players, their average bets are twice the machine average to where if the machine's getting an average of $2 a spin, their host-level players are playing four, four and a half dollars a spin, and it's really driving the revenue per hour. And we've had some of our clients, they're ADTs for their customers that are playing these games are up $70 an hour just because they're playing more higher average bets, more spins per minute. It's a great combination, So you're right.
        The other question I had for you, Dan, on this topic was you talked earlier about that mix between property involvement and picking games and corporate involvement and picking games. What's the right combination? Do you want your properties, I guess the best way to do it is to educate the properties on how to pick the best games. Because you're always going to have turnover at the slot level, at the corporate level. What's your role in it now for helping the properties pick the games?
    Dan Cherry:	
    Don I'm brand new in my role, so I've been with Bally's since November of last year. So I think it's a little bit of a pendulum where I've come in, I'm five months in here, we need to get as Bally's a process up and running quickly that we could purchase against that we can put agreements together for 2024 that we can set a capital budget, get product out the door. So definitely there's been a shift from last year to this year in it being a little bit more centralized, I guess you say that's probably not the right word, but that's the word I'll use. But the goal is how to have that pendulum swing back a bit. So we do things as an enterprise. You said educate the properties, I think it goes both ways. We're getting feedback from properties on how effective is what we are doing?
        Is it the right product for your demographic? And taking that into account is super important. But my goal over the next couple of years is how do we bring in data science? How do we bring in AI? How do we bring in the right analytics to develop the dashboards, develop the metrics, define, how are we going to define? What is a successful cabinet? What's a successful theme? Et cetera. And make that transparent and available to the properties that in two years time, it almost doesn't matter. Is it me making decisions? Is it property making decisions come up with the same decision. But my focus is arming them with those tools to empower them and that's a process to get there for sure.
    Don Retzlaff:	
    One of the biggest things that we've seen, especially since COVID was a lot of the VPs and directors have more and more on their plates, so they have less and less time to do analytics. So they're throwing more darts at the dartboard versus doing some more detailed studies and diving into the analytics to look at what's performing well, what do they need more. Just like you talked about with Chicago, you're seeing trends in your player database and your machine performance that leads you to a different conclusion than when you started. That's always the big thing is making sure that you have enough time to do this analysis work if you don't have a slot analyst on your team, you have someone where you can spend eight hours, 12 hours a week looking at these numbers and trying to figure out what you need for your floor.
    Dan Cherry:
    	You hit the nail on the head. And I think for the most part, in most organizations, the days of everyone having that analyst just are gone. And the industry's growing so much that those folks who, there just aren't enough folks that have that expertise for every property to have one sitting on their payroll. And unfortunately, to your point, there's always more things to do. So that 12 hours a day, hopefully it's not 12, but if it is, it's not coming back. So it sounds counterintuitive, but the ultimate goal here is, we shouldn't be doing analysis. That's not our expertise, neither me or our property folks.
        What we need to figure out is how do we have the systems, how do we have the tools? How do we have the right business intelligence tools using AI, using the 15,000 data points we get every day on slot machines multiplied by every handle pull to decide what are the rules? What are the metrics? What are the KPIs? And let it do the work for us. That's the goal in a data-driven system, giving recommendations that are smart, that are looking at the big picture, managing to our objectives and us figuring out how to execute on them. That's what we're all trying to do here.
    Nick Hogan:	
    And so Dan, you've had a number of roles. Well, so obviously with Penn and now with Valleys, I mean, you're sitting over and you'd worked a great deal with us at Real Metrics in the past, and so you're kind of unique in that you've had access to large data sets for some period of time. And can you maybe walk us through how these larger samples really affected your perspectives on managing, or is it whatnot?
    Dan Cherry:	
    Yeah, yeah, it's been great and we've been super fortunate, because to your point, it was 45 to 50,000 machines at Penn. At Bally's, we have 15,000 machines in our database. And I think really what it lets you do is it opens up insights for you that you don't have the luxury of seeing with small data sets. So just a couple examples. One thing that you talk about all the time is premium footprint, and does it make sense to spend more or expand premium? And the fear at a smaller property is, well, that makes sense to me, but how do I know it's not a mistake? And what having these large data sets lets you do is test these things on a small scale with confidence, so then you can expand them more broadly and know with high confidence, this is what the impact's going to be, this is the right thing to do before we're going to move on it and limit mistakes.
        The second piece is looking at studies in areas such as, one example is payback percent, hold percent, right? This is an area that's had a ton of debate in the industry. Well, because we've had such large data sets, we've been able to see with very high confidence that yes, actually there is truth to the thought that there's limits to payback percent. And in most cases, to Don's point, as multi-denom comes in and volatility increases and average bet increases, the industry has gone too far. And we can show with data that in most cases on penny, slightly lower hold percent actually performs more profitably both in the long term and the short-term. And so those are the kinds of things that we can look at and we can get those answers more quickly just because we get more data points in a month and two months and three months that a smaller organization would need to look at a longer period of time to get confidence on, "Hey, this is real." So I'd say those are two advantages for sure.
    Nick Hogan:	
    Yeah. And it's an interesting point you bring up about RTP because in slots, what's always been such a challenge, I think, for everybody is at the end of the day, it boils down to emotional resonance between the player and the product. So there has to be this emotional connection between player and product. And this is complex stuff, right? It's very hard to just say, let's pinpoint one thing like RTP. Well, if you alter the RTP to a given direction, you may completely disrupt that emotional connection between a player and product. And same with locational positioning and all this kind of stuff. And for us, that's been one of the more amazing things with large data sets as we see that in terms of trying universal RTP strategies and things of this nature, it's really dangerous because there is this intimacy down at the product level, and we almost see that this is almost product dependent.
        Where is the optimum for RTP? Where is it for line count, et cetera, et cetera. We find that it really is all the way down in the individual product level. That's an incredible thing that we see and really helps to tease out just how complex some of these products are and those chips with the people playing. So I'm sure you've seen some of this stuff too, and over the years when you looked at this, it's like some of these strategies we used to take like, okay, this entire floor is a 8% holder or 12% holder or whatever. I mean, what we've seen is that's pretty dangerous to do that.
    Dan Cherry:	
    And payback is just an example. But I think the same example can be applied to a lot of scenarios here where people can say, yeah, that sounds right to me, but I'm scared to do that because what happens if that has a bad impact? And the challenge has just been folks haven't had the data at their fingertips or the analytical tools to be able to answer those questions, which ultimately come down to, am I making money or am I moving money? And the exciting thing is now there's so many folks that are providing those tools. They're out there, we don't have to have this guessing game anymore. We can actually act with confidence and trust that the data will guide us and we'll move beyond this and be making decisions that are right for the player. So it's a great time.
    Don Retzlaff:	
    Yeah. We did a presentation for one of the manufacturers last summer where we charted every single game on every single floor and showed them why some of their games work, whether it was line count based, whole percentage based, location based, they all had commonalities to it. But the only way we were able to do that is we looked at hundreds of placements across our clients and we were able to show them that if you put this game at 8% and stuck it at a hundred lines and put it in an A or B location, it worked. If you did anything else with it, it didn't. And the only reason we were able to do that is we had that big data that you mentioned instead of looking at five properties, we looked at a hundred different properties. And it's really striking, as Nick said, it's really different for each different theme.
        Some games can support a 12% hold, and then you've got other games that have a $5 average bet on a penny games. Why in the world would you put that at 12% hold? It gets back to the win per spin days. In fact, when before you had video, as you put your dollars at this full percentage reporters at this, because you know what your average pets were. Now we've got a lot of penny games out there that have average pets north of $3 a spin, and you don't really have to have those games at 12 and 14%. Those are really good games.
    Dan Cherry:	
    Yeah, 100%. Couldn't agree more.
    Nick Hogan:	
    So Dan in term's of... So what are some things that you're excited about coming down the pipeline for the slot industry over the next couple of years? What's got you intrigued?
    Dan Cherry:	
    Nick, for me and for Bally's, I think it's AI without a doubt, but more importantly, how can we use AI to personalize the player experience and what's that technology that we're going to bring forward to leverage AI to bring everything to the player? So whether it's via app, from enrollment process to cardless process, to getting them offers in a more time and more personalized basis. To communication with host to payments, while it's definitely a part of that conversation, to ultimately figuring out how do we bring and bridge the gap between brick and mortar and digital. So it's really everything, AI and exactly what form that takes, but that's why at Bally's, we're just hyper focused on app development, on app adoption, and figuring out how we can bring more of these features that have traditionally relied on big pieces of hardware, a lot of labor, reducing that friction and bringing them more towards the players. And everyone's focused on this, but I think you're really going to see a revolution very quickly and mass adoption on some of this functionality in really low friction waves being available in a more widespread basis.
    Nick Hogan:	
    Mm-hmm. So any events or anything coming up, Dan, that you'd like to promote? Any speaking engagements or major Valley initiatives or anything else that's out there that you'd like to plug a bit?
    Dan Cherry:	
    No, Nick, we're just focused on all our development projects. Like I said, really excited about Chicago, really excited about the future of that Trop land, and then other stuff in the pipeline that hopefully we can announce coming to fruition soon.
    Nick Hogan:	Okay. Yeah. Very good. And Don, do you have any more questions for Dan today?
    Don Retzlaff:	
    No, not at all. It was always a pleasure to work with Dan. I got to know Dan, obviously working at Penn for those years. And I put Dan on the Mount Rushmore of VPs at Casino Ops. He was so good and the stuff that he did for us was just amazing and so inclusive and so educational. And not only did I enjoy working with him, I also learned from him, which was so cool. And he gave us the opportunity to learn things and gave us assignments to grow us, and it was just always a pleasure to work with him. And so we should nothing but the best at Bally's and I know you got a really good team there. I actually know some of the people at the corporate level, at the property levels, and just a very solid team. I know you'll do great things there.
    Dan Cherry:	
    Oh, thanks so much, Don.
    Nick Hogan:	
    Yeah, and I would echo that as well. I've only never known Dan as always as a customer, and he was always a pleasure because although he'd beat you up a little bit, you always had conversations with Dan. So this was something that I always appreciated is that he'd take your perspective into consideration. I always had really constructive dialogue with him, so it was always a pleasure. So, Dan we're making your ears red here are we?
    Dan Cherry:	
    You guys are kind. Thank you so much. It's always been a pleasure, and I really appreciate talking to you both today.
    `,


};

export default S03E04;
