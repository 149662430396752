import React from "react";
import ReactGA from "react-ga";
import { NavLink } from "react-router-dom";

const Legal = () => {
    ReactGA.pageview(window.location.pathname);

    return (
        <div className="text-page">
            <div className="box box--light">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-offset-1 col-sm-10">
                            <h1 className="title title--lg title--primary">
                                Legal
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="box">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-offset-1 col-sm-10">
                            <h2 id="terms-of-use">Terms of Use</h2>
                            <p>LAST UPDATED: February 8, 2018</p>
                            <p>
                                This website (&ldquo;Site&rdquo;) is owned and
                                operated by RM Holdings B.V. and ReelMetrics
                                B.V. (collectively, &ldquo;ReelMetrics&rdquo;).
                                These Terms of Use (&ldquo;Terms of Use&rdquo;)
                                contain the terms, covenants, conditions, and
                                provisions upon which you (also referred to
                                herein as &ldquo;User&rdquo;) may access and use
                                this Site and the content, including (without
                                limitation) the descriptions, opinions, and
                                other materials, tools, products, services,
                                publications and information (collectively, the
                                &ldquo;Materials&rdquo;) displayed on the Site.
                                REELMETRICS RESERVES THE RIGHT TO CHANGE THE
                                TERMS AND CONDITIONS OF THESE TERMS OF USE UPON
                                NOTICE, WHICH MAY BE GIVEN BY REELMETRICS
                                POSTING SUCH CHANGE (OR REVISED TERMS OF USE) ON
                                THE SITE, BY E-MAIL, OR ANY OTHER REASONABLE
                                WAY. IF A CHANGE IS NOTIFIED BY A POSTING ON THE
                                SITE, IT SHALL BE DEEMED TO TAKE EFFECT WHEN
                                POSTED; IF A CHANGE IS NOTIFIED BY E-MAIL, IT
                                SHALL BE DEEMED TO TAKE EFFECT WHEN THE E-MAIL
                                IS SENT; AND IF A MODIFICATION IS NOTIFIED IN
                                ANY OTHER WAY, IT SHALL BE DEEMED TO TAKE EFFECT
                                WHEN THE RELEVANT NOTICE IS SENT OR ISSUED BY OR
                                ON BEHALF OF REELMETRICS. YOUR CONTINUED USE OF
                                THIS SITE FOLLOWING NOTICE OF SUCH MODIFICATIONS
                                WILL BE CONCLUSIVELY DEEMED ACCEPTANCE OF ANY
                                CHANGES TO THESE TERMS OF USE. YOU AGREE THAT
                                NOTICE OF CHANGES TO THESE TERMS OF USE ON THE
                                SITE, DELIVERED BY EMAIL, OR PROVIDED IN ANY
                                OTHER REASONABLE WAY CONSTITUTES REASONABLE AND
                                SUFFICIENT NOTICE.
                            </p>
                            <p>
                                These Terms of Use shall apply to any
                                ReelMetrics mobile application or ReelMetrics
                                content on third-party social media services
                                (e.g., a ReelMetrics Facebook® or LinkedIn® page) that reference these Terms of
                                Use. In such instances, the term
                                &ldquo;Site&rdquo; shall include the applicable
                                mobile application or ReelMetrics content
                                whenever that term is used herein.
                            </p>
                            <p>
                                By using this Site, you acknowledge that you
                                have read these Terms of Use, understand them,
                                and agree to be bound by their terms and
                                conditions. You also hereby acknowledge that you
                                have read and understand the Privacy Policy and
                                consent to ReelMetrics use of your Personal Data
                                set out therein (including, with respect to
                                Users resident in the European Union, the
                                transfer of your Personal Data outside the
                                European Economic Area). If you do not agree to
                                these Terms of Use or the Privacy Policy, you
                                shall neither access nor use this Site. If you
                                register with this Site, the user agreement you
                                enter into as part of that registration process
                                will, rather than these Terms of Use, govern
                                your use of this Site.
                            </p>

                            <h3>Terms and Conditions</h3>
                            <ol>
                                <li>
                                    <p>
                                        <b>Grant of License.</b> These Terms of
                                        Use provide you with a personal,
                                        revocable, non-exclusive,
                                        non-transferable license to use the Site
                                        conditioned on your continued compliance
                                        with the terms and conditions of these
                                        Terms of Use. You may, on an occasional
                                        and irregular basis, print and download
                                        Materials on the Site solely for
                                        personal and non-commercial use, or,
                                        only to the extent permitted by an Other
                                        Agreement (defined below), for internal
                                        business use, provided that you do not
                                        obscure, alter, remove or delete any
                                        copyright or other proprietary notices
                                        contained in such Materials. With the
                                        exception of the foregoing and except as
                                        otherwise expressly permitted herein,
                                        you may not modify, create derivatives
                                        of, copy, distribute, broadcast,
                                        transmit, reproduce, publish, license,
                                        transfer, sell, mirror, frame,
                                        &ldquo;deep link&rdquo;,
                                        &ldquo;scrape&rdquo;, data mine, or
                                        otherwise use any information or
                                        material obtained from or through this
                                        Site. Further, you may not post any
                                        content from this Site to forums,
                                        newsgroups, list serves, mailing lists,
                                        electronic bulletin boards, or other
                                        websites, without the prior written
                                        consent of ReelMetrics. You warrant to
                                        ReelMetrics that you will not use this
                                        Site for any purpose that is unlawful or
                                        prohibited by these Terms of Use,
                                        including but not limited to attempting
                                        or actually (i) disrupting, impairing or
                                        interfering with this Site, (ii)
                                        collecting any information about other
                                        users of this Site, including passwords,
                                        accounts or other information, or (iii)
                                        systematically extracting data contained
                                        on this Site to populate databases for
                                        internal or external business use.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <b>Intellectual Property Rights.</b> All
                                        Materials contained on the Site, unless
                                        otherwise indicated, are protected by
                                        law including, but not limited to,
                                        United States copyright, trade secret,
                                        and trademark law, as well as other
                                        state, national, and international laws
                                        and regulations. The Site, its content,
                                        layout and design are the exclusive
                                        property of ReelMetrics or its licensors
                                        and, except as expressly provided
                                        herein, ReelMetrics does not grant any
                                        express or implied right in any such
                                        Materials to you. In particular and
                                        without limitation, ReelMetrics owns the
                                        copyright in the Site as a collective
                                        work and/or compilation, any and all
                                        databases accessible on the Site, and in
                                        the selection, coordination,
                                        arrangement, and enhancement of the
                                        content of the Site. ReelMetrics and all
                                        other names, logos, and icons
                                        identifying ReelMetrics and/or
                                        ReelMetrics products and services are
                                        proprietary marks of ReelMetrics or its
                                        licensors. Third-party trademarks
                                        displayed on the Site are the property
                                        of their respective owners.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <b>Privacy Policy.</b> Please see
                                        ReelMetrics Privacy Policy for a summary
                                        of ReelMetrics personal data collection
                                        and use practices with respect to the
                                        Site.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <b>Password Policy.</b> Your use of
                                        certain portions of the Site requires a
                                        password. As part of the registration
                                        process, you must select a member name
                                        and password and provide ReelMetrics
                                        with accurate, complete and up-to-date
                                        information. Anyone with knowledge of
                                        your password can gain access to the
                                        restricted portions of the Site and the
                                        information available to you.
                                        Accordingly, you must keep your password
                                        secret. By agreeing to these Terms of
                                        Use, you agree to be solely responsible
                                        for the confidentiality and use of your
                                        respective password, as well as for any
                                        communications entered through this Site
                                        using your password. You will also
                                        immediately notify ReelMetrics if you
                                        become aware of any loss or theft of
                                        your Password or any unauthorized use of
                                        your password. ReelMetrics reserves the
                                        right to delete or change a password at
                                        any time and for any reason.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <b>Assumption of Risk.</b> You use the
                                        Internet solely at your own risk and
                                        subject to all applicable local, state,
                                        national, and international laws and
                                        regulations. While ReelMetrics has
                                        endeavored to create a secure and
                                        reliable website, please be advised that
                                        the confidentiality of any communication
                                        or material transmitted to/from this
                                        Site over the Internet cannot be
                                        guaranteed. Accordingly, ReelMetrics is
                                        not responsible for the security of any
                                        information transmitted via the
                                        Internet, the accuracy of the
                                        information contained on the Site, or
                                        for the consequences of any reliance on
                                        such information. ReelMetrics and
                                        ReelMetrics has no liability for
                                        interruptions or omissions in Internet,
                                        network or hosting services. You assume
                                        the sole and complete risk of using the
                                        Site.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <b>Enforcing Security.</b> Actual or
                                        attempted unauthorized use of any of the
                                        Site may result in criminal and/or civil
                                        prosecution. For your protection,
                                        ReelMetrics reserves the right to view,
                                        monitor, and record activity on the Site
                                        without notice or further permission
                                        from you, to the fullest extent
                                        permitted by applicable law. Any
                                        information obtained by monitoring,
                                        reviewing, or recording is subject to
                                        review by law enforcement organizations
                                        in connection with the investigation or
                                        prosecution of possible criminal
                                        activity on any of the Site. ReelMetrics
                                        will also comply with all court orders
                                        involving requests for such information.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <b>Links to Other Websites.</b>{" "}
                                        ReelMetrics may provide links, in its
                                        sole discretion, to other websites on
                                        the World Wide Web for your convenience
                                        in locating related information and
                                        services. These websites have not
                                        necessarily been reviewed by ReelMetrics
                                        and are maintained by third parties over
                                        which ReelMetrics exercises no control.
                                        Accordingly, ReelMetrics and its
                                        directors, officers, employees, agents,
                                        representatives, licensors and suppliers
                                        (together, the &ldquo;ReelMetrics
                                        Parties&rdquo;) expressly disclaims any
                                        responsibility for the content, the
                                        accuracy of the information, the quality
                                        of products or services provided by or
                                        advertised on and/or software downloaded
                                        from these third-party websites.
                                        Moreover, these links do not imply an
                                        endorsement of any third party or any
                                        website or the products or services
                                        provided by any third party.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <b>
                                            Events Beyond ReelMetrics&rsquo;
                                            Control.
                                        </b>{" "}
                                        You expressly absolve and release
                                        ReelMetrics from any claim of harm
                                        resulting from a cause beyond their
                                        control, including, but not limited to,
                                        the failure of electronic or mechanical
                                        equipment or communication lines,
                                        telephone or other interconnect
                                        problems, computer viruses, unauthorized
                                        access, theft, operator errors, severe
                                        weather, earthquakes, or natural
                                        disasters, strikes or other labor
                                        problems, wars, terrorism or
                                        governmental restrictions.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <b>DISCLAIMERS. </b> ReelMetrics obtains
                                        all Materials furnished on the Site from
                                        sources believed by it to be accurate
                                        and reliable. You expressly agree that
                                        (a) the product descriptions,
                                        performance rankings, product
                                        configuration optima and other opinions
                                        provided via the Site are, and will be
                                        construed solely as, statements of
                                        opinion and not statements of current or
                                        historical fact as to product quality or
                                        worthiness; procurement, operational or
                                        financial advice; recommendations
                                        regarding decisions to purchase, retain,
                                        sell, trade, modify, hold or sell any
                                        assets; endorsements of the accuracy of
                                        any of the data or conclusions, or
                                        attempts to independently assess or
                                        vouch for the financial condition,
                                        operational or marketing practices or
                                        output quality of any company; (b) the
                                        opinions provided via the Site do not
                                        take into account your or your
                                        organization&rsquo;s personal
                                        objectives, operational situations or
                                        needs; (c) each opinion provided by the
                                        Site will be weighed, if at all, solely
                                        as one factor in any decision made by or
                                        on behalf of you; and (f) you will
                                        accordingly, with due care, make your
                                        own study and evaluation of each
                                        opinion. For the avoidance of doubt,
                                        ReelMetrics Materials and opinions may
                                        also include quantitative model-based
                                        estimates of product performance and
                                        related opinions or commentary published
                                        by ReelMetrics. Further, you expressly
                                        agree that any tools or information made
                                        available on the Site are not a
                                        substitute for the exercise of
                                        independent professional judgment and
                                        expertise.
                                    </p>
                                    <p>
                                        ReelMetrics adopts necessary measures so
                                        that the information it uses in
                                        rendering its opinions is of sufficient
                                        quality and from sources ReelMetrics
                                        considers to be reliable, including,
                                        when appropriate, independent
                                        third-party sources. However,
                                        ReelMetrics is not an auditor and cannot
                                        in every instance independently verify
                                        or validate information received in its
                                        analytical processes or in preparing
                                        ReelMetrics Materials made available on
                                        the Site. Because of the possibility of
                                        human or mechanical error as well as
                                        other factors, the Site and all related
                                        Materials are provided on an &quot;AS
                                        IS&quot; and &ldquo;AS AVAILABLE&rdquo;
                                        basis without representation or warranty
                                        of any kind, and THE REELMETRICS PARTIES
                                        MAKE NO REPRESENTATION OR WARRANTY,
                                        EXPRESS OR IMPLIED, TO YOU OR ANY OTHER
                                        PERSON OR ENTITY AS TO THE ACCURACY,
                                        RESULTS, TIMELINESS, COMPLETENESS,
                                        MERCHANTABILITY, FITNESS FOR ANY
                                        PARTICULAR PURPOSE WITH RESPECT TO THE
                                        SITE OR ANY RELATED MATERIALS.
                                        ReelMetrics makes no representation or
                                        warranty that any Materials on the Site
                                        are appropriate or available for use in
                                        any particular locations, and access to
                                        them from territories where any of the
                                        contents of this Site are illegal is
                                        prohibited. If you choose to access this
                                        Site from such locations, you do so on
                                        your own volition and are responsible
                                        for compliance with any applicable local
                                        laws, rules and regulations. ReelMetrics
                                        may limit the Site&rsquo;s availability,
                                        in whole or in part, to any person,
                                        geographic area or jurisdiction we
                                        choose, at any time and in our sole
                                        discretion. You agree and acknowledge
                                        that no oral or written information or
                                        advice given by ReelMetrics or any of
                                        its employees or agents in respect to
                                        the Site shall constitute a
                                        representation or a warranty unless such
                                        information or advice is incorporated
                                        into these Terms of Use by a written
                                        agreement. FURTHER, THE MATERIALS, MADE
                                        AVAILABLE ON THIS SITE MAY INCLUDE
                                        INACCURACIES OR TYPOGRAPHICAL ERRORS,
                                        AND THERE MAY BE TIMES WHEN THIS SITE OR
                                        ITS contentS ARE UNAVAILABLE. MOREOVER,
                                        REELMETRICS MAY MAKE MODIFICATIONS
                                        AND/OR CHANGES TO THE SITE OR TO THE
                                        MATERIALS DESCRIBED OR MADE AVAILABLE ON
                                        THE SITE AT ANY TIME, FOR ANY REASON.
                                        YOU ASSUME THE SOLE RISK OF MAKING USE
                                        AND/OR RELYING ON THE MATERIALS MADE
                                        AVAILABLE ON THE SITE.
                                    </p>
                                    <p>
                                        TO THE EXTENT PERMITTED BY LAW, THE
                                        REELMETRICS PARTIES DISCLAIM LIABILITY
                                        FOR ANY INDIRECT, SPECIAL,
                                        CONSEQUENTIAL, OR INCIDENTAL DAMAGES
                                        WHATSOEVER ARISING OUT OF OR IN
                                        CONNECTION WITH YOUR ACCESS TO OR USE OF
                                        THIS SITE AND ITS content, EVEN IF ANY
                                        OF THE REELMETRICS PARTIES HAS BEEN
                                        ADVISED OF THE POSSIBILITY OF DAMAGES,
                                        INCLUDING, BUT NOT LIMITED TO:
                                    </p>
                                    <p>
                                        (i) COMPENSATION, REIMBURSEMENT OR
                                        DAMAGES ON ACCOUNT OF THE LOSS OF
                                        PRESENT OR PROSPECTIVE PROFITS;
                                    </p>
                                    <p>
                                        (ii) EXPENDITURES, INVESTMENTS OR
                                        COMMITMENTS, WHETHER MADE IN THE
                                        ESTABLISHMENT, DEVELOPMENT OR
                                        MAINTENANCE OF BUSINESS REPUTATION OR
                                        GOODWILL;
                                    </p>
                                    <p>
                                        (iii) ANY LOSS OR DAMAGE ARISING WHERE
                                        THE RELEVANT FINANCIAL INSTRUMENT IS NOT
                                        THE SUBJECT OF A PARTICULAR REELMETRICS
                                        OPINION;
                                    </p>
                                    <p>(iv) LOSS OF DATA;</p>
                                    <p>(v) COST OF SUBSTITUTE MATERIALS;</p>
                                    <p>(vi) COST OF CAPITAL;</p>
                                    <p>
                                        (vii) THE CLAIMS OF ANY THIRD PARTY, OR
                                    </p>
                                    <p>
                                        (viii) ANY SUCH DAMAGES ARISING OUT OF
                                        OR IN CONNECTION WITH ANY OTHER REASON
                                        WHATSOEVER.
                                    </p>
                                    <p>
                                        TO THE EXTENT PERMITTED BY LAW, THE
                                        REELMETRICS PARTIES DISCLAIM LIABILITY
                                        FOR ANY DIRECT OR COMPENSATORY DAMAGES
                                        CAUSED TO ANY PERSON OR ENTITY,
                                        INCLUDING BUT NOT LIMITED TO BY ANY
                                        NEGLIGENCE (BUT EXCLUDING FRAUD, WILLFUL
                                        MISCONDUCT OR ANY OTHER TYPE OF
                                        LIABILITY THAT, FOR THE AVOIDANCE OF
                                        DOUBT, BY LAW CANNOT BE EXCLUDED) ON THE
                                        PART OF, OR ANY CONTINGENCY WITHIN OR
                                        BEYOND THE CONTROL OF, THE REELMETRICS
                                        PARTIES ARISING FROM OR IN CONNECTION
                                        WITH YOUR USE OF THIS SITE AND ITS
                                        contentS.
                                    </p>
                                    <p>
                                        THE USER MUST USE ALL REASONABLE
                                        ENDEAVORS TO MITIGATE ANY LOSS OR DAMAGE
                                        WHATSOEVER (AND HOWSOEVER ARISING) AND
                                        NOTHING IN THESE TERMS OF USE SHALL BE
                                        DEEMED TO RELIEVE OR ABROGATE THE USER
                                        OF ANY SUCH DUTY TO MITIGATE ANY LOSS OR
                                        DAMAGE.
                                    </p>
                                    <p>
                                        IN ANY EVENT, TO THE EXTENT PERMITTED BY
                                        LAW, THE AGGREGATE LIABILITY OF THE
                                        REELMETRICS PARTIES FOR ANY REASON
                                        WHATSOEVER RELATED TO ACCESS TO OR USE
                                        OF THIS SITE AND ITS contentS SHALL NOT
                                        EXCEED THE GREATER OF (A) THE TOTAL
                                        AMOUNT PAID BY THE USER FOR SERVICES
                                        PROVIDED VIA THIS SITE PURSUANT TO THESE
                                        TERMS OF USE DURING THE 12 MONTHS
                                        IMMEDIATELY PRECEDING THE EVENT GIVING
                                        RISE TO LIABILITY, OR (B) U.S. $100.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <b>Indemnity.</b> You agree to indemnify
                                        and hold harmless ReelMetrics, its
                                        licensors and suppliers, all of their
                                        affiliates, and all of their respective
                                        officers, directors, employees,
                                        shareholders, representatives, agents,
                                        successors and assigns, from and against
                                        any damages, liabilities, costs and
                                        expenses (including reasonable
                                        attorneys&rsquo; and professionals&apos;
                                        fees and court costs) arising out of any
                                        third party claims based on or related
                                        to your use of the Site or any breach by
                                        you of these Terms of Use.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <b>Submissions.</b> ReelMetrics welcomes
                                        your feedback and suggestions, including
                                        about how to improve this Site. We
                                        and/or our service providers may make
                                        available through the Site certain
                                        services to which you are able to post
                                        comments, information and/or feedback
                                        (for example, message boards, blogs,
                                        chat features, messaging and/or comment
                                        functionalities). Any ideas,
                                        suggestions, information, feedback,
                                        know-how, material, and any other
                                        content (collectively,
                                        &ldquo;Submissions&rdquo;) posted and/or
                                        received through this Site, will be
                                        deemed to include a worldwide,
                                        royalty-free, fully paid-up, perpetual,
                                        irrevocable, nonexclusive, transferable
                                        and fully sub-licensable (through
                                        multiple tiers) right and license for
                                        ReelMetrics to adopt, publish,
                                        reproduce, disseminate, transmit,
                                        distribute, copy, use, create derivative
                                        works, display, (in whole or part)
                                        worldwide, or act on such Submissions
                                        without additional approval or
                                        consideration, in any form, media, or
                                        technology now known or later developed
                                        for the full term of any rights that may
                                        exist in such Submissions. You hereby
                                        waive (a) any claim to the contrary; and
                                        (b) any &ldquo;moral rights&rdquo;
                                        associated with your Submissions. You
                                        represent and warrant that you have all
                                        rights necessary for you to grant the
                                        foregoing license, and that each
                                        Submission you provide to the Site
                                        complies with all applicable laws, rules
                                        and regulations. You are and remain
                                        responsible and liable for the content
                                        of any Submission. IF YOU DO NOT WISH TO
                                        GRANT THE RIGHTS GRANTED IN THIS
                                        SECTION, PLEASE DO NOT POST, TRANSMIT OR
                                        OTHERWISE MAKE ANY SUBMISSION. ANY
                                        SUBMISSIONS MADE ARE DONE SO AT YOUR OWN
                                        RISK. Please note, ReelMetrics does not
                                        control any of the User-submitted
                                        Submissions, they do not reflect the
                                        opinion of ReelMetrics, and ReelMetrics
                                        does not guarantee their accuracy or
                                        endorse any of the opinions expressed.
                                        The ReelMetrics Parties are not
                                        responsible or liable for (i) any
                                        Submissions, including, without
                                        limitation, any errors or omissions in
                                        such Submissions, links or images
                                        embedded therein, or results obtained by
                                        using any of the same; or (ii) any loss
                                        or damage caused by the Submissions or
                                        resulting from the use (including
                                        without limitation republication) or
                                        misuse thereof by any third party,
                                        including your reliance thereon.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <b>Export Restrictions.</b> No software
                                        or any other materials associated with
                                        this Site may be downloaded or otherwise
                                        exported or re-exported to countries or
                                        persons prohibited under relevant export
                                        control laws, including, without
                                        limitation, countries against which the
                                        United States has embargoed goods, or to
                                        anyone on the U.S. Treasury Department
                                        list of Specially Designated Nationals
                                        and Blocked Persons or the U.S. Commerce
                                        Department&rsquo;s Table of Deny Orders.
                                        You are responsible for compliance with
                                        the laws of your local jurisdiction
                                        regarding the import, export, or
                                        re-export of any such materials. By
                                        using and/or downloading any such
                                        materials from this Site, you represent
                                        and warrant that you are not located in,
                                        under the control of, or a national or
                                        resident of any such country to which
                                        such import, export, or re-export is
                                        prohibited or are not a person or entity
                                        to which such export is prohibited.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <b>Governing Law.</b> These Terms of
                                        Use, including (without limitation) any
                                        disputes relating to the content the
                                        Site, whether sounding in contract,
                                        tort, statute or otherwise, is governed
                                        by the laws of The Netherlands, without
                                        reference to its conflict of law
                                        principles and without regard to the
                                        U.N. Convention on Contracts for the
                                        International Sale of Goods.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <b>Dispute Resolution.</b> THIS
                                        PARAGRAPH CONTAINS ARBITRATION AND CLASS
                                        ACTION WAIVER PROVISIONS THAT WAIVE THE
                                        RIGHT TO A COURT HEARING OR JURY TRIAL
                                        OR TO PARTICIPATE IN A CLASS ACTION.
                                        PLEASE REVIEW CAREFULLY. Any dispute,
                                        controversy or claim arising out of or
                                        relating to these Terms of Use,
                                        including any disputes relating to the
                                        content of Site, whether sounding in
                                        contract, tort, statute or otherwise,
                                        shall be finally resolved by
                                        arbitration. Any claim must be brought
                                        in the claimant&rsquo;s individual
                                        capacity, and not as a plaintiff or
                                        class member in any purported class,
                                        collective, representative, multiple
                                        plaintiff, or similar proceeding
                                        (&ldquo;Class Action&rdquo;). The
                                        parties expressly waive any ability to
                                        maintain any Class Action in any forum.
                                        The arbitration shall be conducted by
                                        one arbitrator in English and in
                                        accordance with the Arbitration Rules of
                                        the Dutch Arbitration Institute, which
                                        shall administer the arbitration and act
                                        as appointing authority. The place of
                                        the arbitration shall be in Amsterdam,
                                        The Netherlands. The decision of the
                                        arbitrators shall be binding upon the
                                        parties hereto, and the expense of the
                                        arbitration (including without
                                        limitation the award of attorneys&rsquo;
                                        fees to the prevailing party) shall be
                                        paid as the arbitrators determine. The
                                        decision of the arbitrator may be
                                        entered by any court of competent
                                        jurisdiction. You agree to submit to the
                                        jurisdiction of the state and federal
                                        courts in Deleware for the purposes of
                                        any judicial proceedings to obtain
                                        interim relief and in aid of the
                                        arbitration or judicial proceedings to
                                        confirm or enforce the award.
                                        Notwithstanding the foregoing,
                                        ReelMetrics may seek preliminary
                                        injunctive relief from a court of law in
                                        the event of a breach by you.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <b>Term, Termination.</b> These Terms of
                                        Use will take effect when you first
                                        commence using the Site. ReelMetrics
                                        reserves the right at any time and for
                                        any reason to deny you access to the
                                        Site or any portion thereof. Termination
                                        will be effective without notice.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <b>Waiver.</b> Failure to insist on
                                        strict performance of any of the terms
                                        and conditions of these Terms of Use
                                        will not operate as a waiver of any
                                        subsequent default or failure of
                                        performance. No waiver by ReelMetrics of
                                        any right under these Terms of Use will
                                        be deemed to be either a waiver of any
                                        other right or provision or a waiver of
                                        that same right or provision at any
                                        other time.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <b>Nature of Relationship.</b> No joint
                                        venture, partnership, employment, or
                                        agency relationship exists between you
                                        and ReelMetrics as a result of these
                                        Terms of Use or your utilization of this
                                        Site.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <b>Severability.</b> The provisions of
                                        these Terms of Use are severable. If any
                                        provision (or part of any provision)
                                        shall be determined to be void or
                                        unenforceable, the relevant provision or
                                        part of any provision shall be deemed
                                        deleted and these Terms of Use, and the
                                        validity and enforceability of all
                                        remaining provisions (and parts of any
                                        provisions) of these Terms of Use, shall
                                        not be affected.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <b>
                                            Entire Agreement/Reservation of
                                            Rights.
                                        </b>{" "}
                                        Your rights to use certain material
                                        available on or through this Site may be
                                        subject to separate written agreements
                                        with ReelMetrics (&ldquo;Other
                                        Agreements&rdquo;). Particular pages or
                                        features of this Site with content
                                        supplied by ReelMetrics or its licensors
                                        may have different or additional terms
                                        (&quot;Special Terms&quot;), which will
                                        be disclosed to you when you access
                                        those pages or features, and by
                                        accessing or using such pages and
                                        features, you will be deemed to have
                                        agreed to the applicable Special Terms.
                                        If there is a conflict between these
                                        Terms of Use and the Special Terms, the
                                        Special Terms will govern with respect
                                        to such pages or features or content. In
                                        the event of a conflict between these
                                        Terms of Use and one or more Other
                                        Agreements, the terms of such Other
                                        Agreement(s) shall govern and control.
                                        With the exception of any Special Terms
                                        and Other Agreements, these Terms of Use
                                        represent the entire agreement between
                                        you and ReelMetrics with respect to your
                                        use of and material available on or
                                        through the Site, and it supersedes all
                                        prior or contemporaneous communications
                                        and proposals, whether electronic, oral,
                                        or written between you and ReelMetrics
                                        with respect to the Site. Any rights not
                                        expressly granted herein are reserved.
                                        Notwithstanding the foregoing,
                                        ReelMetrics data collection and usage
                                        practices in connection with the Site
                                        are as set forth in the Privacy Policy.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <b>Claims of Copyright Infringement.</b>{" "}
                                        The Digital Millennium Copyright Act of
                                        1998 (the &ldquo;DMCA&rdquo;) provides
                                        recourse for copyright owners who
                                        believe that material appearing on the
                                        Internet infringes their rights under
                                        U.S. copyright law. If you believe in
                                        good faith that materials available on
                                        the Site infringe your copyright, you
                                        (or your agent) may send ReelMetrics a
                                        notice requesting that ReelMetrics
                                        remove the material or block access to
                                        it. If you believe in good faith that
                                        someone has wrongly filed a notice of
                                        copyright infringement against you, the
                                        DMCA permits you to send ReelMetrics a
                                        counter-notice. Notices and
                                        counter-notices must meet the
                                        then-current statutory requirements
                                        imposed by the DMCA. See
                                        http://www.copyright.gov/ for details.
                                        Notices and counter-notices should be
                                        sent by mail to: Legal Department,
                                        ReelMetrics B.V., Ypenburgbocht 3, 2316
                                        WB Leiden, The Netherlands. We suggest
                                        that you consult your legal advisor
                                        before filing a notice or
                                        counter-notice.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <b>Contact Information.</b> If you would
                                        like to contact ReelMetrics regarding
                                        these Terms of Use or the Privacy
                                        Policy, please contact:
                                    </p>
                                    <p>
                                        Legal Department
                                        <br />
                                        ReelMetrics B.V.
                                        <br />
                                        Ypenburgbocht 3 2316 WB Leiden
                                        <br />
                                        The Netherlands
                                        <br />
                                        Tel: +31 71 560 5606
                                    </p>
                                </li>
                            </ol>
                            <p />

                            <NavLink
                                activeClassName="is-active legal-nav"
                                to="/legal"
                            >
                                Top of Page
                            </NavLink>

                            <h2 id="privacy-policy">Privacy Policy</h2>
                            <p>LAST UPDATED: February 17, 2016</p>
                            <p>
                                This Privacy Policy explains how we collect your
                                Personal Data (defined below) on those web sites
                                of ReelMetrics and its service providers that
                                post or link directly to this Privacy Policy
                                (collectively, the &ldquo;Site&rdquo;) and
                                through offline means, how we protect such data,
                                and the choices you have concerning our use of
                                such data. Please read this Privacy Policy
                                carefully. ReelMetrics may from time to time
                                update this Privacy Policy. Any changes to this
                                Privacy Policy will become effective when we
                                post or link to the revised Privacy Policy on
                                the Site. Please review the &ldquo;LAST
                                UPDATED&rdquo; legend at the top of this page to
                                determine when this Privacy Policy was most
                                recently revised.
                            </p>
                            <p>
                                For the purposes of this Privacy Policy,
                                &ldquo;ReelMetrics&rdquo;, &quot;we&quot;,
                                &quot;us&quot;, and &quot;our&quot; means
                                ReelMetrics B.V. and RM Holdings B.V. A complete
                                list of ReelMetrics group affiliates can be
                                found on the www.moodys.com web site.
                            </p>
                            <p>
                                You do not have to provide us with any Personal
                                Data to visit the Site. However, if you choose
                                to withhold requested information, you may not
                                be able to visit all sections or use all
                                features of the Site.
                            </p>
                            <p>
                                This Privacy Policy shall apply to any
                                ReelMetrics mobile application and other forms
                                of ReelMetrics online activity that reference
                                this Privacy Policy. In such instances, the term
                                &ldquo;Site&rdquo; shall include the applicable
                                mobile application or other ReelMetrics online
                                activity whenever that term is used herein. In
                                connection with your use of any ReelMetrics
                                mobile application, your Personal Data may also
                                be collected and used by your mobile device
                                manufacturer, the mobile application store from
                                which you obtained the ReelMetrics mobile
                                application, your telecommunication provider, or
                                other third parties involved in the delivery of
                                the application or data to your mobile device,
                                and such practices shall be subject to the
                                privacy policy of the applicable third party.
                                ReelMetrics is not responsible for any third
                                party&rsquo;s privacy practices.
                            </p>
                            <p className="underline">Types of Data Collected</p>
                            <p>
                                We gather Personal Data and Other Data (as
                                described below).
                            </p>
                            <p className="underline">
                                Collection of Personal Data
                            </p>
                            <p>
                                Personal data is information that identifies you
                                or can be used to identify or contact you, e.g.,
                                your name, email address, address, or phone
                                number (&quot;Personal Data&quot;).
                            </p>

                            <ul>
                                <li>
                                    ReelMetrics requests Personal Data from you
                                    when you register on the Site. The
                                    registration process requests that you
                                    provide your name, business email address,
                                    business address, business phone number, and
                                    company affiliation, and agree to the User
                                    Agreement presented at the time of
                                    registration.
                                </li>
                                <li>
                                    ReelMetrics may collect Personal Data
                                    voluntarily provided by you, including in
                                    emails to the Site, online forms, order and
                                    registration forms, surveys, promotion
                                    participation, and through offline means.
                                </li>
                                <li>
                                    On some of ReelMetrics Sites, including
                                    reelmetrics.com, you are able to maintain
                                    &quot;portfolios&quot;, listing assets in
                                    which you have a special interest. Providing
                                    this information is always at your option.
                                    If you provide such information, you will be
                                    required to select a username and a
                                    password. To be useful, this information
                                    must be stored on the Site, and is
                                    accessible to anyone with whom you share
                                    your username and password. For this reason,
                                    please safeguard your username and password
                                    carefully and do not disclose it to any
                                    other person.
                                </li>
                                <li>
                                    We may use third-party payment services
                                    (each, a &quot;Payment Service&quot;) to
                                    collect payments made through the Site. If
                                    you wish to make a payment through the Site,
                                    you may be directed to a webpage that is
                                    hosted by the Payment Service (or the
                                    Payment Service&apos;s service providers)
                                    and not by ReelMetrics. Any Personal Data
                                    that you provide through a Payment Service
                                    page will be collected by Payment Service
                                    and not by ReelMetrics, and will be subject
                                    to Payment Service&apos;s privacy policy,
                                    rather than this Privacy Policy. ReelMetrics
                                    has no control over, and is not responsible
                                    for, Payment Service&apos;s use of
                                    information collected through the Payment
                                    Service Page.
                                </li>
                                <li>
                                    ReelMetrics collects offline information
                                    through various means, including
                                    participation at conferences, individual
                                    introductions, and from employers that
                                    enroll their employees in our services. From
                                    time to time, we may also acquire from third
                                    parties certain lists containing the names
                                    and contact information of individuals who
                                    may be interested in our products and
                                    services. If we acquire such a list for
                                    promotional purposes, we seek to obtain
                                    appropriate representations from the other
                                    party that the listed individuals have opted
                                    in to receiving promotional messages from
                                    any acquirer of such list.
                                </li>
                            </ul>

                            <p className="underline">
                                Use of Personal Data Collected
                            </p>
                            <p>
                                We may use Personal Data for the following
                                purposes:
                            </p>
                            <ul>
                                <li>
                                    Fulfillment of Requests. ReelMetrics may use
                                    Personal Data about you to deliver products
                                    and services, and provide you with
                                    information about products and services
                                    you&rsquo;ve ordered.
                                </li>
                                <li>
                                    Other Communications. From time to time, we
                                    may use Personal Data about you to inform
                                    you of products, programs, services and
                                    promotions that we believe may be of
                                    interest to you. If your employer is a
                                    ReelMetrics customer, we may also inform you
                                    of such products, programs, services and
                                    promotions using Personal Data about you
                                    that your employer provided to ReelMetrics.
                                    If you would prefer that we not send
                                    electronic marketing messages to you, please
                                    see the &quot;Your Choices&quot; section
                                    below.
                                </li>
                                <li>
                                    Promotions. We may operate sweepstakes,
                                    contests and similar promotions
                                    (collectively, &quot;Promotions&quot;). We
                                    typically ask you for certain Personal Data
                                    when you enter and, if applicable, win a
                                    Promotion. You should carefully review the
                                    rules of each Promotion in which you
                                    participate, as they may contain additional
                                    important information about our use of
                                    Personal Data about you. To the extent that
                                    the terms and conditions of such rules
                                    concerning the treatment of Personal Data
                                    about you conflict with this Privacy Policy,
                                    the terms and conditions of such rules shall
                                    control.
                                </li>
                                <li>
                                    Internal Business Purposes. We may also use
                                    Personal Data about you for our internal
                                    business purposes, such as data analysis,
                                    editorial and feedback purposes, providing
                                    customer support, customizing and improving
                                    the content and layout of the Site,
                                    completing business transactions,
                                    development of marketing and promotion plans
                                    and materials, statistical analysis of user
                                    behavior, product development, administering
                                    individual accounts, and meeting government
                                    regulatory requirements such as sales tax
                                    collection, audits, etc.
                                </li>
                                <li>
                                    Administrative Communications. In addition,
                                    ReelMetrics reserves the right to use
                                    Personal Data to send to you important
                                    information regarding the Site, your account
                                    status, changes to the User Agreement, this
                                    Privacy Policy, or any other policies,
                                    agreements or transactions relevant to your
                                    use of the Site. Because this information
                                    may be important to your use of the Site,
                                    you may not opt-out of receiving such
                                    communications.
                                </li>
                            </ul>

                            <p className="underline">
                                Use of Personal Data Collected
                            </p>
                            <ul>
                                <li>
                                    Monitoring Data. ReelMetrics and its service
                                    providers may collect and/or track other
                                    information such as demographic information,
                                    domain names, computer type, browser types,
                                    screen resolution, and other statistical
                                    data involving the use of the Site
                                    (&quot;Monitoring Data&quot;). We use
                                    Monitoring Data to help us understand who
                                    uses the Site and to improve and market it,
                                    as well as our other web sites and services.
                                </li>
                            </ul>

                            <p>
                                Unless combined with Personal Data, Monitoring
                                Data does not personally identify you or any
                                other user, and we may use it for any purpose.
                            </p>
                            <ul>
                                <li>
                                    Aggregated Data. Additionally, we may
                                    aggregate Personal Data in a manner such
                                    that the end-product does not personally
                                    identify you or any other user of the Site,
                                    for example, by using information to
                                    calculate the percentage of our users who
                                    have a particular telephone area code. Such
                                    aggregate information may also be used for
                                    any purpose.
                                </li>
                                <li>
                                    Cookies. To enhance the Internet experience
                                    on the Site, ReelMetrics and our service
                                    providers may use &lsquo;cookies&rsquo; on
                                    the Site. Cookies are data that a web server
                                    transfers to an individual&apos;s computer
                                    for recordkeeping and other purposes. We use
                                    cookies and other technologies to facilitate
                                    users&apos; ongoing access to and use of our
                                    Site. If you do not want information
                                    collected through the use of cookies, there
                                    is a simple procedure in most browsers that
                                    allows you to automatically decline cookies,
                                    or to be given the choice of declining or
                                    accepting the transfer of a particular
                                    cookie, or cookies from a particular web
                                    site, to your computer. If cookies are
                                    disabled, however, all features of the Site
                                    may not operate as intended. Information
                                    about the procedure you are required to
                                    follow in order to disable cookies can be
                                    found on your Internet browser
                                    provider&rsquo;s web site. We expect our
                                    service providers to honor any decision by
                                    you to block or disable cookies in
                                    connection with the Site.
                                </li>
                                <li>
                                    Tools. We and our service providers may also
                                    use various common Internet tools such as
                                    &apos;pixel tags,&apos; &lsquo;action
                                    tags,&rsquo; &lsquo;web beacons,&rsquo;
                                    &lsquo;.gif tags,&rsquo;
                                    &apos;JavaScript&apos; or similar
                                    technologies (together, &quot;Tools&quot;)
                                    in connection with Site pages and email
                                    messages in certain formats to among other
                                    things, track the actions of Site users and
                                    email recipients, to determine the success
                                    of marketing campaigns and to compile
                                    statistics about Site usage and response
                                    rates. Tools allow us to count users who
                                    have visited certain pages of the Site, to
                                    deliver branded services, and to help
                                    determine the effectiveness of promotional
                                    or advertising campaigns. When used in email
                                    messages in certain formats, Tools can tell
                                    the sender whether and when the email has
                                    been opened.
                                </li>
                            </ul>

                            <p>
                                ReelMetrics uses cookies and Tool technologies
                                to understand how the Site is used and to
                                customize and enhance the Internet experience of
                                individual users. When you revisit the Site,
                                ReelMetrics may recognize you by a Tool and
                                customize your experience. For example, once you
                                have completed the registration process, a
                                cookie and/or Tool will be used to avoid having
                                you register again. We believe cookies and Tool
                                technologies add value to the user experience.
                            </p>
                            <ul>
                                <li>
                                    IP Addresses. When you visit and interact
                                    with the Site, we and our third-party
                                    service providers may collect Internet
                                    Protocol (IP) addresses. Your IP address is
                                    a number that is automatically assigned to
                                    the computer that you are using by your
                                    Internet Service Provider (ISP). This number
                                    is identified and logged automatically in
                                    our server log files whenever you visit the
                                    Site, along with the time(s) of your
                                    visit(s) and the page(s) that you visited.
                                    We use IP addresses to understand how the
                                    Site is used by our users and to improve the
                                    Site. Where required by applicable law, IP
                                    addresses will be treated as Personal Data.
                                </li>
                            </ul>

                            <p className="underline">
                                Disclosure of Personal Data Collected
                            </p>
                            <p>
                                We may disclose Personal Data for the following
                                purposes:
                            </p>
                            <ul>
                                <li>
                                    Third-Party Service Providers. We may
                                    provide Personal Data to third-party service
                                    providers, who provide services to us,
                                    including but not limited to, data analysis,
                                    order fulfillment, payment processing, and
                                    other services of an administrative nature.
                                </li>
                                <li>
                                    Affiliate Communications. ReelMetrics may
                                    transfer Personal Data among our affiliates
                                    to allow our affiliates to contact you
                                    regarding products, programs, services and
                                    promotions that they believe may be of
                                    interest to you, and such affiliates may
                                    otherwise use your Personal Data in
                                    accordance with this Privacy Policy. If you
                                    would prefer not to receive marketing
                                    communications from ReelMetrics or our
                                    affiliates, please see the &quot;Your
                                    Choices&quot; section below. ReelMetrics
                                    will be the party responsible for jointly
                                    used Personal Data.
                                </li>
                                <li>
                                    Strategic Partners and Co-Branded Sites.
                                    From time to time, we may enter into a
                                    special relationship with another company
                                    that is not owned by or affiliated with
                                    ReelMetrics to provide or promote joint
                                    products, services, applications, or
                                    features (together, &ldquo;Jointly Offered
                                    Items&rdquo;). These special relationships
                                    may include co-branded web sites
                                    (&ldquo;co-branded pages&rdquo;). Any
                                    information, including Personal Data, that
                                    you provide in connection with one of these
                                    Jointly Offered Items, and/or on one of
                                    these co-branded pages, will be shared with
                                    our third-party partners. By selecting an
                                    application or providing your information on
                                    these co-branded pages, or by signing up to
                                    receive information about or download a
                                    Jointly Offered Item, you also consent to
                                    our providing Personal Data about you to
                                    those third parties. You should check these
                                    third parties&rsquo; web sites for
                                    information regarding their privacy
                                    practices. PLEASE NOTE THAT THIS PRIVACY
                                    STATEMENT DOES NOT ADDRESS THE PRIVACY OR
                                    INFORMATION PRACTICES OF ANY THIRD PARTIES.
                                    If you do not want Personal Data about you
                                    shared with such third parties, please do
                                    not provide Personal Data in connection with
                                    the Jointly Offered Items and/or co-branded
                                    pages.
                                </li>
                                <li>
                                    Promotions. We may share the Personal Data
                                    you provide in connection with Promotions
                                    with third-party sponsors of such Promotions
                                    (irrespective of whether such Promotions are
                                    hosted by us), or otherwise in accordance
                                    with the rules applicable to those
                                    Promotions.
                                </li>
                                <li>
                                    Assignment. We may transfer any and all
                                    Personal Data that we collect to an
                                    affiliated entity or a third party in the
                                    event of any reorganization, merger, sale,
                                    joint venture, assignment, transfer or other
                                    disposition of all or any portion of our
                                    business, assets or stock (including without
                                    limitation in connection with any bankruptcy
                                    or similar proceedings).
                                </li>
                                <li>
                                    Law enforcement; emergencies; compliance. We
                                    may disclose Personal Data about you to
                                    others as we believe to be appropriate: (a)
                                    in compliance with laws, rules, or
                                    regulations in any country in which
                                    ReelMetrics operates; (b) to comply with
                                    legal process; (c) to respond to requests
                                    from government or public authorities; (d)
                                    to enforce our Terms of Use; (e) to protect
                                    our operations or those of any affiliated
                                    entities; (f) to protect the rights,
                                    privacy, safety or property of ReelMetrics,
                                    its affiliated entities, you, or others; and
                                    (g) to permit us to pursue available
                                    remedies or limit the damages that we may
                                    sustain. For example, we may, to the fullest
                                    extent the law allows, disclose Personal
                                    Data about you to law enforcement agencies
                                    to assist them in identifying individuals
                                    who have been or may be engaged in unlawful
                                    activities.
                                </li>
                            </ul>

                            <p className="underline">Your Choices</p>
                            <p>
                                We give you choices regarding our use and
                                disclosure of your Personal Data for marketing
                                purposes.
                            </p>
                            <ul>
                                <li>
                                    If you no longer want to receive
                                    marketing-related emails from us on a
                                    going-forward basis, you may stop receiving
                                    these marketing-related emails by sending an
                                    e-mail to unsubscribe@reelmetrics.com.
                                </li>
                                <li>
                                    If you would prefer that we do not share
                                    your Personal Data on a going-forward basis
                                    with our affiliates or with unaffiliated
                                    third parties for their marketing purposes,
                                    you may cease this sharing by sending an
                                    e-mail to unsubscribe@reelmetrics.com.
                                </li>
                            </ul>

                            <p>
                                We will try to comply with your request(s) as
                                soon as reasonably practicable. Please note that
                                if you exercise the second option as described
                                above, we will not be able to remove your
                                Personal Data from the databases of our
                                affiliates or unaffiliated third parties with
                                which we have already shared your Personal Data
                                (i.e., to which we have already provided your
                                Personal Data as of the date that we implement
                                your request). Further, please note that
                                requesting us not to share your Personal Data
                                with affiliates or unaffiliated third parties
                                may result in you no longer receiving any
                                marketing emails from ReelMetrics. Please also
                                note that if you choose not to receive
                                marketing-related messages from us, we may still
                                send you important administrative messages, and
                                you cannot elect to stop receiving such
                                administrative messages, unless you choose to
                                stop receiving services from us.
                            </p>
                            <p>
                                If we intend to use your Personal Data for a
                                purpose that is incompatible with the purposes
                                detailed in this Privacy Policy, or if we intend
                                to disclose it to a type of third party not
                                previously identified, we will notify you and
                                offer you the opportunity to opt-out of such
                                uses and/or disclosures.
                            </p>
                            <p>
                                You may also indicate your choices regarding
                                marketing-related emails by changing your
                                preferences on your Site profile at any time, or
                                contacting us via postal mail or telephone using
                                the &ldquo;Contact Information for
                                ReelMetrics&rdquo; below.
                            </p>

                            <p className="underline">
                                How you can access, change or suppress your
                                Personal Data
                            </p>
                            <p>
                                If you would like to review, correct, update,
                                suppress, delete or otherwise limit our use of
                                your Personal Data that has been previously
                                provided to us, you make a request by contacting
                                us using the information provided in the
                                &ldquo;Contact Information for
                                ReelMetrics&rdquo; below. We will try to comply
                                with your request as soon as reasonably
                                practicable and consistent with applicable law.
                            </p>

                            <p className="underline">Links</p>
                            <p>
                                The Site may contain links to other Internet web
                                sites, including social media sites. These
                                linked sites are not under ReelMetrics control
                                and we are not responsible for the privacy
                                practices or the contents of any linked site. We
                                provide links only as a convenience, and
                                ReelMetrics does not endorse or control, and is
                                not responsible for, the privacy practices or
                                the content of these linked sites. If you
                                provide any Personal Data through any
                                third-party web site, your transaction will
                                occur on that third party&apos;s web site (not
                                the Site) and the Personal Data you provide will
                                be collected by, and controlled by the privacy
                                policy of, that third party. We recommend that
                                you familiarize yourself with the privacy
                                policies and practices of any such third
                                parties. PLEASE NOTE THAT THIS PRIVACY POLICY
                                DOES NOT ADDRESS THE PRIVACY OR INFORMATION
                                PRACTICES OF ANY THIRD PARTIES, INCLUDING,
                                WITHOUT LIMITATION, AFFILIATED ENTITIES THAT DO
                                NOT POST OR LINK DIRECTLY TO THIS PRIVACY
                                POLICY.
                            </p>

                            <p className="underline">Security Measures</p>
                            <p>
                                ReelMetrics has established reasonable
                                organizational and technical measures to protect
                                Personal Data from loss, misuse, unauthorized
                                access, disclosure, alteration or destruction.
                                Please note that while ReelMetrics has
                                endeavored to create a secure and reliable Site
                                for users, no site is 100&quot; secure. If you
                                have reason to believe that your interaction
                                with us is no longer secure (for example, if you
                                feel that the security of any account you might
                                have with us has been compromised), please
                                notify us of the problem immediately by
                                contacting us using the information provided
                                below in &quot;Contact Information for
                                ReelMetrics&quot; (note that if you choose to
                                notify us via physical mail, this will delay the
                                time it takes for us to respond to the problem).
                            </p>

                            <p className="underline">Data Integrity</p>
                            <p>
                                ReelMetrics will use Personal Data only in ways
                                that are compatible with the purposes for which
                                it was collected, authorized by this Privacy
                                Policy, or authorized by you. ReelMetrics will
                                take reasonable steps to ensure that Personal
                                Data is relevant to its intended use, and is
                                accurate, complete, and current (as provided by
                                you). ReelMetrics depends on you to update or
                                correct your Personal Data whenever necessary.
                            </p>

                            <p className="underline">Retention Period</p>
                            <p>
                                We will retain Personal Data about you for the
                                period reasonably necessary to fulfill the
                                purposes outlined in this Privacy Policy and
                                consistent with retention periods required or
                                permitted by law.
                            </p>

                            <p className="underline">
                                Cross Border Transfer of Personal Data
                            </p>
                            <p>
                                Your Personal Data may be stored and processed
                                in any country where we have facilities or in
                                which we engage service providers, and by using
                                this Site or sharing Personal Data with us, you
                                consent to the transfer of information to
                                countries outside of your country of residence,
                                including the United States, which may have
                                different data protection rules than in your
                                country.
                            </p>

                            <p className="underline">Enforcement</p>
                            <p>
                                ReelMetrics periodically will review its
                                compliance with this Privacy Policy. ReelMetrics
                                will also review and respond to any formal
                                written complaints that we receive from you.
                                Please contact us at the address listed below in
                                &ldquo;Contact Information for
                                ReelMetrics.&ldquo;
                            </p>

                            <p className="underline">
                                Use of this Site by Minors
                            </p>
                            <p>
                                This Site is not directed to individuals under
                                the age of eighteen (18), and we request that
                                these individuals do not provide Personal Data
                                through this Site.
                            </p>

                            <p className="underline">Sensitive Personal Data</p>
                            <p>
                                We ask that you not send us, and you not
                                disclose, any sensitive Personal Data (e.g.,
                                social security numbers, information related to
                                racial or ethnic origin, political opinions,
                                religion or other beliefs, health, criminal
                                background or trade union membership) on or
                                through this Site or otherwise to us.
                            </p>

                            <p className="underline">
                                Contact Information for ReelMetrics
                            </p>
                            <p>
                                Questions or concerns regarding ReelMetrics data
                                protection practices should be addressed to:
                            </p>
                            <p>
                                Legal Department
                                <br />
                                ReelMetrics B.V. <br />
                                Ypenburgbocht 3 <br />
                                2316 WB Leiden <br />
                                The Netherlands <br />
                                Tel: +31 71 560 5606
                            </p>
                            <p>
                                If you choose to contact ReelMetrics via e-mail
                                about this Policy, please mark the subject
                                heading of your e-mail &ldquo;Privacy
                                Inquiry.&rdquo;
                            </p>

                            <NavLink
                                activeClassName="is-active legal-nav"
                                to="/legal"
                            >
                                Top of Page
                            </NavLink>

                            <h2 id="copyright-notice">Copyright Notice</h2>
                            <h3>Proprietary Rights</h3>
                            <p>
                                © 2015 -2018 RM Holdings B.V. and ReelMetrics
                                B.V. (collectively, &ldquo;REELMETRICS&rdquo;).
                                All rights reserved. ALL INFORMATION CONTAINED
                                HEREIN IS PROTECTED BY LAW, INCLUDING BUT NOT
                                LIMITED TO, COPYRIGHT LAW, AND NONE OF SUCH
                                INFORMATION MAY BE COPIED OR OTHERWISE
                                REPRODUCED, REPACKAGED, FURTHER TRANSMITTED,
                                TRANSFERRED, DISSEMINATED, REDISTRIBUTED OR
                                RESOLD, OR STORED FOR SUBSEQUENT USE FOR ANY
                                SUCH PURPOSE, IN WHOLE OR IN PART, IN ANY FORM
                                OR MANNER OR BY ANY MEANS WHATSOEVER, BY ANY
                                PERSON WITHOUT REELMETRICS PRIOR WRITTEN
                                CONSENT.
                            </p>

                            <NavLink
                                activeClassName="is-active legal-nav"
                                to="/legal"
                            >
                                Top of Page
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Legal;
