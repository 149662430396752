import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

import CardsContainer from "../components/shared/cardsContainer";
import Card from "../components/shared/card";
import Panel from "../components/shared/panel";
import CardsRow from "../components/shared/cardsRow";
import TimeSeriesChart from "../components/shared/TimeSeriesChart";
import PercentileTimeSeriesChart from "../components/shared/PercentileTimeSeriesChart";
import BarChart from "../components/shared/BarChart";
import SingleStatComponent from "../components/shared/SingleStatComponent";
import ConfidenceLegend from "../components/shared/ConfidenceLegend";
import PerformanceProfileTable from "../components/shared/PerformanceProfileTable";
import { measureColors } from "../components/shared/colors";
import PreviewTeaser from "../components/preview_teaser";

export default class GameProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stats: [],
            lifecycle: [],
            performance_profile: [],
            supplier_name: "",
            game_title: "",
            cost_model: "",
            error: false
        };

        this.clickHandler = this.clickHandler.bind(this);

        ReactGA.pageview(window.location.pathname);
    }

    /* eslint-disable */
    UNSAFE_componentWillMount() {
        fetch(`/api/games/${this.props.match.params.uuid}/performance`)
            .then(this.handleErrors)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    performance_profile: data.performance_profile_json,
                    lifecycle: data.lifecyle_analysis_json,
                    stats: data.stats_json,
                    game_title: data.title,
                    cost_model: data.cost_model,
                    supplier_name: data.supplier
                });
            })
            .catch(() => {
                this.setState({ error: true });
            });
    }
    /* eslint-enable */

    convertData = (data, xAxisKey) => {
        const convertedData = data || [];
        const returnValue = convertedData.map(row => ({
            x: new Date(row.reporting_month_yyyymm),
            y: row[xAxisKey]
        }));
        return returnValue;
    };
    
    convertRangesData = (data, xAxisKey) => {
        const convertRangesData = data || [];
        const returnValue = convertRangesData.map(row => {
            if (row && row.reporting_month_yyyymm && row[`${xAxisKey}_median`] && row[`${xAxisKey}_q1`] && row[`${xAxisKey}_q3`]) {
                return {
                    x: new Date(row.reporting_month_yyyymm),
                    y: row[`${xAxisKey}_median`],
                    y0: row[`${xAxisKey}_q1`],
                    y1: row[`${xAxisKey}_q3`]
                };
            }
            return null;
        }).filter(row => row !== null);
        return returnValue;
    };

    error = message => (
        <>
            <section className="box box--light">
                <div className="container">
                    <Container>
                        <Row>
                            <Col md={12}>
                                <div className="alert alert-warning">
                                    {message}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </>
    );

    handleErrors = response => {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    };

    clickHandler(value) {
        ReactGA.event({
            category: `Card ${value}`,
            action: "Navigation",
            label: this.state.game_title
        });
    }

    render() {
        if (this.state.error)
            return this.error(
                "Oh snap! This preview is not available any longer."
            );
        if (this.state.game_title === "") return this.error("Loading....");

        const data = this.state.lifecycle || [];
        const stats = this.state.stats || [];

        /* const gptwData = this.convertData(data, 'gptw_rpm');
      const gpData = this.convertData(data, 'gp_rpm');
      const twData = this.convertData(data, 'tw_rpm');
      const gameData = this.convertData(data, 'games'); */
        const terminalData = this.convertData(data, "terminals");
        const venueData = this.convertData(data, "venues");
        const marketshareData = this.convertData(data, "marketshare");

        const percentileSeriesGP = [
            {
                label: "Games Played",
                color: measureColors.gpColor,
                data: this.convertRangesData(this.state.lifecycle, "gp")
            }
        ];
        const percentileSeriesTW = [
            {
                label: "Theo Win",
                color: measureColors.twColor,
                data: this.convertRangesData(this.state.lifecycle, "tw")
            }
        ];
        const percentileSeriesGPTW = [
            {
                label: "Theo Win",
                color: measureColors.gptwColor,
                data: this.convertRangesData(this.state.lifecycle, "gptw")
            }
        ];
        const series = [
            {
                label: "Pan Industrial",
                color: measureColors.gptwColor,
                gptwData: this.convertData(data, "gptw_rpm"),
                twData: this.convertData(data, "tw_rpm"),
                gpData: this.convertData(data, "gp_rpm"),
                terminalData: this.convertData(data, "terminals"),
                venueData: this.convertData(data, "venues"),
                gameData: this.convertData(data, "games"),
                marketshareData: this.convertData(data, "marketshare")
            }
        ];

        return (
            <>
                <Helmet>
                    <title>
                        {this.state.game_title} by {this.state.supplier_name}
                    </title>
                    <meta
                        name="description"
                        content={`Game performance profile of ${encodeURIComponent(this.state.game_title)} by ${this.state.supplier_name}. From ReelMetrics, the leading source of casino game performance data & analytics for the slot machine industry.`}
                    />
                    <meta
                        name="canonical"
                        content={`https://www.reelmetrics.com/games/${this.state.game_id}/${encodeURIComponent(this.state.game_title)}`}
                    />
                    <meta
                        property="og:title"
                        content={`${encodeURIComponent(this.state.game_title)} by ${this.state.supplier_name} | ReelMetrics | Casino Game Performance Data Science & Analytics`}
                    />
                    <meta
                        property="og:description"
                        content={`Game performance profile of ${this.state.game_title} by ${this.state.supplier_name}. From ReelMetrics, the leading source of casino game performance data & analytics for the slot machine industry.`}
                    />
                    <meta
                        property="og:url"
                        content={`https://www.reelmetrics.com/games/this.state.game_id/${encodeURIComponent(this.state.game_title)}`}
                    />
                </Helmet>

                <section className="box box--light">
                    <div className="container">
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <div className="alert alert-warning">
                                        This is a limited preview of the full
                                        Game Profile page of{" "}
                                        {this.state.game_title} by{" "}
                                        {this.state.supplier_name}.
                                    </div>
                                </Col>
                            </Row>
                            <Row className="card">
                                <Col
                                    md={6}
                                    lg={6}
                                    sm={12}
                                    className="game-title-section card-header"
                                >
                                    <h1 className="game-page-title title">
                                        {this.state.game_title}
                                    </h1>
                                    <h2 style={{ color: "#686D70" }}>
                                        {this.state.supplier_name} -{" "}
                                        {this.state.cost_model}{" "}
                                    </h2>
                                </Col>
                                <Col
                                    md={6}
                                    lg={6}
                                    sm={12}
                                    className="game-stats card-header"
                                >
                                    <div className="game-stats-section">
                                        {stats.map(itemData => (
                                            <SingleStatComponent
                                                key={JSON.stringify(itemData)}
                                                data={itemData}
                                            />
                                        ))}
                                    </div>
                                </Col>
                            </Row>
                            <Row className="card card-locked">
                                <Col
                                    md={12}
                                    className="game-title-section card-header"
                                >
                                    <h3
                                        className="game-page-title title"
                                        style={{
                                            float: "left",
                                            margin: "0",
                                            lineHeight: "1"
                                        }}
                                    >
                                        Configuration Details &gt;
                                    </h3>
                                    <span
                                        className="glyphicon glyphicon-lock"
                                        style={{
                                            float: "left",
                                            paddingLeft: "8px",
                                            top: "-1px"
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Container>

                        <CardsContainer
                            defaultActive="timeseries_analysis"
                            onClick={this.clickHandler}
                        >
                            <CardsRow>
                                <Card
                                    title="Internal Overview"
                                    cardId="internal_overview"
                                    locked
                                >
                                    <img
                                        src="/assets/images/internal_overview.png"
                                        alt="preview"
                                        className="img-responsive width-100"
                                    />
                                </Card>
                                <Card
                                    title="Pricing Analysis"
                                    cardId="pricing_analysis"
                                    locked
                                >
                                    <img
                                        src="/assets/images/pricing.png"
                                        alt="preview"
                                        className="img-responsive width-100"
                                    />
                                </Card>
                                <Card
                                    title="Time Series Analysis"
                                    cardId="timeseries_analysis"
                                >
                                    <TimeSeriesChart
                                        series={series}
                                        height={200}
                                    />
                                </Card>
                                <Card
                                    title="Performance Profile"
                                    cardId="performance_profile"
                                >
                                    {/*
                          <p className="paragraph paragraph--extra-smallest">
                            Games Played & Theo Win Percentile
                          </p>
                          <ProgressBar now={60} style={{width: '100%'}} label="60" className="progress-gptw"/>
                          <p className="paragraph paragraph--extra-smallest">
                            Theo Win Percentile
                          </p>
                          <ProgressBar now={80} style={{width: '100%'}} label="80" className="progress-tw"/>
                          <p className="paragraph paragraph--extra-smallest">
                            Theo Win Percentile
                          </p>
                          <ProgressBar now={40} style={{width: '100%'}} label="40" className="progress-gp"/>
                          */}
                                    <PerformanceProfileTable
                                        data={this.state.performance_profile}
                                    />
                                </Card>
                                <Panel
                                    title="Premium Feature"
                                    cardId="internal_overview"
                                >
                                    <PreviewTeaser />
                                </Panel>
                                <Panel
                                    title="Premium Feature"
                                    cardId="pricing_analysis"
                                >
                                    <PreviewTeaser />
                                </Panel>
                                <Panel
                                    title="Time Series Analysis"
                                    cardId="timeseries_analysis"
                                >
                                    <p className="paragraph paragraph--extra-smallest">
                                        The Time Series Analysis shows
                                        performance (Blended Games Played Theo
                                        Win vs Floor Average) over time.
                                    </p>
                                    <TimeSeriesChart
                                        series={series}
                                        height={350}
                                        legend
                                    />
                                    <h3 className="title title--primary">
                                        Terminals (Sample size)
                                    </h3>
                                    <BarChart
                                        data={terminalData}
                                        height={130}
                                        label="Terminals"
                                    />
                                    <h3 className="title title--primary">
                                        Market Share
                                    </h3>
                                    <BarChart
                                        data={marketshareData}
                                        height={130}
                                        label="Market Share"
                                    />
                                    <h3 className="title title--primary">
                                        Venues
                                    </h3>
                                    <BarChart
                                        data={venueData}
                                        height={130}
                                        label="Venues"
                                    />
                                </Panel>
                                <Panel
                                    title="Performance Profile"
                                    cardId="performance_profile"
                                >
                                    <Row>
                                        <Col md={4} xs={12}>
                                            <PerformanceProfileTable
                                                data={
                                                    this.state
                                                        .performance_profile
                                                }
                                            />
                                        </Col>
                                    </Row>

                                    <h3 className="title title--primary">
                                        Games Played & Theo Win Blend Percentile
                                    </h3>
                                    <PercentileTimeSeriesChart
                                        series={percentileSeriesGPTW}
                                        height={200}
                                    />
                                    <ConfidenceLegend
                                        color={measureColors.gptwColor}
                                    />
                                    <h3 className="title title--primary">
                                        Theo Win Percentile
                                    </h3>
                                    <PercentileTimeSeriesChart
                                        series={percentileSeriesTW}
                                        height={200}
                                    />
                                    <ConfidenceLegend
                                        color={measureColors.twColor}
                                    />
                                    <h3 className="title title--primary">
                                        Games Played Percentile
                                    </h3>
                                    <PercentileTimeSeriesChart
                                        series={percentileSeriesGP}
                                        height={200}
                                    />
                                    <ConfidenceLegend
                                        color={measureColors.gpColor}
                                    />
                                </Panel>
                            </CardsRow>
                            <CardsRow>
                                <Card
                                    title="Locational Analysis"
                                    cardId="locational_analysis"
                                    locked
                                >
                                    <img
                                        src="/assets/images/loco.png"
                                        alt="preview"
                                        className="img-responsive width-100"
                                    />
                                </Card>
                                <Card
                                    title="Game Conversions"
                                    cardId="game_conversions"
                                    locked
                                >
                                    <img
                                        src="/assets/images/conversions.png"
                                        alt="conversions"
                                    />
                                </Card>
                                <Card
                                    title="Average Bet"
                                    cardId="average_bet"
                                    locked
                                >
                                    <img
                                        src="/assets/images/average_bet.png"
                                        alt="preview"
                                        className="img-responsive width-100"
                                    />
                                </Card>
                                <Panel
                                    title="Premium Feature"
                                    cardId="locational_analysis"
                                >
                                    <PreviewTeaser />
                                </Panel>
                                <Panel
                                    title="Premium Feature"
                                    cardId="game_conversions"
                                >
                                    <PreviewTeaser />
                                </Panel>
                                <Panel
                                    title="Premium Feature"
                                    cardId="average_bet"
                                >
                                    <PreviewTeaser />
                                </Panel>
                            </CardsRow>
                        </CardsContainer>
                    </div>
                </section>
            </>
        );
    }
}
