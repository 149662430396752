import React from "react";
import { Container } from "react-bootstrap";

export default class CardsContainer extends React.Component {
    constructor(props) {
        super(props);
        const defaultActive = props.defaultActive ? props.defaultActive : null;
        this.state = { activeCard: defaultActive };
    }

    clickHandler(value) {
        if (this.props.onClick) {
            this.props.onClick(value);
        }
        this.setState({ activeCard: value });
    }

    render() {
        const childrenWithProps = React.Children.map(
            this.props.children,
            child =>
                React.cloneElement(child, {
                    clickHandler: this.clickHandler.bind(this),
                    activeCard: this.state.activeCard
                })
        );
        return <Container>{childrenWithProps}</Container>;
    }
}
