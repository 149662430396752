import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import { selectStyles } from "./selectStyles";

function SelectComponent({
    options,
    defaultValue,
    onChange,
    className,
    placeholder
}) {
    const [selected, setSelected] = useState(null);

    // workaround because defaultValue is not working on async select
    // currently it is an open issue on react-select
    useEffect(() => {
        if (!selected && options.length > 0 && defaultValue) {
            setSelected(defaultValue);
        }
    }, [options, selected, defaultValue, onChange]);

    const handleChange = selectedOption => {
        setSelected(selectedOption);

        if (onChange) {
            onChange(selectedOption);
        }
    };

    return (
        <Select
            className={className}
            options={options}
            value={selected}
            defaultValue={defaultValue}
            onChange={handleChange}
            placeholder={placeholder}
            styles={selectStyles.optionsStyle}
            theme={theme => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    ...selectStyles.primaryColors
                }
            })}
        />
    );
}

SelectComponent.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired
        })
    ),
    defaultValue: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired
    }),
    onChange: PropTypes.func,
    className: PropTypes.string,
    placeholder: PropTypes.string
};

SelectComponent.defaultProps = {
    options: [],
    defaultValue: null,
    onChange: null,
    className: "",
    placeholder: ""
};

export default SelectComponent;
