import React from "react";

const ConfidenceLegend = props => (
    <div className="legend_wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="200.8" height="25.7">
            <g>
                <polygon
                    points="47.575565,2.196026 45.575565,1.951649 43.475563,3.103718 40.975563,1.812004 39.075565,1.916737 36.675564,4.081234 34.475563,1.881824 32.175564,1.881824 29.975563,1.916737 27.775566,2.894253 25.475565,3.767033 23.275564,3.871765 20.975565,1.602535 18.775564,1.637447 16.575565,2.161114 14.275564,5.40786 12.075565,5.477684 9.775566,2.61496 7.575565,2.964073 5.275565,5.966442 3.075565,5.268215 0.875565,2.440407 -1.424435,6.071175 -1.624435,6.455196 -1.624435,17.45224 -1.424435,17.591885 0.875565,16.788925 3.575565,18.255199 5.275565,16.719101 7.575565,18.988335 9.775566,16.439816 12.075565,19.302536 14.275564,16.160526 16.575565,18.290112 18.775564,19.302536 20.975565,16.963482 23.275564,17.696617 25.475565,16.05579 27.775566,16.719101 29.975563,15.741589 32.175564,15.70668 34.475563,18.709045 36.675564,16.369991 38.975563,14.694252 41.175564,16.928574 43.475563,18.464664 45.675564,16.05579 47.875565,16.020878 50.175564,16.614372 50.175564,3.452831 "
                    id="svg_1"
                    className="band"
                    fill={props.color}
                    fillOpacity="0.3"
                />
                <path
                    d="M0,11.75 C0,11.75 16.25,9.75 16.25,9.75 C16.25,9.75 33.25,8.25 33.25,8.25 C33.25,8.25 50.75,8.875 50.75,8.875"
                    strokeWidth="1"
                    stroke={props.color}
                    fill={props.color}
                />
                <line
                    stroke="#000000"
                    y1="8.875px"
                    id="line1"
                    strokeWidth="1px"
                    x1="50px"
                    y2="8.875px"
                    x2="60px"
                    transform=""
                />
                <line
                    stroke="#000000"
                    y1="14.5px"
                    id="line2"
                    strokeWidth="1px"
                    x1="26.5px"
                    y2="22.5px"
                    x2="26.5px"
                    transform=""
                />
                <line
                    stroke="#000000"
                    y1="22.125px"
                    id="line3"
                    strokeWidth="1px"
                    x1="26px"
                    y2="22.125px"
                    x2="60px"
                    transform=""
                />
                <text
                    stroke="none"
                    style={{ outlineStyle: "none" }}
                    id="text2"
                    strokeWidth="1px"
                    x="62.375px"
                    textRendering="geometricPrecision"
                    fontFamily="Helvetica"
                    fill="#000000"
                    fontSize="10px"
                    y="12.25px"
                    transform=""
                    textAnchor="left"
                    // eslint-disable-next-line react/no-unknown-property
                    macsvgid="15C93C89-C1AC-473B-81D0-60606511E6AB-6568-00000A9996AC0F2E"
                >
                    Median
                </text>
                <text
                    stroke="none"
                    style={{ outlineStyle: "none" }}
                    id="text1"
                    strokeWidth="1px"
                    x="62.375px"
                    textRendering="geometricPrecision"
                    fontFamily="Helvetica"
                    fill="#000000"
                    fontSize="10px"
                    y="25.375px"
                    transform=""
                    textAnchor="left"
                    // eslint-disable-next-line react/no-unknown-property
                    macsvgid="02CEF8E4-EB3F-495B-ABA7-45BD66EB2899-6568-00000A79353406DC"
                >
                    90% Confidence Interval
                </text>
            </g>
        </svg>
    </div>
);
export default ConfidenceLegend;
