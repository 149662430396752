import React from "react";

class RedBlock extends React.Component {
    render() {
        return (
            <section className="box box--primary box-background--slot_symbols">
                <a
                    href={this.props.link}
                    className="link--no-decoration"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div className="outside-wrapper container-fluid">
                        <div
                            className="row flex-row"
                            style={{ minHeight: "250px" }}
                        >
                            {this.props.children}
                        </div>
                    </div>
                </a>
            </section>
        );
    }
}

export default RedBlock;
