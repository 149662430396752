import React from "react";

const PlanDescriptionLegend = () => {
    return (
        <div className="plan-description-legend">
            <div style={{ display: "flex" }}>
                <i
                    className="fa fa-check-circle"
                    aria-hidden="true"
                    style={{
                        color: "lightgray",
                        marginRight: "5px"
                    }}
                />
                <p>Functional restrictions apply</p>
            </div>
            <div style={{ display: "flex" }}>
                <i
                    className="fa fa-check-circle"
                    aria-hidden="true"
                    style={{
                        color: "green",
                        marginRight: "5px"
                    }}
                />
                <p>No functional restrictions</p>
            </div>
        </div>
    );
};

export default PlanDescriptionLegend;
