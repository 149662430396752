import React from "react";

const PlanDescription = props => {
    const basicPlanCard = (
        <div
            className="card card-plan-description row--flex-column"
            style={{ minHeight: "365px" }}
        >
            <img
                className="img-responsive"
                style={{
                    maxWidth: "200px",
                    marginBottom: "20px"
                }}
                src="/assets/images/rm_logo_basic.svg"
                alt="rm_logo_basic"
                loading="lazy"
            />
            <p
                className="paragraph"
                style={{
                    width: "100%",
                    textAlign: "center"
                }}
            >
                Data cleansing and standardization with baseline application
                functionality
            </p>
            <p
                className="paragraph"
                style={{
                    width: "100%",
                    textAlign: "center"
                }}
            >
                <span className="text--emphasized text--red">Free</span>, no
                annual subscription fees
            </p>
            <div
                className="btn btn-block-mobile btn-lg btn-orange mw-270 hidden-xs"
                data-toggle="modal"
                data-target="#contact"
            >
                CONTACT US
            </div>
        </div>
    );

    const proPlanCard = (
        <div
            className="card card-plan-description row--flex-column"
            style={{ minHeight: "365px" }}
        >
            <img
                className="img-responsive"
                style={{
                    maxWidth: "200px",
                    marginBottom: "20px"
                }}
                src="/assets/images/rm_logo_pro.svg"
                alt="rm_logo_pro"
                loading="lazy"
            />
            <p
                className="paragraph"
                style={{
                    width: "100%",
                    textAlign: "center"
                }}
            >
                Data cleansing and standardization with full platform
                functionality
            </p>
            <p
                className="paragraph"
                style={{
                    width: "100%",
                    textAlign: "center"
                }}
            >
                Annual subscription fees apply
            </p>
            <div
                className="btn btn-block-mobile btn-lg btn-primary mw-270 hidden-xs"
                data-toggle="modal"
                data-target="#contact"
            >
                CONTACT US
            </div>
        </div>
    );

    const listOfAttributes = [
        {
            attribute: "Slot system anonimization script",
            subAttributes: []
        },
        {
            attribute: "Data cleaning & standardization",
            subAttributes: []
        },
        {
            attribute: "Monthly venue reports",
            subAttributes: []
        },
        {
            attribute: "ReelMetrics Recovery",
            subAttributes: []
        },
        {
            attribute: "On Demand Analytics",
            subAttributes: [
                {
                    attribute: "ReelHot Index"
                },
                {
                    attribute: "Reports",
                    inactive: props.isBasic
                },
                {
                    attribute: "Game Profile",
                    inactive: props.isBasic
                },
                {
                    attribute: "Cabinets",
                    inactive: props.isBasic
                },
                {
                    attribute: "Cabinet Profile",
                    inactive: props.isBasic
                }
            ]
        },
        {
            attribute: "Prescriptive Analytics",
            inactive: props.isBasic,
            subAttributes: [
                {
                    attribute: "Advisor Stars",
                    inactive: props.isBasic
                },
                {
                    attribute: "Advisor Dogs",
                    inactive: props.isBasic
                },
                {
                    attribute: "Advisor Anomalies",
                    inactive: props.isBasic
                },
                {
                    attribute: "Star Scout",
                    inactive: props.isBasic
                }
            ]
        },
        {
            attribute: "Utilities",
            subAttributes: [
                {
                    attribute: "Access Identity Management"
                },
                {
                    attribute: "Dual Factor Authentication"
                },
                {
                    attribute: "My tools"
                },
                {
                    attribute: "Automated Venue Reports"
                },
                {
                    attribute: "ReelScan Pro"
                }
            ]
        }
    ];

    const attributeComponent = obj => (
        <div
            className="flex-row"
            style={{
                justifyContent: "left",
                alignItems: "baseline"
            }}
            key={obj.attribute}
        >
            <i
                className="fa fa-check-circle"
                aria-hidden="true"
                style={{
                    color: obj.inactive ? "lightgray" : "green",
                    marginRight: "5px"
                }}
            />
            <p>{obj.attribute}</p>
            <div className="flex-break" />
            <div
                className="row--flex-column"
                style={{
                    alignItems: "flex-start",
                    marginLeft: "20px"
                }}
            >
                {obj.subAttributes &&
                    obj.subAttributes.map(item => attributeComponent(item))}
            </div>
        </div>
    );

    return (
        <div
            className="col-xs-4 row--flex-column"
            style={{
                flex: "1"
            }}
        >
            {props.isBasic ? basicPlanCard : proPlanCard}
            <div
                className="row--flex-column"
                style={{ alignItems: "flex-start" }}
            >
                {listOfAttributes.map(item => attributeComponent(item))}
            </div>
        </div>
    );
};

export default PlanDescription;
