import React from "react";
import Cms from "./cms";

class AnalyticsInfo extends React.Component {
    render() {
        return (
            <section
                className={`box ${this.props.className}`}
                style={{ paddingBottom: "30px", paddingTop: "50px" }}
            >
                <div className="container">
                    <div className="row flex-row">
                        <div className="col-xs-12 col-sm-6 col-md-4 text-center">
                            <div className="question-box">
                                <figure className="question-box__media">
                                    <img
                                        src="assets/images/product_page_icon_ondemand_apps.svg"
                                        alt="product_page_icon_ondemand_apps"
                                        className="img-responsive center-block"
                                        loading="lazy"
                                    />
                                </figure>
                                <span className="question-box__title">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="analytics_info_1"
                                        type="title"
                                    />
                                </span>
                                <p
                                    className="question-box__body"
                                    style={{ minHeight: "0" }}
                                >
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="analytics_info_1"
                                        type="text"
                                    />
                                </p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6 col-md-4 text-center">
                            <div className="question-box">
                                <figure className="question-box__media">
                                    <img
                                        src="assets/images/product_page_icon_prescriptive_apps.svg"
                                        alt="product_page_icon_prescriptive_apps"
                                        className="img-responsive center-block"
                                        loading="lazy"
                                    />
                                </figure>
                                <span className="question-box__title">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="analytics_info_2"
                                        type="title"
                                    />
                                </span>
                                <p
                                    className="question-box__body"
                                    style={{ minHeight: "0" }}
                                >
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="analytics_info_2"
                                        type="text"
                                    />
                                </p>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6 col-md-4 text-center">
                            <div className="question-box">
                                <figure className="question-box__media">
                                    <img
                                        src="assets/images/product_page_icon_predictive_apps.svg"
                                        alt="product_page_icon_predictive_apps"
                                        className="img-responsive center-block"
                                        loading="lazy"
                                    />
                                </figure>
                                <span className="question-box__title">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="analytics_info_3"
                                        type="title"
                                    />
                                </span>
                                <p
                                    className="question-box__body"
                                    style={{ minHeight: "0" }}
                                >
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="analytics_info_3"
                                        type="text"
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default AnalyticsInfo;
