import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import butter from "../../../utils/butterClient";

import Select from "../../shared/Select/Select";
import Carousel from "./Carousel";

const sortIndexes = indexes =>
    indexes
        .map(index => ({ ...index, date: new Date(index.date) }))
        .sort((a, b) => b.date - a.date);

const filterIndexesByYear = (indexes, year) =>
    indexes.filter(index => index.date.getFullYear() === year);

const getSelectOptions = indexes => {
    if (!indexes || indexes.length === 0) {
        return [];
    }

    const uniqueDates = [
        ...new Set(sortIndexes(indexes).map(index => index.date.getFullYear()))
    ];

    return uniqueDates.map(date => ({ value: date, label: date }));
};

function ReelHotIndexes({ contentField }) {
    const [indexes, setIndexes] = useState();
    const [selectedYear, setSelectedYear] = useState();
    const [selectOptions, setSelectOptions] = useState([]);

    useEffect(() => {
        if (!indexes) {
            butter.content.retrieve([contentField]).then(response => {
                const reelHotIndexes = response.data.data[contentField];

                const sortedIndexes = sortIndexes(reelHotIndexes);
                const defaultYear = sortedIndexes[0].date.getFullYear();

                setIndexes(sortedIndexes);
                setSelectedYear(defaultYear);
                setSelectOptions(getSelectOptions(reelHotIndexes));
            });
        }
    }, [indexes, contentField]);

    const handleSelectChange = selected => {
        setSelectedYear(selected.value);
    };

    return (
        <>
            <section className="box box--light">
                <div className="outside-wrapper container-fluid flex-row">
                    <div className="col-xs-12 col-sm-11 reelhot-indexes">
                        <div className="reelhot-indexes-title">
                            <h2 className="title--banner text--red">
                                ReelHot Indexes
                            </h2>

                            <Select
                                className="reelhot-indexes-select"
                                options={selectOptions}
                                defaultValue={selectOptions[0]}
                                onChange={handleSelectChange}
                            />
                        </div>

                        <Carousel
                            items={
                                indexes &&
                                filterIndexesByYear(indexes, selectedYear)
                            }
                        />
                    </div>
                </div>
            </section>
        </>
    );
}

ReelHotIndexes.propTypes = {
    contentField: PropTypes.string.isRequired
};

export default ReelHotIndexes;
