import React from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import Cms from "../cms";
import ReelCastEpisodes from "./reelcastEpisodes";
import ReelCastEpisodeDescription from "./reelcastEpisodeDescription";
import ReelCastBanner from "../shared/ReelCastBanner";

class ReelCast extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname);
    }

    componentDidMount() {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://www.buzzsprout.com/2057836.js?container_id=buzzsprout-large-player&player=large';
        document.body.appendChild(script);
      }

    render() {
        const episodes = [
            {
                id: "11399244-s01e01-inventory-overdiversification-part-i",
                link: "s01e01-inventory-overdiversification-part-i",
                title: "Inventory Overdiversification - Part I",
                subTitle: "Season 1, Episode 1",
                description:
                    "Join us for the first episode of ReelCast as we dive into the topic of inventory overdiversification and its impact on your slot floor. Learn how to optimize your inventory for maximum performance.",
            },
            {
                id: "11607232-s01e02-inventory-overdiversification-part-ii",
                link: "s01e02-inventory-overdiversification-part-ii",
                title: "Inventory Overdiversification - Part II",
                subTitle: "Season 1, Episode 2",
                description:
                    "In part two of our discussion on inventory overdiversification, we explore the latest data-driven strategies for optimizing your inventory and maximizing player satisfaction to drive revenue.",
            },
            {
                id: "11771755-s01e03-making-your-peace-with-premium",
                title: "Making Your Peace with Premium",
                subTitle: "Season 1, Episode 3",
                description:
                    "Discover the importance of premium content and its impact on your slot floor. Our team shares data-driven insights and best practices for incorporating premium content into your inventory strategy.",
                link: "s01e03-making-your-peace-with-premium",
            },
            {
                id: "11918058-s01e04-top-ten-insights-of-2022",
                title: "Top Ten Insights of 2022",
                subTitle: "Season 1, Episode 4",
                description:
                    "Join us for this special episode of ReelCast as we discuss the top ten insights of 2022 and their impact on your slot floor. Get some 'reel' practical advice on how to prepare for the year ahead.",
                link: "s01e04-top-ten-insights-of-2022",
            },
            {
                id: "12140214-s02e01-updating-best-practices-part-i",
                title: "Updating Best Practices - Part I",
                subTitle: "Season 2, Episode 1",
                description:
                    "We kick off Season 2 of ReelCast as we explore the latest best practices for managing your slot floor. This is a must-listen episode for slot machine managers looking to stay ahead of the curve.",
                link: "s02e01-updating-best-practices-part-i",
            },
            {
                id: "12338678-s02e03-updating-best-practices-part-ii",
                title: "Updating Best Practices - Part II",
                subTitle: "Season 2, Episode 2",
                description:
                    "In part two of our discussion on best practices for managing your slot machines, we dive deeper into data-driven strategies for optimizing your inventory and increasing player satisfaction. ",
                link: "s02e02-updating-best-practices-part-ii",
            },
            { 
                id: "12517011-s02e03-updating-best-practices-part-iii",
                link: "s02e03-updating-best-practices-part-iii",
                title: "Updating Best Practices - Part III",
                subTitle: "Season 2, Episode 3",
                description: "In the final part of our discussion on best practices for managing your slot machines, we focus on the importance of staying up-to-date with industry trends and adjusting your strategies accordingly.",
            },
            { 
                id: "12741246-s02e04-jackpot-liabilities-and-high-denom-links",
                link: "s02e04-Jackpot-Liabilities-and-High-Denom-Links",
                title: "Jackpot Liabilities and High-Denom Links",
                subTitle: "Season 2, Episode 4",
                description: 
                    "Welcome to this episode of Reelcast where Nick and Don share insights on how to manage jackpot liabilities and maximize the performance of high-denomination links on your slot floor.",
            },
            { 
                id: "12867999-s02e05-marketing-mix-management-with-lucien-wijsman",
                link: "s02e05-marketing-mix-management-with-lucien-wijsman",
                title: "Marketing & Mix Management with Lucien Wijsman",
                subTitle: "Season 2, Episode 5",
                description: "Wijsman emphasizes the critical importance of player segmentation, and discusses how over-diversification, imbalance, and obsolete mix management practices are hampering slot performance.",
            },
            {
                id: "13023778-s02e06-video-poker-with-action-gamings-mike-fields",
                link: "s02e06-video-poker-with-action-gamings-mike-fields",
                title: "Video Poker with Action Gaming's Mike Fields",
                subTitle: "Season 2, Episode 6",
                description: "In this discussion, Fields covers the latest trends in video poker, as well as general operational tips for transforming aging, long-neglected poker portfolios into differentiating, high-yield sources of competitive advantage.",
            },
            {
                id: "13137763-s02e07-operator-vendor-tensions-with-buddy-frank",
                link: "s02e07-operator-vendor-tensions-with-buddy-frank",
                title: "Operator/Vendor Tensions with Buddy Frank",
                subTitle: "Season 2, Episode 7",
                description: "Explore operator-vendor tensions with Buddy Frank. Join Buddy, Nick and Don as they discuss hardware obsolescence, frequent cabinet releases, and vendor-operator relationships in the casino industry.",
            },
            {
                id: "13654509-s02e08-increased-jackpot-activity-g2e-2023",
                link: "s02e08-increased-jackpot-activity-g2e-2023",
                title: "Increased Jackpot Activity & G2E 2023",
                subTitle: "Season 2, Episode 8",
                description: "Dive into a comprehensive discussion on increased jackpot activity and G2E 2023. Topics covered include the decision to move the ICE show, equitable revenue splits, and operator-vendor tensions."
            },
            {
                id: "13654509-s02e09-Budgetary-Guidance-for-2024",  
                link: "s02e09-budgetary-guidance-for-2024",
                title: "Budgetary Guidance for 2024",
                subTitle: "Season 2, Episode 9",
                description: "In this post-G2E episode, Nick & Don present budgetary guidance for 2024. Sub-topics include Halloween; conducting fair share, occupancy, layout, and demand analyses; the CapEx / OpEx split & pacing spend."
            },
            {
                id: "13989798-s02e10-aruze-gaming-global-with-kelcey-allison",
                link: "s02e10-aruze-gaming-global-with-kelcey-allison",
                title: "Aruze Gaming Global with Kelcey Allison",
                subTitle: "Season 2, Episode 10",
                description: "Join a candid talk with Kelcey Allison, COO of Aruze Gaming Global, discussing pivotal company events and the vision for its future. Highlights include Vegas F1 insights and casino signage trends."  
            },
            {
                id: "11918058-s02e11-top-ten-insights-of-2023",
                title: "Top Ten Insights of 2023",
                subTitle: "Season 2, Episode 11",
                description:  "Welcome to our holiday episode of ReelCast, where we dive into the top ten insights of 2023. Tune in to discover innovative approaches and gain essential insights that will help you stay ahead. ",
                link: "s02e11-top-ten-insights-of-2023",
            },
            { 
                id: "14296731-s03e01-bc-slots-with-brian-christopher-josh-o-connell",
                link: "s03e01-bc-slots-with-brian-christopher-josh-o-connell",
                title: "BC Slots with Brian Christopher & Josh O'Connell",
                subTitle: "Season 3, Episode 1",
                description: "In this first episode of Season 3, Nick and Don discuss the slot influencer phenomenon with domain pioneers and undisputed leaders, Brian Christopher & Josh O’Connell of BC Slots. Brian and Josh cover the company’s full timeline, from Brian’s first impromptu clip to becoming the industry’s most powerful influencer brand.",
            },
            {
                id: "14577542-s03e02-delaware-north-with-michael-carruthers",
                link: "s03e02-delaware-north-with-michael-carruthers",
                title: "Delaware North with Michael Carruthers",
                subTitle: "Season 3, Episode 2",
                description: "In our second episode of Season  3, Nick and Don speak with Michael Carruthers, Vice President of Gaming Operations at Delaware North. Michael shares his insights on the company’s gaming operations, the impact of the pandemic, and the future of the industry. Join us for a deep dive into the world of gaming operations.",
            },
            {
                id: "14807307-s03e03-blue-delta-jeans-with-nick-weaver",
                link: "s03e03-blue-delta-jeans-with-nick-weaver",
                title: "Blue Delta Jeans with Nick Weaver",
                subTitle: "Season 3, Episode 3",
                description: "Blue jeans?!? Yes, you read that correctly. In this episode, Nick and Don speak with Nick Weaver, Co-Founder and COO of Blue Delta Jeans. Learn how Nick and his team are using $500 custom-tailored blue jeans to thrill slot players and breathe new life into VIP events and continuity programs.",
            },
            {
                id: "14981282-s03e04-bally-s-corporation-with-dan-cherry",
                link: "s03e04-bally-s-corporation-with-dan-cherry",
                title: "Bally's Corporation with Dan Cherry",
                subTitle: "Season 3, Episode 4",
                description: "In this episode, Nick & Don chat with Dan Cherry, Vice President of Gaming Operations at Bally's Corporation. Dan discusses the major projects underway in Vegas and Chicago, as well as the challenges and payoffs of implementing operational and analytical standards within hyper-growth gaming organizations. Also in this episode, should I replace ETGs with slots?",
            },
            {
                id: "15085579-s03e05-gaming-arts-with-mike-dreitzer",
                link: "s03e05-gaming-arts-with-mike-dreitzer",
                title: "Gaming Arts with Mike Dreitzer",
                subTitle: "Season 3, Episode 5",
                description: "In this episode, Nick and Don speak with Mike Dreitzer, CEO of Gaming Arts. Learn about the risks & rewards of building a slot machine company, the group's startup phase, development philosophy, product portfolio, near-term plans, and its strategic alliance with Germany's Merkur Gaming. Also in this episode, demand balancing multi-game product.",
            },
            {
                id: "15300571-s03e06-ags-with-jackson-floyd-and-steve-walther",
                link: "s03e06-ags-with-jackson-floyd-and-steve-walther",
                title: "AGS with Jackson Floyd and Steve Walther",
                subTitle: "Season 3, Episode 6",
                description: "In this episode, Nick and Don catch-up with AGS's VP of Slots, Jackson Floyd, and VP of Game Development, Steve Walther. Learn about AGS's history, cultural leadership, developmental philosophy (Steve's tango analogy is awesome), GameON customer summit, upcoming product releases, and more. Also in this episode, keeping Actual and Theo variances in-check.",
            },
            {
                id: "15397255-s03e07-incredible-technologies-with-dan-schrementi",
                link: "s03e07-incredible-technologies-with-dan-schrementi",
                title: "Incredible Technologies with Dan Schrementi",
                subTitle: "Season 3, Episode 7",
                description: "In this episode, Nick & Don speak with Dan Schrementi, President of Gaming at Incredible Technologies (IT). Learn about IT's history as a pioneering arcade game supplier, its move into gaming, its design & commercial philosophies, its recent strategic rebuild, its product roadmap, and its 'Teed Up' initiative. Also in this episode, how free play reductions impact visitation & spend."
            },
            {
                id: "15628363-zitro-usa-with-derik-mooberry",
                link: "s03e08-zitro-usa-with-derik-mooberry",
                title: "Zitro USA with Derik Mooberry",
                subTitle: "Season 3, Episode 8",
                description: "In this episode, Nick and Don speak with Derik Mooberry, CEO of Zitro USA. Learn about Zitro's rise from niche electronic bingo provider to a global supply-side powerhouse, its plans for the North American market, and the company's increasingly compelling Class III slot portfolio. Also in this episode, leveraging superstar inventory to dial-back free play."   
            }
        ];

        const [
            episode1,
            episode2,
            episode3,
            episode4,
            episode5,
            episode6,
            episode7,
            episode8,
            episode9,
            episode10,
            episode11,
            episode12,
            episode13,
            episode14,
            episode15,
            episode16,
            episode17,
            episode18,
            episode19,
            episode20,
            episode21,
            episode22,
            episode23,
        ] = episodes;

        return (
            <>
                <Helmet>
                    <title>ReelCast</title>
                    <meta
                        name="Description"
                        content="ReelCast is the ReelMetrics Podcast where data-driven insights, tips, and tricks are shared to help professional slot teams improve their game. Produced by ReelMetrics."
                    />
                    <link rel="canonical" href="https://www.reelmetrics.com/reelcast" />
                        <meta property="og:type" content="website" />
                        <meta property="og:title" content="ReelCast" />
                        <meta property="og:description" content="The podcast where data-driven insights, tips, and tricks are shared to help professional slot teams improve their game. Produced by ReelMetrics." />
                        <meta property="og:image" content="https://cdn.buttercms.com/OaWFV5QDqgOJxaKViwdg" />
                        <meta property="og:locale" content="en_US" />  
                </Helmet>
                <div className="text-page">
                    {/* Intro */}
                    <div className="box">
                        <div className="container">
                            <div className="row flex-row">
                                <div className="col-sm-6">
                                    <h1 className="title title--primary title--md">
                                        <Cms
                                            content={this.props.cms_content}
                                            content_key="reelcast_title"
                                            type="title"
                                        />
                                    </h1>
                                    <p className="paragraph--small paragraph--small--small-screen">
                                        <Cms
                                            content={this.props.cms_content}
                                            content_key="reelcast_paragraph"
                                            type="text"
                                        />
                                    </p>
                                </div>
                                <div className="col-sm-1"> </div>
                                <div className="col-sm-5">
                                    <img
                                        src="https://cdn.buttercms.com/9nzbnxR9SnGfKpJAMnrm"    
                                        alt="Reelcast logo"
                                        className="img-responsive"
                                        loading="eager"
                                    />
                                    {/* <div id="buzzsprout-large-player"></div> */}
                                </div>
                            </div>
                        </div>
                    </div>


                {/* Season 3 */}
                <div className="box box--light">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>ReelCast Season 3</h3>
                            </div>
                        </div>

                        {/* Season 3 Episode 8 */}
                        <div className="row paddingTop">
                            <div className="col-sm-6">
                                <ReelCastEpisodeDescription
                                    heading={episode23.title}
                                    subHeading={episode23.subTitle}
                                    link={episode23.link}
                                    description={episode23.description}
                                />
                            </div>
                            <div className="col-sm-6">
                                <img
                                    src="https://cdn.buttercms.com/UfqDFJJGT5uNZfViYcxq"
                                    alt="ReelCast by ReelMetrics s03e08 - Zitro USA with Derik Mooberry"
                                    className="img-responsive"
                                    loading="eager"
                                />
                            </div>
                        </div>

                        {/* Season 3 Episode 7 */}
                        <div className="row paddingTop">
                            <div className="col-sm-6">
                                <ReelCastEpisodeDescription
                                    heading={episode22.title}
                                    subHeading={episode22.subTitle}
                                    link={episode22.link}
                                    description={episode22.description}
                                />
                            </div>
                            <div className="col-sm-6">
                                <img
                                    src="https://cdn.buttercms.com/uvl2RfnQQFOybc2bNujq"
                                    alt="ReelCast by ReelMetrics s03e07 - Incredible Technologies with Dan Schrementi"
                                    className="img-responsive"
                                    loading="eager"
                                />
                            </div>
                        </div>

                        {/* Episode 6 */}
                        <div className="row paddingTop">
                            <div className="col-sm-6">
                                <ReelCastEpisodeDescription
                                    heading={episode21.title}
                                    subHeading={episode21.subTitle}
                                    link={episode21.link}
                                    description={episode21.description}
                                />
                            </div>
                            <div className="col-sm-6">
                                <img
                                    src="https://cdn.buttercms.com/4F3V7IvhSFyPuRgErKqy"
                                    alt="ReelCast by ReelMetrics s03e06 - AGS with Jackson Floyd and Steve Walther"
                                    className="img-responsive"
                                    loading="eager"
                                />
                            </div>
                        </div>

                        {/* Episode 5 */}
                        <div className="row paddingTop">
                            <div className="col-sm-6">
                                <ReelCastEpisodeDescription
                                    heading={episode20.title}
                                    subHeading={episode20.subTitle}
                                    link={episode20.link}
                                    description={episode20.description}
                                />
                            </div>
                            <div className="col-sm-6">
                                <img
                                    src="https://cdn.buttercms.com/PC2Hh3aFRRin56dd1HJf"
                                    alt="ReelCast by ReelMetrics s03e05 - Gaming Arts with Mike Dreitzer"
                                    className="img-responsive"
                                    loading="eager"
                                />
                            </div>
                        </div>
                    {/* Episode 4 */}
                    <div className="row paddingTop">
                            <div className="col-sm-6">
                                <ReelCastEpisodeDescription
                                    heading={episode19.title}
                                    subHeading={episode19.subTitle}
                                    link={episode19.link}
                                    description={episode19.description}
                                />
                            </div>
                            <div className="col-sm-6">
                                <img
                                    src="https://cdn.buttercms.com/rsvE0m7TL1IeYNZkfPlw"
                                    alt="ReelCast by ReelMetrics s03e04 - Bally's Corporation with Dan Cherry"
                                    className="img-responsive"
                                    loading="eager"
                                />
                            </div>
                        </div>


{/* Episode 3 */}

                        <div className="row paddingTop">
                            <div className="col-sm-6">
                                <ReelCastEpisodeDescription

                                    heading={episode18.title}
                                    subHeading={episode18.subTitle}
                                    link={episode18.link}
                                    description={episode18.description}
                                />
                            </div>
                            <div className="col-sm-6">
                                <img
                                    src="https://cdn.buttercms.com/ASFvR4TESEgolGLsZdQr"
                                    alt="ReelCast by ReelMetrics s03e03 - Blue Delta Jeans with Nick Weaver"
                                    className="img-responsive"
                                    loading="eager"
                                />
                            </div>
                        </div>
                    
                    <hr/> {/* Episode 2 */}

                        <div className="row paddingTop"> 
                        <div className="col-sm-6">
                                <ReelCastEpisodeDescription
                                    heading={episode17.title}
                                    subHeading={episode17.subTitle}
                                    link={episode17.link}
                                    description={episode17.description}
                                />
                            </div>
                        <div className="col-sm-6">
                                <img
                                    src="https://cdn.buttercms.com/EpSM5TPnRAelCIlujXOp"    
                                    alt="ReelCast by ReelMetrics s03e02 - Delaware North with Michael Carruthers"
                                    className="img-responsive"
                                    loading="eager"
                                />
                            </div>



                        </div>
                    
                    <hr/> {/* Episode 1 */}

                        <div className="row paddingTopBottom">
                            <div className="col-sm-6">
                                <ReelCastEpisodeDescription
                                    heading={episode16.title}
                                    subHeading={episode16.subTitle}
                                    link={episode16.link}
                                    description={episode16.description}

                                />
                            </div>
                            <div className="col-sm-6">
                                <img
                                        src="https://cdn.buttercms.com/d6SeL4CxTtecxcXGYGbx"    
                                        alt="Brian Christopher and Josh O'Connell at Reelcast"
                                        className="img-responsive"
                                        loading="eager"
                                    />
                            </div>
                        </div>
                    </div>
                </div>

                    {/* Season 2 */}
                    <div className="box">
                        <div className="container">
                            <h3>ReelCast Season 2</h3>
                            <div className="row ">
                                <div className="col-sm-6">
                                    <ReelCastEpisodeDescription
                                        heading={episode15.title}
                                        subHeading={episode15.subTitle}
                                        description={episode15.description}
                                        link={episode15.link}
                                    />
                                </div>
                                </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <ReelCastEpisodeDescription
                                        heading={episode14.title}
                                        subHeading={episode14.subTitle}
                                        description={episode14.description}
                                        link={episode14.link}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <ReelCastEpisodeDescription
                                        heading={episode13.title}
                                        subHeading={episode13.subTitle}
                                        description={episode13.description}
                                        link={episode13.link}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <ReelCastEpisodeDescription
                                        heading={episode12.title}
                                        subHeading={episode12.subTitle}
                                        description={episode12.description}
                                        link={episode12.link}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <ReelCastEpisodeDescription
                                        heading={episode11.title}
                                        subHeading={episode11.subTitle}
                                        description={episode11.description}
                                        link={episode11.link}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <ReelCastEpisodeDescription
                                        heading={episode10.title}
                                        subHeading={episode10.subTitle}
                                        description={episode10.description}
                                        link={episode10.link}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <ReelCastEpisodeDescription

                                        heading={episode9.title}
                                        subHeading={episode9.subTitle}
                                        description={episode9.description}
                                        link={episode9.link}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <ReelCastEpisodeDescription
                                        heading={episode8.title}
                                        subHeading={episode8.subTitle}
                                        description={episode8.description}
                                        link={episode8.link}
                                    />
                                </div>

                                <div className="col-sm-6">
                                    <ReelCastEpisodeDescription
                                        heading={episode7.title}
                                        subHeading={episode7.subTitle}
                                        description={episode7.description}
                                        link={episode7.link}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <ReelCastEpisodeDescription
                                        heading={episode6.title}
                                        subHeading={episode6.subTitle}
                                        description={episode6.description}
                                        link={episode6.link}
                                    />
                                </div>

                                <div className="col-sm-6">
                                    <ReelCastEpisodeDescription
                                        heading={episode5.title}
                                        subHeading={episode5.subTitle}
                                        description={episode5.description}
                                        link={episode5.link}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Reelcast CTA */}
                    <ReelCastBanner />

                    {/* Season 1 */}
                    <div className="box box--light">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h3>ReelCast Season 1</h3>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <ReelCastEpisodeDescription
                                        heading={episode4.title}
                                        subHeading={episode4.subTitle}
                                        link={episode4.link}
                                        description={episode4.description}

                                    />
                                </div>
                                <div className="col-sm-6">
                                    <ReelCastEpisodeDescription
                                        heading={episode3.title}
                                        subHeading={episode3.subTitle}
                                        description={episode3.description}
                                        link={episode3.link}
                                    />
                                </div>

                                <div className="col-sm-6">
                                    <ReelCastEpisodeDescription
                                        heading={episode2.title}
                                        subHeading={episode2.subTitle}
                                        description={episode2.description}
                                        link={episode2.link}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <ReelCastEpisodeDescription
                                        heading={episode1.title}
                                        subHeading={episode1.subTitle}
                                        description={episode1.description}
                                        link={episode1.link}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <ReelCastEpisodes episodes={episodes} />
                
                </div>
            </>
        );
    }
}

export default ReelCast;
