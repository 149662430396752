import React from "react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";

class S03E05 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname);
    }
    
    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);

        if (Helmet.canUseDOM) {
            Helmet.renderStatic();
        }
    }

    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }

    componentDidUpdate() {
        if (Helmet.canUseDOM) {
            Helmet.renderStatic();
        }
    }
    
    render() {
        return (
            <>
                <Helmet>
                    <title>{`${this.props.title} | ${this.props.subTitle} | ReelCast by ReelMetrics`}</title>

                    <meta
                        name="Description"
                        content={this.props.description}
                    />
                    <link
                        rel="canonical"
                        href="https://www.reelmetrics.com/s03e05-gaming-arts-with-mike-dreitzer"
                    />

                    {/* Open Graph / Facebook / LinkedIn */}
                    <meta property="og:type" content="website" />
                    <meta
                        property="og:url"
                        content="https://www.reelmetrics.com/s03e05-gaming-arts-with-mike-dreitzer"
                    />
                    <meta property="og:title" content="ReelCast - S03E05" />
                    <meta
                        property="og:description"
                        content="In this episode, Nick and Don speak with Mike Dreitzer, CEO of Gaming Arts. Learn about the risks & rewards of building a slot machine company, the group's startup phase, development philosophy, product portfolio, near-term plans, and its strategic alliance with Germany's Merkur Gaming. Also in this episode, demand balancing multi-game product."
                    />
                    <meta
                        property="og:image"
                        content="https://cdn.buttercms.com/PC2Hh3aFRRin56dd1HJf"
                    />
                    <meta property="og:locale" content="en_US" />
                
                </Helmet>
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}

    S03E05.defaultProps = {
        id: "15085579-s03e05-gaming-arts-with-mike-dreitzer",
        link: "https://www.reelmetrics.com/s03e05-gaming-arts-with-mike-dreitzer",
        title: "Gaming Arts with Mike Dreitzer",
        subTitle: "Season 3, Episode 5",
        description: "In this episode, Nick and Don speak with Mike Dreitzer, CEO of Gaming Arts. Learn about the risks & rewards of building a slot machine company, the group's startup phase, development philosophy, product portfolio, near-term plans, and its strategic alliance with Germany's Merkur Gaming. Also in this episode, demand balancing multi-game product.",
            topics: [
                "Evolution of Gaming Arts",
                "Starting a slot company",
                "Impact of COVID-19 on gaming operations",
                "Strategic partnership with Merkur",
                "Development of unique games",
                "Brian Christopher Pop'n Pays More",
                "Philosophy behind branded games",
                "Product development in the gaming industry"
            ],

        keywords: [
            "Gaming Arts",
            "Mike Dreitzer",
            "CEO",
            "Bingo",
            "Keno",
            "Slot machine manufacturer",
            "COVID-19 impact",
            "Merkur partnership",
            "Casino Wizard",
            "Brian Christopher Pop'n Pays More",
            "Branded games",
            "Product development"
        ],
    buzzSproutLink:
        "https://www.buzzsprout.com/2057836/15085579-s03e05-gaming-arts-with-mike-dreitzer",
    spotifyLink:
        "https://open.spotify.com/show/314iBnXRGGAuttAsIcZeC5",
    applePodcastsLink:
        "https://podcasts.apple.com/us/podcast/reelcast-by-reelmetrics/id1652485625",
    iHeartRadioLink:
        "https://iheart.com/podcast/104029260/",
    cdcLink:
        "https://cdcgaming.com/reelmetrics/s03e05-gaming-arts-with-mike-dreitzer/",
    
    transcript: `Nick Hogan:
    Howdy Don, what's shaking in the St. Louis area today?
    Don Retzlaff:
    Good morning, Nick. Everything's good. Beautiful spring day, how about you? What's the weather like over there?
    Nick Hogan:
    Oh, we actually have decent stuff now. So I was in shorts yesterday unbelievably, but not so today, but we're in the throes of spring now, so it's great. So let's see, Don. So as predicted a few episodes back, there's been a bit more M&A activity on the supply side. So on the 8th of May, AGS announced that it's agreed to be acquired by Bright Star Capital Partners in an all-cash deal valued at 1250 per share or 1.1 billion overall. AGS's CEO, David Lopez commenting on the deal stated it will leave AGS quote, "Well positioned to make targeted investments in R&D talent operations and industry leading innovation, which should accelerate our global footprint." So kind of tough to disagree with that. First, I think that the capital infusion comes at a great time for a small company with a lot of great momentum and secondly, I think what's the nice thing in here is they can jettison all that administrative and reporting burden inherent to publicly listed companies. So in a nutshell, my guess is it'll make a nimble company even nimbler. Any thoughts from your side on this, Don?
    Don Retzlaff:
    Just the performance side. They've been on a little bit of a roll here the last 18 months. They've got several good themes out there, so they're doing well. They're definitely on an upward projection.
    Nick Hogan:
    Yep, kind of my view of it as well. Okay, so Don, listener question time. Before we jump in here, let me say that we'd love to tackle any questions that any way listing may have. So if you have a question about what we're presenting or something you'd like us to present, please drop us an email at realcastrealmetrics.com. That's R-E-E-L-C-A-S-T at realmetrics.com. Our policy is to keep all questions anonymous, so please speak directly and don't worry about us revealing your identity, that's not something we do. Okay, so the first question comes from an operator in Southern Europe who asks, "Good afternoon gentlemen. I saw Nick's talk in Amsterdam last month and was fascinated by the points he made about over diversified slot floors and the tendency to let doggy products crowd out superstar products. Following the talk, we looked at our own operations and we're shocked to see that this is precisely what's happening on our floors."
    That's interesting. "My question relates to multi-game slots. We have many and when we analyze the game level detail, which isn't easy, we see two things, a very small number of titles generating around 90% of the revenue and big variances in average bets. In many cases, we see average bet ranges from 50 Euro cents to more than 15 Euros per spin with the higher average bets producing around three quarters of the revenue performance. In Europe, the philosophy has been that multi-games allow smaller operations to pack more variety into smaller floors. This has been viewed as a good thing, a one-size-fits-all approach. However, following the talk, I have the impression that it's actually the same problem. Large numbers of poorly performing titles crowding out a small number of great titles and big players failing to access their favorite games because less valuable players are in the seats. Am I thinking about this correctly?"
    So first, enormous thanks to the listener for that question. It's really a great one, and now Don, I'm not sure where you land on this, but I personally have always had kind of a tortured relationship with multi-game slots. So on the one hand it's a good format for obviously for slot hauls and route operations where you have low statutory unit caps and really limited physical real estate. So let's say in the UK for example, when you have high traffic levels and regulations at cap slots at let's say 20 units per venue, the multi-game makes a ton of sense. Also, as a platform for experimentation, let's say smaller companies that are kind of test marketing, multiple titles on one box, also some great arguments in that format, and then for certain non-slot game types, so some ETG stuff, poker, Keno, bingo, etc.
    It's really well established and from what I've seen anyway, it's fine, but as pointed out by the listener, analysis is always the first problem. Can you get to the game level detail? And if you can't, the arguments in favor of the format lose quite a bit of luster, and then secondly, as it pertains to the over diversification and constraining host level favorites, I would say yes, it's the same problem in a different package. So if you're pointing the 50 cent players and the 15 Euro players to the same box, what you're really doing is playing fast and loose with a 30X revenue multiple. So in my book anyway, it's a fairly dangerous strategy. So my answer to that listener's question would be yes, you're thinking about this correctly, but Don, what are your thoughts on this?
    Don Retzlaff:
    I've always been a big fan of giving players more options and you're right, especially if you look at things like the Illinois VGT market, you're going to have five, eight machines. You want to have as many options on a box as you can. In a big casino, there are obviously things like Game King, Casino Wizard, Selection. You're going to have a lot of different choices and those games usually perform pretty well. It is not easy to get the information on the theme by theme basis. It was one of my slot tech's worst assignments. They hated going game by game and trying to get all the coin in and win information from it, but you really need it because you want to limit those screens to one page if you can.
    I used Selection a lot on games that were declining in revenue from Konami, especially when they weren't coming out with very many new games. So I gave them a selection. Game King's probably the best, Casino Wizard, those are games that do really well, but you really do want to limit it to one screen unless you just have a massive number of hits. It's going to be interesting. I know aristocrats come out with a game where you're going to see all the classics on it. So that's going to be one that's going to be interesting to see set up across the industry. Are you going to have nine titles, 12 titles, 18 titles? What are you going to have? And it becomes more of what we're seeing online.
    Nick Hogan:
    And we do see in Europe, I've seen a lot of multi-game terminals out there where you're talking about in excess of a hundred titles on a single box, and that always just seemed to me like, "Yeah, I don't know about this." But okay, very good. Well, thank you to the listener for that question. It was a truly great one and we truly appreciate it, so thank you for that. Okay, Don, time has come to introduce today's guest. So this gentleman is a New York native and a graduate of both Cornell University and Emory Law School. He began his career with a three-year stent as Nevada's Deputy Attorney General, and from there he moved on to serve as EVP and general counsel at MyCon/Progressive Gaming, Co-Founder and COO at Global Gaming Group, COO at BMM Test Labs, and President at Ainsworth North America. These days, he serves as Chief executive Officer at Gaming Arts, and I'm referring of course to Mr. Mike Dreitzer. Mike, how are things in [inaudible 00:08:21] this morning?
    Mike Dreitzer:
    Thanks for that introduction. I hardly recognize who you were talking about. Things are nice. Actually, it's that period between spring and summer where it starts to get hot, but it's not fatally hot yet.
    Nick Hogan:
    Yeah, your skin isn't melting yet.
    Mike Dreitzer:
    Right, but yeah, things are good. Now, as you indicated, lots of activity in our space.
    Nick Hogan:
    For sure and in the interest of full disclosure thing about how the two of us are connected, but concluded that would probably take little too much time. So I guess the quick version is we've known each other for about 25 years, we've been colleagues twice. Your brother is our American legal counsel. I'm not sure what I'm missing. There's some Iowa connections as well back in there.
    Mike Dreitzer:
    And I lived in Leiden for a time too.
    Nick Hogan:
    That's true. Yeah, that's right. Did you do a year of law school here? Was that the reason you were here?
    Mike Dreitzer:
    I did a semester and then stayed a little longer, but I was there back in 1993 in Leiden, yes.
    Nick Hogan:
    Man, okay and you have only fond memories of the place?
    Mike Dreitzer:
    Absolutely. It was a great experience. It was my first time abroad ever and really enjoyed the people and the classwork and yeah, very fond memories. I've been back a couple of times since and Holland is one of my favorite countries in Europe.
    Nick Hogan:
    Oh, great. Yeah, cool. Well, it's a nice little town. It's a great place to live and the fun thing is I meet a lot of attorneys and also doctors who spent a lot of time here and everybody always seems to have really fond memories of the place. I think mainly because they were students and partying a lot.
    Mike Dreitzer:
    There is that, yes, but enjoyed the beer immensely. Enjoyed the market days and the canals. It was great.
    Nick Hogan:
    Oh yeah, cool. Okay, so we get jumping in here. So Don, you want to lead off on this?
    Don Retzlaff:
    Sure. Mike, I don't know you as well as Nick. Could you give us a little bit of your background on your gaming career? Where did you start, when did you start and what was your path to get to where you are today?
    Mike Dreitzer:
    Well, sure, I can talk a little bit about that. So as Nick indicated, I started at the Attorney General's office. So my entree into the gaming space was through the legal and regulatory side. Early on, I was counseled to the Nevada Gaming control board and this was a time in the late '90s where things were booming and a lot was happening. So I was able to learn a lot, particularly from the great members at the time of the gaming control board, Bill Bible was chairman of the board, and then Steve Ducharme and many others were around and so learned a lot from them in a couple of years about how this all works, and so that was my entree into the industry. From there, I went to MyCon, which was my first stop in working with Nick. The interesting world that was MyCon at the time on the supplier side, that was my first job in gaming. Very interesting, a lot of trial by fire, a lot of learning what to do, a lot of learning what not to do.
    So that was a very interesting experience with all these companies that had all of a sudden gone public in the mid and late '90s as a result of the craze where if you had gaming in your name at the time, you were able to go public and frankly, there are many companies in my view at that stage who went public that shouldn't have been public and conducted themselves in ways that they were sort of acting private, but were public. So we did that for a number of years, and then my path has been in the supplier side of course and in particular in the smaller part of the supplier side. So I have had a lot of great experience developing the smaller companies. Obviously, that comes with a lot of challenges. It also can be very rewarding when you find success.
    In the interim there, my second stop with Nick had a stint at BMM Test Labs, which was obviously a little bit different than the other roles that I'd had, which was interesting. BMM was in hypergrowth mode at the time in North America, so enjoyed that as well, but I have been truly blessed to have a wonderful career now spanning almost 25 years, and you win some, you lose some, but at the end of the day, it's really about the people and the friends that I've made and the relationships that I've forged and we've had a bit of success along the way, setbacks, and I think we've sort of left the companies in a better place than we started with them so to speak as we moved along here, and now I'm super excited about being with Gaming Arts. It's been six years or so and we have a really interesting story to tell and I definitely appreciate you all and thank you for allowing me to come on and talk with you and tell you about our story.
    Don Retzlaff:
    Well, let's talk a little bit about Gaming Arts, can you tell us about the early days of Gaming Arts, how it was founded, the financing, how everything came together?
    Mike Dreitzer:
    Sure, so I joined in early 2018. Prior to that, Gaming Arts was a bingo and keynote company and when I say bingo, I don't mean class two slots, I mean actual bingo, daub or bingo type thing.
    Nick Hogan:
    Oh, okay.
    Mike Dreitzer:
    Where the players put up their good luck symbols and they rubbed the cards and all that sort of stuff and of course, Keno, which is an important but very small piece of the gaming puzzle, and so I started conversations with David Colvin, who is the owner of Gaming Arts, and he had this vision to start a slot machine manufacturer supplier from scratch, and this was in late 2017, early 18 and for whatever reason, I was taken by his passion. David has a very interesting background. He's an entrepreneur and a prolific patent owner. He's patented many different things from unique wrenches that have excessive torque to codes to prevent a software piracy, and he literally is the story of the inventor in Michigan who took on Microsoft and won. So it's a very interesting story and he had long been a gambler and had been coming to Vegas I think as early as the '70s, and was taken by the industry and said to me, "Can we work together?"
    Can I use my experience to help him realize his vision of moving forward with a slot company? Now, it would've been absolutely fair game to tell him he's nuts and shake his hand and to move on because in 2018, to start a slot business was a crazy notion given where the industry was at that point, much less now, this was even six years ago, but nevertheless, we got into a partnership so to speak where we were going to build a slot business. We're going to respect and continue to grow the Mingo and Keno business, which we've done, although it's small and it's sort of limited in its outlook, but still great business, great partners in the industry that are our customers. So we set a course to build a slot business in 2018, and that of course is a playbook that I'm reasonably familiar with having done it to a certain extent at Global Gaming Group with Benny Sum, who I think Nick.
    And then also helped to grow the slot business at Ainsworth as well for the time I was there for North America. So yeah, it's a playbook and so you have to make sure that you have all of your swim lanes set up within the pool so to speak, and so starting in 2018, we set about the business of setting up the business and my first hire was Gene Veneman, who is a very well established and well respected industry veteran. Gene had been a leader at both IGT in their heyday as well as Valley, and so I thought who else would be a better partner than that? And Gene and I have worked with David and building this team here the last six plus years. So we did all the things you'd expect.
    First thing is we have to get a platform for games, that's critical and then you build your R&D team around that and then you build up all the, for lack of a better term, swim lanes or verticals within the company to build up the company. So we got going and things were going well. We had our first install in 2019, it was April of 2019 in San Diego. And then a little thing that you might've heard of hit called COVID and that to say it knocked us back. I mean it knocked the world back, right? So the question was what now? We just had started to build a little bit of momentum and what were we going to do? Like most other companies in our space and in the world, we lost all of our customers, right? Because the casinos were closed or at least severely impaired and so we sat down and I said, "Well, what is it that we can and should keep going? We had massive furloughs as everybody did, but what can we keep going?"
    And we made two bets, so to speak. The first bet was to at some measure, keep the sales team going because I felt that if we didn't fly the Gaming Arts flag, it would just be lost and be done, and so we kept sales employed and engaged fully to have them interact with the customers essentially during this period to say, "Hey, we're still here." And so there was a lot of virtual happy hours, virtual teenies, things like that. Everybody cheers-ing over Zoom and that sort of thing to keep that, and then the other bet so to speak that we had to keep going was with R&D because we needed to continue that process. We were behind enough having started in 2018 and so is there a chance for us during that period to catch up if you will, and keep making games, and so we did that and we built what became the basis of our games portfolio through COVID because we kept going, and so those were the two areas and then obviously things started to recover and get better, and then here we are. So it's been a very interesting journey.
    The one thing I've learned is I came into the industry as a regulatory guy and everybody has different sort of entry points. You see people come in on the finance side, on the sales side, but I think the thing that really resonates at this point, having gone through the mistakes and the struggles and the successes is we have to all be product people. At the end of the day, that is the thing that matters most of all. So yeah, I'm happy to talk more about that product philosophies and things that we do to try and set ourselves apart in a very crowded and expensive space, but at the end of the day, if you ask me what I've learned in 25 years, I would say obviously relationships are paramount, of course and then product.
    Nick Hogan:
    Now interestingly, so you mentioned that when David said he wanted to start a slot company, you were thinking, "Are you nuts?" And I think everybody on the line here, we know that there are some big inherent challenges to creating a slot company. It's capitally very intensive, the reg work is really heavy, and then the failure rates in product are absolutely gigantic and we recently spoke to another person with whom you've worked twice and know well, and that's Kelcey Allison and I liked the way he phrased it, he said, "Yes, starting a slot company you have to spend three million to do a million in sales." I thought that was pretty much a great summary.
    Mike Dreitzer:
    If you're lucky, right? So my philosophy in this as largely shaped, there's an industry veteran that you may have heard of, it's been around a long time. His name is Nick Hogan. I don't know if you've heard of that guy, and not to pump tires here, but Nick said something. Nick, you said something I believe at an event or what have you, and that you've since repeated to me that I wake up and work with every single day because it really does resonate and that is that approximately, and I'm paraphrasing, but approximately 18% of games on the floor reach house average, something to that effect.
    Nick Hogan:
    Yeah, that's around there. Yeah, I think that's right. At any point in their life cycle.
    Mike Dreitzer:
    And so I look at that going, oh my god, that confirms the mountain that we're climbing. The flip side of that is 82, 83% of games that don't, right? And so when you have massive R&D budgets and the ability to create hundreds of games, literally you're going to have a massive amount of failure as every company does, but for the larger companies, I think that's okay because you'll also find your success amongst that larger number of games and larger companies in our industry have done very well to focus on the success and maybe sort of tweak the failures, but the point is, yes, it's a massive amount of failure that you have to go through when you're a company like us that for lack of a better term has fewer bullets to fire or whatever the analogy you want to use. It's just empirically difficult. If I'm making 10 or 12 games a year with that kind of failure rate, I'd be lucky to get one game, maybe two, but probably one that will hit house average or better.
    And so that's just a super daunting task and so earlier in my career, I made the mistake of saying, "Well, I can sort of address these other areas." I certainly have the regulatory background and we know how to build a team and I've been fairly good in the business development space and all that, but the product stuff, that's the black box and I'm not a product guy, so I'll have to defer and rely, which I still do obviously because if I were making games, they'd be stick figures, but I think we all need to have a strong product consciousness, a strong product sensibility even if we're not product people to really all support and be part of the product process.
    And so lately, I've been much more involved and we've been much more strategic about the manner in which we select product and what categories we're trying to get into, and I'm happy to talk about all that, but it is quite the hill to climb and it's sort of like I don't know, you could look at this, let's say you know nothing about gaming, you come in, you say, "I'm going to jump into slot manufacturing." If you look at this sort of the analytics and the profile of particular land-based gaming, you could make a case that we're sort of firely insane getting into this business.
    In many ways, it doesn't make logical sense, but on the other hand, every moment there's hope, right? You never know where the next great game is coming from. There have been multiple examples of companies frankly our size in the last year or two, we all know what they are. They have defied the odds and they have transcended this product chasm if you will, and done a great job, and so we go at it every day with the creative belief that we are next. Now we have had a couple of successes as of late with maybe a small S, which I'll talk about, but that's the joy, that's the passion is to say we're going to figure this out.
    Nick Hogan:
    Now, with the original business plan, I am assuming you had a unique angle or selling proposition that you guys were pursuing. So can you maybe illuminate that a little bit and then also maybe explain how that's changed a bit since the very early days and how you've evolved on that front?
    Mike Dreitzer:
    Oh, absolutely. So David Colvin likes to say that we need to be unique in everything we do. He is very much an iconoclast, he's very much someone who goes the different direction from others and so early on the philosophy was we're going to create these great games and they're going to be very different and they're going to have an entertainment value that perhaps isn't seen or hasn't been seen and we're going to create a new mousetrap so to speak, and you can imagine that particular strategy, while inspiring also is one that sets you up in this space for a whole lot of lumps and a whole lot of core performance.
    So early on, yes, Nick, we tried really super different stuff. We had games, Dice Seekers was one that was incredibly unique. We had a hamster who was a luchador in another game and had some uniqueness to it, and so I think what we learned was that while it's a good idea to have a segment of your portfolio with uniqueness and with sort of mechanics that maybe haven't been seen. As a smaller company, honestly, you can do a little bit of that, but you also have to fish where the fish are, right? The Blue Ocean strategy or whatever they say.
    I think it's more important to go to the games and to the mechanics that the customer's players have proven they like and proven that they want to play, and so the last five or six years has been very much in terms of the change question you had, a migration. We'll still do a couple of super unique things, and I can talk about the Brian Christopher game, which we've been very proud of and I think it's been groundbreaking for us and working with Brian has been a joy, it's been terrific, but we also need to really and are doing more that is grounded in what the players want, what the top players want, and I know discussions with you guys have helped frame that and countless others. We can't just say, "Oh, we'll be different. We'll be unique." Because that is not a recipe for success given the current guidelines in our space.
    Nick Hogan:
    Yeah, got you. Yeah, no, you mentioned Brian Christopher Pop'n Pays More, and we had Brian on several months ago and we discussed this a bit and he was stating very clearly that is by far his favorite game.
    Mike Dreitzer:
    There you go.
    Nick Hogan:
    How did that all come together? I mean you guys, it seems like a natural that somebody wanted to capitalize upon what he's been doing, so how did that whole thing come together with you guys?
    Mike Dreitzer:
    Yeah, so we had of course been monitoring the influencer space. It's obviously a trend that we've all seen in the last couple of years, particularly through COVID and beyond and really, it's such a fine line for us because yes, as I said a moment ago, on one hand you want to create games that are consistent with what players want, but on the other hand, you also have to stand out and you have to find a way to say, "Hey, I'm over here. Try this game." In a crowded, very entertaining floor. So you try and thread that needle in different ways and we have long been observers of Brian, and we saw the really organic and compelling relationship that he had with his players.
    Don Retzlaff:
    Oh, yeah.
    Mike Dreitzer:
    You talk to some of them and they say, "Well, Brian got us through COVID. I look forward to his videos and I look forward to interactions with him and he does his cruises, he does his meet and greets, and there's a very sort of emotional connection, and we say, "Well, that's really something that we can seek to capture." Maybe players want to have that emotional experience while playing the Brian Christopher game, but at the same time, we needed to do something that was a little bit more tried and true from a mechanic standpoint. So we had a game called Pop'n Pays More. That had been a successful game for us early on in our early days and so we said, "What if we sort of marry the two and give a mechanic that we know has had some success and couple that with Brian and his persona."
    And so what was born was the Brian Christopher game, which of course Brian's been very supportive and yes, it's his favorite game and it's one of our favorite games and it really lends itself to merchandising, right? Because Brian will appear, Brian has great relationships with many casinos across the US so it all sort of fit together and we thought this is a way for us to stand out while at the same time being a little bit truer to mechanics that we know work, but it is a tough proposition, because you're basically saying you want the tall person who's short and all that sort of stuff, and so it's marrying these two ideas of being new and fresh while at the same time being more traditional and giving players what we know has given us success. Super proud of that though.
    Don Retzlaff:
    Absolutely. I remember a couple years ago with G2E looking at your new cabinets and they're visually striking. I think it was Kung Fu Empress was the title I was looking at and the cabinet looks great, the graphics, the sound, the speed of the play. The cabinets very solid. I don't think you can improve on it.
    Nick Hogan:
    That's the Verdex you're talking about Don?
    Don Retzlaff:
    Which one the focus or the two cabinets that look really good on the floor and I'm a big fan of Casino Wizard. I love Pai gow Poker and being able to play on Casino Wizard too, Pai gow was just fantastic for me, but what about your product before portfolio? You've talked about it a little bit, what are the areas that you're developing games in? I know you've talked about unique math models. Those can be very challenging. We don't see a whole lot unique math models out there successful, and when they are successful, we're seeing them in prime locations. Obviously, we've seen a lot of stuff come out from all the major and minor manufacturers, but the traditional stuff that works well for everybody, especially for the host level players, seems to be where everyone's heading right now. So what do you see for your future for the next 12 months or so for your products?
    Mike Dreitzer:
    Yeah, well Don, you touched on some really key points in terms of our portfolio and our strategy, so I'll just touch on two of them and then talk about the next 12 months. So the Kung Fu Empress game. Yeah, it's super unique and it definitely has great features, great graphics, and now that is with a partnership with a third party studio in Japan called Yamasa and we've been very close and built a number of games with them. They have a strong history and heritage in the Pachi slot space as you can probably tell from the game, it's beautifully done. It has anime sort of characters in its roots and so that was an opportunity to do something unique, but at the same time, we work with them on coming up with the right math, in a math that we thought would be compelling based upon what was out there and it did very well for us because Wizard is an ETG in a slot box, which we also felt was sort of threading that same needle.
    On one hand, we wanted to give players play games that they wanted to play, and Blackjack in 2024 is very similar if not identical to Blackjack in 1974. It is timeless and so that was a way of giving players what they want and an opportunity to play Blackjack and high gown roulette and craps, and we thought that that was super unique because the typical ETG form factor are much larger. This is a much more flexible ETG form factor and so we've had some great success with Casino Wizard. Our slogan is every casino should have at least one Casino Wizard box on its floor, and we believe that's bearing itself out, and it also is an opportunity for players to maybe learn games that are of interest to them. Let's say you decide I want to learn craps. As you well know, anybody who stood at a craps table, that can be fairly intimidating to figure out what's going on with the dice and the box men and all that stuff.
    So you can sort of sit by yourself if you will, or with your one other person and sort of learn. So it's a good learning box if you want to play those games or play Pai gow in a less intimidating environment and maybe a little bit less social while you figure out what's going on.
    Nick Hogan:
    Mike, is it a game pack? So is that a multi-game pack always? Okay, gotcha, with all ETG titles.
    Mike Dreitzer:
    Well, yes, multi-packing and then we did the Vertex Grand, it has Casino Wizard, a version of it that has eight titles that we added some proprietary poker and some other things, but yeah, at core it's an ETG like others except just in a different form factor. We say it's a pit in a box. So we found that those were ways to stand out while at the same time being true to things that we know players would like on the casino floor, but having said that, when we go to our five real slots, we have had a lot of lack of success, frankly, in our five real slot games and I think that this is attributable to we've tried some different things and maybe some of the stuff that we've tried, Don and Nick, were too different, and the mechanics, we thought they were interesting and cool and would sort of forge a new path, but that's a really hard mark to hit as you well know.
    So we definitely in the last 18 months, and these things take time. It's literally taking a ship and saying, "Okay, now we're going to go this way." And we are really much more focused on the market and what players are currently playing and can we take tried and true, things that players want to play, game mechanics that are tried and true and can we put our little twist on them, our little improvement on them, and going more towards the blue ocean? Most definitely.
    We just finished a what we call a game summit where a lot of the team gets together and talks about games for the 25 roadmap, and this was a much different meeting for us than it's ever been because we now need to focus more on that and we see our competitors in our segment of the space, what I'll call the smaller guys, they're starting to find games that work and we need to do more of that sort of thing and we are, it just takes time, and then there's sort of this other piece that we have that has been our most successful game to date, which is unique, but at the same time it's also very recognizable, which is Deal or No Deal.
    We've had for us, excellent success with that game and that of course is different. It's not a fast follow or what have you, but it's different, but people, Deal or No Deal, they want to buy the features so they can play the Deal or No Deal game. There are thousands of videos online of people playing the Deal or No Deal feature, and so that's unique, but in terms of the core of our five real roadmap, it has to be closer to what the players want and what is more tried and true. I think in a G2E and beyond, you're going to see a lot of that from us.
    Don Retzlaff:
    Awesome. Well, you mentioned Deal or No Deal. We've talked about Brian Christopher. What is your philosophy on going out there and getting the rights to these names, these TV shows, movies, celebrities? What's your philosophy on that?
    Nick Hogan:
    Do you want to do a bunch of brand slapping, Mike, or how do you feel about it? Yeah, just in general.
    Mike Dreitzer:
    Okay, so I would say this, I would never ever waste time doing a brand slap, right?
    Don Retzlaff:
    Yeah.
    Mike Dreitzer:
    I think that obviously we have a long 25-year history in our industry of titles that have worked famously, right? Wheel of Fortune and such, and then other titles, maybe Ellen or others that haven't worked, right?
    Nick Hogan:
    Oh boy, we could sit here all day to listen to those.
    Mike Dreitzer:
    Yeah, for sure and we all have, and I think that a couple things. If you have a game or a family of games that you think a particular brand could lend itself to, then perhaps it's worth going and getting that brand. So I don't do it the other way around. I don't say, "Hey, there's a great brand, let's build a game around the brand." I think you have to have a game and then if a brand can enhance the game, an individual, what have you, then you should go and get it.
    Deal of No Deal was a little unique in that we felt that the bonus round had resonance in terms of how it was, so that was one worthy of going after, but that's only the first piece, right? The second piece is to get the licensors, the brand holders to understand that this isn't 2003, that the days of the eight figure, seven figure advance are gone, and if they're willing to work with you to earn on the back end, right? Feet per day or whatever, the notion that they're putting their name, image, likeness or what have you and then we're going to put a lot of capital to develop the game, then of course get it approved and then put it out in boxes, then that's a partnership and you'll be rewarded if the game goes well on the back end.
    So I would say, "Let's build a game." If there's a brand that can enhance the game, let's do it and then let's make sure that it fits into the 2024 economic model, and of all those boxes check, then we're good. With respect to Deal or No Deal, the brand was brought to us and the licensor Banajay has been a wonderful partner. They fully understand where the branded business is these days and have worked with us and it's been tremendous, and then there's been a bit of a renaissance, as you see in the US there's Deal or No Deal Island, so Deal or No Deal's out there again, it's sort of a timeless game and as you can see online with the countless YouTube videos, people love to play our bonus round. So yeah, it's a mix of all the above.
    And we are, yes, Nick pursuing other brands that are I think good to enhance families of games. So I know that the industry was heavily branded and then it was no branding, and then it came back a little then a lot of people are weary, they don't want to do it, but honestly, I think if you can get a mechanic that is tried and true and consistent with what players want to play and then get the brand to enhance that, it's a good strategy. It's a good way to stand out because people will come to play a branded game, but in my opinion, they won't stay there if the gameplay is not what they're hoping for.
    Nick Hogan:
    Yeah, we got some attention.
    Mike Dreitzer:
    You'll get noticed, people are going to try the game and then from there, it's up to you to make the game that people will stay at. So yeah, there's a place for branding as long as it's consistent with the games that we're making and it enhances the value proposition to the player.
    Nick Hogan:
    Now, it's interesting what you said. I wasn't aware that people aren't really doing the advances anymore because I do know a couple of cases. I mean there was one, I know it was like a $30 million advance and the thing just didn't work, and I mean that is not easy to bounce back from something like that. So very interested to hear. So nobody's doing that anymore, the advanced days are over?
    Mike Dreitzer:
    Well, I'll say this, the only one I can truly speak for is Gaming Arts, right? It just from an economic deal making standpoint, it's not something we can do because it doesn't per se pencil outright, and so just generally speaking, yes, you can talk about guarantees from the perspective of can you put out a certain number of titles, this sort of thing, but really I think the question that license holders and rights holders should be asking is how quickly can you get the game? And not that you want to brand slap, but will you give it a priority within your queue? And that's one of the benefits of a small company.
    I think we have a nimbler queue if you will to be able to make adjustments. If we can marry a brand with a really good game and say, "Hey, we should do this." Then we be more nimble around getting product out. So those are the questions I think they are asking us, and they should be asking, "How many can we get out all of that as opposed to how much do I get up front?" In this day, not a reasonable economic approach for anybody.
    Nick Hogan:
    Right. Yeah, for sure and Mike, how many before we got started today, I was telling Don, out of all the people I know in the industry, nobody is better at hoovering up regulatory approvals than Mike Dreitzer. So I wanted to see, you're so great on the reg fund, etc. How many jurisdictions are you guys licensed now?
    Mike Dreitzer:
    We're licensed in approximately 160. I say approximately because we're sort of always adding, and that includes commercial jurisdictions, the states if you will, Nevada, Mississippi, Indiana, New Jersey, so forth, those states and then of course tribally, there are multiple tribes in states that have tribal gaming. So yeah, we're pushing 160 plus.
    Nick Hogan:
    Okay, fantastic.
    Mike Dreitzer:
    Which incidentally, there's many more to get on the commercial side and on the tribal side, but we do the best we can.
    Nick Hogan:
    Yeah, and you have to obviously prioritize, and not all these markets are going to give you returns, etc., but that's impressive. 160, that's quite great coverage. Okay, so now let's see, it was October of last year, you all announced that you had entered a strategic agreement with Merkur/Gauselmann Group out of Germany. So first, for those who aren't familiar with Merkur, maybe you can give us a sense of summarize the company a bit, and then second, just give us some senses to the nature of the agreement and what you all are up to.
    Mike Dreitzer:
    Absolutely, yeah. So correct. In October of last year, right around G2E, we announced a strategic partnership with Merkur Gauselmann Group, now known as Merkur. They are a very large and very successful gaming manufacturer based in Germany. They are hugely successful throughout Europe and have a strong presence in Latin America as well. They have for a long time been very active of course in the German market, in what they call the street market as well as the casinos there all throughout Germany, and then of course all throughout Europe. Years ago, Merkur was involved with a company called Atronic that was a manufacturer supplier in the United States.
    And so they had familiarity with the US market and more importantly recently, a strong interest in getting more involved in the US market. So I began a conversation with some of the executives there, even actually pre-COVID, to talk about synergies and ways that we could work together in the US market and perhaps elsewhere, and so that relationship has grown to where we have this strategic relationship. They are very strong in areas that would very beneficial to us, which I'm happy to talk about and then we certainly have developed a platform for the US market, and so I think one-on-one is really three in that context. I think we can work together in ways that will benefit both parties tremendously.
    Nick Hogan:
    Now, Gauselmann as you mentioned, they operate heavily in Germany, so the Spielhallen as they call them, the slot halls that are around, it's a huge, I don't know how many units they have out in Germany, but it's a huge number and so in their Latin American operations, are they operating down there as well? Do they have their own venues and whatnot?
    Mike Dreitzer:
    They operate a lot of machines in Latin America.
    Nick Hogan:
    Are they their own operations or is it all on a supplier basis?
    Mike Dreitzer:
    I'd have to check it. I don't believe so. I believe that they're a supplier in most cases.
    Nick Hogan:
    Okay, just wasn't sure.
    Mike Dreitzer:
    But it's a substantial operation. They've been down in Latin America for some time, and it just makes natural sense for them to extend and continue and show interest and want to get involved in the US market. North America is defined as in this case, the US and Canada.
    So the synergies are tremendous in us working together. So far the cooperation's been really strong in a few different ways, which I can touch on a you'd like.
    Nick Hogan:
    Yeah, I'd love to hear it.
    Mike Dreitzer:
    We look forward to a very strong G2E. So a couple of things, they have access as you might imagine through a number of their own companies, to great content, great games, compelling games. Games that we've seen have worked online, have worked in other markets. Now, we definitely understand and they understand and everybody understands going into this, you really cannot take for example, a slot cabinet from Germany or Europe and just put it in the United States or you can't just take a game in Germany or other parts of Europe and put it in the United States. There's a fair amount of localization that needs to be done across the entire product.
    And frankly, that's a doable thing and we are working together collaboratively and mindfully to take their boxes and their games and localize them for the North American market, and so we've been really working very hard and mindful across the two companies to come up with the best representations of that, but as we go through that process, there's a whole lot to show at G2E and beyond, which we will and very excited because when you conclude that localization process, you end up with world-class hardware, you end up with an extension of our content library beyond what we could ever do by ourselves, and so we're really excited in the run-up here to G2E and beyond to start to show off some of the benefits of that collaboration on the game side, on the hardware side.
    You're going to see a whole bunch of new ideas, a whole bunch of new hardware form factors that will make us jointly as we continue in our collaboration, very competitive in this process. So they've been a joy of work with tremendous partners. We definitely have identified how their strengths help improve our weaknesses and how our localization capabilities can really help them understand and be more involved in the US market. So it's been a wonderful collaboration and I think G2E will be the next step for us jointly to be able to take things to the next level, so to speak. I think a lot of people are going to turn their heads and say, "Wow, what have these guys been working on under the hood so to speak as strategic partners." So we look forward to that.
    Nick Hogan:
    Great. Yeah, and as you mentioned, I mean the depth and breadth of that experience inside that company is just kind of endless. I know a lot of people in there and there's some really great minds in that organization for sure.
    Mike Dreitzer:
    I can't say enough great things about AmeriCorps as a company. They are strategic, they are very interested to learn. They're very respectful of the fact that they don't have the expertise within the US market. So on a collaborative basis, it's really terrific to work with their entire team and so very much looking forward to continuing to grow that relationship as a core strategic tenant to our plants moving forward.
    Nick Hogan:
    Excellent. Great, so let's see, Don, did you have more questions for Mike today? I see we're coming kind of toward up on the hour here.
    Don Retzlaff:
    No, I was just thinking about one thing though that he mentioned and talking about ETGs and Casino Wizard and we see such a high failure rate and such low win per seat rates for casinos where most of the people are lucky to get their fees back on some of these games where Casino Wizard's doing basically house average or above house average at most of the placements, and I've really never thought about that before to where the success rate for an ETG, obviously it's large and the wind per units are really small versus you can get house off of a slot box that plays ETGs, kind of a compelling argument and like I said, it plays really well. I've played it, I played both one and two and heck, I think the first time I played it was at the Venetian at G2E a couple of years ago. They had a placement right there by their ETGs by the sports book. So it's one of my favorite games to play and it's very well done.
    Mike Dreitzer:
    I appreciate that and I think it's interesting, and I'd be curious your guys take on this. The question for ETGs is one of, depends on how you look at it, right? Because on one hand, and especially if you have what we have, which is the hybrid, it's a slot box. So do you look at our Casino Wizard ETG as a slot or do you say that it's an incremental wallet and therefore should be judged differently? And that's a conversation that we have with operators on a regular basis, which way do you look at it? And frankly, we look at it as incremental wallet that they wouldn't otherwise get, but if you judge us against a slot, oftentimes it would be real difficult to achieve the metric that they need. So that's how I see it.
    Don Retzlaff:
    No, it's a great point. We just had this conversation with a client with Keno and other games where would you lose revenue if you pulled these games? And I think the answer to a lot of this is yes, the Keno players won't play anything else, and you've got some dedicated Casino Wizard players that if they can't play that, there's nothing else for them to play. That's what they play, that's what they go there for. So I think you have to judge them differently and you have to look at the revenue losses if you pull these games, you think of a traditional five real game, they may play something else, they're likely to play something else, but for these specialized games like Casino Wizard or Keno, ATGs, a lot of times that's the only reason they're coming to the casino is to play that and if it's not there, they'll play at another casino where they can play it.
    Nick Hogan:
    And in fact, we just discussed this I think a few weeks back with Dan Cherry as I recall, and I'm the one who's always bitchier on this topic because I'm always saying, "Oh, we put some slots there." But then you and Dan, both of whom I have a lot of time for Don and Dan, and they're saying, "Hold on there pal, you're looking at this the wrong way.
    Mike Dreitzer:
    The other piece that's interesting too that and I'll just put it cherry on top of that is we see locations that have one or two super high rollers, right? They go right to the high limit and they will keep a casino wizard on the floor for one player because God forbid you take that game away from that player who is a huge player, the top rating of the top casinos that we see sometimes, and they're playing that machine and so that's certainly incremental, and if they can't play that game there, then yes, they will go elsewhere.
    Nick Hogan:
    No, you're absolutely right because we get this question from operators all the time, which is we have this whatever slot on the floor, it's maybe a classic or whatever, but this one big player comes and hammers it and we always just tell them, "Leave it there. Don't mess around with this. You leave that thing in place."
    Mike Dreitzer:
    Absolutely, and there are these great stories of other manufacturers I used to work at Ainsworth for example, and they had their old box called the Ambassador if you remember.
    Nick Hogan:
    Oh, yes.
    Mike Dreitzer:
    And there was like in some casinos, there'd be like one person who's been playing that ambassador for 20 years and they can't get the parts. The casinos go on eBay to try and find parts that are no longer existing. They cannibalize the heck out of it just to keep the game running for the one guy.
    Nick Hogan:
    Was that not their first platform that they put out? It was among the first, yeah, like in 2020, or excuse me, around 2000 I think, right?
    Mike Dreitzer:
    Yeah, early on and they had the smaller screens and very different from obviously what we see today and yeah, so some of the slot directors say, "You touch that game, I'll break your hands." Right?
    Don Retzlaff:
    I still see them on the floor.
    Mike Dreitzer:
    Oh yeah, you do.
    Don Retzlaff:
    Absolutely.
    Mike Dreitzer:
    For sure, that's super interesting.
    Nick Hogan:
    Yeah, indeed. Okay, so Mike, are there any upcoming company events or initiatives that you'd like to plug here on our podcast?
    Mike Dreitzer:
    Well, yeah, I mean first of all, thank you for taking the time. We always enjoy the opportunity to tell the Gaming Arts story in full both with the failures and then the successes, right? I think it's instructive and interesting. We are really going to put our head down this summer, get ready for polishing and preparing for G2E. I think this will be without question by a mile, our most important G2E and we look forward to really turning heads and really changing perceptions, frankly in an improvement of where Gaming Arts has been and where we're going. So we really look forward to it.
    Nick Hogan:
    Are you sharing a booth with Merkur this year, or are you doing your own?
    Mike Dreitzer:
    Yeah, so we'll be displaying within Merkur booth and really showing that the collaboration between the companies and the connection within the companies is very strong.
    Nick Hogan:
    Awesome. Okay, cool. Well I think that pretty much wraps it up, guys. Don, anything else on your side that you'd like to touch on for Mike?
    Don Retzlaff:
    No, thank you for your time. It was a pleasure talking to you. There's a lot of people rooting for you in Gaming Arts out there, so I wish you nothing but the best and hope you have a great summer and a great G2E and we look forward to seeing the product and you can tell by the excitement in your voice that you think you're on the right path and we wish you nothing but success.
    Nick Hogan:
    Absolutely.
    Mike Dreitzer:
    Thank you Don and Nick, we really appreciate the time and the insight and again, always happy to tell our story and yeah, Nick, it's been 25 years, so here's to 25 more, right?
    Nick Hogan:
    Yeah, there you go. Oh God, I hope I'm well retired by then.
    Mike Dreitzer:
    Right, I hope I'm alive by then.
    Nick Hogan:
    Okay, cool. Well Mike, thanks so much again for joining us today. Always great to talk to you and great to see you and yeah, thanks so much. So I guess that's it guys.
    Mike Dreitzer:
    Thanks, anytime. You guys do great work, keep up the great work you're doing to help us with the most difficult of problems. You guys handle big and difficult problems.
    Nick Hogan:
    Yeah, thanks.
    Don Retzlaff:
    Thank you, Mike.
    Nick Hogan:
    We do our best.
    Mike Dreitzer:
    All right.
    Nick Hogan:
    Okay, thanks gents.
    Don Retzlaff:
    Thanks, cheer guys.
    Nick Hogan:
    Bye-bye.
    `,


};

export default S03E05;
