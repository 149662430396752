import React from "react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";

class S02E01 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname);
    }

    // calls API and attaches scriptt to DOM
    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);

        if (Helmet.canUseDOM) {
            Helmet.renderStatic();
        }
    }

    // checkes if component should update based on props in Helmet
    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }

    // ensures Helmet sets teh code when page loads
    componentDidUpdate() {
        if (Helmet.canUseDOM) {
            Helmet.renderStatic();
        }
    }
    
    render() {
        return (
            <>
                <Helmet>
                    <title>
                        {this.props.title} | {this.props.subTitle} | ReelCast by ReelMetrics the world&apos;s best slot analytics data platform
                    </title>
                    <meta
                        name="Description"
                        content={this.props.description}
                    />
                    <meta
                        name="Keywords"
                        content="best practices, slot floor, Season 2, slot machine managers, staying ahead"
                    />
                    <link
                        rel="canonical"
                        href="https://www.reelmetrics.com/s02e01-updating-best-practices-part-i"
                    />

                    {/* Open Graph / Facebook / LinkedIn */}
                    <meta property="og:type" content="website" />
                    <meta
                        property="og:url"
                        content="https://www.reelmetrics.com/s02e01-updating-best-practices-part-i"
                    />
                    <meta
                        property="og:title"
                        content="Updating Best Practices - Part I"
                    />
                    <meta
                        property="og:description"
                        content="We kick off Season 2 of ReelCast as we explore the latest best practices for managing your slot floor. This is a must-listen episode for slot machine managers looking to stay ahead of the curve."
                    />
                    <meta
                        property="og:image"
                        content="https://cdn.buttercms.com/OaWFV5QDqgOJxaKViwdg"
                    />
                    <meta property="og:locale" content="en_US" />
                </Helmet>
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}

S02E01.defaultProps = {
    id: "12140214-s02e01-updating-best-practices-part-i",
    title: "Updating Best Practices",
    subTitle: "Part I",
    buzzSproutLink:
        "https://reelcast.buzzsprout.com/2057836/12140214-s02e01-updating-best-practices-part-i",
    spotifyLink:
        "https://open.spotify.com/episode/31yIPV54ngObcAat2vIeF5?si=cLR-9GLESouHTGAOE0udoQ",
    applePodcastsLink:
        "https://podcasts.apple.com/us/podcast/s02e01-updating-best-practices-part-i/id1652485625?i=1000597136096",
    iHeartRadioLink:
        "https://www.iheart.com/podcast/269-reelcast-by-reelmetrics-104029260/episode/s02e01-updating-best-practices-part-108007375/",
    cdcLink:
        "https://cdcgaming.com/reelmetrics/s02e01-updating-best-practices-part-i/",
    description:
        "We kick off Season 2 of ReelCast as we explore the latest best practices for managing your slot floor. This is a must-listen episode for slot machine managers looking to stay ahead of the curve.",
    link: "s02e01-updating-best-practices-part-i",
    keywords: [
        "best practices",
        "slot floor",
        "Season 2",
        "slot machine managers",
        "staying ahead"
    ],
    topics: [
        "What are the latest best practices for managing your slot floor?",
        "How can these best practices help you stay ahead of the curve?",
        "What are the key takeaways from Season 2 of ReelCast?",
        "How can slot machine managers apply these best practices to their operations?"
    ],
    transcript: `Nick Hogan:
    At ReelMetrics, we collect and analyze a ton of slide data and we're hit up frequently for insights, tips, and tricks. So, we decided to do a webcast in which we present, discuss, and otherwise nerd out on our work. Coming to you from our offices in lovely Leiden in the Netherlands, welcome to ReelCast. Good morning, Don. How are things today in gateway to the West?
    Don Retzlaff:
    Good morning, Nick. Everything's fine. Nice winter's day.
    Nick Hogan:
    Okay, lovely. Yeah. Have you noticed I keep trying to find new nicknames for your part of the world? So, I'll just keep doing that. So, all right. So, here we are, first episode of our second season and I guess that's an accomplishment of sorts. We arbitrarily decided that we'd synchronize the seasons with the calendar, so not exactly a call for champagne or confetti here, but when we look back at why we decided to do this podcast, I must say that I'm pretty happy with the results so far. A lot of industry people have reached out to us with compliments and questions. Really some of these interactions, I have to say, have surprised me.
    So, first, it's a very diverse group of people, both functionally and geographically. We've heard from people from all over the world, operators, consultants, journalists, pretty much every flavor of banker imaginable and product folks on the supply side. So, that's just phenomenal. Secondly, when we've asked for feedback, the depth of the observations has definitely exceeded my expectations. So, the adjectives practical and actionable are on pretty heavy rotation in these conversations, but I'd say that the main thrust of what they're saying is that when it comes to topics like analytics, marketing, player behavior, segmentation, in the gaming industry, there's simply too much noise out there. Some have shared examples.
    One that came to me from multiple sources was either it was an op-ed or a blog post. I don't remember which, but it had market segmentation in the title then went on to discuss nothing but floor layout. The comments were, "Hey, this isn't segmentation. This is just layout." Seeing how people are isolating that distinction and conveying a real thirst for the nuance that we're really striving for here, I must say that it's incredibly encouraging. It's just always great to learn that your audience is smart. It really makes our jobs a lot easier. So, thanks to those who've reached out. We have day jobs. We're doing this for free. It's not sponsored or paid in any way, and we typically record these things on the weekends. We're doing this one on a Sunday night.
    So, your encouragement really keeps us going, and we can't thank you enough for all that positive feedback. Okay. So, season two, episode one. Now if you recall, we devoted our last podcast to our top 10 insights of 2022. The number one position was held by the insight that the best practices models out there in the industry for inventory management are obsolete and must be updated. As we've alluded to in a number of podcasts, ReelMetrics has actually created a contemporary best practices model that takes into account all the changes that we've seen to the industry's data landscape over the past decade or so. We refer to this model as Demand-Driven Inventory Management or DDIM.
    So, rather than throwing that best practices observation out there and doing nothing with it, we thought it would be a cool idea to devote a few episodes to DDIM and walk our listeners through it, thumbnail, exposing them to the main principles and techniques. So, before we dive into that, let me state at the top here that we'd love to tackle any questions that anyone listening may have. So, if you have a question about what we're presenting or something you'd like us to present, please drop us an email at reelcast!reelmetrics.com. Again, that's R-E-E-L-C-A-S-T @reelmetrics.com. Our policy is to keep all questions anonymous, so please speak directly and don't worry about us revealing your identity. That's not something that we do.
    So, today, we're going to open with the first steps in DDIM, the first in particular, which is really serving the floor and getting a solid notion of your operational baseline and then also introducing a number of analytical methods, which although some utilized versions of them here and there are not generally in the quivers of your slots and P&A departments. So, that's really our topic for today. But before we dive into that, we want to cover four points that we have to assume are in place before we start diving into this survey. So, number one is that you've already committed to pursuing a new best practices model for managing inventory that uses player demand as the foundational vector driving every slot decision you make.
    We'd also strongly recommend and this is hopefully not controversial for anybody hearing this, but that marketing has a seat at this table. It really is a unique thing about the gaming industry that marketing doesn't have any role in the products that we're putting in our environment. So, there are a lot of reasons for this. We can go on about that for days, but suffice to say, we strongly recommend that marketing is involved. Secondly, you've decided that what you're going to do as part of this is you're going to target the bulk of your CapEx and OpEx spend to your top player segments, the people who are really driving all the performance of your casino and that we're going to prioritize based upon that demand and patronage.
    Third, you've made a conscious decision that within this realm, this is the domain of proven product, not blind experimentation. For experimentation, you've committed to getting far more systematic about it, allocating a small percentage of the floor share for experimentation. This is the domain where you take chances and put stuff in there that's not necessarily proven elsewhere, maybe brand-new to market, but the idea here is you really limit that to product that you can put in there almost on a consignment basis. So, looking at it in terms of trials and very short term commitments. Fourth and very importantly, you've agreed to drop all assumptions regarding premium floor share and this notion of moving money around the floor.
    So, as we've mentioned in previous episodes, we have conducted gigantic studies on this. What we're seeing is it's not really a force that you need to concern yourself with. So, what we've agreed then as it pertains to premium product, demand will determine floor share by supplier, by game type, by cost model, et cetera. So, these are four core principles that need to be in place before we get going on the points that Don is going to cover today. With that, Don, I will turn it over to you.
    Don Retzlaff:
    Thanks, Nick. I know it's a lot to cover, but the bulk of this comes down to getting the right machines on the floor, the machines that your best customers want to play, because that's where all your profit is, whether that's a lease game, whether that's a core game. The other big thing is the price of games keeps going up and up and up. Your CapEx budget is not in most cases unless you're very, very lucky. So, the games that you're buying now are going to be on the floor for 10+ years, and we know they're going to going to be obsoleted halfway through that. So, it is making sure that the customers that you need to take care of are being taken care of.
    So, we always start off with player demand. What are your host level players playing? Are they playing your best lease games? Are they playing your best core games? It gets down to a theme by theme, manufacturer by manufacturer study of what your host level players are playing. I would put together a top 50 theme list for your host level players. Then you start looking at things like, "Are these games maxed out on the weekends? Can your best players get to these games? Are they having more than 8,000 spins per day?" If so, this is where you need to start. It's not starting with the brand new cabinets and the brand new themes that are being released in 2023.
    Do you still have enough of the current things on your floor to meet the demand? That's where we usually start. Most casinos don't have enough of the key themes, whether it's premium or core, and we'll concentrate on core to start. There's a lot of really good themes out there that are sitting there on the weekends that are just jam-packed with customers playing the minimum bet when you've got host level players just waiting to play them and these games need to be expanded.
    Nick Hogan:
    Don, you brought up something there. I had heard a consultant in the industry say this least recently, which I really liked. He said, "Everybody in this environment is always interested in having the latest and the greatest." He said, "Now, I want you to eliminate the word latest from your vocabulary." Would you subscribe to that idea?
    Don Retzlaff:
    Yeah, absolutely. We found that 70% of all new product fails. So, if you're going to jump in and just buy the latest product, you're going to have a high failure rate. You've got to have that data, things like what ReelMetrics supplies to look at how these games are performing. Vendors go through slumps. Vendors have good cabinets and bad cabinets, and vendors do produce a lot of themes. A lot of them, you're not going to see on your floors in two or three years. So, you've got to have the best stuff out there. There's just a lot of floors out there that just don't have enough of the key themes. Some properties do a really good job of that. I've got one property that has 20 of the best theme on their floor, and the competition has six. Those 20 games are doing fantastic.
    I think that's where we basically start on everything, whether it's lease or core, is do you have enough of these games that your host level players want to play? Because they are driving your revenue. Conversely, your high frequency customers, they will not play the new cabinets. So, the customers that are coming in 100+ times a year, 150+ times a year that have that low ADT, say below $100, they shy away from anything new. They don't play lease games. They don't play your new core. They play your obsolete games. They play their favorites and they don't branch out. So, they may be the majority of your customers as far as volume, but they have a very minimal impact on your new purchases.
    The average bets are too high for them, and they basically get stuck playing their favorite games, whether it's classic IGT or Aristocrat or side game stuff. It is what they like to play. If you look at their top five games, they are all classic games. Conversely, your host level players, they play the new stuff. They play the volatile games. They play the lease games. These are the customers that you really need to target with your CapEx. They're going to drive that high win per unit. Some of it's average bet driven. Your best customers will play that $3, $4, $5 average bet where your low ADT players are around that dollar per bet spin. So, you really need to look at what your host level players are wanting and playing first.
    So, the next thing we look at is basically, we'll start doing things like fair share analysis. Especially post-COVID, the floors have changed a lot. A lot of the older clientele have not come back, and it's really impacted the physical real play. The physical real play at probably 85% of the casinos that we've looked at, there's just too many products out there on the floor. They need to be reduced. They need to reduce significantly 25 to 50% at most places. So, we start off with a fair share analysis. We separate the core from the lease.
    Nick Hogan:
    Because one thing I know is although we assume that everyone is completely familiar with the notion of fair share analysis, can you maybe just provide a summary of what that's all about?
    Don Retzlaff:
    Yeah, it's a way to look at what your floor is in balance or out of balance with. So, for instance, if your floor is 10% reels and it's producing 5% of your revenue, you probably have 50% too much of your floor. Conversely, if your floor is penny video is doing 85% of your revenue and it's only 60% of your floor, you don't have enough of that product. So, it's just basically taking the floor size and then looking at the ratios of revenue and units and coming up with a percentage. So, 100% would be 50% of your floor is doing 50% of your revenue. So, if video poker is 10% of your floor and doing 10% of your revenue, you're probably in pretty good shape. But if it's 10% of your floor doing 2% of your revenue, you probably have too many units.
    Nick Hogan:
    Correct. Yeah. I think it's important just to note for everybody who's listening that in mainstream retail, fair share analyses are just absolutely core to what everybody's doing in segments such as, let's say, consumer package goods. They look at things like the amount of linear shelf space consumed by something and then what that product is and then what percentage or revenue for that category or even for the entire product mix, that thing comprises. Then the idea is, is this product punching above or below its weight? That's the fundamental principle driving that.
    Don Retzlaff:
    I usually do it by game type and by denomination. It's really where you need to back out your premium from your core games because it will throw off your balances and then look at it by vendor. Every property's different in regards to what your customers like. We've got casinos that are clients that they love dollar reels and then we've got other ones that shy completely away from dollar reels. So, everyone's a little bit different. So, look at it as far as, "Do they like this vendor more than another? Do you not have enough of this vendor? Do you not have enough of this game type or this denomination?" You can use 100% as your balancing point. There's some fine tuning you can do there.
    Things like dollar reels, they can be above 100% just because the density on those games, the occupancy on those games is going to be lower. You don't expect your dollar reels to be at 75% occupied all the time. It's more in that teens range. So, those percentages can be higher where your penny video is, you're going to have your bodies in the seats most of the time. So, 100% fair share to 110% share is probably what you're aiming for. Unless you have unlimited capital and very few casinos have that, you're not going to be able to get everything to 100%, but it's a great place to start. Then when you're looking at things like your lease games, I generally use 250% for my fair share.
    If you do have a lot of lease games on your floor, say above 20% of your floor, you can probably drop that down to 200% to where your lease games are doing two times house average, but 250%'s probably a pretty good spot to be at for your lease games. There are a few casinos that are our clients that have jumped above that 20% range and it does alter your calculations a little bit, but two and a half times house average is a pretty good place to be.
    Nick Hogan:
    I think you've pointed out before and we have encountered this in the field where we have people who, let's say they have a lease bank of this or that title and they'll say things like, "Oh, it's fantastic. It's doing five or six times floor and it has 90% occupancy," things of this nature. You say, "Congrats, but that's not what you want," right?
    Don Retzlaff:
    Right. You're leaving money on the table. All the studies that we've done, all the experiments that we've done show that your high worth customers are pounding those high occupancy lease games. When they can't get on it, they play less than they do when they play those lease games. That's one of the neat things about this is you can fix your floors with a small number of good games. Especially on the lease side, the premium side, you can see the results in 30, 60, 90 days. It is not something that's a long-term ROI. You'll see it within weeks and it's not just moving money around. You'll see growth in theoretical per day for your best players.
    We've done these studies and we've seen growth of 50% on theoretical almost instantly. People play longer. They play more as their average bet. So, you can fix this stuff, you can fix it pretty quick. So, if you've got games on your floor doing five and six times house average, congratulations, but you're making a mistake. You need to expand that simple enough. So, like Nick said, when you've got games that are having 85, 90% occupancy, they need to be expanded, especially if you look at it and it's your best customers playing those games. We had an example.
    We had a case where we had a lot of low occupancy games surrounded by high occupancy games, and we were trying to figure out why. So, we talked to the property and what had happened was customers were sitting at those games not playing, waiting for spots to open up on their favorite lease games. It was just killing the performance of the other games that were actually halfway decent games. Once they rectified that situation-
    Nick Hogan:
    You end up with a $25,000 bar stool.
    Don Retzlaff:
    Yeah, exactly. So, once they fixed that situation, that problem disappeared in the units that were surrounding those lease games went up, which is something we'll probably talk about in the later episode of building neighborhoods and making sure that your games are in the right location for your host level players. But if you're looking at 85% occupancy, generally, you need to expand. Some properties are more weekend heavy. So, you really look at those seven key shifts every week that Thursday night through Sunday night stuff, look at those occupancy, look at those handle poles. If your occupancy on those days is super high, you're probably leaving money on the table on the weekends and that's not a really good place to be.
    On the core games, anything above 30% occupancy, you really need to look at expanding, especially if you look at the win per units and the average bets on those games are above average. It's a great place to start. They're easy calls to expand, and your best players will love it. Of course, the other big thing too is all the casinos, especially over the last 15 years or so, have had no smoking areas versus smoking areas. Or if you're unlucky enough, you're in a non-smoking environment like I was. You can see dramatic changes in win per units. But if you are in a spot where you've got both smoking and non-smoking, try to separate those on your occupational analysis and your occupancy analysis, because they will impact your decision making.
    I've had places that have had the best premium banks in smoking and non-smoking sections and the results have been halved. Anyway, it's a great idea and concept to put those premium games in low occupancy areas, but it really can crush the performance of those games. Putting your best lease games in their best locations is not a bad strategy. I've done both, put them in areas where you want to drive traffic to and the performance does go down. Even the best lease games will push the house average if you bury them.
    Nick Hogan:
    Yeah, and there's really a point on the data landscape having changed in the last decade or so. So, this one, like locational sweet spots and whatnot, this is something we track on every single title that we cover, which portions of the floor it's safest to install. So, that's another case where this best practices model is informed by that change. You can actually get systematic about that and get some quantitative data points on that.
    Don Retzlaff:
    We usually break out the locations to letter grades A, B, C, D, F, and most of your best games will work anywhere in an A, B, or a C location. But once you start putting them in a D or an F location, you will see a degraded performance. That's both for core and lease games. There's a lot of core games that won't work in anything but an A location. There's a lot of core games that work any place you put them and it's the same thing with the lease. You can put them in an A, B, or a C, but if you do put them in that D or an F location, expect less performance out of them. It just doesn't happen. Then the big thing too is making sure that your floor size is right. There's been a lot of changes over the last couple years and there's a lot of properties that have a lot of older games on the floor.
    They still have large banks. You've got banks of 8, 10, 12. If you're looking at your handle pulls on a monthly basis and you've got a lot of units under 25,000 spins in a month, you probably have too many machines on your floor and it'd be a great chance to eliminate some of those obsolete titles, those obsolete cabinets, the ones that are costing you a lot of money and repairs and maintenance. You can safely pull those games. It will not hurt your revenue. Especially for properties have been open for over 20 years, we're finding that a lot of properties are in that 20 to 30% too big, because they have so many obsolete games that people are not playing.
    Nick Hogan:
    Just to, again, flashback to previous episodes, we also have spoken about this notion of dogs on the floor. We had always come up in the industry thinking that well, they're really benign forces on the floor. They're not going to hurt anybody and they may not be helping you, but they're not hurting you. Based on the analysis that we've done, some of these big studies that we've done, really looking at demand, we see that they're actually malignant forces on the floor. That if you get the wrong people on those games, it can really impact how they're going to deploy their budget during their visit.
    Don Retzlaff:
    If they play their favorite games, they pound them. If they get down to playing the games they have to play, they really throttle back on their spend. We're seeing this at a lot of properties, like I said, especially post-COVID. There's a lot of older quarter reels. There's a lot of older obsolete penny video out there that people just don't play anymore. We had a couple properties we looked at for floor size and 25% of their floor gets played an average of once every four hours.
    So, they've just got a lot of dead spaces. There's no energy in those spots, especially if you've got those areas where you've got banks of six or banks of eight. It's a great opportunity to right size those banks, get them the banks of four, those long walls, bust those up, have spaces in between. It will increase your play.
    Nick Hogan:
    Yeah, I really have the feeling that those long linear banks, we're going to confine those to the dustbin of history, I think, the way it's looking.
    Don Retzlaff:
    I remember going to Atlantic City years ago and walking into a couple of those places. Those banks of 16, banks of 20, it'd take you half a minute just to walk around the other side of the bank.
    Nick Hogan:
    A lot of bowling alleys.
    Don Retzlaff:
    Yeah, exactly. Exactly. Now, especially post-COVID, you're seeing a lot of banks of four, even the banks of six aren't traditional shapes. They're more football shaped or carousels. That's the one nice thing that COVID did was it made people re-look at their floors and get rid of as many middle machines as possible. So, the next thing we wanted to look at was your core purchases. Like we said earlier, any machine that you buy now, you're likely going to have for 10 or 15 years. So, you've got to make sure you get it right. The way we do that obviously is we'll use data. You're using performance data, you're looking at the depth of the library, but that is all after you make sure that you've expanded the games that are working on your floor right now.
    Do that first and then look for the new games. There's always that fear of missing out. There are some really good cabinets that came out this year, but there are also some tenants that came out this year that are just doing house average. You cannot afford buying a bank of machines for an average of $25,000, $35,000 a machine. You cannot afford to have those machines go on your floor and do house average. We've talked about, I think, the last episode, that performance declines 10% year over year.
    Nick Hogan:
    Yeah, I was just going to mention that decay and performance. Yeah, exactly. We had to decided that it has to be factored in.
    Don Retzlaff:
    So you want those new core games to start off over one and a half times house average. There's a lot of new core games out there that are starting off way above that. We've got new core games that we're tracking that are over two and a half times house average that are pushing lease game performance. That's where you want to start because you know that these games are going to be out there long term, nice libraries. Even with the new cabinets, you're going to have some choices. The good thing is you put a game out there on the floor that's doing two and a half times house average at start. You're not going to have to worry about that game for the next two years because you know it's still going to be above one and a half times house average. So, that's a great place to start.
    So, start with expanding the things that are working and then just hit those proven winners, those home runs. You don't have to get deep. Unless you're in that enviable position where you're buying 100, 200 machines every year, then you can dip down a little bit lower. But for the majority of the casinos that are buying less than 100 machines per year, you can just start with those ones that are doing home runs and space your capital out. You don't have to buy everything in the first quarter.
    I've done both where I've spent everything by the end of March and others where I've kept some powder dry for G2E sales. It's nice to get all your capital and work early in the year, but it's more important to get the decisions right. If there's nothing out there that you absolutely want and need to have, wait. Wait for the next series of games to come out. There's always seven new themes coming out, and maybe those are the ones that hit. So, you can wait and make sure that you're making the right decisions.
    Nick Hogan:
    Don, well, for 2023, I guess one of the things we do have to factor in on that point is I know you're in touch with the suppliers on this stuff very frequently. How's everybody doing right now on supply chain issues and lead times and whatnot?
    Don Retzlaff:
    Much improved over this time last year and even over the fall. I think things started turning around last year in about the third quarter. There are still some cabinets that are taking six months to get back, some premium games, six months plus. But generally, they're back into that 8 to 12-week range. So, it's definitely gotten better. This time last year, it was 4, 5, 6 months for ordering. So, there's been some additional supply put into the market. People solve the problems, and it just took them a while to ramp back up and get back online. So, I think we're in pretty good shape now.
    Nick Hogan:
    Would you generally recommend for this year, just based on all the work that we've done over the last year as it pertains to premium performance and core performance and demand and whatnot, would you generally advise people this year to just pause a little bit on their current OpEx-CapEx split and let's say that they haven't necessarily really dug into the whole demand side of things yet? Do you feel that's generally an okay principle for moving forward?
    Don Retzlaff:
    Absolutely. Most properties have a budget for lease game and it's based on last year's budget instead of player demand. There are so many good lease games that will improve your revenue and improve your bottom line. I think that's probably the most exciting thing that we did last year, Nick, was on the experiments, we've proved that we're just not moving money around when you spend money correctly. That if you do it right, you will increase your host players theoretical per day, you'll increase their trips, you'll increase their average bet if you give them the games that they want to play.
    A lot of the times that's least games that they can't get on. It's really been remarkable. It was every place that we looked at and it started off with host level players and their performance was incredible. I remember looking at one of them last week, the average trips per month nearly doubled.
    Nick Hogan:
    All these metrics are just my eyes just popping out of my head every time I read one of these decks that comes through.
    Don Retzlaff:
    The number of people that they were serving for these key titles just went through the roof. If you've got a bank of six of your best premium games, there's a limited number of players who can get on those games. Your host level players will sit there for a long time, and it's denying other customers a chance to play those games. Once you go from 6 to say 20, that player pool really expands. Then what we saw also was those places who went from say 20 to 30, 20 to 35, the players just below that host level, that $150 to $300 ADT player, they started getting on those games. Their average bets went up, their trips per month went up. It was interesting. It happened that at each property that we looked at.
    Nick Hogan:
    Yeah. As this organic player development, just based on your mix.
    Don Retzlaff:
    We've always said that it was marketing's job to get them in the casino and casino ops job to keep them coming back. The best way to keep them coming back is to give them the games that they like to play, give them the games they want to have that good experience on. The best we can do is have the friendly smiling faces and great customer service, but give them the games they want to play. That's what gets them to come back. So, when you get back to the lease game problem, it's a lot of money, especially when you go from 20 lease games to 50 lease games. We've had properties go to over 20% of their floor being lease games and they've had record years. Also, they have record costs, but their EBITDAs have just gone through the roof.
    Their customer satisfaction scores have gone through the roof. So, you really can't put an artificial cap on lease games just because it's a budgetary number. You're really sacrificing revenue potential when you do that. There are some properties who don't need more lease games. Their occupancy levels are low, they have enough, or maybe they don't have the right ones. That's obviously key, is making sure you have the right lease games. You should not settle for lease games that are doing 1.4, 1.5 times house average. There's enough lease games out there that are doing two and a half, three and four times house average that you shouldn't have anything on your floor below two.
    Nick Hogan:
    Just demanding more from them because as we've covered in previous episodes as well, as opposed to core, what you can do is just maintain those very lofty performance levels over time simply by mixing the stuff up. So, when you start to see a given title dropping off, you just convert it or eject it and replace it with another premium product. That's really one of the most appealing things about that segment of products is just all that flexibility that it gets you.
    Don Retzlaff:
    The big thing that people like to do, and I did the same thing, is you want to keep your floor fresh. That's usually using your CapEx dollars to buy new cabinets. You can also do the same thing with your lease games. You can keep your floor fresh. You don't have to worry about the rising cost of cabinets. Like I said, you're going to have these games on your floor for a long time once you buy them. if you just buy the All Stars, you don't have to worry about these cabinets being obsoleted three and four years from now and not being able to touch them. What you see out there on your floor now that's doing $50 and $60 a day win per unit, that'll be these games 10 years from now. So, if you've got those lease games, it just makes the job a lot easier of picking All Stars.
    We've seen it with new casino installs, new casinos that have opened up. You open up with just nothing but the best games and give your customers a chance to play the good games and play more of them. That goes for both core and premium, but the artificial cap on the premium is probably one of the things that we've seen holding most casinos back. It's tough. It's not an easy conversation with your CFOs saying, "I want to spend another $2 million this year on lease games." But the good thing is if you can convince them to do a trial for 90 days and get the manufacturer on board, say, "I want to try this for 90 days and see what happens," check out what happens to your host level players. It'll definitely be worth it. We're seeing returns on investments that are sky high.
    Nick Hogan:
    Yeah. I've actually been now in quite a few of these meetings where we have the CFOs in the room where we go through some of these validations in the work that we've done in the field and stuff. Boy, do guys gobble that info up. They really like it. What I'm finding is that I think a lot of times the CFOs, they're looking at stuff that they can control. So, I think that's always been a thought behind purchasing. It's like, "Yeah, well, we can control the cost going out for this stuff, but we don't really have any control over the revenue."
    So when they start seeing that conscious decisions and conscious strategies to break this stuff up, segment it, look at demand, buy segment, and then make your decisions, and along that, utilizing that calculus, then they see that those decisions pay off. What I'm seeing is that they're loosening the purse strings and they're being far easier in terms of putting that OpEx out there.
    Don Retzlaff:
    It was always that age-old problem is prove it. You're going to spend more money, prove that it's going to make us more money versus moving money around. The neat thing is over the last year, we've proven it. The CFOs, the marketing people, all of our meetings that we had in Vegas with all the different organizations where we get to have the COOs and CEOs and Chief Marketing Officers in the room, very excited about a lot of the validations that we did. You can see why.
    I mean, the numbers weren't up 6%, up 8%. They were up 30%, 40%, 70%. It made a big difference. These validations, they pay for themselves quite quickly. So, even if you add another bank or two, you'll see the results and you'll see it if you pick the right games. Look at that demand, look at that occupancy, and check out who's playing those games and it'll definitely be worth it for you.
    Nick Hogan:
    Okay, cool. Well, Don, as you mentioned, what we want to do is just orient people toward those, just some basic foundational principles that you need to have in place as part of Demand-Driven Inventory Management. Then today, we talked a bit about these tools, let's say fair share analysis, occupancy analysis in order to determine this data of your floor and your operational basement. That's really what we wanted to discuss today. I think as we dive into the February podcast, I think we'll start getting more into demand and more into the segmenting demand and getting more nuance and understanding it in greater detail. So, I think that's probably a decent objection for our next podcast.
    Don Retzlaff:
    Excellent.
    Nick Hogan:
    Okay. Well, great, Don. Well, thank you so much for your time today. Yeah, until February, I guess.
    Don Retzlaff:
    We're getting close.
    Nick Hogan:
    Okay. Well, thank you so much, Don.
    Don Retzlaff:
    Have a good day, Nick.
    Nick Hogan:
    You do the same. We'll see you. Bye-bye.
    Don Retzlaff:
    Take care.
    `
};

export default S02E01;
