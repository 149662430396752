import React from "react";
import * as Survey from "survey-react";
import axios from "axios";
import $ from "jquery";
import Response from "../components/response";

Survey.Survey.cssType = "bootstrap";
Survey.requiredText = "(Required)";
Survey.defaultBootstrapCss.navigationButton =
    "btn btn-block-mobile btn-lg btn-primary";
Survey.defaultBootstrapCss.panel.title = "question-box__title";
Survey.defaultBootstrapCss.header = "title title--primary";
Survey.defaultBootstrapCss.question.title = "title title--primary fs-24";
Survey.defaultBootstrapCss.question.description = "paragraph";
Survey.defaultBootstrapCss.matrix.root =
    "table table-striped table-sm table-bordered";
Survey.defaultBootstrapCss.matrix.label = "cell-content--centered";
Survey.defaultBootstrapCss.matrixdropdown.root =
    "table table-striped table-sm table-bordered";
Survey.defaultBootstrapCss.root = "";
Survey.defaultBootstrapCss.navigation.next = "pull-right";
Survey.defaultBootstrapCss.navigation.prev = "";
Survey.defaultBootstrapCss.navigation.complete = "pull-right";
Survey.defaultBootstrapCss.navigation.start = "pull-right";

class SurveyWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            survey_json: {},
            survey_status: "",
            response_message: {
                colorClass: "",
                text: ""
            },
            completedHtml: `<h3 class="title title--primary fs-24">
                        <span>Thank you for sharing this information with us!</span>
                      </h3>
                       <div class="paragraph">
                         <span>Click <a href="/">here</a> to go back to the home page.</span>
                         <div style="margin-top: 30px">
                          <img src="https://media.giphy.com/media/3oz8xIsloV7zOmt81G/giphy.gif" alt="victorious animated gif">
                         </div>
                       </div>`
        };
    }
    /* eslint-disable */
    UNSAFE_componentWillMount() {
        axios
            .get(`/api/venue-survey/${this.props.match.params.code}`)
            .then(response => {
                if (
                    response.data.status &&
                    response.data.status === "not_found"
                ) {
                    this.setResponse(
                        "The survey you’ve tried to reach does not exist or has been completed!"
                    );
                } else {
                    const surveyObj = response.data.survey;

                    surveyObj.completedHtml = this.state.completedHtml;

                    surveyObj.data = response.data.response;

                    Survey.defaultBootstrapCss.root = response.data.survey_type;

                    this.setState({
                        survey_status: "ok",
                        survey_json: surveyObj,
                        survey_type: response.data.survey_type
                    });
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 404) {
                    this.setResponse(
                        "The survey you’ve tried to reach does not exist or has been completed!"
                    );
                } else {
                    this.setResponse(
                        "Oops...Seems like something went wrong when retrieving the data!"
                    );
                }
            });
    }

    /* eslint-enable */
    setResponse(text) {
        this.setState({
            survey_status: "error",
            response_message: {
                colorClass: "alert alert-warning",
                text
            }
        });
    }

    sendDataToServer = survey => {
        axios
            .put(this.updateUrl(survey, this.props.match.params.code), {
                response: survey.data
            })
            .then() // response => console.log(response))
            .catch(); // error => console.log(error));
    };

    updateUrl = (survey, param) => {
        const url = `/api/venue-survey/${param}`;
        return survey.isCompleted ? `${url}/complete` : `${url}`;
    };

    /* eslint-disable */
    onAfterRenderPage = () => {
        const isValidJSON = string => {
            try {
                JSON.parse(string);
            } catch (e) {
                return false;
            }
            return true;
        };

        if (this.state.survey_type === "cabinet-survey") {
            $("table").each(function () {
                const table = this;
                /* Let's find the first cell to see if we have a JSON */
                const cellValue = $(table).find("td:first").text();
                if (isValidJSON(cellValue)) {
                    const columns = Object.keys(
                        JSON.parse(cellValue)
                    ).reverse();
                    $(table).find("th:first").hide();
                    columns.forEach(function (key) {
                        $(table).find("th:first").before(`<th>${key}</th>`);
                    });
                    $(table)
                        .find(`th:eq(${columns.length - 1})`)
                        .addClass(
                            "survey-header--provided survey-header--smaller"
                        );
                    $(table)
                        .find(`th:eq(${columns.length - 2})`)
                        .addClass("survey-header--small");
                    $(table)
                        .find(`th:eq(${columns.length - 3})`)
                        .addClass("survey-header--large");
                    $(table)
                        .find(`th:eq(${columns.length - 4})`)
                        .addClass("survey-header--small");
                    $(table)
                        .find(`th:eq(${columns.length + 1})`)
                        .addClass("survey-header--medium");
                    $(table)
                        .find(`th:eq(${columns.length + 2})`)
                        .addClass("survey-header--smaller");
                    $(table)
                        .find(`th:eq(${columns.length + 3})`)
                        .addClass("survey-header--medium");
                }
                /* Loop over all rows in the table */
                $(table)
                    .find("tr:not(:first)")
                    .each(function () {
                        const row = this;
                        const tdValue = $(row).find("td:first").text();

                        $(row).find("td:first").hide();
                        if (isValidJSON(tdValue)) {
                            const columns = Object.values(
                                JSON.parse(tdValue)
                            ).reverse();
                            columns.forEach(function (value) {
                                $(row)
                                    .find("td:first")
                                    .before(`<td>${value}</td>`);
                            });
                            $(row)
                                .find(`td:eq(${columns.length - 1})`)
                                .addClass("survey-header--provided");
                        }
                    });
            });
            const toggleJackpotName = field => {
                if ($(field).is(":checked")) {
                    $(field).closest("td").next("td").find("input").show();
                    return;
                }
                $(field).closest("td").next("td").find("input").hide();
            };
            /* Set up a change handler for the checkboxes */
            $("[aria-label='Has Link/NAP/WAP?']").change(function () {
                toggleJackpotName(this);
            });
            /* Set the initial value of the visibility of the input */
            $("[aria-label='Has Link/NAP/WAP?']").each(function () {
                toggleJackpotName(this);
            });
            /* Group options on Cabinet Selector */
            $("[aria-label='Cabinet']")
                .find("option")
                .each(function () {
                    const values = $(this).text().split(" | ");
                    if (values[0] && values[1]) {
                        $(this).text(values[1]);
                        if (
                            $(this)
                                .closest("select")
                                .find(`optgroup[label='${values[0]}']`)
                                .length === 0
                        ) {
                            $(this)
                                .closest("select")
                                .append(`<optgroup label="${values[0]}"/>`);
                        }
                        $(this).appendTo(
                            $(this)
                                .closest("select")
                                .find(`optgroup[label='${values[0]}']`)
                        );
                    }
                });
            $("[aria-label='Cabinet']").each(function () {
                $(this).closest("td").attr("data-chosen", $(this)[0].value);
                $(this).change(() =>
                    $(this).closest("td").attr("data-chosen", $(this)[0].value)
                );
            });

            /* Autocomplete: Define different datalist based on the question */
            const keys = this.state.survey_json.data
                ? Object.keys(this.state.survey_json.data)
                : [];

            keys.forEach(key => {
                const inputs = $(`[name= '${key}']`).find("input");

                inputs.click(function () {
                    inputs.attr("list", key);
                });

                inputs.focusout(function () {
                    inputs.removeAttr("list");
                });
            });
        }
    };
    /* eslint-enable */

    createDatalists() {
        const keys = this.state.survey_json.data
            ? Object.keys(this.state.survey_json.data)
            : [];
        const list = this.state.survey_json.autocomplete
            ? this.state.survey_json.autocomplete.list
            : [];
        const datalists = [];

        keys.forEach(key => {
            let options = list.filter(item => item.value === key);

            options = options.map(result => (
                <option key={result.text}>{result.text}</option>
            ));

            datalists.push(
                <datalist id={key} key={key}>
                    {options}
                </datalist>
            );
        });

        return datalists;
    }

    render() {
        let surveyComponent;
        const model = new Survey.Model(this.state.survey_json);

        if (this.state.survey_status === "ok") {
            surveyComponent = (
                <Survey.Survey
                    model={model}
                    data={this.state.survey_json.data}
                    onComplete={this.sendDataToServer}
                    onValueChanged={this.sendDataToServer}
                    onAfterRenderPage={this.onAfterRenderPage}
                />
            );
        }

        return (
            <section className="box box--light screen-height">
                <div className="container container--1400">
                    {surveyComponent}

                    <Response
                        colorClass={this.state.response_message.colorClass}
                        text={this.state.response_message.text}
                    />

                    {this.createDatalists()}
                </div>
            </section>
        );
    }
}

export default SurveyWrapper;
