import React from "react";
import PropTypes from "prop-types";

function Index({ link, date }) {
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = date.getFullYear();

    return (
        <div className="reelhot-index">
            <a href={link} target="_blank" rel="noopener noreferrer">
                <img
                    src="assets/images/reelhot-index-thumbnail.png"
                    alt="reelhot-index"
                    loading="lazy"
                />
            </a>

            <p>{`${month} ${year}`}</p>
        </div>
    );
}

Index.propTypes = {
    link: PropTypes.string.isRequired,
    date: PropTypes.instanceOf(Date).isRequired
};

export default Index;
