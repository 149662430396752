import React from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";

import Cms from "../components/cms";
import { butterImage } from "../utils/butterHandler";

class Contact extends React.Component {
    constructor(props) {
        super(props);

        ReactGA.pageview(window.location.pathname);
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Contact us</title>
                    <meta
                        name="Description"
                        content="Contact ReelMetrics for fact-based actionable insights! Improve purchases and conversions,
                        Optimize conf, Drive revenues, Detect hits early, Gain clearer insights, Stay in the loop" />                
                        <link rel="canonical" href="https://www.reelmetrics.com/contact" />
                        <meta property="og:url" content="https://www.reelmetrics.com/contact" />
                        <meta property="og:type" content="website" />
                        <meta property="og:title" content="Slot Machine Data Analytics Platform" />
                        <meta property="og:description" content="Contact ReelMetrics for fact-based actionable insights! Improve purchases and conversions, Optimize conf, Drive revenues, Detect hits early, Gain clearer insights, Stay in the loop" />
                        <meta property="og:image" content="https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ" />
                </Helmet>

                <div className="box--light">
                    <div className="container">
                        <div className="row img-stretched">
                            <div className="col-sm-6 col-md-offset-1 col-md-5">
                                <div className="box--padded">
                                    <div className="mt-sm-2">
                                        <h1 className="title title--lg title--primary">
                                            <Cms
                                                content={this.props.cms_content}
                                                content_key="contact_splash"
                                                type="title"
                                            />
                                        </h1>
                                        {/* <p className="paragraph mb-xs-4"><Cms content={this.props.cms_content} content_key="contact_splash" type='text'/></p> */}

                                        <div className="paragraph paragraph--emphasized mb-xs-4">
                                            <address className="mb-xs-3 link--primary">
                                                Ypenburgbocht 3 <br />
                                                2316 WB Leiden <br />
                                                The Netherlands
                                            </address>
                                            <Cms
                                                content={this.props.cms_content}
                                                content_key="contact_phone_numbers"
                                                type="text"
                                            />
                                            <a
                                                className="link--primary"
                                                href="mailto:info@reelmetrics.com"
                                            >
                                                info@reelmetrics.com
                                            </a>
                                        </div>

                                        <p className="paragraph paragraph--smallest">
                                            Trade Register: 62121537 <br />
                                            VAT Number: NL8546.65.687.B.01
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 img-stretched__media">
                                <div
                                    className="media-img media-img--stretch-right"
                                    style={butterImage(
                                        this.props.cms_content,
                                        "contact_page"
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Contact;
