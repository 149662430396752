import React from "react";
import {
    XYChart,
    AreaSeries,
    LineSeries,
    CrossHair,
    XAxis,
    YAxis,
    PatternLines,
    withParentSize
} from "@data-ui/xy-chart";

const PercentileTimeSeriesChart = props => (
    <XYChart
        margin={{ top: 10, left: 0, right: 40, bottom: 40 }}
        ariaLabel="Bar chart showing ..."
        width={props.parentWidth}
        height={props.height}
        showYGrid
        showXGrid
        xScale={{ type: "time" }}
        yScale={{ type: "linear", domain: [0, 105] }}
        renderTooltip={({ datum, color }) => {
            const monthNames = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
            ];
            const month = monthNames[datum.x.getMonth()];
            return (
                <div>
                    <strong style={{ color }}>{datum.label}</strong>
                    <div>
                        <strong>Month: </strong>
                        {month} {datum.x.getYear() + 1900}
                    </div>
                    <div>
                        <strong>Value: </strong>
                        {datum.y}
                    </div>
                </div>
            );
        }}
        snapTooltipToDataX
    >
        <XAxis label="Time" />
        <YAxis label="Percentile" numTicks={8} />

        {props.series.map((serie, index) => (
            <PatternLines
                key={JSON.stringify(serie)}
                id={`confidence_pattern_${index}`}
                height={5}
                width={5}
                stroke={serie.color}
                strokeWidth={1}
                orientation={["diagonal"]}
            />
        ))}
        {props.series.map((serie, index) => {
            return (
                <AreaSeries
                    key={JSON.stringify(serie)}
                    strokeWidth={1}
                    data={serie.data}
                    stroke={serie.color}
                    fill={serie.color}
                    fillx={`url('#confidence_pattern_${index}')`}
                />
            );
        })}
        {props.series.map(serie => (
            <LineSeries
                key={JSON.stringify(serie)}
                data={serie.data}
                stroke={serie.color}
                label={serie.label}
            />
        ))}
        <CrossHair showHorizontalLine={false} fullHeight stroke="black" />
    </XYChart>
);
export default withParentSize(PercentileTimeSeriesChart);
