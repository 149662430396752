import React from "react";
import OwlCarousel from "react-owl-carousel2";
import SlideItem from "./SlideItem";
import { slideImages } from "./SlideImages";

function ProductCompatibility() {
    const carouselOptions = {
        autoplay: true,
        dots: false,
        autoplayTimeout: 4000,
        loop: true,
        lazyLoad: true,
        items: 1,
        animateOut: "fadeOut",
        animateIn: "fadeIn"
    };

    const carouselEvents = {
        onChange: event => {
            let index = event.relatedTarget._current;
            if (index < 2) {
                index = 1;
            }

            const slides = Object.keys(slideImages);
            const slideIndex = slides[index - 1] || 0;
            const slide = slideImages[slideIndex]?.key ?? "";

            if (document) {
                document.getElementById("key").innerHTML = slide;
            }
        }
    };

    return (
        <div className="box--dark container-fluid compatibility_container">
            <div className="outside-wrapper container-fluid compatibility_wrapper">
                <div className="col-md-3 compatibility_title_wrapper">
                    <p className="compatibility_title">
                        Key <span id="key" /> Compatibilities
                    </p>
                </div>
                <div className="col-md-9">
                    <OwlCarousel
                        options={carouselOptions}
                        events={carouselEvents}
                        className="owl-carousel owl-theme"
                    >
                        {Object.keys(slideImages).map(slide => (
                            <SlideItem
                                key={slideImages[slide].key}
                                slideKey={slideImages[slide].key}
                                images={slideImages[slide].items}
                            />
                        ))}
                    </OwlCarousel>
                </div>
            </div>
        </div>
    );
}

export default ProductCompatibility;
