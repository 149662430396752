import React from "react";

const SingleStatComponent = props => {
    if (Object.keys(props.data).length > 0) {
        const [firstElem, ...rest] = props.data;

        return (
            <div className="stats-group col">
                <h3>{firstElem.key}</h3>
                <h4>{firstElem.value}</h4>
                <table>
                    <tbody>
                        {rest.map(stat => (
                            <tr key={JSON.stringify(stat)}>
                                <th>{stat && stat.key ? stat.key : null}</th>
                                <td>
                                    {stat && stat.value ? stat.value : null}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
    return null;
};

export default SingleStatComponent;
