import React from "react";
import PropTypes from "prop-types";

function ServicesBlockItem({ image, title, text }) {
    return (
        <div className="col-xs-12 col-sm-6 col-md-4 text-center">
            <div className="question-box">
                <figure className="question-box__media">
                    <img
                        src={image}
                        alt={title}
                        className="img-responsive center-block"
                        loading="eager"
                    />
                </figure>
                <span className="question-box__title">{title}</span>
                <p
                    className="question-box__body"
                    dangerouslySetInnerHTML={{
                        __html: text
                    }}
                />
            </div>
        </div>
    );
}

ServicesBlockItem.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired
};

export default ServicesBlockItem;
