import React from "react";
import {
    XYChart,
    BarSeries,
    XAxis,
    YAxis,
    LinearGradient,
    withParentSize
} from "@data-ui/xy-chart";

const BarChart = props => (
    <XYChart
        ariaLabel="Bar chart showing ..."
        width={props.parentWidth}
        height={props.height}
        xScale={{ type: "time" }}
        yScale={{ type: "linear" }}
        showYGrid
        showXGrid
        margin={{ top: 10, left: 10, right: 40, bottom: 40 }}
        renderTooltip={({ datum, color }) => {
            const monthNames = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
            ];
            const month = monthNames[datum.x.getMonth()];
            return (
                <div>
                    <strong style={{ color }}>{datum.label}</strong>
                    <div>
                        <strong>Month: </strong>
                        {month} {datum.x.getYear() + 1900}
                    </div>
                    <div>
                        <strong>{props.label}: </strong>
                        {datum.y}
                    </div>
                </div>
            );
        }}
        snapTooltipToDataX
    >
        <LinearGradient id="bar_gradient" from="#a6262b" to="#d02f35" />
        <XAxis label="Time" />
        <YAxis label={props.label} numTicks={4} />
        <BarSeries
            data={props.data}
            stroke="#fff"
            strokeWidth={0.5}
            fill="url('#bar_gradient')"
        />
    </XYChart>
);
export default withParentSize(BarChart);
