import React from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";

class S02E05 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname);
    }

    // calls API and attaches scriptt to DOM
    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);
    }

    // checkes if component should update based on props in Helmet
    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }

    // ensures Helmet sets teh code when page loads
    componentDidUpdate() {
        if (Helmet.canUseDOM) {
            Helmet.renderStatic();
        }
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>
                        {this.props.title} | {this.props.subTitle} | ReelCast by
                        ReelMetrics the world&apos;s best slot analytics data
                        platform
                    </title>
                    <meta name="Description" content={this.props.description} />
                    <link
                        rel="canonical"
                        href="https://www.reelmetrics.com/s02e05-marketing-mix-management-with-lucien-wijsman"
                    />

                    {/* Open Graph / Facebook / LinkedIn */}
                    <meta property="og:type" content="website" />
                    <meta
                        property="og:url"
                        content="https://www.reelmetrics.com/s02e05-marketing-mix-management-with-lucien-wijsman"
                    />
                    <meta property="og:title" content={this.props.title} />
                    <meta
                        property="og:description"
                        content="Nick & Don welcome Lucien Wijsman to discuss player segmentation, and how over-diversification, imbalance, and obsolete mix management practices are hampering slot performance."
                    />
                    <meta
                        property="og:image"
                        content="https://cdn.buttercms.com/OaWFV5QDqgOJxaKViwdg"
                    />
                    <meta property="og:locale" content="en_US" />
                </Helmet>
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}

S02E05.defaultProps = {
    id: "12893578-s02e05-marketing-mix-management-with-lucien-wijsman",
    link: "s02e05-marketing-mix-management-with-lucien-wijsman",
    title: "Marketing & Mix Management with Lucien Wijsman",
    subTitle: "Season 2, Episode 5",
    description:
        "In this episode, Nick & Don welcome ReelCast's first guest--industry consultant and executive education pioneer, Lucien Wijsman where he emphasizes the critical importance of player segmentation, and how over-diversification, imbalance, and obsolete mix management practices are hampering slot performance.",
    keywords: [
        "Player segmentation",
        "Over-diversification",
        "Mix management",
        "Slot performance",
        "Inventory management"
    ],
    topics: [
        "The importance of player segmentation in slot floor management",
        "Identifying the impacts of over-diversification and imbalance on slot performance",
        "Addressing obsolete mix management practices",
        "Strategies for optimizing slot performance through inventory management"
    ],
    buzzSproutLink:
        "https://www.buzzsprout.com/2057836/12893578-s02e05-marketing-mix-management-with-lucien-wijsman",
    spotifyLink: "https://open.spotify.com/show/314iBnXRGGAuttAsIcZeC5",
    applePodcastsLink:
        "https://podcasts.apple.com/us/podcast/reelcast-by-reelmetrics/id1652485625",
   iHeartRadioLink: "https://iheart.com/podcast/104029260/",
    cdcLink:
        "https://cdcgaming.com/reelmetrics/s02e05-marketing-mix-management-with-lucien-wijsman/",
    transcript: `Nick Hogan:
At Reel Metrics. We collect and analyze a ton of slot data, and we're hit up frequently for insights, tips, and tricks. So we decided to do a webcast in which we present, discuss an otherwise nerd out on our work. Coming to you from our offices in lovely Leiden in the Netherlands, welcome to Reel Cast.
Hi Don. How are things today in your neck of the woods?
Don Retzlaff:
Gorgeous. It's a beautiful day. How about you?
Nick Hogan:
Yep. Spring has finally sprung over here. We're recording this on Mother's Day, and incidentally, the day of the Leiden Marathon in which plenty of mamas are participating. My wife is also out there running today. So after this, I need to run over to a post-race barbecue that we'll have raging next to our canal this evening, so that should be a hoot. As you know, I'm fresh back from Mexico City where I spoke at AGS's Game on Mexico event. Once again, AGS absolutely hit it out of the park. Cool venue, great speakers, very enthusiastic customers. There was a big marketing focus at this one with a great presentation by a Mexican marketing professor named Cesar Enriquez Moran. That was phenomenal. And then a very lively keynote by the Mexican boxing champion, Ricardo Finito Lopez. Do you remember this guy is Don?
Don Retzlaff:
I do.
Nick Hogan:
Okay. So that guy has a ton of energy and interestingly during that keynote he quoted Jose Saramago, George Bernard Shaw, Hermann Hesse, and Theodore Dostoevsky. So not exactly the type of books you'd expect to see on a boxer's nightstand. A pretty fascinating, dude. But in any event, it was a great event. Huge thanks to AGS, the attendees and fellow speakers. It was absolutely worth the trip over. So moving along here, we did receive a listener question that I thought we could address quickly. And on that note, we'd love to tackle any questions that anybody listening may have. So if you have a question about what we're presenting or something you'd like us to present, please drop us an email at reelcast@reelmetrics.com. Again, that's R-E-E-L-C-A-S-T @reelmetrics.com. Our policy is to keep all questions anonymous, so please speak directly and don't worry about us revealing your identity. We don't do that.
Okay, so this question came from a name that really surprised me. Now, we have our anonymity policy here, so I can't reveal the name. But let's just say it's a person who wrote one of the more foundational books ever written about casino operations. I read it in my late twenties. It remains my bookshelf to this day. So can't name the name, but trust that it's a very heavy hitter in our industry. So the question was in response to our last episode on Jackpot Liabilities and High Links and it reads, I recently discovered Reel Metrics enjoying the podcast. One question. Are the comments and data derived from your analyses relevant to American tribal casinos? Many are in more rural locations and tend to be much smaller operations. So again, many thanks to listener who sent us that. Hugely appreciated.
Now, first, just to clarify, a sizable portion of our data comes from tribal casinos, both big and small. So not only are the insights relevant to tribal casinos, but many are derived directly from them, so that's that. And with respect to the jackpot liability topic, Don, in that first or in that episode, you were very careful to introduce a few caveats about six digit jackpot seeds and very small operations, specifically those that lack the players who can afford those higher average bets. So any chance you could reiterate a few of those points on the smaller casinos with those much larger jackpot seeds?
Don Retzlaff:
Sure. You definitely have to have the clientele to be able to pull off one of those large progressives. Just like you wouldn't have a hundred dollars blackjack game at eight o'clock on a Monday morning when all your players are betting $5 [inaudible 00:04:44] you really can't have those large jackpots if you have a small casino. You can, you just have to have a lot of courage to be able to pull it off. I've got casinos that track their large players because there's so much of their win. If they come in and they drop a hundred thousand dollars, it's a large percentage of their daily win. So you really have to look at it. Do you have the clientele to be able to pull these off? Most casinos have $5 and $10 machines on their floor. You know that over the life cycle of the machine, they're going to hit, but it's not going to kill you.
But some of these new video games that have these million dollar jackpots or half a million dollar jackpots, you've really got to be careful and make sure you have that customer base. It's the same thing on the video poker side. Some of the action gaming games, if you set them up incorrectly and someone gets super, super lucky, it could have a really big effect on your bottom line. So you just have to look at your liability and all your games and figure out what your comfort point is. Yeah, is it a hundred thousand dollars jackpot? Are you comfortable with that? Is it a quarter million dollar jackpot, are you comfortable with that? Look at your customer clientele. Talk to your GMs, talk to your executive teams and figure out where you want to be for what's your largest hit, because they do hit.
Nick Hogan:
Yep. And I seem to recall, Don, you were saying something kind of in terms of the size of the slot floor. I think you were saying something like below two to 3 million a month in slot revenue. You may want to be a little bit more careful with some of these. You would stick by that?
Don Retzlaff:
I would, yeah. If you've got a large clientele, a lot of thousand dollars a day players, you can get away with a lot more. If you only have a handful of people who are coming in a couple times a month that'll drop more than a thousand dollars a trip on those types of games, you probably need to back off a little bit and make sure those top awards are in that 25 to $50,000 range. Enough that they'll play, but not enough that it's going to give you a bad week or have a bad conversation with your GM.
Nick Hogan:
Sure, sure. Okay, great. Well thanks for that, Don. And again, many thanks to the listener who submitted that question. We really appreciate it. Okay, so Don, big day on Reel Cast in it's the first time that we have a guest on the show, so we're letting somebody into the inner circle.
Don Retzlaff:
It's about time.
Nick Hogan:
So this gentleman has been in the biz for decades and has had a deeply interesting career. So he began his career with Holland Casino back in the eighties and then went on to become one of the most sought after operational consultants in the world with engagements in and get ready, Don, because this is quite a list: Spain, Belgium, Italy, Finland, Romania, Bulgaria, Russia, Belgium, Greece, the Baltics, Poland, Hungary, Switzerland, Ukraine, United Kingdom, Macedonia, Slovenia, Austria, Croatia, Monte Carlo, Morocco, Uganda, Tanzania, Mexico, Panama, Argentina, Peru, Chile, Puerto Rico, Dutch Antilles, Singapore, Philippines, Vietnam, Korea, Japan, and the United States. And-
Don Retzlaff:
I've been to one of those places.
Nick Hogan:
Okay. Yeah. And that's just operations. So he is been extremely active on the supply side as well, having done a ton of work for Novomatic, Aristocrat Casino Technology, Inspired, Global Draw, Automatica, Playtech and more. And if this wasn't sufficient, he founded certainly what I consider to be the best executive education company in the business, which was a Slot Academy, which was eventually purchased by Clarion and became the Totally Gaming Academy. And he is also founded two slot oriented annual events here in Europe, the slot summit, and then the Casino Operations Summit, which just completed, I believe it's second annual gathering in Bucharest, Romania this last year. So like you Don, he's a walking slot encyclopedia who loves to gamble, and a guy with whom I've maintained both professional and personal relationships for close to 30 years now. So without further ado, our guest today is Mr. Lucien Wijsman. So hello Lune.
Lucien Wijsman:
Hi Nick. I don't open the window and scream you would hear me. Coincidentally, we're in the same city. We're separated by a marathon at the moment.
Nick Hogan:
Indeed. We live in the same town, Leiden The Netherlands. And so it's a bit of a neighbor here. And I was thinking back today to when, so for how long you and I have known one another and I think... So when did you guys open the Grand Budapest in, what was that? '98
Lucien Wijsman:
And I think that's when we met. Yeah, I think that was the first time.
Nick Hogan:
Yeah, we met actually-
Lucien Wijsman:
At '98.
Nick Hogan:
Yeah, it was yourself and there's some names going back away. So it was at the Van der Valk Hotel in Haarlem. It was yourself, Annetta [inaudible 00:10:07] Peter Bennick and I. That was I think the first time we met. And so that was '98 you said?
Lucien Wijsman:
Yep.
Nick Hogan:
Okay. Yeah, so good long time. Some would say too long. But we were putting together, obviously we, as you know Lucien, we spent a lot of time on this podcast talking about marketing and specifically really understanding the players, who they are, what they like, et cetera. So when we were kicking around people to bring on to discuss some of this, I thought back to a slot academy event that I had attended back in Brussels in [inaudible 00:10:50] years and years and years ago. And I remember being somewhat surprised when the first slide that came up on your deck said in all caps, with exclamation points, "KNOW YOUR PLAYERS!"
And that was kind of the foundation on which you began the rest of it. And I remember talking to you ask afterward and asking, "Well, why do you start there?" And you said something along the lines of, "Because that's the one thing that nobody does." It was kind of along those lines. So yeah, I thought it'd be great to jump in and talk about this, and especially this notion of knowing the players. So maybe you could kind of walk us through that and your philosophy on that when you go into these environments and these operations and whatnot.
Lucien Wijsman:
Yeah. Yeah, I think that must have been 15, 16 years ago and nothing has really changed. It's still about the player. If marketing is understanding what your players want and then trying to exceed or provide better to them than the competition, what they want, then there's a couple of things you need to know, know what the competition does, and you need to know what your players want and the player doesn't exist. There are, at least depending on how you look at it, seven different categories of players. You can say I separate players based on what motivates them to visit casinos. So that would be the neuromarketing approach. You can say, I separate my players based on if they are time constrained or money constrained. That would be an interesting approach because that means that your promotions that would give players extra playing time would only work for those that are money constrained and not time constrained.
But you can also separate your players based on the bet level. You could say, "We have players that play low minimum bets, medium minimum bets, high minimum bets and high maximum bets. And I want to treat each of these categories differently."
And I think what I find out with analysis in a different casino is it doesn't really matter which approach you take and how you separate your players. If you say, "We have fun players, people who come in groups in the weekends and want to be entertained. And we have retired fun players, which time on device players, people who come during weekdays and spend a lot of time on the device and they're risk averse and they play small bets. And we have players who come to gamble. The disproportionate part of the disposable income goes to making bets on games of chance."
It doesn't really matter what approach you take. What I find is in all casinos where I come, 10 to 15% of the players produces 60 to 70% of the bottom line.
And when a slot manager tells me, "I know my players," my heart jumps because then I think, "Wow, that would be fantastic." The reality is that when you walk a casino floor, what you see is the 70, 80% of the players that produces 15% of the income. And I think that is a very commonly made mistake. I think intuitively probably right, is that managers want to make that category of people happy. Because if I tell them, "Well, maybe you should reserve some product for the people that you don't see that produce a much bigger part of your income," they say, "Yeah, but I don't want to disappoint my players."
Well, in not disappointing the 80% of the players that produces 15% of your income, you're going to disappoint the 15% that produces 80% of your income. So that is I think one of the things that I run into very, very frequently lately.
Nick Hogan:
Yes. And we see this, I think as we do a lot of research on this and we see it reflected very clearly in the inventory, meaning that the stuff that is really performant and huge with those what we call the host level players or your VIPs, or again, that 15% of your player base that's generating well over the majority of your revenues, we see that the product for them is really crowded out by the stuff that's really kind of out there and played only by the riffraff. And I'm sure as you see this, I'm sure you see this in every market you touch, do you not?
Lucien Wijsman:
Yeah. And I think I've a big follower of the things you are doing. And then in Europe, I think a big difference between the United States and Europe is that in Europe, the penetration of multi game devices, slot machines that have up to 64 different games is much more prevalent than in the United States. But even on those devices, if I ask slot managers to look at individual machines with more than 30 games and tell me where the total bet, where the games played are being played, what you find is that out of these 64 games that you have on a multi game device, 90% of the game played, 90% of the total bet is on three or four games. So what you see on gaming floors at large, you also see that on individual multi game devices where if a multi game device is being played by the 80% that produces 15% of the income, that is not necessarily the game that the gambler wants to play.
He then has to find another device. It's not solving the problem. Having more multi game devices is not solving a problem that you have in the United States. The problem is the same. And on a big floor or on a multi game device, what happens is players lose either time if they're time constrained or they lose money when they're bet constrained in playing the games that they don't really want to play because they can't find the game title that is their favorite game title. So they end up wandering the floor and playing game titles that are not their first choice.
And I think I saw this in one of the presentations that you [inaudible 00:17:16] and I fully subscribe to that. What you see is that when people end up playing the game that is not their preferred game. They don't play the same bet, they don't play the same time. So as a result, at the end of the day, the casino operator is not maximizing his result. And I would add to that, if this happens three or four times on the row, if you are somebody who has a fairly deep pocket and likes to play in a casino and three or four times on a row you've been disappointed in a sense that you can't find the game available that you like to play, I think you're still going to that casino.
Nick Hogan:
Yep.
Don Retzlaff:
We've done dozens and dozens of reviews looking at where customers are playing and its shocks every single casino that we do, what their best players are playing, what their daily frequent players are playing, who's playing their least games. And almost every one of them are completely shocked just because they don't have the capability to look at it to find out what their best players are playing. So it's a total unknown to them right now.
Nick Hogan:
And what we see is when we try to unpack it, then really understand, we find that there are just a lot of things in the way. One of the biggest problems, of course, is the fixation on EBITDA and its accounting treatment in the industry. So a lot of stuff, even though it's in it has basically no productive capacity. Let's say older core games that have been on there 5, 7, 9 years, they get depreciated over such a long period of time, they're not going off the floor because this is all driven by cost assumptions and EBITDA assumptions and things of this nature.
And then a lot of times the CFOs, particularly when it pertains to these high octane lease products that cost $55, $65 a day, a lot of those CFOs are saying, "Look, we're not going to expand this particular category of product because you can't prove to me that it's actually going to generate incremental revenue.That what we're doing, that our revenue basically has a ceiling. Maybe we can go 5% above it, maybe 5% below, but we're roughly at that ceiling. And if you put more performant product, all it's going to do is cannibalize lesser product."
And luckily we've now established with great empirical evidence that this isn't happening. But it is perfectly understandable how CFOs take this position. And as I said, unless it can generate something truly incremental, we don't want to put out that extra budget. But I'm assuming that when you've done things like this expanded these categories, you're finding it does create incremental revenue and better spend on the higher end.
Lucien Wijsman:
I think one of the most surprising things that I get when I talk to casino operators is that I sometimes get to hear that, "It never happens in our casino that we have more than 70% of the machines being played at the busiest moment of the time. So we don't need more machines."
And by that they mean that they don't have to look at changing the mix of games, which is fallacy obviously, because you could have 50% of your machines not being played and 10% of your game titles is being played a hundred percent. And if it proves anything that when a casino operator says, "I never have more than 70% of my machines being played," but they have a house full of people and they still have 30% of the games that are not being played, that the one single thing that proves, and I remember the slides that you made with the Tom and Jerry's ice cream and the toothpaste carrot ice cream. People want to eat Oreo ice cream from Ben and Jerry's and Oreo is finished. They're not going to eat carrot and toothpaste ice cream.
So your casino could be absolutely jam-packed with players, but if they all want to play the same game, 10 game titles and these game titles are full, some of them will play other machines, some of them will play other machines. But you'll always have a percentage of machines that is not going to play. What casino managers should do is take those titles off the floor.
Nick Hogan:
Yeah. Now, when we've-
Don Retzlaff:
One of the things that we've been looking at is I'd say at an average casino that's been open for more than 10 years, 25% on their floor does not get touched. Doesn't matter how busy it is. I want to say the number I looked at, it gets played four times a day for eight minutes. So for 32 minutes a day, this game's busy and it doesn't matter, Monday or Sunday, four times a day, these games get played for 25% of their floor that could easily go away and be replaced with better quality new games that you buy or better quality lease games.
Lucien Wijsman:
And at the same time that you have 25% of the floor not being played, there is 25% of the floor that is being played far above the average, where at busy moments, people who want to play higher bets can't because people who are playing smaller bets are sitting there and occupying the machines. Sometimes it goes even further than that. I have examples of casinos where they give players player points that can be converted to free credit. And it's okay to give players player points. It's absolutely fine with me. Even on the most popular product, I'm not against giving them player points. Where I have a problem is that we allow these players to convert these points to free play on the most popular machines. So now you end up in the crazy situation that you have people playing with your money on machines which are very popular while other machines, while other players are playing behind them with a wallet full of money that would like to play the same machine with a higher bet. And if I tell slot managers I wouldn't do that, "Yeah, but then I disappoint my players."
I hear again, who am I going to disappoint? Am I going to disappoint the player who is drinking at the cheap house line or the guy who wants to drink the Petrus? And this is what happens in casinos. It's nonsensical to an extent.
Don Retzlaff:
Yeah. We're seeing more and more of our clients asking us, especially with increased labor costs and increased costs to retain staff, we've been asking a lot of questions about what you wanted to do with your floor and there's not a whole lot of answers coming back. So what we've been describing is we've been adding a lot of mid denom video, so going above pennies, going to 2 cent, 5 cent 10 cents, dropping the whole percentage a little bit. And we know then the cost of cover is a lot higher and it's satisfying those high limit players who can't get on their favorite games on the weekends. Now they can get on their games because those low limit customers, those low average bet customers can't play 50 cents, 75 cents, a dollar on these machines. You have to bet up. And we're seeing great success with nickel and dime videos outside of high limit.
Matter of fact, we're working on design for a casino right now where we probably have a hundred games on the penny side that are... on the video side that are above a penny. And what we're seeing across the industry is just really strong numbers and higher densities than you'd expect density is in the 30 and 40% range for a 10 cent video game. And average bets are north of $5 a spin. So the good players can get on these games now versus having to stand back and watch and wait for a machine to become open for. Yeah.
Nick Hogan:
Now I can tell you one of the more... It was actually a place where Lucien was very active not too long ago. I was down in Monaco at Monte Carlo, and this is just a case where... So to describe that, you have the big grand casino there. It's the old famous casino and in the main kind of play area, the casino area, it's quite intimidating. So the place is just gorgeous, looks more like a museum than it does a casino. Just absolutely beautiful place where they film the James Bond, all the James Bond casino scenes and whatnot. And so I was walking the floor with the operations team there and we come in, so just as we're about to go into the Grand Casino, there's a little slot room. And I walk into this and I look around and it's all this inventory. It's older dog-ear stuff, not very interesting, et cetera.
And I turned to the manager and I said, "Hey, what the hell are you guys doing here?" And he said, "No, no, no, no, no, no, no, no. Listen, listen. This was a thing that Lucien came up with." And I said, "Well, what's going on here?" He said, well, every day they have cruise ships booming in to the harbor here. And there are a lot of people rolling off of those cruise ships and their objective is really to go into the casino and play a little bit, just a few euros or whatever. But they then have the bragging rights to say that they've played at the Casino de Monte Carlo. And after listening to that and understanding that this came from a very informed position about the players in the casino, I thought, "Okay, this is actually brilliant." And I think Lucien, I'm not certain, but I think that thing has done really well for them is it not, that room there?
Lucien Wijsman:
Well, I think there's another element to that, and that is that the main casino is not open the same hours that small area has been opened. And you have to register for the main casino to visit the table games. And then there's slot machines behind there as well. So yes, we took machines that are easy to play that American audiences like to play. We put the minimum bets fairly high and not high maximum bets and very low, actually that area of the casino has the lowest RTP in the building. Taking into consideration that the people who walk in there spend an hour and hour and a half at best, and then they leave, they would never know.
For a player to fill the RTP, this comes back in trainings lately, every single time. Can players feel the RTP? Well, for a player to fell the RTP in one and a half hours of play, the answer is clear. The players will not be able to fill the RTP. And the moment that I get into, and that goes a little bit to what Don is talking about, I'm trying to stay away from defining machines as a penny or a nickel machine.
I don't look at the credit values so much anymore. I'm not sure why we're still communicating credit values to players. It's a bit confusing. If you have a penny slope with 50 lines, then the cost to cover is 50 cents. And that machine would then be installed in the area where you have a big 1 cent credit value sign on the floor. And then in another area of the casino you have a 25 cent credit value. And there is a one line real slot where the cost covers 25 cents. So I find that confusing. What I do in most European casinos at the moment is I tell people, "Why don't you take all your line configurations and all your credit value configurations and then you create areas on the floor where you say, 'If you want to play in this area after casino, the minimum be is going to be between 40 cents and 60 cents or whatever between 25 and 50 cents.' And in this end, that would be the cost to cover not the credit value."
So that's the number of lines times a credit value. And in another area after casino, you could have more or less the same mix of machines, but with a higher cost of cover. And in the third area. So that approach would create a part of the casino floor for the low minimum bet for the medium minimum bet for the high minimum bet. And then you can have a VIP room. Because what I notice in casinos is casino operators know their VIPs, they know their high maximum bet players, they know what they drink, they know what perfume they use, they know the drinks of the girlfriends, they know the girlfriends, they know everything about these guys. They know their time on device players because these people live in the casino and they bring them candies on their birthdays and things like that.
Where they miss it a little bit is with people who play medium or high minimum bets and not frequent. People like me, people who go to... I visit casinos a lot, but I don't visit the same casino a lot. So if I visit a casino for the first time and I end up playing five euro to start with per spin, $5 per spin, and when I get lucky, I increase my bet to $10 per spin and I look around me, then I realized that my bet is probably the combined bet of 20 people around me, which really means that the casino operators should identify me and come, but they don't. And I visit casinos not when it's very busy because it's usually at the end of the working day, something like that. But I think what happens in a lot of casinos is people on my level that don't go into casinos frequently that have not been recognized and identified by the casino operator, they won't find plays to play because the games are all being occupied by the low minimum bet players.
So creating a casino floor with different minimum bet levels and then the maximum bet level they, that's the spread. Or you look at the financial exposure, I think that's a different discussion altogether. But I think casino operators should start by creating areas with a similar minimum bet. And what that will do is it'll allow people who like to play a higher bet to find their game because it's there and it's not being occupied by a small bets player. And I think that's where it starts and then for me, players are almost like normal human beings. What it costs always comes before what it does. If you go out to buy shoes or a watch or a car or a holiday, it doesn't matter what. I think you look at what it costs before you decide what you're going to buy.
Most people when they go on hotels.com and they select a hotel, they enter the price range before anything else. And I think in casinos it's no different. If you have an area where people think, "Ah, that's my area because that's what I can afford." And then once they're in the area, they're going to look at the game they want to play.
So I think the cost, having the right amount of machines at the right minimum bet levels for your players, then in each of these areas, having the right mix of games for your players and then you can start looking at the configuration.
Don Retzlaff:
Yeah. Yeah. I think you'd really like the casino I just laid out because I did it exactly how you just described. I started actually with high limit and then what did I want to put outside of high limit? And I created neighborhoods. So what did I have outside of Height Limit was the higher denomination video. And then what did those customers also play? Since we've got all this Cupid data, I started using it to figure out if you play Dragon Cash, what else do you play? If you play Lightning Link, what else do you play? If you play 25 Cent Video, what else do you play? And I was able to create neighborhoods with like minimum bets, like average bets, and then just start connecting the areas to areas. So outside of high limit was high limit video, next to high limit video was dollar reels and so on and so forth.
And then your casino just naturally flows and there's a natural [inaudible 00:33:21] pattern. So these customers don't have to leave that area with the games that they want to play. I did the same thing with video poker, and then you can fill in the rest of the casino with the video penny stuff and you can put cost of cover games next to cost to cover games. And then you start looking at volatility of themes and how to bank machines properly. But it's everything that you just said. And the other thing that I was reflecting on when you were talking was one of the things that I did as an operator, and I hoped everyone does it now still is when you get to know your customers... I made it a point to learn about one of my top 100 players every day. I talked to my host team and when one of those customers would come onto the floor, I'd say, "If I don't know them, please come and get me. I want to be introduced to them, I want to learn about them, I want to see what they play."
Because those top 100 players have so much of your revenue. And then you're going to start expanding. Once you learn those top a hundred players, learn your top 250 players, you're learning one new player a day after a year, you've learned your top 250 players. And it's a great way to learn those customers and figure out what they want. And because there's a lot of customers that we have that just don't have that knowledge base of what their customers play, and it's not easy to get out of the slot systems. You've got to do some digging, but if you look at one customer per day, you can really get a good jump on it.
Nick Hogan:
Indeed. Yeah. And that's just back to the example I gave earlier down in Monaco. That's what I had seen having gone in there, Lucien, prior to your work there, it really did kind of seem like there was not a clear merchandising strategy on that floor, kind of how the mix was laid out. It didn't make much sense. Whereas now I would say that floor is hyper segmented to where you have the heavy hitters really going one specific, and then you have that ultra, ultra ultra VIP set up there. It's really just been, it was fascinating. I have no doubt that the results were fantastic after implementing that.
Lucien Wijsman:
Yeah, without going into the detail, yes. I think there is a clear relationship between the result trend and the fact that they've decided to embrace a strategy where they say, "We are not a number of casinos in the same town competing with each other, but we are one casino in a town in different buildings complimenting each other." And that started with saying, "Okay, which player category are we going to tailor to on each area of each casino in the city?"
And I think that's what they followed through and are still following through and it is on the slot machines side, definitely. And I think you can see the results of that. Absolutely.
Nick Hogan:
And so then Lucien, one of the things that I wanted to discuss with you here today too is I know that when you go in and you're doing engagements, you're quite big on planning and actually putting together formal plans for these organizations. So could you cover that a little bit? How you approach this topic when you go into new engagements and how you develop these plans and whatnot.
Lucien Wijsman:
Being a consultant, I think what you want to do is you want to tell your customer, "Listen, we need a plan for five years because then you have a small chance that you can stay on board for five years." No, not really. Well, I typically when I walk through a casino, the first visit is usually as a mystery guest where although management knows, and I'm not looking at staff so much, I visit casinos where I sign agreements usually as a player. And what I do is I put on my fun player glasses and I walk through the casino and I ask myself the question, where is the fun? And then I put all my time on the vice player's glasses. And in these two roles I'm really acting because I'm not a fun player and I'm not a time only vice player, but I do ask myself the question where is the area, the lounge, the area where time of vice players would feel comfortable.
And then I take off my glasses and I am myself, and then I'm the gambler. And then I ask myself a question, "Do I want to play in this casino? Does this casino make me want to gamble, make me go to the cash machine again and again and again?" And based on that first visit, I typically propose a roadmap, a five-year plan where I tell casinos, "Well, this is where you are now and I think this is where you want to be five years from now."
And that always includes creating different areas for different players based on the data sets or the feedback that I get from the casino. Casinos typically know how many fun players they have, how many time on device players they have, how many gamblers they have, how many low stakes, medium stakes, high stakes gamblers. They have. They typically also in a position to say, "Well, and this is the segment we would like to grow."
And so in the five year plan, it starts with defining the player groups that the casino has and the player groups that they want to have. Then the next part is to say, "Okay, then what does that mean for the layout of your casino? Which player group organically, naturally goes where."
If a casino has or can make daylight, then that's the area where your time on device players go. If a casino has an area where the ceiling is much higher than other areas, that would be the area where your fun players could go. If there was an area with lower ceilings where people can hide more than other places, these would be the areas where your gamblers would go. In most casinos, dividing a casino up into different areas for different players is very organic. It's usually not that complicated to come up with different areas for different players.
And the next question is, what product are we going to put in each of these areas and what minimum or maximum bets are we then going to apply on these, and I still didn't talk about RTPs. So what product goes there, what's the minimum maximum bets that we associate with it? And then the question is, "What are we going to do with RTP? What RTP are we going to give back to each of the player categories at the end of the five-year period and how we're going to do that?" Because RTP is much more than the pay table percentage on the slot machine. RTP for me is the sum of the pay table, percentage of the machine plus the points that you give on the card, if any, plus the increment that goes to progressive prices, if any, plus the increment that goes to mystery prices, if any.
And if the percentage that goes to mystery prices, you can say that comes from supplier driven mystery price, Dragon Link would be the ultimate example there I think, or lightning link. So this is a supplier that has a bank of machines and they also control the controller that does the increments through the different mystery prices. You could also say I do system driven mystery prices. For the RTP that you give back to the players. System driven mystery prices are interesting because you are in the driving seat as an operator.
You can say, "I'm going to give mystery increments to players that fulfill conditions." So that can be in an area where you have time on device players Monday through Thursday, 10 o'clock in the morning to five o'clock in the afternoon. If your bet is smaller than $1, little bigger than, smaller than $1, you're going to increment a mystery price that hits very frequently and goes to the credit meter of your machine. What that does, it gives your time on device players extra playing time. At the same time, you could say in the weekends, on a Friday, Saturday, Sunday night in the fun players area of the casino, I'm going to use my system to do a mystery prize that I give to players who are willing to play a bet bigger than X. So that is to drive-
Nick Hogan:
What you're describing here is this is the whole promotional program. So what are your promotions? Yeah.
Lucien Wijsman:
It's not all the promotions because you could do raffles as well, but I would say bets based promotions. So promotions which are part of the bet and therefore part of the RTP to the players. I think casinos need a strategy there. They need a strategy where they say who gets what, who gets what and how do they get it. That's always part of the marketing plan that I make.
Nick Hogan:
Yeah, so there's like you have a specific breakout description of the promotions. I'm sure everything is measured and you have a whole program for scheduling it, refining it, et cetera, et cetera. And interestingly, so yeah, indeed, a couple of things there. So the configuration was not even something you answered until you'd answered multiple other questions. I think that's an important one, including RTP, very front and center. But one question I have is when you go in there and look at that, are you generally finding that when you're, let's say, going after, let's say, that higher end player, are you finding that you're just in a really difficult starting position with the inventory that is you just have too little inventory that's really wanted by those premium segments? Is that generally what you find when you go into these places, that it's just been crowded out and there's too much junk out there?
Lucien Wijsman:
Well, it very much depends on the operator. I think there are a couple of European operators. Sorry, there are a couple of European suppliers which are also operators, and they seem to have game titles that are very, very popular with their players and they use this. So in their own locations, you would see a very large number of very limited number of game titles. And it works. It works. Can we talk suppliers here?
Nick Hogan:
Yeah, I think you mentioned one to me a while back that was in Austria. Yeah, it was a Novomatic thing.
Lucien Wijsman:
So if you take Novomatic and Novomatic has a number of game titles that have been popular with them for the last more than 10 years, Book of Ra is one of them and I think Lucky Lady Charm is one of them. And what I noticed when I was in one of the Novomatic locations, which is not there anymore, the legislation has changed. They had a very large slot operation in the middle of Vienna, and when I went there for the first time, what I expected to see was all the latest and the greatest. This is something that I hear from slots operators. "We want to be the latest and the greatest." And part of me then thinks, "Why don't you just want to be the greatest? What is this obsession of wanting to be the latest?" Be the greatest. Greatest makes much more money than latest. And what I found out when I went to this Noma Novomatic location, there were about 400 machines, and what I would've expected was to find the latest and the latest of what that great company has on offer.
And when I walked inside, I realized that out of the 400 machines they had, 300 were single game, Book of Ra and Lucky Lady Charm and full and being played and they made money. You could see the money leak down the walls. And if there is any case study, which fully hundred support present supports what you say, then it was that.
Nick Hogan:
Yeah, that's the very opposite of over diversification, where they had just had a handful of titles on the floor than it sounds like.
Lucien Wijsman:
But they had much more titles. I mean, they had hundreds of titles, but in their own flagship casino, which was a very successful location, Book of Ra and Lucky Lady Charm. And I thought, "Yeah, that's how it works." I mean when people say to me a Lightning Link with a hundred machines won't work, then I think, "Hell, it'll work. Of course, it'll work." In most of the European casinos putting a hundred Lightning Link machines will immediately increase the bottom line by quite a large amount. Latest and the greatest not sure, try the greatest and then try the latest.
Nick Hogan:
Yeah, we've plagiarized you on that one at least once, possibly a few times on here using that phrase, which I always love. Just eliminate latest from your vocabulary is what I think I've heard you say in the past, which is great. Interestingly, another thing that you haven't mentioned here as we've talked about marketing promotions, knowing a player et cetera, is you have not mentioned free play, which is a huge thing of course in the United States. So are you generally a little bit conservative on that front that is issuing a lot of-
Lucien Wijsman:
I'm not conservative at all on free play, but here is the problem. If you have 96% games on the floor, there is no space for free play. I think that the problem in Europe is that because of the high RTPs and there is a race to the top, one of the biggest suppliers has now released a game title, which they reluctantly allow in the market for less than 98% RTP, and then I think 98% RTP. And this game is being utilized in parts of Europe where players get a discount. So on top of the 98%, players can get 10 20, 20 5% discount. And I wonder, I mean at the end of the day, you have to pay the electricity bill. And I don't mean that as a joke. The electricity bill has gone up threefold in many countries. I really wonder how that is going to work.
I would love to reward players with free play, but I think if you want to be serious about free play, you have to be generous. When a player puts a card in a machine, if you give him 0.1% of total bet in points and then let them convert that, that players have to play. The players who would feel the free play have to play so long that it doesn't make any sense. So you need to give a percent, a percent and a half, maybe even 2%, which is possible if you have a 90% game and you give a player 2% in points, that means you give up 20% of your theoretical hold. Or if you flip it the other way around, you give players 20% extra playing time, then it works. I think a player would always insert his card if he feels a time on device player, if he feels he gets 20% extra playing time, 0.1% or [inaudible 00:48:47].
Don Retzlaff:
Yeah, we don't have that problem in the States. Even our most generous casinos here have their games set at 92%. It's even tough to find a video poker machine set at 98% or 97%, but most of the video, most of the reels are set in that 10% range plus or minus two points.
Lucien Wijsman:
And you what, most of the casinos in the US fold themselves. So I think it's a fallacy for European managers to say, "You need to give a player 95, 96%, or he will feel..." It's also not completely true. I mean will always, always remember an experience that I had in South Africa where I advised in Johannesburg a group that operated bingos. Now these bingos there, when you press start on the slot machine, it has a small bingo card that produces a game outcome and then it translates the bingo outcome to a game outcome. And that's how it is. This bingo club came to me and they said, "Listen, we're a little bit afraid they're going to open up this huge Times Square casino near our bingo, and we're afraid that all our players will leave."
And we had a conversation about, and I told them, "Listen, I don't think. I'm pretty sure they will visit that casino, but the style in your casino, they can park, they have a coffee, they're part of the interior, they will go, but I think they will come back because they like the atmosphere in your place better. And I think at the end of the day, staff interaction atmosphere is most important."
This is exactly what happened. All the players who visited the bingo would visit Times Square, and then they came back and very much what I had not expected was that when the managers asked them, "So what do you think of the casino? All the players said, you pay more than the casino. The RTP in your bingo is higher than the RTP in the casino." They all said that, a hundred percent of the players, and I knew that it was not true. I knew that the bingo paid 92%, whereas in the casino it was 94%. So this was a bit of a puzzle to me. I didn't understand why that was. And then all of a sudden the quarter dropped with me. The players went to these bingos, they have a certain amount of money in their wallet, they play low volatile games, and they know if they go, they're very frequent that they can play three hours, three and a half hours for their money. On a bad day, they can play three hours on a good day, they can play four hours.
Now they go to the casino with their money and they play the same game, and they can play two and a half hours on a bad day, and they can play three hours on a good day. The reason for that is not the RTP. The reason for that is that in the bingo, the game duration is longer. You have to wait for the outcome on your bingo card. That takes two seconds. Then you have the game outcome. So you play 500 games in an hour as opposed to 600 games. And that for me, you can't look at RTP in isolation. You cannot. You have to take the game duration into account and you have to take the cost to cover into account.
And so the whole discussion about RTP, for me, if a player plays the same game with the same cost to cover and the same game duration for a very, very extended period of time, and then he plays another game with the same game duration and the same cost to cover for the same extended period of time, he might be able to say, "You know what, on that other game, on average, theoretically I play longer with my money," but you have to play so long. The whole RTP discussion for me might be a discussion, but I would love to have that discussion after the casino has addressed the real issues and the low hanging fruit. RTP is not low hanging fruit. Yeah, yeah, sure. The mix of the games, the mix of the games is low hanging fruit. The cost to cover is low hanging fruit availability of games. That's the combination of the two. That's low hanging fruit.
Nick Hogan:
Now Lucien, one of the things that we found too is when we go at this topic and we have exactly the same philosophy and we're saying just get to know each category of players as well as you possibly can, really understand what they're all about, what makes them tick, et cetera. And one of the things that we find is one of the weirdest barriers to this inside of gaming organizations is that the departments of slot operations and marketing are just not working together. So in some cases, what we find is that, you may have directors of each of the disciplines absolutely hate each other's guts, or maybe they're just, in some cases there are policies that operations is not permitted to see player data, et cetera, et cetera. So in your experience, is this pretty common when you go throughout operations, do you see big divisions in the, let's say, between the operations and marketing departments that they're just not working closely enough together?
Lucien Wijsman:
I think the whole definition of marketing, and when I do, I have a slot course, which is called, it's all about marketing, and it centers around the extended marketing mix for the services industry. And I tell managers, "Okay, you need to have a strategy on the field of product price, promotion, placement, physical environment, personnel and process management." Those are the seven Ps. Who controls the product? It's the slot manager. Who controls the placement of the product? It's the slot manager. Who controls the minimum maximum bet? It's a slot manager who controls most of the bet based promotions? It's the slot manager.
So I think when you talk about casinos, the person that we call marketing manager in most casinos might be the PR manager. He might be the communications manager, but in most cases, he's not the marketing manager. I think if slot managers would start to understand that in fact they are the marketing manager slots and that there is another manager who is the communication manager slots and other products that would already help. I find it amazing that marketing managers typically do not attend my course. And the ones who do, they have this aha, this wow. There are a couple of marketing managers in the US also that have attended my course and all the marketing managers who do they say, "Okay."
Nick Hogan:
Yeah, it's pretty amazing. One of the things we've talked about many times on here is that we just have kind of a... The way that marketing is set up in the casino industry relative to other retail industries is just so basic and in many ways kind of unsophisticated. And it is this kind of idea. And Don talks about this dichotomy a lot, that it's job to bring people into the casino and then operation's job to give them a reason to come back. And that's kind of where it stops.
And indeed, what you see there is that marketing teams really focus on things like ad buys, social media stuff, et cetera, et cetera. It's all communications. Whereas in the product marketing side of things, there's no role whatsoever. So the idea we're putting things on our shelves ostensibly because it's stuff that our consumers want to buy, yet the organization or the portion of the organization is really responsible for that just doesn't have a role in it. And it's something that we're trying to encourage more and more, that there's more of this. Marketing gets deeper into that chain and really starts having a role in looking at the mix and trying to understand together with operations how they market to these players.
Lucien Wijsman:
What I saw in the American casinos where I was involved was that the marketing people do play a fairly big role in sending promotional vouchers and in setting up the promotions, the rules for the promotions.
Nick Hogan:
Absolutely.
Lucien Wijsman:
And then I think, "Well, if you don't know the pay table percentages of the machines, if you don't know which machines are being played by which category of players, then what's the success rate of these promotions going to be? Giving points to a guy who has an hour per day and who plays $10 per spin on a highly volatile game. And if he wins, if he's lucky, he increases his bet because he's time constraint, he wants to leave after an hour. What's the real benefit of giving a player like that a promotional voucher? The only thing he will do if he gets it is he will play maximum be on another game to get out of trouble."
I think you're probably much better off giving that category of players a discount as a percentage of the actual loss. Whereas promo vouchers are usually not. They're usually a percentage of the theoretical laws or percentage of the debt if you want. So yeah, it doesn't really make sense to have promotions being organized by people who do not really understand the mechanics of slot games.
Nick Hogan:
And of course, everything going back to as the most foundational point is first of all, make sure we understand who these people are and understand what are their preferences, what are they like, and I mean, begin carving them up. Then you can really tailor different promotions and messaging and all that stuff to people in a more of a micro-targeting environment, which is really what we're, I think, all trying to move toward. Okay.
Well guys, I know this is our first episode with a guest, and I think we're probably bumping up against the time that we normally are. We normally try to keep these things around 45 minutes long. I think we're about there, aren't we? Not entirely-
Don Retzlaff:
Darn. But I've got 30 more questions, so I guess we'll for another day.
Nick Hogan:
Well Don, can you zero down to one or two that you may have for Lucien just to end it, cap it off?
Lucien Wijsman:
And they'll do the other 28, we'll do next time. If the feedback's good, why not?
Don Retzlaff:
One of the things I worked on a lot, one of my current projects is starting a clean slate. If I had a clean slate for a casino, buying all the machines that I want to buy, place them on the floor where I want to place them, cost of cover, and I get down to RTP. One of the things I've been working a lot is win per spin. So it's that cost to cover, times the whole percentage equals this value. You go back 25, 30 years ago before the advent of video and it was always your a hundred dollars machines were set the loosest. And then the 25 and then the five, and then the dollar, and then the quarter, right? Because you knew what the average bet was going to be. And then with the pennies when they started off, that average bet was 50 cents and then 70 cents.
And now I've got pennies out there that have over $3 average bet per spin. Like game like Buffalo Link out there is over $3 per spin, but we're still treating it like it was 2001 and we're putting these games on the floor at 12 and 14% and you would never put a dollar reel with a $3 average bet at 14%. And just trying to get people to look at it as far as a win per spin basis. So if you're averaging $3 per spin and you want to make 45 cents per spin, you know what the hold's going to be. Do you find that the case still in Europe that they're looking at... Your whole percentages over there are definitely a lot higher than what we're seeing here, but do you think that's still a relative strategy to use as a bet per spin?
Lucien Wijsman:
First of all, I'm not looking at the [inaudible 01:01:00] spin. I look at the expected theoretical income per hour, but essentially that's the same thing, but it's the KPI is the same. I would love managers to start looking at games and take a key KPI, which is either the [inaudible 01:01:18] win per spin or the theoretical expected income per hour and let go the cent, 2 cent, 5 cent, 10 cent methodology from the past.
Nick Hogan:
Yeah, it's truly a pricing strategy, as we see in retail, right? You're looking at in terms of pricing.
Don Retzlaff:
Yeah, I could definitely-
Lucien Wijsman:
When I do examples where I give a list with different players, player on a 92% slot and on a 94% poker and on a single zero roulette and a double zero roulette and a blackjack player, and then I say they're all playing for one hour and what's the expected theoretical income after the hour? And then they all say that the guy who was playing hundred dollar blackjack for sure is the guy, but it's actually the guy who was playing $1.50 on the slot machine. And so you have completely different RTPs, completely different game durations, completely different debt levels. And I think that's when people understand that RTP alone means nothing really. It's a combination of a couple of things.
Don Retzlaff:
Absolutely. Yeah. You give me the guy who's playing $3 per spin on a 10% video penny game, he's going to out produce-
Lucien Wijsman:
[inaudible 01:02:40] per hour. Thank you.
Don Retzlaff:
Yeah, it's amazing. And that's why you're looking at some of these games like Dragon Link and Dragon Cash doing two and $3,000. We got some places are doing up with $7,000 win per day on these games. And it's just because it's so much volume and at a decent whole percentage, but the average bets are just carrying these games.
Nick Hogan:
Yep. For sure. Well, Don, did you have any other questions for Lucien before we sign off here today?
Don Retzlaff:
No, but thank you. It was wonderful to talk to you and it's great to hear some of the things that I've been talking about for the last 10 years hold true with other people as well. It's nice to have that confirmation that I'm in the right direction.
Lucien Wijsman:
Let's grow the group, let's grow the group.
Nick Hogan:
And Lucien, before we sign off today, do you have any upcoming events or anything that you wanted to mention?
Lucien Wijsman:
Yeah, we might actually have an opportunity to have a beer together. I'm going to be in Toronto at the SBC summit early June. So from the top of my head I'm saying that that's June 14th and 15. And I'm going to do masterclass there so people can stay,. I think I'm sure they can still register for that through SBC.
Nick Hogan:
Okay, excellent. Any Gaming Operations Academy seminars or anything coming up as well? Are you doing-
Lucien Wijsman:
I have an online webinar coming up in June through the Gaming Operations Academy from Adam Rubik gamingoperationsacademy.com. That's three times three hours, June 12th, 19, 26. And we always pick a time, so it accommodates both the US and Europe. So it'll be 10 o'clock in the morning US time, depending where you are in the late afternoon in Europe.
Nick Hogan:
And to sign up, where do people go for that?
Lucien Wijsman:
So it's gamingoperationsacademy.com.
Nick Hogan:
Okay, gamingoperationsacademy.com.
Lucien Wijsman:
That's for the gamingoperationsacademy.com. And for the summit in Toronto, it's SBC Summit.
Nick Hogan:
Okay.
Don Retzlaff:
I attended the Gaming Operations Academy classes last year and I highly recommend it. It's very educational and you'll walk away with some great knowledge.
Nick Hogan:
Yeah, for sure. No doubt. Okay, cool. And as for the real metrics side of things, I'll be speaking at AGS's game on 2023, which will be happening in Reno from June 13th through the 15th, and then after that Raving Consultings Casino Technology and marketing conference in Reno on July 18th and 19th. So if you have the opportunity to attend either of those fantastic industry events, please do drop in. We'd absolutely love to see you. So guys, I think that's pretty much a wrap for today. Lucien, thanks so much for coming and nerding out with us a bit about slots and hopefully you would like a return visit sometime in the not too distant future.
Lucien Wijsman:
Thank you Nick, and nice to meet you Don.
Don Retzlaff:
It was a pleasure to meet you and thanks for coming on. Really appreciate it.
Nick Hogan:
Okay, thanks so much guys. You take care.
Don Retzlaff:
Thanks Nick.
Nick Hogan:
Yeah, bye.
`
};

export default S02E05;
