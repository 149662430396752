import React from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import butter from "../utils/butterClient";
import { butterImage, butterImageURL } from "../utils/butterHandler";

import Cms from "../components/cms";
import Well from "../components/well";

class Team extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            team_members: []
        };

        this._getBackgroundImage = x => {
            return { backgroundImage: `url(${x.photo})` };
        };

        ReactGA.pageview(window.location.pathname);
    }

    componentDidMount() {
        butter.content.retrieve(["team_member"]).then(response => {
            this.setState({ team_members: response.data.data.team_member });
        });
    }

    render() {
        return (
            <>
            <Helmet>
                <title>ReelMetrics | Team</title>
                <meta
                    name="description"
                    content="ReelMetrics is a team of data scientists who are passionate about casino data and slot machine analytics."
                />
                <meta
                    name="keywords"
                    content="casino data, slot machine analytics, casino analytics, casino data analytics, slot machine data, slot machine data analytics, casino data scientist, slot machine data scientist, casino data science, slot machine data science, casino data analysis, slot machine data analysis, casino data analytics company, slot machine data analytics company, casino data science company, slot machine data science company, casino data analysis company, slot machine data analysis company"
                />
                <meta
                    rel="canonical"
                    content="https://www.reelmetrics.com/about-us"
                />
            </Helmet>
                <section className="box box--light">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="mt-xs-2">
                                    <h1 className="title title--primary title--lg">
                                        <Cms
                                            content={this.props.cms_content}
                                            content_key="team_splash"
                                            type="title"
                                        />
                                    </h1>
                                    <p className="paragraph">
                                        <Cms
                                            content={this.props.cms_content}
                                            content_key="team_splash"
                                            type="text"
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="box box--end">
                                    <img
                                        src={butterImageURL(
                                            this.props.cms_content,
                                            "about_us_splash"
                                        )}
                                        className="img-responsive width-100"
                                        alt="The ReelMetrics team"
                                        loading="eager"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="box">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6">
                                <h2 className="title title--primary">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="team_1"
                                        type="title"
                                    />
                                </h2>
                                <p className="paragraph">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="team_1"
                                        type="text"
                                    />
                                </p>
                            </div>
                            <div className="col-sm-6">
                                <h2 className="title title--primary">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="team_2"
                                        type="title"
                                    />
                                </h2>
                                <p className="paragraph">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="team_2"
                                        type="text"
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <ul className="list-unstyled grid row">
                        <li className="col-sm-6">
                            <div
                                className="grid__item grid__item--rectangle squared"
                                style={butterImage(
                                    this.props.cms_content,
                                    "about_us_1"
                                )}
                            />
                        </li>

                        <li className="col-xs-6 col-sm-3">
                            <div
                                className="grid__item grid__item--squared squared"
                                style={butterImage(
                                    this.props.cms_content,
                                    "about_us_2"
                                )}
                            />
                        </li>
                        <li className="col-xs-6 col-sm-3">
                            <div
                                className="grid__item grid__item--squared squared"
                                style={butterImage(
                                    this.props.cms_content,
                                    "about_us_3"
                                )}
                            />
                        </li>
                    </ul>
                    <ul className="list-unstyled grid row">
                        <li className="col-xs-6 col-sm-3">
                            <div
                                className="grid__item grid__item--squared squared"
                                style={butterImage(
                                    this.props.cms_content,
                                    "about_us_4"
                                )}
                            />
                        </li>
                        <li className="col-xs-6 col-sm-3">
                            <div
                                className="grid__item grid__item--squared squared"
                                style={butterImage(
                                    this.props.cms_content,
                                    "about_us_5"
                                )}
                            />
                        </li>
                        <li className="col-sm-6">
                            <div
                                className="grid__item grid__item--rectangle squared hidden-xs"
                                style={butterImage(
                                    this.props.cms_content,
                                    "about_us_6"
                                )}
                            />
                        </li>

                        {/* <li className="col-sm-6">
                    <div className="grid__item grid__item--squared-large squared" style={{'backgroundImage': `url('assets/images/nerdery-1.jpg')`}}></div>
                </li> */}
                    </ul>
                </div>

                <div className="box">
                    <div className="container">
                        <div className="text-center">
                            <h2 className="title title--primary">
                                <Cms
                                    content={this.props.cms_content}
                                    content_key="team_3"
                                    type="title"
                                />
                            </h2>
                            <div className="mw-570 center-block">
                                <p className="paragraph">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="team_3"
                                        type="text"
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="box box--start">
                    <div className="container">
                        <div className="row mb-xs-3">
                            {/* }{[...Array(4)].map((x, i) => */}
                            {this.state.team_members.map(x => (
                                <div key={x.name}>
                                    <div className="col-sm-3 col-xs-6">
                                        <figure
                                            className="team"
                                            style={this._getBackgroundImage(x)}
                                        >
                                            <figcaption>
                                                <span className="team__name">
                                                    {x.name}
                                                </span>
                                                <span className="team__function">
                                                    {x.role}
                                                </span>
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                            ))}
                            {/* }<div>
                        <div className="col-sm-3">
                            <figure className="team" style={{ backgroundImage: "url('assets/images/staff2.jpg')" }}>
                                <figcaption>
                                    <span className="team__name">John Doe</span>
                                    <span className="team__function">Lead programming</span>
                                </figcaption>
                            </figure>
                        </div>

                        <div className="col-sm-3">
                            <figure className="team" style={{ backgroundImage: "url('assets/images/staff3.jpg')" }}>
                                <figcaption>
                                    <span className="team__name">John Doe</span>
                                    <span className="team__function">Lead programming</span>
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                    */}
                        </div>

                        <Well
                            title="We're always on the lookout for new talent"
                            link={{
                                title: "View our job openings",
                                target: "/careers"
                            }}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default Team;
