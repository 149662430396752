// http://react-components.buildo.io/#cookiebanner

import React from "react";
import CookieBanner, { Cookies } from "react-cookie-banner";

class CookieBannerComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dismissOnScroll: true,
            cookies: new Cookies(document.cookie)
        };
    }

    /* eslint-disable*/
    UNSAFE_componentWillMount() {
        // reset cookie
        // !this.state.accepted && this.state.cookies.get('accepts-cookies') && this.state.cookies.remove('accepts-cookies')
    }
    /* eslint-enable */

    componentDidMount() {
        const header = document.getElementsByTagName("header")[0];
        if (!this.state.cookies.get("accepts-cookies")) {
            header.style.position = "relative";
            if (
                !document.getElementsByClassName("cookie-banner-wrapper")[0]
                    .children.length
            ) {
                const banner = document.getElementsByClassName(
                    "cookie-banner-wrapper"
                );
                banner.parentNode.removeChild(banner);
                document.getElementsByTagName("body").prependChild(banner);
            }
            document.getElementsByTagName("body")[0].style.paddingTop = "0px";
        } else {
            header.style.position = "fixed";
        }
    }

    render() {
        return (
            <div className="cookie-banner-wrapper">
                <CookieBanner
                    message="By continuing to browse our site you agree to our use of cookies and Terms of Use."
                    buttonMessage="Accept"
                    dismissOnScroll={this.state.dismissOnScroll}
                    onAccept={() => {
                        document.getElementsByTagName(
                            "header"
                        )[0].style.position = "fixed";
                        document.getElementsByTagName(
                            "body"
                        )[0].style.paddingTop = "65px";

                        /* eslint-disable*/
                        this.setState({
                            accepted: true
                        });
                        /* eslint-enable */
                    }}
                />
            </div>
        );
    }
}

export default CookieBannerComponent;
