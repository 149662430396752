import React from "react";
import OwlCarousel from "react-owl-carousel2";
import butter from "../utils/butterClient";
import SingleTestimonial from "./single-testimonial";

class Testimonial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            testimonials: []
        };
    }

    /* eslint-disable */
    componentDidMount() {
        butter.content.retrieve(["testimonial"]).then(response => {
            this.setState({ testimonials: response.data.data.testimonial });
        });
    }
    /* eslint-enable */

    renderTestimonials() {
        if (this.state.testimonials && this.state.testimonials.length > 0) {
            const options = {
                autoplay: true,
                autoplayTimeout: 6000,
                dots: false,
                nav: true,
                items: 1,
                loop: true
            };

            return (
                <OwlCarousel
                    options={options}
                    className="list-unstyled js-slider-single testimonial owl-carousel owl-theme"
                >
                    {this.state.testimonials.map(testimonial => {
                        return (
                            <SingleTestimonial
                                key={JSON.stringify(testimonial)}
                                name={testimonial.name}
                                company={testimonial.company}
                                text={testimonial.text}
                            />
                        );
                    })}
                </OwlCarousel>
            );
        }

        return null;
    }

    render() {
        return (
            <section className="box box--light">
                <img
                    src="assets/images/findings.png"
                    alt="findings"
                    className="img-responsive center-block mb-xs-4"
                    loading="lazy"
                />
                <div className="relative testimonial-wrapper outside-wrapper">
                    {this.renderTestimonials()}
                </div>
            </section>
        );
    }
}

export default Testimonial;
