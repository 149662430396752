import React from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";

class S02E08 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname);
    }

    // calls API and attaches script to DOM
    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);

        if (Helmet.canUseDOM) {
            Helmet.renderStatic();
        }
    }

    // checkes if component should update based on props in Helmet
    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }

    // ensures Helmet sets teh code when page loads
    componentDidUpdate() {
        if (Helmet.canUseDOM) {
            Helmet.renderStatic();
        }
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>
                        {this.props.title} | {this.props.subTitle} | ReelCast by
                        ReelMetrics the world&apos;s best slot analytics data
                        platform
                    </title>
                    <meta 
                        name="Description" 
                        content="In this episode, Nick & Don present metrics and theories related to operator reports of significantly increased jackpot activity within North American casinos. They also discuss key areas of interest for this year's G2E trade show in Las Vegas." 
                    />
                    <meta
                        name="Keywords"
                        content="Increased Jackpot Activity, G2E 2023, ICE show, Revenue Splits, Operator-Vendor Relations, Operational Costs, Small Vendors, Revenue Share Arrangements, Casino Data, Reel Metrics, Reelcast"
                    />
                    <link
                        rel="canonical"
                        href="https://www.reelmetrics.com/s02e08-increased-jackpot-activity-g2e-2023"
                    />
                    <meta
                        property="og:url"
                        content="https://www.reelmetrics.com/s02e08-increased-jackpot-activity-g2e-2023"
                    />
                    <meta
                        property="og:title"
                        content="Increased Jackpot Activity & G2E 2023"
                    />
                    <meta
                        property="og:description"
                        content="In this episode, Nick & Don present metrics and theories related to operator reports of significantly increased jackpot activity within North American casinos. They also discuss key areas of interest for this year's G2E trade show in Las Vegas."
                    />
                    <meta
                        property="og:image"
                        content="https://cdn.buttercms.com/OaWFV5QDqgOJxaKViwdg"
                    />
                </Helmet>
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}

S02E08.defaultProps = {
    id: "13654509-s02e08-increased-jackpot-activity-g2e-2023",  
    link: "s02e08-increased-jackpot-activity-g2e-2023",
    title: "Increased Jackpot Activity & G2E 2023",
    subTitle: "Season 2, Episode 8",
    description: "In this episode, Nick & Don present metrics and theories related to operator reports of significantly increased jackpot activity within North American casinos. They also discuss key areas of interest for this year's G2E trade show in Las Vegas.",
    keywords: ["Increased Jackpot Activity", "G2E 2023", "ICE show", "Revenue Splits", "Operator-Vendor Relations", "Operational Costs", "Small Vendors", "Revenue Share Arrangements, Casino Data, Reel Metrics, Reelcast"],
    topics: [
        "Reports of increased jackpot activity throughout North American casinos",
        "Key metrics describing increased jackpot activity",
        "Theories explaining increased jackpot activity",
        "Products / services of interest at G2E 2023",
        "Answering listener questions regarding operator/vendor relations",
    ],
    buzzSproutLink:
        "https://www.buzzsprout.com/2057836/13654509-s02e08-increased-jackpot-activity-g2e-2023",
    spotifyLink:
        "https://open.spotify.com/episode/2WDm5QBbXPyJEAIBmQoYmj?si=fx9Np2vNRcyIVSgmnMfBzQ",
    applePodcastsLink:
        "https://podcasts.apple.com/us/podcast/s02e08-increased-jackpot-activity-g2e-2023/id1652485625?i=1000629052325",
    iHeartRadioLink:
        "https://www.iheart.com/podcast/269-reelcast-by-reelmetrics-104029260/episode/s02e08-increased-jackpot-activity-124005880/",
    cdcLink:
        "https://cdcgaming.com/reelmetrics/s02e08-increased-jackpot-activity-and-g2e-2023",
    transcript: `Nick Hogan:
    Good morning, Don. How are things today in the land of Lincoln?
    Don Retzlaff:
    Morning, Nick, everything's great. We've finally broke the 100 degree days and we're down to beautiful fall days, temperatures in the 80s, so it's been a good week. Beat the Bucs all weekend for football, so that was good. And actually bowled well last night too, so it's been a good week.
    Nick Hogan:
    Okay, all right. What was your score? Your bowling score always freak me out.
    Don Retzlaff:
    Well, my middle game was the highlight. I had a spare in the first, then I had the next 11 strikes in a row for 290.
    Nick Hogan:
    God.
    Don Retzlaff:
    So it's the highest game I've had in a few years. It's been, gosh, it's probably been 10 years since I've shot a 300. I had a couple 298s in there. But the 290 last night was pretty nice.
    Nick Hogan:
    Those are completely insane numbers. Remind me to never go bowling with you ever.
    Don Retzlaff:
    I've been doing it, gosh, I've been bowling since 1969, so I've been bowling for a long time.
    Nick Hogan:
    Yeah, yeah, yeah, yeah.
    Don Retzlaff:
    Had to shut my phone's off during these things.
    Nick Hogan:
    No worries. No problem, no problem. Let's see. Okay, so the big news since our last episode, cyber attacks against MGM and Caesars.
    Don Retzlaff:
    Very scary.
    Nick Hogan:
    Man oh man, those were horror stories. So I saw MGM posted this morning that their systems are back online, but boy did it ever disrupt their operations. So it sounded as though virtually every system was affected, so reservations, F&B, loyalty slots, you name it. And my understanding is they've been doing hand pays since it started.
    Don Retzlaff:
    Oh, my.
    Nick Hogan:
    The free play and loyalty were both locked down. They couldn't clear credit card transactions and even the room keys were affected. So it went on for 10 days in total. And I saw this morning that the Wall Street Journal estimates that the financial impact was around $8 million a day.
    Don Retzlaff:
    Yikes.
    Nick Hogan:
    So that qualifies as an ouchie for sure. And as for Caesars, so that came out in a recent 8-K SEC filing that hackers recently infiltrated and locked down the Total Rewards database demanding a $30 million ransom.
    So it appears the hack didn't go much farther than that, and Caesars was able to negotiate it down to $15 million, which it ultimately paid. And as is so common and maddening with these scenarios, Caesars rightly noted that it can't guarantee that the data stolen won't be resold or redistributed, nor that can they really warrant that paying the ransom will truly neutralize the threat. So good on them for stating that. And it really just underscores how loaded with dilemma these situations are.
    And it appears that in each case, the hackers gained access by impersonating employees with the IT support desks, and then somehow managed to secure super admin credentials within the identity access management systems. And for anybody who uses those IAM products, you know what this means. It's the nightmare scenario. It's effectively a universal passkey.
    And I have to say, when you hear these stories recounted in person, I mean, I was recently speaking with some folks at a California property that got nailed and their version of how it unfolded just truly sent chills down my spine. It was just at one point in the night, department after department starts reporting system outages until blamo, everything is locked down. And just trying to imagine the pit in your stomach and the feelings of powerlessness. It's crazy. So Don, have you ever experienced that? Have you ever been in operation that's been hacked?
    Don Retzlaff:
    No, I have not. We had a ransomware attack at one of the properties that affected a single computer, but nothing system wide. We spent a lot of time, a lot of effort on training the teams. And IT would actually send out emails that would try to get people to click on stuff even though they've been trained not to. And they would scorecard the employees. And if you missed one, if you had a fake phishing attack and you clicked on it, the managers would get reports and you'd have to retrain people. If you did it again, you retrain people. You did it a third time, it starts costing people their jobs because you can see how serious it is. So I bet all the compliance and all of the IT staffs are gearing up more training here in the very near future.
    Nick Hogan:
    Yeah. And that's really the problem with so much of this is it really is, it does come down to those human vulnerabilities, which is really what hit here. And so you can be as locked down as you want to, as possible technically, but still those human frailties are there and it can really cause problems. Oh, well.
    Okay. So we did have a couple of listener questions that I wanted to hit. And before I do so, we'd love to tackle any questions that anyone listening may have. So if you have a question about what we're presenting or something you'd like us to present, please drop us an email at reelcast@reelmetrics.com. Again, that's R-E-E-L C-A-S-T @reelmetrics.com. Our policy is to keep all questions anonymous, so please speak directly and don't worry about us revealing your identity. That's not something we do.
    Okay, so the first question comes from a French operator, and it is, "Hi, Nick and Don, love the podcast. Any thoughts about the decision to move the ICE show from London to Barcelona?" So I somehow failed to mention that news item last month. But for anybody who was not aware, Clarion announced in early August that the I-C-E, International Casino Exhibition will move from London where it's been held for decades to Barcelona beginning in 2025.
    So thoughts on that? Well, I don't know what to say other than the obvious and that is Brexit was positively moronic logistically and financially. London has never really been, let's say, a great venue for that show, especially when it was in Earl's Court down in Kensington. And yeah, the added expense and hassle that accompanies Brexit, it's just the final straw. So in the end, I expect it'll be good for both attendees and exhibitors. Lower costs, less hassle, no Heathrow and as ICE is a winter show, significantly nicer weather.
    Don Retzlaff:
    True. Good point.
    Nick Hogan:
    Yeah, not much more to say on that, but thanks for the question.
    The next one, this is a good one. Okay. It comes from a fairly prominent vendor who asks, "Okay, so in your episode on operator vendor tensions, Nick mentioned a vendor proposal to move from 80/20 revenue splits during trials to something more equitable, such as a 50/50 split. Buddy and Don shot it down mentioning that in such revenue share deals, costs are higher for casinos with higher average daily wins. However, they didn't mention that this is relative, it's a fixed percentage applied to variable revenue values so what's the problem? If they make more money so do we. If they don't, we don't. Just generally, I didn't feel that they really defended the current practice. It seemed more like they were arguing against the loss of an entitlement."
    Don Retzlaff:
    Well ...
    Nick Hogan:
    I really like that phrasing.
    Don Retzlaff:
    I get that. The only problem I have with it is there are so many states out there that have a very high tax rate. So there are states out there with a 50% tax rate or a 30% tax rate. So if I'm giving the vendor 50% before taxes and my tax rate is pushing 50%, well, what did I make?
    Nick Hogan:
    Yeah, yeah, yeah. Okay. Fair point on that one. Yeah, for sure.
    Don Retzlaff:
    So my tax rate's 8%, okay, well maybe it's doable. But when your tax rates are north of 20, 30, 50%, that makes this a little bit tougher.
    Nick Hogan:
    Yeah, fair enough. And I know you guys had said something about perhaps checking in a incremental daily fee on it or something of those nature. And I think just the main takeaway for me on that was that there's some room to talk and to really just lay this out. And especially as we mentioned, I think it gets acutely painful for the smaller vendors, some of these things. But I do feel you're right. You guys did open up and say, "Yeah, there is a reason to maybe get them a bit more compensation on these things."
    Don Retzlaff:
    Maybe if there is something you can do after taxes, that would be more palatable. If I'm running a 40% tax rate and we take that off the top and then we look at some type of revenue share or a daily fee. I was at an Illinois Gaming Board meeting years and years ago, and the gaming board talked about why aren't the major manufacturers paying taxes on this stuff to the state if they're getting daily fees? And it opened up a whole can of worms. And thankfully they let me down from the podium as they gently eased me off the stage saying, "Don, this is a topic for a different day and this isn't something you have to answer." So, thank you.
    Nick Hogan:
    Well, it was interesting as we were just having that discussion with Buddy last week. So Buddy had talked about when this all started and he said it was really in the video poker realm and then Megabucks and things of this nature. And one of the things I'd forgotten to mention was that back in the '90s, the Nevada Resort Association, I think that was the name of it, they actually sponsored an assembly bill in the Nevada Assembly. And they had argued basically that it was IGT at the time was effectively operating, and therefore they needed to be subjected to state gaming tax. It was a hell of a fight that was going on there. But yeah, it was back then, same type of topic. Should they really be paying operational taxes because they're operating? I don't know. But ultimately it didn't go through in Nevada, but I don't know what they're thinking in Missouri. Or was it Illinois you said?
    Don Retzlaff:
    It was Illinois.
    Nick Hogan:
    Illinois.
    Don Retzlaff:
    Yeah, I can still remember being up there. I was trying to get a game approved. I think it was a table game. It might've been Pai Gow or back in the day, multi-card poker or something, multi-hand poker. I'm pretty sure it was Pai Gow. Anyway, the conversation came up about revenue sharing with lease games and how much is a fee was I paying per month for the rights to these games and how much was I paying for shuffling machines and things like that. And so all of a sudden the board and the chairman started having this conversation with me standing up there trying to get Pai Gow approved. And they're firing these questions back and forth to the lawyer and everything else. I'm thinking, "Oh my goodness, I just want to get Pai Gow approved so I can put two tables on my floor."
    Nick Hogan:
    Yeah, just plead the fifth and get out.
    Don Retzlaff:
    Thankfully. And I always appreciated the chairman actually looking at me and saying, "Don, don't worry. This is not a conversation you have to get involved in. This is what we're talking about behind closed doors. And it just came up while you're up here. So thank you for your time." "Thank you, Mr. Chairman. I'm taking my seat."
    Nick Hogan:
    Okay, well thank you to that listener for submitting that question. Appreciate it. It's very well articulated. Did like it.
    Okay, so for today's episode, we wanted to tackle two topics. So the first is an issue regarding which we've been receiving a lot of calls from folks throughout the industry, and that is a significant uptick in the number of Jackpot awards. So I'm happy to report that Don has broken out the nerd tools and has some data to share on this as well as a few thoughts as to what's happening there. And then secondly, with G2E approaching here in October, we just wanted to talk briefly about some debuts and other areas of interest and that all this we'll be pursuing here in just a few weeks.
    So Don, increased Jackpot activity. Maybe you can give us a bit of background on this and then talk about what you found?
    Don Retzlaff:
    Sure. Well, the easy place to start would be the IRS limit. It's been $1,200 for 45 years now. And if you look at inflation, and if my math is right, that's like $6,000 in current value. So that's where you start. And we know that the average bets have gotten higher and higher over the years. I remember in the late '90s when we first started putting video pennies on the floor, the max bets were a dollar. And now you're seeing video penny games the last few years at $8, $10, $20. And I remember the average bets on video penny as being $.50, $.60 the first few years until the manufacturer started putting $3, $4 average bets or max bets out there. So you just weren't seeing many jackpots off of the penny machines.
    And then multiple manufacturers started increasing the max bets on different games, Action Gaming on their video poker on their nickels. So you went from nickel video poker averaging $.20 a spin or $.20 a pull to pushing $3 a spin now. Dollar reels, the max coin back then was $3. And now the average dollar reel spin is $4, and EFRI's are $8 a spin. So obviously that increases the jackpot amount, but the big thing has been the video pennies.
    We're looking now at average bets on video pennies at $1.50 a spin. And recently the big thing has been the additional play on denominations above 1 cent. At most floors, your 2 cents to dollar video play is now 15% of your coin in. So you're seeing this massive increase in average bets. Nickel videos are averaging $4 a spin a couple years ago. Now they're up over $5 a spin. Dimes are $9 a spin.
    Nick Hogan:
    Man.
    Don Retzlaff:
    So when you're betting $9 a spin and you get a decent bonus round, that $300 hit in the bonus round when you're playing $1.50 is now pushing $2,000. And those trigger those jackpots. I remember when I was running the floors, most of your jackpots were on dollar royal flushes for $4,000 or your dollar reels. Now nearly 70% of all the jackpots are on penny machines.
    Nick Hogan:
    Man.
    Don Retzlaff:
    Yeah, I was actually kind of surprised at that. And the biggest thing for me was the average jackpot amount isn't much different on penny machines than dollar machines anymore.
    Nick Hogan:
    Okay.
    Don Retzlaff:
    Yeah, the average penny jackpot is pushing $2,500 and the average dollar jackpot's $2,700.
    Nick Hogan:
    Okay. Were the operators mainly, is it they just noticed that they're filling out so much more IRS paperwork and they're just seeing a crazily higher frequency in these things being awarded?
    Don Retzlaff:
    It is. And especially now since most of your floors are 60, 70, 75% pennies, and these average bets keep climbing, they're having more. I looked at a couple casinos this last week and just trying to figure out where the jackpots are coming from on the pennies, and obviously it's the games that your host level players like to play. I was just looking on Cupid before the call today, Nick, looking at average bets on games that casinos own, their core games for their host level players. The last three or four months has been about an average, about $3.30 a spin. On their lease games, the average bet is as it populates $4.50 cents a spin.
    Nick Hogan:
    Man.
    Don Retzlaff:
    So your host level players are coming in $4.50 a spin on leased video penny games. Like I said, those $1, $1.50 bets, now you can multiply that by three or four. And you get a lot of $200 and $300 outcomes in these bonus rounds. Now you multiply that by four, and you're right at that threshold. And the other big thing is you've got these multi bonus round math models now. A lot of the perceived jackpots, you can have three bonus rounds hit at the same time. And when those hit and you're playing $4, $5, $6, you've got a legitimate chance of reducing the W2G jackpot.
    Nick Hogan:
    Okay. And do you feel Don, with those increases in average bet, do you feel that's just organic and more of a macroeconomic driver there that's pushing that? Or do you see some other kind of activity that may be driving those average bets? And obviously the game, the way that they're set up?
    Don Retzlaff:
    I'll tie it back to Lightning Link. At the time when Lightning Link first came out, most of my host level players were either high-end video poker or dollar reel and up players. When Lightning Link came out, a lot of my high-end customers started dabbling with video. And as they got more entertaining and the bonus rounds and the math models got better and better, a lot of the host level players shifted from dollar reels to video pennies. And that's what's happening. I'm seeing it over and over where the percentage of play on host level players keeps decreasing on reels and keeps increasing on the video. And they're keeping their average bets. If they're comfortable playing $3, $4 or $5 a spin on the reels, they're comfortable playing $3, $4 or $5 a spin on the video. And when you do that, it's just basically increasing the jackpot amount.
    Nick Hogan:
    And have you seen that-
    Don Retzlaff:
    Especially with these more volatile math models.
    Nick Hogan:
    Sure. And Don, so do you think that there's any type of team play or pool effect in any of this stuff at all?
    Don Retzlaff:
    Absolutely. We've definitely talked about that, that the YouTube sensations have really helped. You'll see people pooling their play to increase their average bet, shorter session times. And we're also seeing a lot of jackpot chasing. People are chasing with their average bets, especially on games like Ultimate Fire Link, Dragon Link, Buffalo Link, things like that, where they'll play $10 an average spin for 20 or 30 minutes, and if they lose, they're done. If they obviously hit a jackpot, everyone's happy.
    But you see that on YouTube too. People are pooling money and they're playing $25 average bet on Dragon Cash or Huff & More Puff, things like that. And you get some legitimate hits. Last G2E, I played Huff & More Puff for the first time, and I was playing $8, $9 a spin. I got a bonus round. That was $1,000. So it happens.
    Nick Hogan:
    Yep, yep, yep.
    Don Retzlaff:
    So if you're experiencing more jackpots at your casino floor, join the club.
    Nick Hogan:
    It sounds like you're in good company.
    Don Retzlaff:
    It's happening everywhere. I think it's going to continue to climb, especially as I look at what math models have come out in the last year or so. The volatility is still really strong. Multiple bonus rounds occurring at the same time. And that's just all it's going to do is increase the jackpot level.
    Nick Hogan:
    Sure, sure, sure. Okay. Well, any other points on the increased jackpot activity, Don down there?
    Don Retzlaff:
    No, that's about it. I actually saw a little bit of a decline in dollar reel average bet, which was kind of interesting. I think that just tells me that some of those host level players are really shifting their play over to video. And I think that's one of the reasons why I'm seeing these strong average bets. Back during the first quarter, pull that back up again, back during the first quarter, we were seeing average bets from host level players on video pennies north of $5 a spin, $5.18, $5.04, $4.91. That's pretty strong average bet.
    Nick Hogan:
    Yeah. Okay. All right, very good. Well, so I guess we can turn the focus a little bit here to G2E that's coming up here shortly. And as you know Don, we have a very packed schedule for this year's show. I do hope to get some time on the floor, and I think the main things I'll be running down are really the payment technologies.
    So there's a lot of interesting things happening in this space, including things like ticket scanning and Apple Pay integration, all this kind of stuff. And so I'm going to be taking a look at that and just see what's happening. And I'll also be roaming around taking a peek at a few AI applications.
    And really on that AI front, I'd just like to throw out really a cautionary note to everybody listening. So AI and machine learning are really getting a lot of hype at present. And to a great extent, I see both vendors and operators kind of positioning it as some sort of nebulous panacea that's going to solve all of our problems. So this is just not the case.
    So as the head of a company that's been authoring AI and ML apps for close to a decade now, I'll give you one big piece of advice here and that is temper your expectations. So the number one rule in AI is to start small. And you're going to be far less disappointed if you align your expectations with limited, gradual and iterative implementation here. So AI and ML apps can be incredibly helpful, but in terms of hype, I'm hearing echoes of server-based and skill-based gaming here. I am getting a little bit nervous about this, so I can't say I'm overly stoked about that in any event. I think I'm going into this with fairly modest expectations and I certainly encourage those listening to do the same on the AI front.
    So Don, that's really what I'm most interested in this show. How about yourself? What are you looking for this year?
    Don Retzlaff:
    For me, I spent, even when I was a head of tables and slots, most of the time I spent was actually meeting with the slot manufacturers. And I would do the table games producers as well, but it's a great time to see and meet with the slot manufacturers to look at their cabinets, try to get a feel for what's coming out in 2024. The nice change is the nice changes to G2E over the last, say five years or so is there's not so much forward-looking slot machine stuff that would be out three years from now or concept machines. It was more stuff that's available in the next nine months.
    Nick Hogan:
    Right.
    Don Retzlaff:
    Which was always welcome. I remember having the green light, yellow light, red light stuff out there to where green was available now, yellow in the near future, and red was concept. And it was great, but there's so many games that either fail in testing or the math models just don't work. I was more interested in what I was going to buy in the next year or so. Love looking at the new technology and seeing that stuff. There was some really neat stuff I've seen over the years, but a good chunk of that never came to the floors.
    Nick Hogan:
    Sure, sure, sure.
    Don Retzlaff:
    So I think this'll be, my gosh, 25th year at G2E. So for those going for the first time, wear comfortable shoes. You are on your feet a lot. There aren't many chairs. Make appointments with the vendors if you can.
    Nick Hogan:
    For sure.
    Don Retzlaff:
    For bigger ones, you probably need an hour, the smaller ones a half an hour. And it's a great time to meet with the smaller vendors. There's some really neat stuff out there from the Bluberis and ITs, and Gaming Arts and AGSs of the world. So take a chance to meet with them and it's a great time. Then maybe set up some trials. A lot of them have some really good deals this time of year.
    Nick Hogan:
    For sure.
    Don Retzlaff:
    So definitely do stuff like that.
    Nick Hogan:
    And I think I saw that IT is launching a new platform. I believe they're planning that. And then I did see something from Andrew Burke, Bluberi yesterday that they've got a bunch of new content that they're going to be showing, so that'll be interesting. They've had a great year, and so it'd be nice to see what the next lineup looks like.
    Don Retzlaff:
    Yeah, it's always. The one thing that's pretty common now is all the cabinets are fantastic.
    Nick Hogan:
    Yeah, they all look great.
    Don Retzlaff:
    They're all 49 inch, 4K, and so you're not going to get too many bad screens. It's a lot different than 20 years ago when you can see the dots on the screens and all that type of stuff. So the hardware is good. So try to concentrate on the math models. Try to look for the ones that are a little bit different. You're going to see a lot of attack of the clones. You're going to see a lot of people, I'll bet you you'll see a half a dozen different Devil's Lock type clones from the different manufacturers.
    Nick Hogan:
    For sure.
    Don Retzlaff:
    And that's what happens. It's when the Hold and Spin stuff came out, that year I called it attack of the colognes because everybody had their hold and spin. Look at that. Spend some time in each one. There's a couple of things that I'll be looking for. One of my favorite machines that has never really taken off was Dealer No Deal. Gaming Arts looks like they have that now. So we'll see what Gaming Arts can do with that.
    Nick Hogan:
    The first run at that, was that IGT that ran at that the first?
    Don Retzlaff:
    It was. I think it was. I remember playing it.
    Nick Hogan:
    It's a good community game, as I recall, right?
    Don Retzlaff:
    Right. And I always thought with that, such a great following and it really hits that age demographic that you want to hit. If they do a good job with it, that could be a really nice hit for them.
    Nick Hogan:
    Sure.
    Don Retzlaff:
    I know Everi was coming out with a new stepper line, I think it was a player classic reserve that they're coming out. So if you're looking for new stepper product, that is always a great place. You got to spend a little more time at Everi than you do with some of the other smaller vendors because they do have a nice line of video and a nice line of stepper, then their tournament stuff. So that's one you got to spend a little bit of time at.
    A lot of good stuff from IGT. A lot of their peak curves and peak duals. Of course, ATI is I'm sure we're going to see a lot of NFL stuff from them with their line. Then Light & Wonder, they had a really nice year last year. Their Kascada Dual screen stuff is doing really well. So looking for new product there.
    One of my favorites every year has always been Konami because they always showcase something from the future. And I think this year they're coming out with that 43 by three stack screen. It looks really good on paper, so I'll be interested to see that in person to see what that looks like and how tall that end's going to be. So there's all sorts of stuff to see.
    Nick Hogan:
    Do you know, are any of the big four, are they launching any new platforms that you've heard of?
    Don Retzlaff:
    Yeah, I know ATI is. I think they've got a marquee cabinet and a King Max cabinet. I think a lot of that's holding the NFL games. The interesting thing about G2E is everything's a secret now until the first week of October. And then everyone has their cameras and their phones and everything else. But sometimes it's really tough to get information unless you're actually a vendor or actually a casino going to pre G2E. And a lot of times then they're still keeping that stuff under wraps. So I'm sure there'll be other stuff introduced, but a lot of them, it's still hush hush until you go.
    Nick Hogan:
    Yeah. Yeah, yeah, yeah. Now, Don, you mentioned the NFL thing from Aristocrat. So I actually have not looked at that at all. So can you explain that a little bit? I just am not familiar with the product, what they're doing there.
    Don Retzlaff:
    Yeah. They have a license to do business with the National Football League. And they have produced a series of themes that are basically team selectable. You can walk up to the game and pick your favorite team and the logos change. So that concept's been around for a while, but it's really neat when you want to play the Raiders in Las Vegas or you want to play the Bears in Chicago. It's been introduced at a few casinos so far, mostly on the West Coast. And I think the M Resort just got theirs, and they're the official home of the Las Vegas Raiders. And I heard they're doing really well. I'll be very interested to play them. And that's the other neat thing about G2E, especially since it's at the Sands now, is a lot of these games that you'll see at G2E, you can play on the floor at the Venetian.
    Nick Hogan:
    Yeah. They have them on the floor. Yep. Definitely.
    Don Retzlaff:
    The Venetian does a great job of putting these games out on the floor.
    Nick Hogan:
    They really do.
    Don Retzlaff:
    It's amazing. It is one of the neatest floors. It's a convention hotel, but they do such a great job of refreshing their floor with these lease games and these trials. And so after Tuesday or after Wednesday, walk the Venetian floor, you can actually stick a $20 or stick a $100 bill in these machines and give them a whirl so you actually see them outside of their show settings.
    I did that last year. Gaming Arts came out with a game that I really wanted to play, and it was actually on the casino floor, and I sat down and played it. Same thing with one of the new cabinets for Aristocrat.
    Nick Hogan:
    Well, I remember you bitching about you couldn't get on a Dragon Link machine because they were always young men on them.
    Don Retzlaff:
    Oh yeah. Nuts. So yeah, we've had that conversation before. So yeah, G2E is always a neat show. Try to have a little bit of a plan. Once I started making appointments, it really helped me out a lot. I had standing appointments with several vendors. And it's a great time to meet, a great time to talk. Try to be as punctual as you can. Because if you've got a meeting from 10:00 AM to 11:00 AM, somebody else has a meeting from 11:00 AM to 12:00 PM. And at 11:00 AM, they're time to move on to a different vendor.
    Nick Hogan:
    Yeah. You get these knock-ons and they get really problematic for everybody, so indeed, watch the clock.
    Don Retzlaff:
    Everyone wants to jump in and say hello and shake hands and all that stuff is fine, but try to be as considerate as you can. And the other neat thing too is especially with your phones, take pictures. I take notes on my phone on the note apps and just looking at key things from each vendor. I wrote down some themes that I wanted to try, themes that I really wanted to look at and did that for each vendor that I went to. It doesn't have to be a ton of notes. You can carry around your yellow notebook too if you want. I've got one sitting right in front of me right now. But the phone ones were always nice because I could always refer back to those when I was either meeting with the vendors or in their showrooms or something. So take a few notes here and there. Snap a couple pictures, and there are plenty of cameras and phones in action out there.
    Nick Hogan:
    How about on the property front? Any places you want to walk and check out that you haven't seen in a while, renovated. It's been a new build there in a while? I'm not thinking of one.
    Don Retzlaff:
    No. Well, there's always a couple floors. I always enjoy walking the Wynn property. I really enjoy walking because he's got such an interesting layout where he is got no wall space and they do such a great job there of laying out their games in small banks, especially post COVID. So I always walked away from walking his floor with ideas. I think last time, I can picture that floor in my mind right now. And I think the only banks he had larger than six were two Lightning Link or Dragon Link banks. Everything else with small pods and carousels. And so that's always a good one.
    Nick Hogan:
    I love the way they do high limit there too is just great.
    Don Retzlaff:
    It's beautiful.
    Nick Hogan:
    It's really just so well executed.
    Don Retzlaff:
    Yeah. So yeah, steal those ideas for your casino floor. Walk and think about how you can do that on your casino floor. It's a great time to do it. The Wynn's always one I like to walk. The Venetian, obviously with their floor. It's a little more interesting because they've got so much table games. They've got such a big sports book and they have such a large area for ETGs. Resorts was fun to walk last year. That was a neat one. I enjoyed walking up and down the strip. Walk from Bellagio to Cosmo to Aria.
    Nick Hogan:
    Now have you been into the rebranded Bally's, the Horseshoe there? Have you gone on that floor yet?
    Don Retzlaff:
    I have.
    Nick Hogan:
    Okay.
    Don Retzlaff:
    And I used to always stay at Paris when I was out there and they were linked via a tunnel. And so it's always been a really nice property and it's wide open. It's always been a really nice property to stay at. And good restaurants, good floor. They do a good job there. It's really a nice floor. That floor and the floor at Paris always liked. So there's a lot of good floors out there to walk. And each one's a little bit different. Walk from the Cromwell to Bally's to Paris to Planet Hollywood. And all those floors are unique.
    Nick Hogan:
    Yeah, that's the interesting thing. And have you seen the Sphere yet?
    Don Retzlaff:
    Only during construction. I haven't been out there since it's opened, so that'll be my first time to see it.
    Nick Hogan:
    Oh, man. It is cool. So I had the wife and kids there. And we went up on the High Roller, the Ferris wheel that Caesars has there mainly just to get a look at that thing. And it was going at night. And I mean, the resolution of this thing, it is just amazing. It's so cool looking. My favorite display that they had was they were promoting some kind of summer basketball league, and it was just a giant basketball that looked so real. Yeah, it is really, really cool.
    Don Retzlaff:
    One of our clients, the analyst is based in Las Vegas, and I was talking to him recently. He said they have problems with people stopping their cars in the middle of the road and jumping out to take pictures.
    Nick Hogan:
    I understand it completely. I mean, you just can't look at it and not. I saw Tony Lewin from Side Games, he was posting shots of it while he was golfing in the day. So I mean, it is really, really, really impressive. It's a cool thing. And I think, are they actually kicking it off during G2E? Is that?
    Don Retzlaff:
    Think U2's playing there.
    Nick Hogan:
    That's what I heard. Okay. Boy.
    Don Retzlaff:
    I actually looked at tickets. I got tickets to a couple shows out there, G2E. And I looked at U2 and forget it. They're sold out every night.
    Nick Hogan:
    Yeah, yeah, yeah. It's going to be a big one. Okay.
    Don Retzlaff:
    That's the other nice thing about Vegas is you can really network. And it makes a difference sometimes, especially if you're working with those vendors or connecting with the past employees and directors and VPs and all that. And Tuesday's show is a lot different than Thursday's show.
    Nick Hogan:
    Oh yes.
    Don Retzlaff:
    Thursday's a great day to walk around and talk to the vendors and spend some time. Tuesday you see a lot of the CFOs and chief marketing officers and CEOs and all that around walking the floor trying to take in what's new. But it really thins out each day after that.
    Nick Hogan:
    Yep, yep, yep. Yeah, Thursday I've always found it's one of the best days. That's one of the days I always really liked, just really swinging around the booths and hitting them because people have a bit more time and space.
    Okay, cool. Well, let's see on the last-
    Don Retzlaff:
    We'll be out there.
    Nick Hogan:
    Yep, we will most certainly be out there. So let's see. In the last podcast I mentioned that I'll be speaking at this year's G2E, but I didn't have the date and location yet, so that's now solved. So I'll be speaking on Player Behavior and Demand Driven Inventory Management. And that will be from 11:40 AM to 12:30 PM on Wednesday the 11th. And the room is going to be Titian 2303. So that's T-I-T-I-A-N as in the Italian Renaissance artist, 2303. So please, if you have a chance, swing by, absolutely love to see you. So Don, anything else for today or have we pretty much drained it for today?
    Don Retzlaff:
    I think that's about it. We're not too far away from G2E.
    Nick Hogan:
    No, no. It's just a couple weeks away.
    Don Retzlaff:
    You'll have to catch your plane soon.
    Nick Hogan:
    Yep, yep, yep. Looking forward to it. And I'm sure you're planning on doing some heavy gambling out there while you're in town.
    Don Retzlaff:
    I'm actually going to be out there for 10 days, which is-
    Nick Hogan:
    Uh oh.
    Don Retzlaff:
    ... kind of scary. So I better have a good start to the week.
    Nick Hogan:
    Yeah, yeah, yeah. Okay, cool. Well, I think that's about it then. So thanks so much for your time today, Don.
    Don Retzlaff:
    Take care, Nick. We'll talk to you soon.
    Nick Hogan:
    All right. See you. Bye-bye.
    Don Retzlaff:
    Bye-bye.    
    `
};

export default S02E08;
