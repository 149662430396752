export const slideImages = {
    slide_1: {
        key: "SMS",
        items: [
            {
                src: "/assets/images/product-compatibility/slide_1/logo_aristocrat.svg",
                alt: "logo_aristocrat",
                height: 52
            },
            {
                src: "/assets/images/product-compatibility/slide_1/logo_drgt.svg",
                alt: "logo_drgt",
                height: 40
            },
            {
                src: "/assets/images/product-compatibility/slide_1/logo_igt.svg",
                alt: "logo_igt",
                height: 45
            },
            {
                src: "/assets/images/product-compatibility/slide_1/logo_konami.svg",
                alt: "logo_konami",
                height: 30
            },
            {
                src: "/assets/images/product-compatibility/slide_1/logo_playtech.svg",
                alt: "logo_playtech",
                height: 40
            },
            {
                src: "/assets/images/product-compatibility/slide_1/logo_sci_games.svg",
                alt: "logo_sci_games",
                height: 45
            }
        ]
    },
    slide_2: {
        key: "EDW",
        items: [
            {
                src: "/assets/images/product-compatibility/slide_2/logo_aws.svg",
                alt: "logo_aws",
                height: 51
            },
            {
                src: "/assets/images/product-compatibility/slide_2/logo_microsoft.svg",
                alt: "logo_microsoft",
                height: 49
            },
            {
                src: "/assets/images/product-compatibility/slide_2/logo_cloudera.svg",
                alt: "logo_cloudera",
                height: 32
            },
            {
                src: "/assets/images/product-compatibility/slide_2/logo_oracle.svg",
                alt: "logo_oracle",
                height: 31
            },
            {
                src: "/assets/images/product-compatibility/slide_2/logo_IBM.svg",
                alt: "logo_IBM",
                height: 34
            },
            {
                src: "/assets/images/product-compatibility/slide_2/logo_teradata.svg",
                alt: "logo_teradata",
                height: 42
            },
            {
                src: "/assets/images/product-compatibility/slide_2/logo_vertica.svg",
                alt: "logo_vertica",
                height: 40
            },
            {
                src: "/assets/images/product-compatibility/slide_2/logo_mark_logic.svg",
                alt: "logo_mark_logic",
                height: 50
            }
        ]
    },
    slide_3: {
        key: "BI Suite",
        items: [
            {
                src: "/assets/images/product-compatibility/slide_3/logo_tableau.svg",
                alt: "logo_tableau",
                height: 45
            },
            {
                src: "/assets/images/product-compatibility/slide_3/logo_cognos.svg",
                alt: "logo_cognos",
                height: 37
            },
            {
                src: "/assets/images/product-compatibility/slide_3/logo_bally_bi.svg",
                alt: "logo_bally_bi",
                height: 60
            },
            {
                src: "/assets/images/product-compatibility/slide_3/logo_power_bi.svg",
                alt: "logo_power_bi",
                height: 37
            },
            {
                src: "/assets/images/product-compatibility/slide_3/logo_viz_explorer.svg",
                alt: "logo_viz_explorer",
                height: 57
            }
        ]
    }
};
