import React, { Suspense, lazy } from "react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { Button, Row, Col } from 'react-bootstrap';

import Hero from "../components/hero";
import Statistics from "../components/statistics";
import Questions from "../components/questions";
import Trust from "../components/trust";
import Testimonial from "../components/testimonial";
import Cms from "../components/cms";
import CaseStudy from "../components/case-study";
import RedBlock from "./redBlock";


const Vimeo = lazy(() => import("react-vimeo"));

class Index extends React.Component {
    constructor(props) {
        super(props);

        ReactGA.pageview(window.location.pathname);
    }

    render() {
        return (
            <>
            <Helmet>
                        <meta
                        name="Description"
                        content="ReelMetrics combines industry's deepest slot data pools, hardest science& hottest AI to deliver BIG efficiencies in purchasing, configuration, conversion & marketing."
                    />
                    <link
                        rel="canonical"
                        href="https://www.reelmetrics.com"
                    />

                <meta property="og:title" content="ReelMetrics - Powering the Casino Industries Leading Slot Data Analysis Tools" />
                <meta property="og:description" content="ReelMetrics combines industry's deepest slot data pools, hardest science& hottest AI to deliver BIG efficiencies in purchasing, configuration, conversion & marketing. "/>
                <meta property="og:image" content="https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ" />  
            </Helmet>
                <Hero cmsContent={this.props.cms_content} />

                <Statistics cms_content={this.props.cms_content} />

                {/* DEFAULT BLOCK OF TEXT  */}
                <section className="box box--end">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-md-8 col-md-offset-2">
                                <div className="text-center">
                                    <h2 className="title title--primary">
                                        <Cms
                                            content={this.props.cms_content}
                                            content_key="hard_questions_1"
                                            type="title"
                                        />
                                    </h2>
                                    <p className="paragraph">
                                        <Cms
                                            content={this.props.cms_content}
                                            content_key="hard_questions_1"
                                            type="text"
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="box box--end">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-md-8 col-md-offset-2">
                                <div className="text-center">
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <Vimeo
                                            videoId="293602484"
                                            className="vimeo video-background-transparent"
                                        />
                                    </Suspense>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* END DEFAULT BLOCK OF TEXT  */}

                <Questions cms_content={this.props.cms_content} />
                <RedBlock link="/slot-machine-data-analytics-platform">
                    <img
                        src="/assets/images/rm_logo_basic_white.svg"
                        alt="rm_logo_basic"
                        loading="lazy"
                        width="350"
                        className="col-md-3"
                    />
                    <div className="text-center col-md-6">
                        <h2
                            className="title hidden-sm hidden-xs"
                            style={{ marginTop: "0" }}
                        >
                            <Cms
                                content={this.props.cms_content}
                                content_key="red_block_plans_title"
                                type="text"
                            />
                        </h2>
                        <p
                            className="paragraph"
                            style={{ marginBottom: "0", padding: "20px" }}
                        >
                            <Cms
                                content={this.props.cms_content}
                                content_key="red_block_plans"
                                type="text"
                            />
                        </p>
                    </div>
                    <img
                        src="/assets/images/rm_logo_pro_white.svg"
                        alt="rm_logo_pro"
                        loading="lazy"
                        width="350"
                        className="col-md-3"
                    />
                </RedBlock>
                <Trust cms_content={this.props.cms_content} />
                <CaseStudy />
                <Testimonial />
                <section className="box box--end">
                    <div className="container">
                        <div className="container">
                            <Row className="justify-content-md-start justify-content-center text-center">
                                <Col className="col-sm-1" />
                                <Col className="col-sm-10">
                                            <a href="/reelcast"> <h2 className="title title--primary">
                                                Check out the ReelMetrics Podcast!
                                            </h2>
                                            </a>
                                            <p className="paragraph">
                                                The Casino Podcast for Slot Professionals and Enthusiasts Where data-driven insights, tips, and tricks are shared to help professional slot teams improve their game. Produced by ReelMetrics, the world&apos;s largest independent aggregator of slot machine data, ReelCast brings you the latest industry knowledge and expertise.
                                            </p>
                                    <Row className="justify-content-center">
                                        <Col className="col-sm-12 text-center">
                                            <Button variant="default" style={{ border: "none" }} onClick={() => window.open(`https://cdcgaming.com/reelcast/`, '_blank')}>
                                                <img src="/assets/images/cdcLogo.svg" alt="CDC Gaming" width="40" height="40" style={{ verticalAlign: "middle" }} />
                                            </Button>
                                            <Button variant="default" style={{ marginLeft: 8, border: "none" }} onClick={() => window.open(`https://open.spotify.com/show/314iBnXRGGAuttAsIcZeC5`, '_blank')}>
                                                <img src="https://upload.wikimedia.org/wikipedia/commons/1/19/Spotify_logo_without_text.svg" alt="Spotify Logo" width="40" height="40" style={{ verticalAlign: "middle" }} />
                                            </Button>
                                            <Button variant="default" style={{ marginLeft: 8, border: "none" }} onClick={() => window.open(`https://podcasts.apple.com/us/podcast/reelcast-by-reelmetrics/id1652485625`, '_blank')}>
                                                <img src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg" alt="Apple Music" width="40" height="40" style={{ verticalAlign: "middle" }} />
                                            </Button>
                                            <Button variant="default" style={{ marginLeft: 8, border: "none" }} onClick={() => window.open(`https://iheart.com/podcast/104029260/`, '_blank')}>
                                                <img src="https://images.squarespace-cdn.com/content/v1/54becebee4b05d09416fe7e4/1475275223141-LJT001HD9RFZQUCM9H0F/favicon.ico" alt="iHeartRadio" width="40" height="40" style={{ verticalAlign: "middle" }} />
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center ">
                                        <Col className="col-sm-12 text-center">
                                            <Button variant="default" style={{padding: 8, marginTop: 10, marginBottom: 10}}onClick={() => window.open('/reelcast', '_blank')}>
                                                <img src="https://cdn.pixabay.com/photo/2012/04/24/16/24/microphone-40327_960_720.png" alt="Reelcast_logo" width="13" height="20" style={{ verticalAlign: "middle" }} />
                                                {" "} Listen Now
                                            </Button>
                                            <Button variant="default" style={{padding: 8, marginTop: 10, marginBottom: 10}}onClick={() => window.open('https://www.buzzsprout.com/2057836', '_blank')}>
                                                <img src="https://cdn.pixabay.com/photo/2013/07/12/17/11/add-151736_1280.png" alt="Buzzsprout" width="20" height="20" style={{ verticalAlign: "middle" }} />
                                                {" "} All Platforms
                                            </Button>


                                        </Col>
                                    </Row>
                                </Col>

                                <Col className="col-sm-1" />
                            </Row>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Index;
