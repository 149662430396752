import React, { useState } from "react";
import PropTypes from "prop-types";
import OwlCarousel from "react-owl-carousel2";

import useWindowSize from "../../../utils/hooks/windowSize";

import Modal from "../../shared/Modal";
import Details from "./Details";

const itemCount = width => {
    if (width < 768) {
        return 1;
    }

    if (width < 1200) {
        return 2;
    }

    return 3;
};

const carouselPadding = width => {
    if (width < 768) {
        return 0;
    }

    if (width < 1200) {
        return 100;
    }

    return 110;
};

function Carousel({ items }) {
    const [clickedItem, setClickedItem] = useState();
    const [showModal, setShowModal] = useState(false);

    const { width } = useWindowSize();

    const handleImageClick = (e, item) => {
        e.preventDefault();

        setClickedItem(item);
        setShowModal(true);
    };

    const handleModalHide = () => setShowModal(false);

    const carouselOptions = {
        dots: false,
        nav: true,
        items: itemCount(width),
        slideBy: itemCount(width),
        stagePadding: carouselPadding(width)
    };

    let carouselItems = Array(3)
        .fill()
        .map((_, index) => (
            <div
                key={index} // eslint-disable-line react/no-array-index-key
                className="reelhot-index"
            />
        ));

    const hasItems = items && items.length > 0;
    const noItems = items && items.length === 0;

    if (hasItems) {
        carouselItems = items.map(item => {
            const { image, title } = item;

            return (
                <figure key={title}>
                    <img
                        src={image}
                        alt={title}
                        role="presentation"
                        onClick={e => handleImageClick(e, item)}
                    />
                </figure>
            );
        });
    }

    return (
        <>
            <div className="outside-wrapper container-fluid flex-row spotlight-hidden-gems-carousel">
                <div className="col-xs-12 col-sm-11">
                    {(!items || hasItems) && (
                        <OwlCarousel
                            options={carouselOptions}
                            className="list-unstyled js-slider-single testimonial owl-carousel owl-theme"
                        >
                            {carouselItems}
                        </OwlCarousel>
                    )}
                    {noItems && (
                        <div className="no-data">
                            <div className="alert alert-warning">No data</div>
                        </div>
                    )}
                </div>
            </div>

            <Modal show={showModal} onHide={handleModalHide}>
                <Details imageDetails={clickedItem} />
            </Modal>
        </>
    );
}

Carousel.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            title_slug: PropTypes.string.isRequired,
            uuid: PropTypes.string.isRequired,
            image: PropTypes.string.isRequired
        })
    )
};

Carousel.defaultProps = {
    items: null
};

export default Carousel;
