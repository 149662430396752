import React from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import butter from "../utils/butterClient";

class Job extends React.Component {
    constructor(props) {
        super(props);
        this.state = { job: [] };
        ReactGA.pageview(window.location.pathname);
    }

    componentDidMount() {
        butter.content.retrieve(["jobs"]).then(response => {
            this.setState({
                job: response.data.data.jobs.filter(
                    job => job.title === this.props.match.params.title
                )[0]
            });
        });
    }

    render() {
        return (

            <>
                <div className="text-page">
                    <div className="box box--light">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-offset-1 col-sm-10">
                                    <h1 className="title title--lg title--primary">
                                        {this.state.job.title}
                                    </h1>
                                    <p className="paragraph">
                                        {this.state.job.summary}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="box">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-offset-1 col-sm-10">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: this.state.job
                                                .description
                                        }}
                                    />

                                    <div className="row--flex-column-small-screen mt-xs-3">
                                        <a
                                            href="mailto:info@reelmetrics.com"
                                            className="btn btn-lg btn-primary btn-job"
                                        >
                                            Send us an email
                                        </a>
                                        <Link
                                            className="btn btn-lg btn-default btn-job"
                                            to="/careers"
                                        >
                                            Back to career overview
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Job;
