import React from "react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";

class S03E07 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname); 
    }
    
    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);

        if (Helmet.canUseDOM) {
            Helmet.renderStatic();
        }
    }

    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }

    componentDidUpdate() {
        if (Helmet.canUseDOM) {
            Helmet.renderStatic();
        }
    }
    
    render() {
        return (
            <>
                <Helmet>
                    <title>{`${this.props.title} | ${this.props.subTitle} | ReelCast by ReelMetrics`}</title>

                    <meta
                        name="Description"
                        content={this.props.description}
                    />
                    <link
                        rel="canonical"
                        href="https://www.reelmetrics.com/s03e07-incredible-technologies-with-dan-schrementi"
                    />

                    {/* Open Graph / Facebook / LinkedIn */}
                    <meta property="og:type" content="website" />
                    <meta
                        property="og:url"
                        content="https://www.reelmetrics.com/s03e07-incredible-technologies-with-dan-schrementi"
                    />
                    <meta property="og:title" content="ReelCast - S03E07" />
                    <meta
                        property="og:description"
                        content={this.props.description}
                    />
                    <meta
                        property="og:image"
                        content="https://cdn.buttercms.com/uvl2RfnQQFOybc2bNujq"
                    />
                    <meta property="og:locale" content="en_US" />
                
                </Helmet>
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}

    S03E07.defaultProps = {
        id: "15397255-s03e07-incredible-technologies-with-dan-schrementi",
        link: "https://www.reelmetrics.com/15397255-s03e07-incredible-technologies-with-dan-schrementi",
        title: "Incredible Technologies with Dan Schrementi",
        subTitle: "Season 3, Episode 7",
        description: "In this episode, Nick & Don speak with Dan Schrementi, President of Gaming at Incredible Technologies (IT). Learn about IT's history as a pioneering arcade game supplier, its move into gaming, its design & commercial philosophies, its recent strategic rebuild, its product roadmap, and its 'Teed Up' initiative. Also in this episode, how free play reductions impact visitation & spend.",
        topics: [
            "IT's company history",
            "How IT’s amusement DNA affects slot design",
            "IT’s multi-year strategic rebuild",
            "The Teed Up initiative",
            "The Prism hardware family & game library",
            "Moving into the ETG space",
            "The impact of free play mods on visitation & spend"
        ],
        keywords: [
            "Incredible Technologies",
            "Dan Schrementi",
            "Golden Tee",
            "slot machines",
            "casino gaming",
            "gaming market share",
            "G2E",
            "free play",
            "casino loyalty",
            "gaming cabinets",
            "penny slots",
            "gaming trends",
            "class II gaming",
            "electronic table games",
            "gaming innovations"
        ],    
    buzzSproutLink:
        "https://www.buzzsprout.com/2057836/15300571-s03e07-incredible-technologies-with-dan-schrementi",
    spotifyLink:
        "https://open.spotify.com/show/314iBnXRGGAuttAsIcZeC5",
    applePodcastsLink:
        "https://podcasts.apple.com/us/podcast/reelcast-by-reelmetrics/id1652485625",
    iHeartRadioLink:
        "https://iheart.com/podcast/104029260/",
    cdcLink:
        "https://cdcgaming.com/reelmetrics/s03e07-incredible-technologies-with-dan-schrementi",
    
    transcript: `Nick Hogan:
Good morning, Don. How are things in your neck of the woods today?
Don Retzlaff:
Morning, Nick. Everything's fine, drying off. We had the hurricane roll through St. Louis yesterday and dumped about three inches of rain on us, but other than that, it's back to be 95 degrees here before we know it.
Nick Hogan:
Okay, so I take it the surf was pretty gnarly there in St. Louis?
Don Retzlaff:
Yeah, surf around the Mississippi is always fun.
Nick Hogan:
All right, well so today Don, I wanted do something a bit different. So, I wanted to combine just at the top here, a listener question with a news item. So, let's go ahead and start with the listener question but before I read it, let me say that we love to tackle any questions anybody listening may have, if you have a question about what we're presenting or something you'd like us to present, please drop us an email at reelcast@reelmetrics.com. Again, that's R-E-E-L-C-A-S-T@reelmetrics.com. Our policy is to keep all questions anonymous, so please speak directly and don't worry about us revealing your identity, that's not something we do.
So, this question actually came in several months ago and it was from an operator in the American Northeast and it reads, "Hi, guys. I'm operating in a highly competitive market, which in my view has gone way overboard with free play. I'm curious if you've done any research related to how dialing back free play impacts player visitation and spend. Thanks, and keep up the great work."
So first thanks to the listener for that question, much appreciated. Sorry it took us a while to get to it, but Don, I remembered that question earlier this week when I spotted a new study in UNLV's Gaming Research and Review Journal, and for anybody who's unfamiliar with the journal, you can find it online at digitalscholarship.unlv.edu/grrj for Gaming Research and Review Journal. So this study was just published and has the self-explanatory title, Measuring the Impact of Reduced Free Play Offers on Casino Loyalty Behavior. It's published by Anthony Lucas at UNLV, a name we know, he's done a lot of interesting studies, and Katherine Spilde from San Diego State University.
So they opened the study by quantifying the scale of the practice and they cite three markets on this front. In essence, how much free play is being issued these days? So, three stats. In 2021, Pennsylvania accepted 425 million of free play wagers, which represented 25% of total state slot win for the year. 2020, Resorts World New York reported 117 million in free play wagers, which represented roughly 18% of total annual slot win. And in 2020, a Vegas strip reported that free play was roughly 24% of its total annual rated slot wins. So that's around a fifth to a quarter of the win, which I'd say sounds pretty standard. Don, would you concur on that one?
Don Retzlaff:
Yeah, usually we see between 15 and 25%.
Nick Hogan:
Okay. All right, great. So the experiment basically went like this, so they selected a tribal property in California and dove into the loyalty program. They selected 400 lower tier players who had standing free play awards of $15 per week. So I guess we're talking about, that's a pretty low ADT, but it gives you an idea, 15 per week. Okay, and so those 400 players were then broken into four groups. So group one stayed at $15 weekly free play, group two was demoted to $10, group three demoted to five and group four demoted to zero. So from there they monitored the visitation and spend of each group over a 180 day period.
Fast forward to the results, nothing happened. So regardless of the change to the free play levels, visitation and spend remained completely unaffected. So it didn't surprise me in the slightest, and Don, I'm guessing you're not overly surprised either by that. But as we both know and as the researchers themselves acknowledge in the study, it would've been more interesting to conduct this test in the loyalty program's upper tiers, obviously where the bulk of the free play is rewarded. But realistically, of course, as we've talked in here many times about, nobody's going to experiment on those players, they're just too few of them and they're way too valuable, so there was that.
But I suppose what's really most interesting to me here, Don, is that in all the research and experiments that we conduct, free play is never a factor because it's one of the first controls that we impose. We say no monkeying with free play levels while we're testing or you'll contaminate our data here, so, but what sticks with me here is how we visualize to our clients the impacts of these experiments when we finish. So invariably we have this, it's a line graph that's essentially flat for let's say two to three years, pre-test. There's some kind of marker showing where our experiment started and then blamo, there's this hockey stick jump in going to a new plateau. So, let's say we work on this stuff where we modified inventory so we just made these very modest, highly targeted inventory modifications and then we saw Theo win incrementing 30 to 150%. It was just kind of like, that's the way that this went.
However, in those flat lines preceding our experiments, we know full well that they were monkeying with free play levels throughout those periods, and what do we see in those lines? Well, the occasional three or 4% blip, maybe something like that but to me this really tees up the question. It's, what exactly are we trying to achieve with free play? Because when I see the impacts of our inventory mods and compare those to the impacts of the free play mods, I can't help but feeling that what we're doing here is just like discounting crappy experiences, right? So it's, sorry we couldn't give you the experience or also known as product that you were after, but here's some free money which they'll spend of course, no problem there, but obviously if you want them to open their wallets and spend their own money, you really need to deliver the product that they're after.
So in other words, I get competing in the mailbox, I get the incentives to drive visitation, but it just strikes me here that all we're really doing is treating a symptom in lieu of the disease and it's really from what we've seen, really more about the imbalance between supply and demand, that's really the problem. And if we're dumping margins into just subsidizing and perpetuating that condition, I don't know, to me it's just, it's bad science and I can't help question what we're doing there as an industry. And something really tells me we're going to need to do a full episode on free play. I guess my question Don, is, am I missing something here in all of this? Do I have the wrong view of this?
Don Retzlaff:
Well, free play is such an interesting topic. I've been in part of, I can't even tell you how many different tests for free play that we did at the casinos, moving them up, moving them down, trying to find that right balance, trying to find that right rebate percentage and the impact on hold percentage.
There's just so many variables that you have to consider and there's so many good marketing people out there that disagree on free play and it's so impactful to the bottom line, but there are a lot of companies that just compete in the mailbox or in their email box for free plays. They know in a competitive environment, if I'm giving you 50 and somebody else is giving you 35, I've got a decent chance of getting your play, and it's really tough to stop that and it's a gamble to stop that. But we've shown that if you have the right products, that at the right whole percentage, you don't need to spend that type of money and your margins do improve.
And that's where I keep going back to, is making sure you have the best games. You'll see a lot of casino management teams saying things like, our games are the same as our competition. And you really can differentiate yourself by having the best games in the market, by having them in quantity, and then you don't have to subsidize your players. Well just think, you're probably, if you're at 65% rated or 70% rated, you're still missing a third of your customers. They're not getting that experience, they're getting a higher hold percentage, they're not getting anything in the mail. The new customers who walk in the door, their experience is different. So that's why I think it's got to be a balance between the right product, the right hold percentage, and the right marketing efforts. It's super tough to get right.
Nick Hogan:
Yeah, and it is a really complex topic and that's why I'm thinking at some point we're going to have to get somebody in here who's a master of free play science and get down to the bottom of there, but okay, fair enough. That's enough of that for now, and we can move on to today's guest. Again before we jump in, thanks again to the listener for that question and well, thanks to Anthony Lucas and Professor Spilde for this study. It was quite interesting.
So today's guest, so Don, you're an Illinois guy and I know you're an Illinois fan, and this dude is as Land of Lincoln as it gets. He did his undergrad degree at Illinois State. Incidentally, my nephew is currently enrolled there, and then his MBA at Northern Illinois University. And Incidentally, Don, he also references Abe Froman on his LinkedIn profile, and I don't think it gets any more Chicago than that, so.
Don Retzlaff:
[inaudible 00:10:11].
Nick Hogan:
Yeah, so after beginning his career in the ad space, including one year with the Chicago White Sox there, Don, he made the leap into gaming, starting as a marketing associate with the Chicago area slot and arcade game supplier called Incredible Technologies. So 20 years later, he's running the company's entire casino gaming vertical there at IT. I'm referring of course to IT's President of Gaming, Mr. Dan Schrementi. So Dan, how are things in the windy city today?
Dan Schrementi:
I'm doing great, that was a nice intro. Things are good, we got the remnants of a hurricane coming through here, but nobody's complaining, at least it's not hot.
Nick Hogan:
Oh, you guys in the Midwest bitching about hurricanes? I don't know what's happening here. So Dan, now I think, couldn't find anything on this, but you're a Chicagoland native? Did you grow up there?
Dan Schrementi:
Yeah, I grew up about an hour south of Chicago, and I always say it's kind of like the brackish waters of Chicago, it's where the farm and the city mix together. So it gave me a good perspective, an even keel perspective, which is one of I think the best parts about Chicago people in general. We're a big city that doesn't take ourselves too seriously.
Nick Hogan:
Indeed.
Dan Schrementi:
And yeah, it's in my bones and in my blood and I live in the city now and work here at IT for, as you say, 20 years, which is hard to imagine. But being in the gaming space and being here, which has this history of amusement and a history, Chicago's got a lot of history in the gaming space.
Nick Hogan:
For sure.
Dan Schrementi:
But there's not many of us left. We view it as just one of the positives of the company, it's just one of the unique parts about us.
Nick Hogan:
Yeah, gotcha, gotcha. So, tell us a bit about your gaming career. So how did it start and what's a path that led you to basically running all of gaming there at IT?
Dan Schrementi:
Yeah, it's an interesting path. When I graduated college, I was a marketing person, a marketing degree, wanted a job in sports marketing, worse than anything that I could get. I'm a lifelong diehard Chicago White Sox fan. I had this theory, I'm only going to apply to places that I like.
Nick Hogan:
Sure.
Dan Schrementi:
And I was like, I love the White Sox, I get this opportunity to go and it's a one-year contract. They extended it for, I think I went 16 months and it never came to fruition to turn into a lifetime of a full-time job, but also a peak into sports really wasn't exactly what I wanted, what I thought it would be.
Meanwhile, I'm an avid Golden Tee player, all through college cart carrying, tournament playing. I used to skip dates with my wife, now wife, to go play Golden Tee. I mean, when my friends from college found out that I got a job marketing Golden Tee, they were like, you really did it, man. So I come here for the interview and I pull my Golden Tee card out and they're basically like they needed somebody to run tournaments for Golden Tee and websites and podcasts and things like this, 20 years ago.
I took the job, my first major project then was this big tournament series where we were going to have 50 Golden Tee machines and a couple hundred players come play for a lot of money, and I made this pitch to say we should have all those tournaments at casinos because our players liked to gamble back then. So I go to Turning Stone, Tunica, The Grand, I think, The Riviera, a lot of casinos that no longer are here that I'm saying, and a couple other places, Hollywood Joliet, we have these big tournaments, but part of it, I had to go to each of these and plan them. So I'd spend the week there and I had no money and I became an avid penny slot player. I just loved penny slots, I love WMS BB2 is like my sweet spot of play. Even in my camera here, I have Dean Martin's Wild Party behind me because it's my favorite game. It taught me everything about slot machines, I always say that.
So that, a few years later, the company here decides the bar market's not what it once was for amusement, we think we're going to take our skills and transfer it into gaming. Oh, that'll be easy, they say, sure as hell it wasn't. And they say, hey, does anybody here know how to do marketing for this and product? And I'm like a little kid. I'm like, I love slots, give me a shot. And essentially from that moment forward, I was a part of the team that gaming was a new upstart here at IT. I jumped off of the Golden Tee amusement team and basically became a product person from the ground up through the lens of a player and a person who's in the market a lot.
So I'm now from there I became head of sales, head of marketing obviously, but over time, Elaine and Richard, the founders of the company, they wanted to kind of take a little bit of a step back. They go, what better type of person to manage and protect what they've built for almost 40 years, than one, I've been here and I understand know the company, but two, I live and breathe the industry through our customers and through products. So I can't say I was a seasoned CEO or president prior to that, so I've learned a lot of that stuff the hard way, but what keeps me coming to work every day is I love the games and I love the people and our business.
Nick Hogan:
Great, cool. Well now you touch on some things there, which is really, IT has quite a fascinating history. And so you mentioned the amusement side and Golden Tee, and I know that this year you're actually celebrating some big anniversaries-
Dan Schrementi:
Yep.
Nick Hogan:
... for some stuff? So maybe can you kind of walk us through those? Well, and so you mentioned Golden Tee, which is a golf game with a track ball instead of a joystick, which I think was a huge innovation and whatnot, but can you walk us through those early days and explain how they yeah, well just tell me how they did venture in there.
Dan Schrementi:
Yeah, when Elaine and Richard founded the company, which will be 40 years in 2025.
Nick Hogan:
Wow.
Dan Schrementi:
So we'll be celebrating our 40 year anniversary. They were doing work for hire for companies like Data East Pinball and Capcom and Epics and companies doing computer games. At that time, arcades were top dog companies, companies that we now work with a lot of people in gaming today, that Bali and places like WMS, Midway.
Nick Hogan:
WMS, for sure, yep.
Dan Schrementi:
Were the kings of that. Arcades started to crumble when home consoles became more popular. This company took a, we call it surprising innovation, it's kind of a core culture of ours. It's, we take this clever approach, but a lot of times it's driven out of absolute need. The need of this company at that time ago was, hey, how can we make cheap kits that our operators would be happy to operate and maybe put them somewhere else besides the arcade where we can't compete with Midway?
So they were making games for what became the bar market, at the time. You're competing against the pool table and the dartboard and the jukebox. When the arcades crumbled. The bar market was kind of the only thing left standing and meanwhile, another core culture here is it's all about the game, is a thing we say all the time. We, I say we, I was a young kid at the time, developed games like Golden Tee Golf that were simple to play and really difficult to master that had a deep level of competition that you could go deeper if you wanted to play live tournaments, which was incredibly innovative. In 1994, they were-
Nick Hogan:
I mean, yeah, you guys were way ahead of the curve there.
Dan Schrementi:
Way ahead, they hooked. AOL was just coming online and Incredible Technologies had a call center to send modem data back and forth to have live leader boards for money, for skill prizes. And I think about that and that was way ahead of its time. So, that's kind of why the game in the late '90s had this explosive growth because the bar market at the time was rocking and rolling.
It too suffered though, when the iPhone and mobile came out. On top of that, smoking bans went into effect, drunk driving laws got much stricter and they should, that's a good thing. There wasn't as many bars as there was in the '90s, in the early 2000s, which kind of... So this company, while it may, continues to this day to be the largest player in the on-premise entertainment market in the bar market, decided with these people that we have here who know how to make hardware and software, well, let's figure out slot machines. Kind of the same thing, at least on the outside until you dive way in, there's a lot of differences in this business. So that's how the path happened and to this day, we operate together. We're under one umbrella. The amusement guys are right down the street from me here in Vernon Hills, but yet we work together on a lot of things.
Nick Hogan:
Gotcha. Well, it's totally interesting. So now I'm thinking about this retroactively, because we were talking about when I was a kid, I was playing Capcom bowling, I loved it. But now that I think about it, it was always, I played the bowling and Golden Tee, always in bars. And half the times, as I recall, it was like a tabletop cabinet with two player, yeah, okay, so it was like-
Dan Schrementi:
Yeah, [inaudible 00:19:59] version.
Nick Hogan:
Yeah, it had the community element to it, tabletop for a bar. I mean, it's brilliant, right?
Dan Schrementi:
Yeah, it's turn based. It's take a shot, drink, take a shot, take a break, it's social. Where everything [inaudible 00:20:12], video games back then was concentration.
Nick Hogan:
Absolutely, yeah and it was social because as I recall, we played a game, it was called Beer Frames, and basically it was based on the bowling and then who had to buy the next round.
Dan Schrementi:
Yeah, it's lighthearted, it's fun and it stands the test of time because people want to have fun.
Nick Hogan:
Yeah, gotcha, gotcha. So, and I would, so with that arcade DNA, how do you see that finding its way into your slot games then?
Dan Schrementi:
Yeah, it's one of the unique parts of IT. So like I said before, we're still in Chicago area and part of the reason why is we have a unique batch of really smart people here who want to live here. They're from here, they make games, they work in an environment here that they like, but they're brilliant long-time programmers, coders, artists, animators, who just happen to live in Chicagoland. We have a list of people here who, we have one guy who's been here I think 30 years, who was the lead programmer on the arcade game Joust.
Nick Hogan:
Oh, wow.
Dan Schrementi:
We have one guy here that's one of the top Pinball engineers known in the Pinball world, and they work here applying their skills to making, for instance, the platform for our gaming system or the stuff, the same mindset that it took for us to build Golden Tee, into that. But additionally, our Head of game design has been here 25 years, he was one of the lead guys on the Golden Tee project. Larry Hodgson, one of the co-inventors of Golden Tee is the Head cabinet designer, Head of engineering for IT. A lot of the same brain power has been applied to figuring out how to become a gaming company.
I will say, we had some naivete at the start about how hard all the other gaming stuff, and I say gaming stuff, compliance, regulation, math.
Nick Hogan:
Oh, yeah.
Dan Schrementi:
Psychology, retail, like all this other stuff, we had to learn, sales. So we spent the better part of the past decade figuring out how to do that easy stuff and now we're set up to be a competing, one of the only privately held gaming companies going against all these giants.
Nick Hogan:
Oh, sure. Yeah, and you mentioned to me recently, you said you had that last couple of years you guys have gone through what you described as a strategic rebuild. So can you maybe walk us through that?
Dan Schrementi:
Yeah, we're not shy about it at all. So basically, mission number one on gaming for IT was, figure out how to make a good game and get games out in the market. That took us 10 years.
Nick Hogan:
Sure.
Dan Schrementi:
We had a great run 2016, 2017 through basically up to COVID, 2020. We made some failures right then at that COVID era. We didn't anticipate the COVID and the supply chain crunch that was going to happen, but also, I take a lot of accountability publicly to say we didn't change our playbook to be the company that you need to be next. So it's one thing to be the company where you've got none of our games and now you've got two or three banks of them. It's another thing to say you've got two or three five-year-old banks of our games and you're going to buy two or three more banks hopefully a year of our new games. You throw COVID into the mix, the work from home, the supply chain crunch that happened, and we didn't pivot quick enough. We built a complicated, expensive cabinet that was hard to get parts for when the world didn't want that. We weren't ready to serve the modern type of player that was now out there that wanted a more gambler centric experience. A lot of those entertainment players didn't come back after COVID. The bells and whistles throw them off.
So all that said, last year I spent the better part of a year. We brought in help on product management, all the same product leaders making the same games, we have a very receptive team here, but we brought in help there. Some blocking and tackling work, new CFO, new Head of Operations, new HR, none of it is a knock on where we were. It's just, we needed to reinvigorate ourselves here a little bit.
And then it was really rebuilding the trust out in the customer market and telling our customers, you've worked with us for a long time, you know we can pivot fast because small, you trust us and I'm thankful. It's one of those things I'm most thankful for, for what we are as a company, our customers do have a very close relationship with us and they do trust us and they gave us that shot.
And so with that, we developed the Prism Element cabinet, which was designed for an avid player, very simple cabinet. It's not a lot of lights and bells and whistles, it's gambler style games with modern features with clever twists on them, and it's been a hammer since it's come out. It's one of the top performing cabinets in the country, throughout three to four months so far this year, with top themes on it. But that part aside, we're just proud that we put a plan together, we acted within a year and did it.
We've had some other bad luck prior to that too. We faced a cyber attack, which a few other companies have too. It's like whatever could have gone wrong, went wrong, and a lot of resilience here, we call it Chicago grit. It's a lot of our people hunkered down and said, we can lay down and cry about it or we can just tell the market the reality, which is, we're going to make you the games you want and we're going to live up to what we say, which is fair share based growth. Meaning, we're not going to force you to buy these games, they got to be worth it to you. And we're set up this year going into G2E season on really great footing with a great roadmap and a lot of new stuff that we're pretty pumped about.
Nick Hogan:
Okay, cool, and I know Don has plenty of questions about that stuff. I can see him chomping at the bit with product questions. So Don, you want to dive in?
Don Retzlaff:
Obviously I've bought your games in the past and had great success with them, and one of the things I always liked was it was very innovative when it came to bank design, from great displays. I remember buying the Infinity U23s and having that wonderful display above it, to the Sawtooth banks. I'm a believer in content selling games, but do you feel that the bank designs that IT has developed over the years has had an impact on your sales?
Dan Schrementi:
Yeah, so it's a great question and near and dear to my heart. I'm a big proponent of retailing in the casino space. I view it-
Nick Hogan:
Thank you, thank you.
Dan Schrementi:
I view it as, it's a retail environment and I always say retail is a war, it just is, we're competing for eyeballs. That was part of our strategy. The other part of it was we like to be disruptive and clever in our innovation, so you talk about the Skybox was the first kind of edition we made. The clever innovation on that was, we patented a way to put the poles in the cabinet so that you didn't have to buy a sign package and you could just have this giant game. The Sawtooth, which was the next level, what you're talking about, where we put these edges in between the games and it allowed us to do these different shapes. The approach with that was similar. It was, what's something that when you walk through this place, it'll just visually strike you as, well, that's different? And then maybe give the operator a new kind of canvas to paint on, put advertising or do something with. And we're still doing a lot of these things to this day. We now have our flex edges, we have our new banner sign. We're trying to make all of them attainable and cheap but useful.
But to your question of, could we track exactly that those designs and those shapes attributed to an increase in performance? Sometimes the answer was yes, but a lot of times, like probably the work you guys do, sometimes the answer was no. And then you go, well, why was that? Well, what we learned was it's true benefit was, it got the customer excited about installing our product. It got them thinking about our product in a different way than everybody else's product. It got them thinking about, well, I got this cool saw tooth shape or this pinwheel shape, maybe I'll put it somewhere a little bit better. So what we kind of attribute that to is, I think it got us better positions on the floor.
Now, on the flip side of that, the Skybox for instance, looked great on a wall because it's tall and had these huge screens. So we would've customers on that box go, hey, I put you on our wall of death, which I've heard more than once. I put you on our wall of death and it's doing pretty good. And then we'd be walking around with a salesperson going like, wait a minute, we're on the wall of death. That's not going to be good in the long haul. But it's balancing those two things. I think at the end of the day, it really is more about position than it is about the shape. And to your point, exactly Don, the game is the game. No shape on the planet is going to make somebody play it again if they didn't like it the first time. So we've learned to not lean on that too hard, but we've also learned that if there's ways to get the customer excited about a better position on the floor, then we're going to do those things.
Don Retzlaff:
Well, you've been very deliberate on bringing new cabinets to the market, which as an operator, I loved. I wanted one good cabinet, a lot of good content on it, that was perfect, instead of obsoleting cabinets consistently. So what's the trigger for introducing new hardware? Do you get to the point in theme development where you have to have a new cabinet to portray the themes in its best light, or is there a different trigger for introducing a new [inaudible 00:30:35] cabinet for you?
Dan Schrementi:
That's a good question and one that Incredible Technologies probably approaches differently. I think for the bigger companies, the trigger is new hardware sells games, so you have to launch enough things to keep people excited to buy the new thing. Where for us, we're more boutique in size. We want to make all great quality content and kind of lean less on that quantity. We believe it's more about lifecycle management here. For us, how many VXPs is the right amount of VXPs on a floor to protect fair share? Well, that should be the target for how many we're going to sell. How many elements should there be along with that? Well, maybe then you can sell two types of cabinets at once, but you still can't go over that fair share.
So right now for us, the other twist we're making in there, and the change that's happened in the industry is, a lot of stuff is portrait based. Well, does that portrait content, how many portrait cabinets are you going to launch at once? I mean, how many shapes can you possibly do? One of the major changes we made in our development roadmap here is, to help support the older cabinets, let's try to design more content that can run on different cabinets.
Don Retzlaff:
With kind of a lighter footprint, logically.
Dan Schrementi:
And this is not probably a popular thing to say out loud in the industry because it's like, of course you're going to reserve your best stuff for your newest cabinets, and I think that's okay, you just got to be honest about that to the market. The new cabinet gets these themes, our product management team will help manage what other themes can help support the older cabinets. When we made that change, we saw a big difference in where we're concentrating our efforts because you don't want to be paying your ACE game development team to content for the old box that somebody's going to want for free. You want something new that's great because that's going to carry over to those other boxes too.
So, long-winded answer to your question, it's kind of based on those two things. It's fair share for us and what's the real life of that box, and it's, how do we keep it supported after you've bought it for enough years so that you're happy with the investment.
Don Retzlaff:
Okay, well that makes sense. I love that, and it leads me to my next question, which is, one of the neat things I've got to do at ReelMetrics is I've got to study theme performance over a long period of time. And what we've seen in the past is games usually fall off about 10% a year every year. There are exceptions to that, and IT games is actually one of the exceptions. You've got games that have been out on the floor now for 8, 9, 10 years that are still doing a roundhouse average, which is really impressive. There's just a handful of those types of themes out there. There's some classic ones from Aristocrat, for instance, on the old Mark cabinets, Mark Five cabinets that do really well, but you've got games out there that are classic games like Crazy Money Two, that still are doing... I just looked yesterday, they are still doing house average at most of our clients. So, what's your philosophy on bringing those older titles to your newer cabinets or updating those older titles for the newer cabinets?
Dan Schrementi:
Yeah, it's changed over the years and it's weird because we're fair share based in our mindset, that helps us when the floor is at 1.0 or near it for an old eight-year game because the customer is going to still look at it that way. Unfortunately, it doesn't really help us sell a lot more new games. You don't get a lot of credit for that 1.0, eight years later, which is kind of weird, but it's true. I'd say we've kind of gone through different mindsets for bringing that kind of content forward. You can definitely do it too fast, too much, and you can definitely overmatch it to the original.
So, we have a new cabinet coming out at G2E, and we are bringing Crazy Money. Essentially what started as Crazy Money Deluxe back. It's time, it's been like five years. We've given it a pretty big gap, but also, we're adding into it a modern feature. This is one of the changes that we've made to this mindset. It's like, how do you keep the game at its core, all the parts of it that Crazy Money player are going to like, but then how do you add the new thing that's changed in the ethos of gaming in the past five years? There's going to be something that they're going to expect to be in there and not just be that same old game. So you'll see from us that we're going to be using that a little bit more sparingly and usually adding some type of new twist, a meaningful twist to it, where in the past sometimes we would just add a little twist to it and that's usually not enough.
Don Retzlaff:
Awesome. When I look at what you've done with Golden Tee and your slot machines, the electronic table games market, I was not surprised that you entered that. It seemed like a natural fit for you. How's the development on that sector gone and are you looking at things beyond blackjack and roulette, craps?
Dan Schrementi:
Yeah, so an opportunity had popped up for us over COVID. The manufacturer, Zoom in Slovenia we're looking for US partners who understand the market and they were perfect partners for us because they have a similar mindset to us. They like disruptive technology, they like going against the big dogs, they're good at what they're good at, which was designing the hardware for electronic table games, and we're good at bringing it to market and then adding these other twists to it.
We learned right away for us, we started with a roulette product in class three, difficult to go up against the big dogs in electronic table games straight up, who can lower the price, who can get it if they have a bigger footprint of salespeople, all of that stuff. That really was never part of our plan. We wanted to take that product and come up with something disruptive and in our eyes, the tool for that was class two, we're the first class two electronic table game with physical balls in a roulette wheel. We've had great success with that product in niche markets, Washington, Texas, places where they otherwise wouldn't be able to have roulette but now they have this bingo based version of roulette that kind of plays very similar. It looks the same to a player, but plays as bingo.
Nick Hogan:
So Dan, do you even offer a class three variant of it or is strictly class two?
Dan Schrementi:
We offer a class three variant of it, but we're not really focused on, it's more of a quality install opportunity for us. So, we're looking for places that are hungry to be partners with it, they're excited about putting this product in first, and in return they're getting a better yield out of their player and we're getting a better yield out of that player, out of that market too. As opposed to, we don't want to be in a race to the bottom.
Nick Hogan:
Sure.
Dan Schrementi:
Which a lot of the class three, there's a lot of that going on. So we also, we launched a version of the game using balls, basically kind of what looked like bingo balls on a trampoline instead of the roulette wheel. It's called Fastball, and it's also out in Washington right now on test. And part of the reason is there are some markets, some class two markets where they still don't even want to see a physical roulette wheel. So it plays just like roulette, but it uses black and red ball with numbers on it, and green, just selected on this little trampoline and on a stand and actually plays twice as fast as roulette.
Nick Hogan:
Nice.
Dan Schrementi:
So we're seeing really good results out of that to start, and our hope is to find more markets, more tribal markets where we can put this out. And that product we may put out in class three as well, if we see that it can handle it.
Don Retzlaff:
Okay, well the banks look great and I love the different configuration options for it. I thought it was a solid look in banks, so very much visually appealing.
Obviously, I've bought IT games in the past and enjoyed the product. I would say that we were looking at probably two to 3% market share for your penny video over the past few years. Does the company have a goal for market share? Is 5% reachable for you in the next few years?
Dan Schrementi:
Yeah, it's a good question. So we've analyzed our share, we're in every market in the US and Canada, except for Missouri and Maine, pretty much. Missouri, to be honest, is back on the hit list for us. So we've got access to most of the market. We're also not in Washington, non class two. So if you look at that and say, all right, could IT handle... We've always targeted about 3% of that market, which is close to a million machines in total in that space that I just said. We've always targeted about 3% as our target as a company. To achieve that, we need about a five to 6% ship share per year, which we fell behind on in those years that I talked about with the rebuild and the trouble that we've had. We believe that's the sweet spot for IT, and if we can hit that rate and then you add in the attrition that you get of the old games coming off the floor at all times, it's probably going to sit somewhere between three and 4% over the next five years. And I believe we're built for that size.
I think one of the weird things in our industry is when our competitors, including ourselves, go, no, I want to be at 7%. It's always like the little guys who want to be the middle guys and the middle guys who want to be the big guys. When really, if you could just be great at the segment you're in, there's profit and there's a business to be had for everybody inside that space. So we don't aspire to be competing. Our goal is not to be the biggest gaming company in the country. There's a lot of great competitors that are really good at fighting for that space. I don't know that our goal is to really be in the next tier. We want to be the best at what we do. We want customers to want our games and players to seek them out, and we want to create a lifestyle and a culture for our employees where they share in on that winning kind of of attitude. And I think at three to 5%, that's the great size for us.
Nick Hogan:
Well, I have to say your analytical paradigm approaching this is, I don't think I've heard anybody on the supply side say fair share as many times I've heard you say it today. So-
Dan Schrementi:
Here's thing-
Nick Hogan:
But it's critical, yeah, and it's really a healthy way to look at this.
Dan Schrementi:
If you had talked to me eight years ago when we were writing the first roadmap, we were obsessed with saying fair share back then because we were growing like crazy and it was super fun to say it. I've now lived through fair share being negative. We had to do it appropriately, had to be accountable for the fact that you said fair share and your fair share is not positive. And you go, you're right, we got to get it back there because at the end of the day, why else would you want to buy these games? You know?
Nick Hogan:
Yeah, yeah, yeah.
Dan Schrementi:
So we've lived through that and what Incredible Technologies wants now is a chance that, hey, we did what we said we were going to do. We've developed the games that we said we're going to develop. We're coming to the trade show with a great roadmap that we know is based on a lot of hits that we've been making. We know we can sustain that footprint, you got to give us a chance, and that's where we're at.
Don Retzlaff:
You got to be happy with the results on the element, especially the stuff that we released this year. Lotus Link, the Stackup Pays, Treasure Lock. All those games are doing well over one and a half times house average and most of them were over two times house average at our clients, and they're off to a great start. And we've talked about in the past, especially with some of the smaller vendors, having single theme or dual theme banks where you've got a bank of six and you've just got Lotus Link on that bank and they're doing really, really well. And up and down our client list, I'm looking at this and doing two and a half times house average, 2.9 times house average. Those are fantastic numbers, and heck, they beat a lot of our least gain performance that we [inaudible 00:43:39]. So you got to be super happy now.
Dan Schrementi:
And it's on a very simple cabinet and it's a very purposeful design where we spent more time pulling features out of the game than putting them in. The sounds are toned down, the count up are toned down, the graphics are simplified. I think Aristocrat was the masters of this over the years. They figured it out first. It isn't easy, all of our companies have incredibly talented artists and animators and sound engineers. They can all make games that can compete on consoles, Xbox, whatever, you name it. Gaming is about restraint and luck and the gambler feeling like they're getting that experience they want, and the math matching to how it looks. And a lot of the work we've put in in the year is under the hood on that kind of stuff. So we've never had gambler style avid player games do well in the time that I've ever been working at IT. We had more of the entertainment, arcade, retail type of stuff doing great. This is a big moment for us.
Nick Hogan:
Yeah, I think that we had discussed a bit ago that Stack Up Riches, right? Is that, that's the-
Dan Schrementi:
Stack Up Pays, yeah, there's two different versions in there.
Nick Hogan:
Okay. That's one of these I was looking at, extremely volatile game that was just looking really solid, in terms of performance, so yeah.
Dan Schrementi:
Yep, and Stack Up Pays, the two titles we have, it features true persistence in it, which is a contentious topic in our business right now, but we think we kind of hit it on the sweet spot of, you got to put these kind of features in there that are going to attract these players who think they're essentially pro players players, but the game is, it's a volatile game. So we've had a lot of success with that title. I think we're just now starting to hit your year lists and things with it, and you're going to see more innovations from that coming out. We have a game coming out that's similar to it on the VXP cabinet that we're, it's called Ascending Fortunes, we're all pumped up about, that's just hitting the market now, which we think it'll go really nice with the monitor that goes up and down and-
Nick Hogan:
Yeah, that's cool. It's a neat little twist there. Yeah, so the monitor grows vertically, right? when you start stacking, yeah.
Dan Schrementi:
Yeah, as the pots fill, yeah, so it's pretty neat.
Nick Hogan:
Yeah, cool. Yeah, yeah, and well, yeah, let's talk about that a little bit, Dan. So Infinity, of course, everybody knows that it's all over casinos in North America, then you followed up with Prism. So maybe you can kind of tell us with that family, what did you learn from Infinity that's made its way into Prism, and how do you change that?
Dan Schrementi:
A lot of the hardware platform is shared, so the logic box and the OS that's on it, and some of the nuts and bolts that make the game work. The Prism, but the bottom half of the Prism, so basically the control panel on down, to us that's kind of like the hardware platform that we build on for the next five years. Whether right or wrong, coming through COVID, we were designing the Grand Slam product first, which was the Prism VXP that came with the motor that goes up and down, has the screen in front of the screen, which was a unique shape and a unique look that we wanted to toy with. The problem was, the supply chain issues being what they were developing a really complicated cabinet from our basements basically at the time. When you say, what did we learn? We learned a few hard lessons with that, that we had to redo this past year to go actually, how do we simplify this thing so that it can go up and down? For instance, the VXP, but a little easier or more elegant in its design. So there were some growing pains with that.
And the new cabinet that we have coming out at G2E this year will be a new Skybox cabinet that is built on the Prism platform on the bottom. But even in that, the Prism element, which came in between here, we really simplified the design. So it's like there's an iteration that happens all along the way where you design it the way you think you're going to design it, and then sometimes you have to go back to the drawing board and go, maybe this control panel needs a different hinge, maybe this, oh, operators are having a hard time opening this belly door. We want customers to think our tech's... We want to be the most tech friendly cabinet out there. And there were things we had to change to do that.
So yeah, in our eyes, I view the hardware platforms as a five-year run to build on, and then after that you maybe come up with something new.
Nick Hogan:
Yep, and then in terms of for that platform then, the game releases, how many games are you expecting to put out this year, for example?
Dan Schrementi:
It's a good question. We've got about 28 titles or so in motion at any given time. A lot of those can get transferred back to our old legacy cabinets, like the new Skybox that's in motion is going to come out with four at launch. We don't sell anything until we test it, which is another thing if we're going to live by fair share that we have to do. The Element, we've got two to four coming out per quarter to support it and to support its growth, and luckily there's not been a lot of misses in it. So a lot of the stuff we're making is very usable. So we've got a great roadmap protected for that.
And then the VXP right now has new stuff coming out for it, because ready for a refresh. Power Push was a great title, but it was a six to eight month run on that title. We've had great success with Mega Money Catch on the Prism VXP, which was in the family of Crazy Money, but very different. So yeah, the VXP, which is the cabinet that goes up and down, we were putting more entertainment style games on that box because of the novelty and we're kind of switching that strategy too, where it's now about to get more of the gambler style games. This is what everybody wants.
Nick Hogan:
Gotcha, gotcha. Okay, and then I also just had a question. I was down in Monaco several months ago and I saw bankier games down there, I believe. And what have you done internationally, markets? What are your plans there?
Dan Schrementi:
Yeah, so we partnered with Ludi SFM out in France, and they've been a great distributor for us in France and Monaco. And yeah, we're actually, so France is, probably outside of Canada, would be our number one international market. We've got a lot of games live there. The behavior of the player, very similar to here.
Nick Hogan:
Yeah, very much.
Dan Schrementi:
And then we're actually in the midst of going to Holland and going to Portugal, and maybe a little bit of Philippines too. But they're all kind of secondary, to us, it's got to be the right mix of player. We can't go to markets where the VLT style, the Novomatic style games are the number one games. It needs a retail style player.
Nick Hogan:
Yeah, yeah, yeah, yeah. Yeah, you guys aren't into the whole multi game [inaudible 00:51:07].
Dan Schrementi:
Yes, yeah, yep.
Nick Hogan:
That's okay, we're behind you on that one.
Dan Schrementi:
Yeah, they don't get to send me to Monaco anymore to go check on those games, but I'd like to a big race.
Nick Hogan:
Well, that parking lot they have there, which is in the front, which is, you get all that crazy hardware, all those nutty sports cars. And then in the back, you have all the billion dollar boats. So it's just incredible place. Well, cool. Okay, so any products or upcoming events that you'd like to mention [inaudible 00:51:38]?
Dan Schrementi:
Yeah, for us, there'll be a big run of news coming out of us coming into the trade show for G2E. We have the new cabinet that is going to be revealed and launched at that show. We will be testing it kind of quietly in the background prior to that show. So, I'm really excited about that.
And then of course, we're going to be at OIGA. I'm not sure if you guys will be there, but we'll be there. We're exhibiting in GCGs or gaming capitals, excuse me, Gaming Capital's booth, but we will have our whole staff involved. And then, yeah really, this time of year becomes preparing for G2E. It really is a three month project.
Nick Hogan:
Yeah, it is sometimes. I think we were talking to Kelcey Allison in November of last year and he said, "Man, G2E is just around the corner." Man, we just finished. What are you talking about?
Dan Schrementi:
It does feel that way, and we've got a lot. So we kind of came up with this slogan this year as teed up because we're going to be bringing a sports bar essentially to our G2E booth.
Nick Hogan:
Oh, perfect.
Dan Schrementi:
Going to be revealing a lot of our corporate history is going to be up and for display, and we're bringing a Chicago style sports bar to the show because we want to talk about our 40th anniversary, we want to talk about the Golden Tee anniversary. We're going to have the original Golden Tee in the booth. But really, the teed up is also because we've got the right products, we've got the right cabinets, we've got the right staff. We are ready to rock, and this show has the most buzz for us, the most optimism that I've seen us have since probably when we launched the Skybox, the original Skybox.
Nick Hogan:
Cool.
Don Retzlaff:
Must visit booth at G2E every year.
Nick Hogan:
For sure.
Don Retzlaff:
I've been to 25 G2Es, and I would say that on a good percentage of them, your booth had the thing that I talked about the most when I left G2E, which is impressive, and especially considering how much space the Aristocrats, IGTs and like runners of the world.
Nick Hogan:
Not to mention the sensory overload of thousands of-
Dan Schrementi:
[inaudible 00:53:51].
Don Retzlaff:
I remember sitting at the Sawtooth bank when that first came out and I said, this is awesome.
Dan Schrementi:
We've tried to codify the culture here and we call that surprising innovation, which is just, it's like simple innovation that's attainable, it's... You can have innovation being 3D glasses and the tunnel of LED and all this stuff that's so expensive and huge and innovative. We try to do things that are innovative in their practical nature, and that's the Chicago way about us. I don't know, and a lot of it comes out of necessity. We're not going to be able to outspend Aristocrat or Light and Wonder, and I'm glad that they're doing it because they make our industry better by adding crazy technologies with they're doing all this stuff. But for companies like us, we want to be the one where when a slot operator or a tech comes in our booth and they see something small that we did, and they go, oh man, that was clever. Oh, that makes our hearts happy here.
Don Retzlaff:
And then plus you've got some of the nicest people-
Nick Hogan:
Indeed.
Don Retzlaff:
... in your organization. Always enjoy meeting with them in the office or at G2E, and so you've got-
Dan Schrementi:
I appreciate hearing that.
Don Retzlaff:
... [inaudible 00:55:06].
Nick Hogan:
Yeah, great crew. For sure, for sure. Okay, well Don, did you have any other questions for Dan today?
Don Retzlaff:
We better let him get back to work.
Nick Hogan:
Yeah, he's got to sell some boxes there.
Dan Schrementi:
Yeah, sell some boxes to pay for that G2E booth.
Don Retzlaff:
It's not cheap.
Nick Hogan:
Well, Dan, thank you so much for joining us today and absolutely, best of luck with everything. It really indeed, it sounds like you guys are indeed teed up. I think that's an awesome marketing message for sure. It just sounds great and certainly, what we're seeing there in that new platform and so we're seeing some really hot stuff in there, so we're liking that for sure.
Dan Schrementi:
I appreciate hearing that, guys. And like I said, thankful for being on the show and glad that you guys have a medium like this out in our industry. I think it's good.
Nick Hogan:
All you need is two people with absolutely no social life.
Dan Schrementi:
I agree.
Nick Hogan:
I guess Don has one, but. Okay, well, thank you so much for your time today, Dan. Really great to talk to you.
Dan Schrementi:
No problem.
Don Retzlaff:
Thank you, Dan.
Dan Schrementi:
Thanks, guys.
Nick Hogan:
Okay. Bye-bye.
Okay guys, smooth.
Don Retzlaff:
[inaudible 00:56:25].
Nick Hogan:
No edits. I'm feeling great. So, cool.
Don Retzlaff:
`,


};

export default S03E07;
