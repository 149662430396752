import React from "react";

const SingleTestimonial = props => {
    return (
        <div className="testimonial__item">
            <div className="container mb-xs-4">
                <span className="testimonial__quote">{props.text}</span>
            </div>
            <div className="container text-center">
                <span className="testimonial__author__name">{props.name},</span>
                <span className="testimonial__author__function">
                    {props.company}
                </span>
            </div>
        </div>
    );
};

export default SingleTestimonial;
